import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import IMix from '@/interfaces/IMix';

const isVariant = (
  item: IProduct | IVariant | IMix
): item is IVariant => {
 return (item as IVariant).key === "variant";
};

export default isVariant;
