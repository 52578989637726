import EGroupDeliveryCode from '@/enums/EGroupDeliveryCode';
import IGroupDeliveryDeniedResponse from '@/interfaces/IGroupDeliveryDeniedResponse';
import IGroupDeliveryShippingConfiguration from '@/interfaces/IGroupDeliveryShippingConfiguration';
import IGroupDeliverySuccessResponse from '@/interfaces/IGroupDeliverySuccessResponse';

const isGroupDeliverySuccessResponse = (
  groupDelivery: IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>
): groupDelivery is IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration> => {
 return (groupDelivery as IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>).code === EGroupDeliveryCode.Success;
};

export default isGroupDeliverySuccessResponse;
