import React, { memo, useLayoutEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import getTranlation from '@/helpers/getTranslation';
import IProductAttributeOption from '@/interfaces/IProductAttributeOption';
import useDimensions from '@/hooks/useDimensions';

interface IProductAttributeOptions {
  attributeOptions: IProductAttributeOption[];
  heightLimit?: number;
}

const ProductAttributeOptions = memo(({
  attributeOptions,
  heightLimit = 100,
}: IProductAttributeOptions) => {
  const { t } = useTranslation('common');
  
  const { 
    height,
    showMore,
    targetRef, 
    maskClassName,
    isPossibleShowMore,
    onShowMore, 
    getDiminension 
  } = useDimensions({ heightLimit });

  useLayoutEffect(() => {
    getDiminension();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeOptions]);

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={5}>
          {t('g.product_characteristics')}:
        </Typography.Title>
      </Col>
      <Col 
        span={24}
        ref={targetRef} 
        className={maskClassName}
        style={{ height }}
      >
        <Row gutter={[20, 15]}>
          {
            attributeOptions.map(attrOpt => (
              <Col key={attrOpt.id} flex="33%">
                <Typography.Text strong>
                  {`${getTranlation(attrOpt.attribute.label)}: `}
                </Typography.Text>
                <Typography.Text>
                  {getTranlation(attrOpt.label)}
                </Typography.Text>
              </Col>
            ))
          }
        </Row>
      </Col>
      {
        isPossibleShowMore && (
          <Col span={24}>
            <div style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}>
              <Button 
                type="link" 
                onClick={() => onShowMore()} 
                style={{ padding: '0px'}}
              >
                {showMore ? t('g.show_less') : t('g.show_more') }
              </Button>
            </div>
          </Col>
        )
      }
    </Row>
  );
});

export default ProductAttributeOptions;
