import { List } from 'antd';
import ICategory from '@/interfaces/ICategory';
import Category from '@/components/General/Category';
import getTranlation from '@/helpers/getTranslation';

interface ICategoriesListProps {
  options: ICategory[];
  selected?: ICategory['id'];
  onChange: (category: ICategory) => void;
  layout?: 'horizontal' | 'vertical';
}

const CategoriesList = ({
  onChange,
  options,
  layout = 'vertical',
  selected,
}: ICategoriesListProps) => {
  //   const columnAmount = layout === 'vertical' ? 1 : options.length;
  // console.log('selected', selected);
  // console.log('category.id', options);
  return (
    <List
      dataSource={options}
      grid={{ gutter: 12 , xs: 3, sm: 4, md: 6 }}
      className='pt-1'
      renderItem={(category: ICategory) => (
        <List.Item className="m-0">
          <Category
            onClick={() => onChange(category)}
            isSelected={String(selected) === String(category.id)}
            layout={layout === 'vertical' ? 'horizontal' : 'vertical'}
            icon={<img src={category.icon} alt={getTranlation(category.label)} />}
            label={getTranlation(category.label)}
          />
        </List.Item>
      )}
    />
  );
};

export default CategoriesList;
