import { Col, Row } from 'antd';

interface ILocationProps {
  icon?: React.ReactNode;
  hint?: React.ReactNode;
  content?: React.ReactNode;
  extra?: React.ReactNode;
}

const Location = ({
  icon,
  hint,
  content,
  extra,
}: ILocationProps) => {
  return (
    <>
      <Row align="middle">
        { icon && 
          <Col span={3} className="text-center">
            {icon}
          </Col>
        }
        <Col span={21}>
          <Row>
            <Col span={24}>
              {hint}
            </Col>
            <Col span={24}>
              {content}
            </Col>
            {extra && (
              <Col span={24}>
                {extra}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Location;
