import React from 'react';
import IWarehouse from '@/interfaces/IWarehouse';
import { Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import IScheduleDay from '@/interfaces/IScheduleDay';
import Day from '@/enums/Day';
import moment from 'moment';

interface IWarehouseSchedule {
  schedule: IWarehouse['schedule'];
}

const WarehouseSchedule = ({
  schedule
}: IWarehouseSchedule) => {
  const { t } = useTranslation('common');

  const getSchedule = (scheduleDay: IScheduleDay<Day>) => {
    let content: JSX.Element[] | JSX.Element = <></>;

    if (scheduleDay.isClosed) {
      content = (
        <Typography.Text className="m-0">
          {t('g.close')}
        </Typography.Text>
      );
    }

    if (scheduleDay.isOpen24hr) {
      content = (
        <Typography.Text className="m-0">
          {t('g.open_24_hrs')}
        </Typography.Text>
      );
    }

    if (scheduleDay.rangeSchedule && scheduleDay.rangeSchedule.length > 0) {
      content = scheduleDay.rangeSchedule.map(range => {
        const startTime = moment(range.startTime).format('hh:mm a');
        const endTime = moment(range.endTime).format('hh:mm a');

        return (
          <Typography.Paragraph className="m-0">
            {`${startTime} - ${endTime}`}
          </Typography.Paragraph>
        );
      });
    }

    return (
      <Row>
        <Col span={6}>
          <Typography.Title level={5}>
            {t(`g.${scheduleDay.day}`)}
          </Typography.Title>
        </Col>
        <Col>
          {content}
        </Col>
        <Divider className="m-2"/>
      </Row>
    )
  };

  return (
    <Row>
      <Col span={24}>
        {getSchedule(schedule.sun)}
        {getSchedule(schedule.mon)}
        {getSchedule(schedule.tue)}
        {getSchedule(schedule.wed)}
        {getSchedule(schedule.thu)}
        {getSchedule(schedule.fri)}
        {getSchedule(schedule.sat)}
      </Col>
    </Row>
  );
};

export default WarehouseSchedule;
