import React, { useEffect, useState } from 'react';
import { FormInstance, Form, Row, Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import IShoppingCart from '@/interfaces/IShoppingCart';
import DebounceSelect from '@/components/General/DebounceSelect';
import getShoppingCarts, { getSingleShoppingCart } from '@/services/ShoppingCart';
import EShoppingCartStatus from '@/enums/EShoppingCartStatus';
import IDebounceSelectOptions from '@/interfaces/IDebounceSelectOptions';


export interface IShoppingCartTransferData {
  fromId: IShoppingCart['id'];
  toId: IShoppingCart['id'];
}

interface IShoppingCartTransferForm {
  form: FormInstance<IShoppingCartTransferData>;
  initialShoppingCart: IShoppingCart['id'];
}

const ShoppingCartTransferForm = ({
  form,
  initialShoppingCart,
}: IShoppingCartTransferForm) => {
  const { t } = useTranslation('common');
  const [initShoppingCart, setInitShoppingCart] = useState<IDebounceSelectOptions[]>([]);

  const getInitialShoppingCart = async () => {
    const shoppingCarts: IDebounceSelectOptions[] = [];

    const shoppingCart = await getSingleShoppingCart({
      id: initialShoppingCart
    });
    
    shoppingCarts.push({
      value: shoppingCart.id,
      label: shoppingCart?.alias ?? '----',
      tag: shoppingCart?.owner?.name ?? t('g.guest')
    });

    form.setFieldsValue({
      fromId: initialShoppingCart
    });

    setInitShoppingCart(shoppingCarts);
  };

  const fetchFuction = async (value: string) => {
    const shoppingCarts = await getShoppingCarts({
      '$or[0][alias][$like]': `%${value}%`, 
      '$or[1][id][$like]': `%${value}%`, 
      'status': EShoppingCartStatus.Active
    });

    return shoppingCarts;
  };

  const refactFunction = (shoppingCarts: IShoppingCart[]) => (
    shoppingCarts.map(shoppingCart => ({
      value: shoppingCart.id,
      label: shoppingCart?.alias ?? '----',
      tag: shoppingCart?.owner?.name ?? t('g.guest')
    }))
  );

  useEffect(() => {
    getInitialShoppingCart();
  }, [initialShoppingCart]);
  
  return (
    <Form 
      form={form}
      layout="vertical"
    >
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item 
            name="fromId" 
            label={t('g.from')}
            rules={[{ required: true, message: t('validation.required') }]}
          >
            {
              initShoppingCart && initShoppingCart.length > 0 
              ? (
                <DebounceSelect 
                  style={{ width: '100%' }}
                  fetchOptions={fetchFuction}
                  refactFunc={refactFunction}
                  initialOptions={initShoppingCart}
                  disabled={true}
                />
              ) : (
                <Row justify='center'>
                  <Col>
                    <Spin size='large' className='m-4' />
                  </Col>
              </Row>
              )
            }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            name="toId" 
            label={t('g.to')}
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <DebounceSelect 
              style={{ width: '100%' }}
              fetchOptions={fetchFuction}
              refactFunc={refactFunction}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShoppingCartTransferForm;
