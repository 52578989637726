import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IDeliveryPointDto from '@/interfaces/IDeliveryPointDto';
import { adapterDtoToAddress } from '@/adapters/AddressAdapter';
import { adapterDtoToSchedule } from '@/adapters/ScheduleAdapter';

export const adapterDtoToDeliveryPoint = (
  deliveryPoint: IDeliveryPointDto,
): IDeliveryPoint => {
  const { 
    id,
    alias,
    isActive,
    receiverName,
    receiverEmail,
    receiverPhone,
    notes,
    address,
    schedule,
  } = deliveryPoint;

  return {
    id,
    alias,
    isActive,
    receiverName,
    receiverPhone,
    receiverEmail,
    notes,
    address: adapterDtoToAddress(address),
    schedule: adapterDtoToSchedule(schedule),
  };
};
