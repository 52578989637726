import React, { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import IOrder from '@/interfaces/IOrder';
import Ticket, { ITicketProduct, ITicketSubProduct } from '@/components/Ticket';
import moment from 'moment';
import { getSaleOrigin } from '@/services/SalesOriginsService';
import { getWarehouseNameById } from '@/services/WarehouseService';
import getTranlation from '@/helpers/getTranslation';
import { getFranchiseNameByWarehouseId } from '@/services/FranchisesService';
import useShoppingCartItemsList from './useShoppingCartItemsList';
import getMixToppingSelection from '@/helpers/getMixToppingSelection';
import IDeliveryProduct from '@/interfaces/IDeliveryProduct';
import IDelivery from '@/interfaces/IDelivery';
import IShoppingCartItemWithConfig from '@/interfaces/IShoppingCartItemWithConfig';
import { IDeliveryProductWithProduct } from '@/components/ThankYou/DeliveryCard';

const useOrderTicket = (order: IOrder) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { shoppingCartItems, loadingCart } = useShoppingCartItemsList(order.shoppingCart.id.toString());
  
  const refDelivery = order.deliveries[0];
  const refPayment = order.payments[0];

  const deliveryMethod = refDelivery.deliveryType;
  const address = refDelivery.address;

  const paymentStrategy = refPayment.strategy;

  const getTicketMixProducts = (
    deliveryProducts: IDeliveryProduct[], 
    shoppingCartItem: IShoppingCartItemWithConfig
  ) => {
    const mix = deliveryProducts.find(
      shoppingCartProduct => shoppingCartProduct.product === null
    );

    const mixProducts = deliveryProducts.filter(
      shoppingCartProduct => shoppingCartProduct.product !== null
    ) as IDeliveryProductWithProduct[];

    if (!mix) throw new Error('mix not found');

    const baseProducts: ITicketSubProduct[] = shoppingCartItem.baseProducts ? shoppingCartItem.baseProducts.map(baseProduct => {
      const mixProduct = mixProducts.find(mixProduct => mixProduct.product.id === baseProduct.product.id);

      if (!mixProduct) throw new Error('mixProduct not found');

      return {
        id: mixProduct.product.id,
        name: mixProduct.product ? getTranlation(mixProduct.product.name) : '',
        price: 'N/A',
        qty: mixProduct.qty,
        isExtra: false
      }
    }) : [];

    const toppings: ITicketSubProduct[] = getMixToppingSelection(shoppingCartItem).map(topping => {
      const mixProduct = mixProducts.find(mixProduct => mixProduct.product.id === topping.product.id);

      if (!mixProduct) throw new Error('mixProduct not found');

      return {
        id: mixProduct.product.id,
        name: mixProduct.product ? getTranlation(mixProduct.product.name) : '',
        price: mixProduct.price * mixProduct.qty,
        qty: mixProduct.qty,
        isExtra: true
      };
    });

    return {
      id: shoppingCartItem.id,
      name: shoppingCartItem.item ? getTranlation(shoppingCartItem.item?.name) : '',
      price: mix.price * mix.qty,
      qty: mix.qty,
      subProducts: [...baseProducts, ...toppings]
    };
  };

  const printAndOpen = async () => {
    try {
      setLoading(true);

      const salesOriginName = (await getSaleOrigin(order.salesOriginCode)).alias;
      const warehouseName = await getWarehouseNameById(order.warehouseId);
      const franchiseName = await getFranchiseNameByWarehouseId(order.warehouseId);

      const deliveryProducts: IDeliveryProduct[] = order.deliveries.reduce((
        deliveryProducts: IDeliveryProduct[], 
        delivery: IDelivery
      ) => [...delivery.products, ...deliveryProducts], []);

      const ticketProducts: ITicketProduct[] = shoppingCartItems.map(item => {
        
        if (item.entityType === 'mix') {
          const itemDeliveryProducts = deliveryProducts.filter(
            deliveryProduct => deliveryProduct.shoppingCartItemId === item.id
          );
          console.log('itemDeliveryProducts', itemDeliveryProducts)
          console.log('deliveryProduct.deliveryProducts', deliveryProducts)
          console.log('item.id', item.id)
          const res = getTicketMixProducts(itemDeliveryProducts, item);
          return res;
        }

        return {
          id: item.id,
          name: item.item ? getTranlation(item.item?.name) : '',
          price: item.price,
          qty: item.qty,
          subProducts: [],
        };
      });

      const pdfDoc = pdf(
        <Ticket 
          address={address} 
          ticketId={`#${order.id}`} 
          customer={order.customer} 
          products={ticketProducts} 
          createdAt={moment(order.createdAt).format('LLLL')} 
          salesOrigin={getTranlation(salesOriginName)} 
          amounts={order.amounts} 
          operativeName={franchiseName} 
          warehouseName={warehouseName} 
          deliveryMethod={deliveryMethod}
          paymentStrategy={paymentStrategy}          
        />
      );

      const blob = await pdfDoc.toBlob();

      const fileURL = window.URL.createObjectURL(blob);

      window.open(fileURL, '_blank');
    } catch (error) { 
      console.error(error);
      // TODO: ADD notification
    } finally {
      setLoading(false);
    }
  };

  return {
    loading: loading || loadingCart,
    printAndOpen
  };
};

export default useOrderTicket;

