import React from 'react';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useTranslation } from 'react-i18next';
import { 
  Row, Col, Typography, Button
} from 'antd';
import useBreakpoints from '@/hooks/useBreakpoint';

interface IShoppingCartsHeader {
  onAddShoppingCart: React.MouseEventHandler<HTMLElement> | undefined
}

const ShoppingCartsHeader = ({
  onAddShoppingCart,
}: IShoppingCartsHeader) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  return (
    <Row gutter={[20, 20]}>
      <Col 
        xs={24} 
        sm={12} 
        lg={6}
      >
        <Typography.Title level={4}>
          {t('g.shopping_carts')}
        </Typography.Title>
      </Col>
      <Col 
        xs={24} 
        sm={12} 
        lg={6} 
        offset={isBreakpointEqualsAndBelow('md') ? '0' : '12'}
      >
        <Button 
          className="md:float-right w-full md:w-auto"
          icon={<PlusOutlined className="fix-icon"/>}
          onClick={onAddShoppingCart}
        >
          {t('g.shopping_cart')}
        </Button>
      </Col>
    </Row>
  );
};

export default ShoppingCartsHeader;
