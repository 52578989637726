import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tag, Typography } from 'antd';
import IMixSection from '@/interfaces/IMixSection';
import MixToppings from '@/components/Mix/MixToppings';
import EMixLimit from '@/enums/EMixLimit';
import getTranlation from '@/helpers/getTranslation';
import { SelectedToppingsType } from '@/containers/views/ItemDetails/MixDetailsView';
import IMixTopping from '@/interfaces/IMixTopping';
import ToppingType from '@/types/ToppingType';

interface IMixSectionProp {
  section: IMixSection;
  loading?: boolean;
  selectedOptions: SelectedToppingsType[];
  onChange: (
    topping: IMixTopping, 
    sectionId: IMixSection['id'], 
    type: ToppingType, 
    value: {
      qty?: number;
      checked?: boolean;
    }
  ) => void;
}

const MixSection = ({
  section,
  loading = false,
  selectedOptions,
  onChange,
}: IMixSectionProp) => {
  const { t } = useTranslation('common');

  return (
    <Row 
      gutter={[0, 0]} 
      className="mt-4" 
      style={{background: '#fcfcfc', padding: 0, margin: '10px 0px', border: '1px solid #f9f9f9', borderRadius: 5}}
    >
      <Col 
        span={24}
        style={{
          padding: 12,
          background: '#f5f5f5',
        }}
      >
        <Row>
          <Col span={12}>
            <Typography.Title level={5} style={{margin: 0}}>
              {`${getTranlation(section.title)}:`}
            </Typography.Title>
            {
              section.subTitle && (
                <Typography.Text>
                  {getTranlation(section.subTitle)}
                </Typography.Text>
              )
            }
          </Col>
          <Col span={12}>
            <Row justify="end" gutter={10}>
              <Col order={2}>
                <Tag style={{margin: 0}}>
                  {t('g.max')}. {section.restrictions.max}
                </Tag>
              </Col>
              {
                section.restrictions.limit === EMixLimit.Required && (
                  <>
                    <Col order={1}>
                      <Tag style={{margin: 0}}>
                        {t('g.min')}. {section.restrictions.min}
                      </Tag>
                    </Col>
                    <Col order={3}>
                      <Tag>
                        {t('g.required')}
                      </Tag>
                    </Col>
                  </>
                )
              }
            </Row>
          </Col>
        </Row>
      </Col>
      <Col 
        span={24}
        style={{
          padding: "0px 16px",
        }}
      >
        <MixToppings 
          loading={loading}
          toppings={section.toppings}
          selectedOptions={selectedOptions}
          sectionRestrictions={section.restrictions}
          onChange={onChange}
          sectionId={section.id}
        />
        <Typography.Paragraph style={{ fontSize: "10px", textAlign: "right" }}>
          * Cantidad neta
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default MixSection;
