export default function formatNumberQty(number: number) {
  if (Number.isInteger(number)) {
    return number;
  }

  if (Number(number.toFixed(1)) === number) {
    return number.toFixed(1);
  }

  return number.toFixed(2);
}