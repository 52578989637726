import IProductConfiguration from '@/interfaces/IProductConfiguration';

const allowsSaleWithoutInventory = (data: IProductConfiguration[] | null) => {
  if (!data) return false;

  const allowsSale = data.every(
    (config) => config?.inventory?.allowSaleWithoutInventory === true
  );

  return allowsSale;
};

export default allowsSaleWithoutInventory;
