import ISchedule from '@/interfaces/ISchedule';
import moment from 'moment';
import { Moment } from 'moment';
import equalizeDate from '@/helpers/equalizeDate';
import getScheduleDayByDate from '@/helpers/getScheduleDayByDate';
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter';

function nextOpenSchedule(
  schedule: ISchedule, 
  referenceDate: Moment = moment(),
  timesExecuted = 1
): string {
  const DATE_FORMAT = "ddd hh:mm a";
  if (timesExecuted > 7) return "--:--";

  const nextDay = referenceDate.add(1, 'd').startOf('day');
  const nextScheduleDay = getScheduleDayByDate(schedule, nextDay);

  if (nextScheduleDay.isOpen24hr) return capitalizeFirstLetter(moment().startOf('day').format(DATE_FORMAT));

  if (nextScheduleDay.isClosed) return nextOpenSchedule(schedule, nextDay, timesExecuted + 1);

  if (
    nextScheduleDay?.rangeSchedule 
    && nextScheduleDay.rangeSchedule.length > 0
  ) {
    const nextOpen = moment(nextScheduleDay.rangeSchedule[0].startTime);

    return capitalizeFirstLetter(equalizeDate(nextOpen, nextDay).format(DATE_FORMAT));
  }

  return nextOpenSchedule(schedule, nextDay, timesExecuted + 1);
};

export default nextOpenSchedule;
