import IMixSection from '@/interfaces/IMixSection';
import IMixSectionDto from '@/interfaces/IMixSectionDto';
import { adapterDtoToMixTopping } from '@/adapters/MixToppingAdapter';

export const adapterDtoToMixSection = (mixSection: IMixSectionDto): IMixSection => {
  const { 
    id,
    labelEn,
    labelEs,
    descEn,
    descEs,
    restrictedLimit,
    maxToppings,
    minToppings,
    mix_section_toppings,
    order,
  } = mixSection;
  
  return {
    id,
    title: {
      en: labelEn,
      es: labelEs,
    },
    subTitle: {
      en: descEn,
      es: descEs,
    },
    restrictions: {
      limit: restrictedLimit,
      min: minToppings,
      max: maxToppings,
    },
    order,
    toppings: mix_section_toppings.map(mixTopping => adapterDtoToMixTopping(mixTopping)) 
  };
};
