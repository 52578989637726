import CustomRadioSelect from '@/components/CustomRadioSelect';
import getTranslation from '@/helpers/getTranslation';
import { IUseModal } from '@/hooks/useModal';
import { FilteredItemsParams } from '@/services/FilteredItem';
import { Drawer, Typography, Grid } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export interface ISortOption{
  label: string,
  value: { 'sortByPriority' : 'ASC' } 
  | { 'sortByName' : 'ASC' } 
  | { 'sortByName' : 'DESC' },
}

interface ISortFilterProps{
  drawerHandle: IUseModal,
  sortOptions: ISortOption[],
  sortSelected: ISortOption,
  onChangeSortSelected: (option: ISortOption) => void,
}

const SortFilter = ({
  drawerHandle,
  sortOptions,
  sortSelected,
  onChangeSortSelected,
}: ISortFilterProps) => {
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const DRAWER_WIDTH = screens.xs ? '80%' : '378px';

  const HeaderFilter = () => (
    <>
      <Text strong className="text-xl block">
        {t('g.sort_by')}:
      </Text>
      <Text strong className="text-blue-500 text-sm block">
        {sortSelected.label}
      </Text>
    </>
  );

  const OptionRenderItem = ({ sortOption }: {sortOption: ISortOption}) => {
    const className = (sortOption.label === sortSelected.label) ? 'text-blue-500' : '';

    return (
      <Text className={className}>
        {sortOption.label}
      </Text>
    );
  }

  return (
    <Drawer
      width={DRAWER_WIDTH}
      title={<HeaderFilter />}
      placement="right"
      closable={false}
      onClose={() => drawerHandle.hide()}
      open={drawerHandle.visible}
      bodyStyle={{ padding: '24px' }}
    >
      <CustomRadioSelect
        rowSelectable
        bordered={false}
        options={sortOptions} 
        optionSelected={sortSelected}
        renderOption={(sortOption) => <OptionRenderItem sortOption={sortOption} />}
        onChange={(sortOption) => onChangeSortSelected(sortOption)}
      />
    </Drawer>
  );
};

export default SortFilter;
