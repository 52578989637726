import { Col, List, Checkbox, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface CustomCheckboxSelectProps<T> {
  options: T[];
  optionsSelected: T[];
  bordered?: boolean;
  rowSelectable?: boolean;
  renderOption: (option: T) => React.ReactNode;
  renderCheckbox?: (option: T) => React.ReactNode | void;
  onChange?: (options: T[]) => void;
  onClick?: (option: T, checked: boolean) => void;
  showCheckAll?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const CustomCheckboxSelect = <T,>({
  options,
  optionsSelected,
  bordered = true,
  rowSelectable = false,
  renderOption,
  renderCheckbox = () => {},
  onChange = () => {},
  onClick = () => {},
  showCheckAll = false,
  header,
  footer,
}: CustomCheckboxSelectProps<T>) => {
  const { t } = useTranslation('common');

  function onChangeHandler(optionsChecked: CheckboxValueType[]){
    const optionsCheckedString = optionsChecked.map(op => op.toString());
    const optionsCheckedParse: T[] = optionsCheckedString.map(op => JSON.parse(op));

    onChange(optionsCheckedParse);
  }

  function onClickHandler(event: any){
    const optionString: string = event.target.value;
    const optionParse: T = JSON.parse(optionString);

    const checked: boolean = event.target.checked;

    onClick(optionParse, checked);
  }

  function onChangeCheckAll(event: CheckboxChangeEvent){
    const checked: boolean = event.target.checked;

    switch (checked) {
      case true:
        onChange(options);
      return

      case false:
        onChange([]);
      return
    }
  }

  function renderCheckboxOption(option: T){
    const rowPointer = rowSelectable ? 'cursor-pointer' : '';

    const uuid = uuidv4();

    const onClickRow = rowSelectable ? (event: any) => {
      if (event.target.id === uuid) { return };
      
      const radioInput = document.getElementById(uuid);
      radioInput?.click();
    } : () => {};

    return (
      <List.Item onClick={onClickRow} className={rowPointer}>
        <Row 
          gutter={[0, 8]}
          align='middle' 
          justify="space-between" 
          className="w-full"
        >
          <Col>
            {renderOption(option)}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {renderCheckbox(option) || <Checkbox id={uuid} value={JSON.stringify(option)} onClick={onClickHandler}/>}
          </Col>
        </Row>
      </List.Item>
    );
  }

  return (
    <List
      bordered={bordered}
      header={header}
      footer={footer}
    >
      {showCheckAll && (
        <List.Item>
          <Row align="top" justify="space-between">
            <Col flex="70%">
              {t('g.check_all')}
            </Col>
            <Col flex="30%" style={{ textAlign: 'right' }}>
              <Checkbox onChange={onChangeCheckAll}/>
            </Col>
          </Row>
        </List.Item>
      )}
      <Checkbox.Group 
        className="w-full"
        value={optionsSelected.map(op => JSON.stringify(op))} 
        onChange={onChangeHandler}
      >
        <List
          dataSource={options}
          renderItem={option => renderCheckboxOption(option)}
        />
      </Checkbox.Group>
    </List>
  );
};

export default CustomCheckboxSelect;
