import getTranlation from "@/helpers/getTranslation";
import IProductRelatedToList from "@/interfaces/IProductRelatedToList";
import { Card, Col, List, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import formatCurrencyMX from '@/utils/formatCurrency';
import Paragraph from "antd/lib/typography/Paragraph";
import { ListGridType } from "antd/lib/list";
import { ItemExtra } from "@/services/FilteredItem";
import { memo } from "react";
import goToItemDetails from "@/helpers/goToItemDetails";
import { useNavigate } from "react-router-dom";
import { NotFoundImage } from "@/assets/images";

interface RelatedProductsProps{
  relatedProducts: IProductRelatedToList[],
  isLoading?: boolean,
  gridRules?: ListGridType,
}

const RelatedProducts = memo(({ 
  relatedProducts,
  isLoading = false,
  gridRules,
}: RelatedProductsProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const ItemRelatedCard = (params: {item: ItemExtra}) => {
    const { item } = params;
    const itemName = getTranlation(item.name);
    const itemImage = item.imgs[0]?.url || NotFoundImage;
    const price = item.calculatedCost;

    return (
      <Card 
        size="small" 
        bodyStyle={{ padding: 0, cursor: 'pointer' }} 
        onClick={() => goToItemDetails(item, navigate)}
      >
        <Row>
          <Col span={24} className="text-center p-2">
            <div className="bg-gray-100">
              <LazyLoadImage
                alt={itemName}
                placeholderSrc={NotFoundImage}
                src={itemImage}
                className="h-40 w-full"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  aspectRatio: 1,
                  marginBottom: '8px',
                  backgroundColor: '#f3f3f3',
                }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Paragraph className="px-2 m-0 h-20 text-sm text-center" ellipsis={{ rows: 4 }}>
              {getTranlation(item.name)}
            </Paragraph>
          </Col>
          <Col span={24} className="p-2 font-semibold text-lg text-center">
            {price ? formatCurrencyMX(price) : t('g.pending_price')}
          </Col>
        </Row>
      </Card>
    );
  }
  
  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            {t('g.related_items')}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[18, 12]}>
        <List
          rowKey="id"
          grid={gridRules}
          loading={isLoading}
          dataSource={relatedProducts}
          renderItem={relatedProduct => (
            <List.Item key={relatedProduct.id} className='shadow-lg rounded-md'>
              <ItemRelatedCard item={relatedProduct.item} />
            </List.Item>
          )}
        />
      </Row>
    </>
  );
});

export default RelatedProducts;
