import React from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useBreakpoints from '@/hooks/useBreakpoint';
import useAuth from '@/hooks/useAuth';
import { emailPattern } from '@utils/emailPattern';
import { useTranslation } from 'react-i18next';

interface ILoginInput {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm = () => {
  const { login, authError, cleanupErrors, loading } = useAuth();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const onFinish = async (values: ILoginInput) => {
    try {
      await login({
        strategy: 'local',
        ...values,
      });

      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const resetAuth = () => {
    cleanupErrors();
  };

  return (
    <>
      {
        process.env.REACT_APP_GOOGLE_AUTH === '1' && (
          <Button
            style={{ marginBottom: 12 }}
            shape='round'
            type='primary'
            block
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            icon={<GoogleOutlined className="fix-icon"/>}
            className="bg-blue-500"
          >
            Google
          </Button>
        )
      }
      {
        process.env.REACT_APP_FACEBOOK_AUTH === '1' && (
          <Button
            shape='round'
            type='primary'
            block
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            icon={<FacebookOutlined className="fix-icon"/>}
            className="bg-blue-500"
          >
            Facebook
          </Button>
        )
      }
      {
        (process.env.REACT_APP_GOOGLE_AUTH === '1' || process.env.REACT_APP_FACEBOOK_AUTH === '1') && (
          <Divider plain orientation='center'>
            {t('g.or')}
          </Divider>
        )
      }

      <Form name='login' initialValues={{ remember: true }} onFinish={onFinish}>
        {authError && (
          <Alert 
            message={`${authError}.`} 
            type="error" 
            className="mb-4"
            showIcon
          />
        )}
        <Form.Item
          name='email'
          rules={[
            { required: true, message: t('validation.required') },
            {
              pattern: emailPattern,
              message: t('validation.email'),
            },
          ]}
        >
          <Input
            className="rounded-3xl"
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            placeholder='Email'
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input
            className="rounded-3xl"
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            type='password'
            placeholder={t('g.password')}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button bg-blue-500'
            shape='round'
            size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
            block
            loading={loading}
          >
            {t('g.log_in')}
          </Button>
        </Form.Item>
        <Form.Item
          name='remember'
          valuePropName='checked'
          className="text-center"
        >
          <Checkbox>{t('g.remember_days_hint')}</Checkbox>
        </Form.Item>
        <Form.Item className="text-center mb-0">
          <>
            {t('g.forgot_pwd')}
            <Button 
              type="link" 
              onClick={() => navigate('/password_recovery_request')}
            >
              {t('g.recover_hint')}
            </Button>
          </>
        </Form.Item>
        <Form.Item className="text-center mb-0">
          <>
            {t('g.account_hint')}
            <Button 
              type="link" 
              onClick={() => {
                resetAuth();
                navigate('/signin');
              }}
            >
              {t('g.register_hint')}
            </Button>
          </>
        </Form.Item>
      </Form>
      <Button type="link" block onClick={() => navigate('/')}>
        {t('g.continue_as_guest')}
      </Button>
    </>
  );
};

export default LoginForm;
