import AccessPin from '@/components/AccessPin';
import AccessPinOrder from '@/containers/views/AccessPin/AccessPinOrder/AccessPinOrder';
import { LockFilled } from '@ant-design/icons';
import { Card, Col, Row, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const AccessPinOrderPage = () => {
  const { t } = useTranslation('common');

  const {
    orderId,
    showAuthFail,
    setOrderAccessKey,
  } = AccessPinOrder();

  const pinRules: Rule[] = [
    { required:  true },
    { len:  6 },
  ];

  const infoLabel = t('g.to_be_able_to_see_the_order_it_is_necessary_to_type_the_access_pin', { orderId });

  return (
    <Row gutter={[0, 12]} className="p-4" align='middle' style={{ height: '50vh' }}>
      <Col span={24}>
        <Card size="small" className="shadow-lg rounded-md">
          <Row>
            <Title level={5}>
              {t('g.order_detail_access_pin_title')}
            </Title>
            <Text>
              {t('g.order_detail_access_pin_text_first')}
            </Text>
            <Text>
              {t('g.order_detail_access_pin_text_second')}
            </Text>
          </Row>
          <Row className='pb-4'>
            <Col span={24} className="text-lg p-1">
              <Text type="warning">
                <LockFilled className="inline-flex mr-1"/>
                {t('g.type_your_pin')}
              </Text>
            </Col>
            <Col span={24} className="py-2 border-t">
              <Text className="font-medium">
                {infoLabel}
              </Text>
            </Col>
            <Col span={24} className="pt-4">
              <AccessPin
                rules={pinRules}
                callback={setOrderAccessKey} 
                authFail={showAuthFail}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default AccessPinOrderPage;
