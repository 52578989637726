import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useShoppingCart from '@/hooks/useShoppingCart';

interface IValidateShoppingCart {
  children: ReactElement ;
}

const ValidateShoppingCart = ({
  children
}: IValidateShoppingCart) => {
  const { shoppingCart } = useShoppingCart();

  const itemsQty = shoppingCart?.items.length ?? 0;

  if (itemsQty <=0) return (<Navigate to='/' replace />)
  
  return children ? children : <Outlet />;
};

export default ValidateShoppingCart;
