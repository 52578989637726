import { Col, List, Row } from 'antd';
import { ReactNode } from 'react';
import QtyInput from '@/components/General/QtyInput';
import TypeWithSelectedQuantity from '@/types/TypeWithSelectedQuantity';



interface ICustomQtySelectProps<T> {
  options: TypeWithSelectedQuantity<T>[];
  onChange?: (option: T, qtySelected: number) => void;
  renderItem: (option: T) => ReactNode;
  renderQty?: (
    option: T & { qtySelected: number }, 
    onChange: ((option: T, qtySelected: number) => void)
  ) => ReactNode;
}

const CustomQtySelect = <T,>({
  onChange = () => {},
  options,
  renderItem,
  renderQty,
}: ICustomQtySelectProps<T>) => {

  return (
    <List
      dataSource={options}
      renderItem={(option: T & { qtySelected: number }) => (
        <List.Item>
          <Row 
            gutter={[0, 8]}
            align="top" 
            justify="space-between"
            style={{ width: "100%" }}
          >
            <Col>
              <Row>{renderItem(option)}</Row>
            </Col>
            <Col>
              <Row justify="end">
                {renderQty ? (
                  renderQty(option, onChange)
                ) : (
                  <Col>
                    <QtyInput
                      value={option.qtySelected}
                      min={0}
                      onDecrease={() => onChange(option, option.qtySelected - 1)}
                      onIncrease={() => onChange(option, option.qtySelected + 1)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default CustomQtySelect;
