import axiosApiConfig from '@/config/axiosConfig';
import formatShoppingCart from '@/helpers/formatters/formatShoppingCart';
import IPaginationResponse from '@/interfaces/IPaginationResponse';
import IParams from '@/interfaces/IParams';
import SelectionMix from '@/interfaces/ISelectionMix';
import IShoppingCart from '@/interfaces/IShoppingCart';
import IShoppingCartDto from '@/interfaces/IShoppingCartDto';
import ItemType from '@/types/ItemType';

const getShoppingCarts = async (params?: IParams): Promise<IShoppingCart[]> => {
  const result = await axiosApiConfig.get<IShoppingCartDto[]>(
    '/shopping_carts',
    { params: { ...params } }
  );
  
  const finalData: IShoppingCart[] = result.data.map(sc => formatShoppingCart(sc));

  return finalData;
};

export const getShoppingCartsPagination = async (params?: IParams): Promise<IPaginationResponse<IShoppingCart>> => {
  const result = await axiosApiConfig.get<IPaginationResponse<IShoppingCartDto>>(
    '/shopping_carts',
    { params: { ...params } }
  );
  
  const finalData: IPaginationResponse<IShoppingCart> = {
    total: result.data.total,
    data: result.data.data.map(sc => formatShoppingCart(sc)),
    limit: result.data.limit,
    skip: result.data.skip,
  };

  return finalData;
};

export const updateShoppingCart = async (id:IShoppingCart['id'], data: Partial<IShoppingCart>): Promise<IShoppingCart> => {
  const result = await axiosApiConfig.patch<IShoppingCartDto>(`/shopping_carts/${id}`, data);

  return formatShoppingCart(result.data);
};

export const getSingleShoppingCart = async (data: {
  id?: IShoppingCart['id'];
}): Promise<IShoppingCart> => {
  const result = await axiosApiConfig.get<IShoppingCartDto>('/shopping_cart', {
    params: { shoppingCartId: data.id }
  });
  const finalData = formatShoppingCart(result.data);
  return finalData;
};

export const createShoppingCart = async (
  data: Partial<IShoppingCart>
): Promise<IShoppingCart> => {
  const result = await axiosApiConfig.post<IShoppingCart>(
    '/shopping_carts',
    data
  );
  return result.data;
};

export const editShoppingCart = async (
  data: Partial<IShoppingCart>
): Promise<IShoppingCart> => {
  const result = await axiosApiConfig.patch<IShoppingCart>(
    `/shopping_carts/${data.id}`,
    data
  );
  return result.data;
};

export const deleteShoppingCart = async (data: {
  id?: IShoppingCart['id'];
}): Promise<IShoppingCart> => {
  const result = await axiosApiConfig.delete<IShoppingCart>(
    `/shopping_carts/${data.id}`
  );
  return result.data;
};

interface ShoppingCartAddData {
  entityType: ItemType;
  entityId: string;
  selection: string | SelectionMix;
  qty: number;
  shoppingCartId: string;
}

export const addItemToShoppingCart = async (
  data: Partial<ShoppingCartAddData>
): Promise<ShoppingCartAddData> => {
  const result = await axiosApiConfig.post<ShoppingCartAddData>(
    '/shopping_carts/add',
    data
  );
  return result.data;
};

export const removeItemFromShoppingCart = async (
  data: Partial<ShoppingCartAddData>
): Promise<ShoppingCartAddData> => {
  const result = await axiosApiConfig.post<ShoppingCartAddData>(
    '/shopping_carts/remove',
    data
  );
  return result.data;
};

export const transferFromOneShoppingCartToAnother = async (data: {
  fromId: string;
  toId: string;
}): Promise<string> => {
  const result = await axiosApiConfig.post<string>(
    '/shopping_carts/transfer',
    data
  );
  return result.data;
};

export default getShoppingCarts;
