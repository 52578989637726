import React from 'react';
import EPriceFormats from '@/enums/EPriceFormat';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import IContactInfo from '@/interfaces/IContactInfo';
import IDelivery from '@/interfaces/IDelivery';
import { Card, Col, List, Row, Typography, Image, Tag } from 'antd';
import DeliveryMethod from '@/components/DeliveryMethod';
import Price from '@/components/General/Price';
import ItemCard from '@/components/ShoppingCart/ItemCard';
import ItemDetails from '@/components/ShoppingCart/ItemDetails';
import AddressDetailsCard from '@/components/ThankYou/AddressDetailsCard';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import IDeliveryProduct from '@/interfaces/IDeliveryProduct';
import IProduct from '../../interfaces/IProduct';
import { NotFoundImage } from '@/assets/images';

export interface IDeliveryProductWithProduct extends IDeliveryProduct {
  product: IProduct;
}

interface IDeliveryItem extends IDeliveryProductWithProduct{
  previewImage: string;
}

interface IDeliveryCardProps {
  delivery: IDelivery;
  contactInfo: IContactInfo;
}

const DeliveryCard = ({ contactInfo, delivery }: IDeliveryCardProps) => {
  const { t } = useTranslation('common');

  let location;
  let locationLabel;
  let deliveryTag;
  let deliveryAddress;

  const deliveryWithProduct: IDeliveryProductWithProduct[] = delivery.products.filter(
    product => product.product !== null
  ) as IDeliveryProductWithProduct[];

  switch (delivery.deliveryType) {
    case EDeliveryMethod.Shipping:
      location = delivery.shippingType?.warehouse?.alias;
      locationLabel = t('g.products_sent_from');
      deliveryTag = i18next.language === 'en'
      ? delivery.shippingType?.labelEn
      : delivery.shippingType?.labelEs

      if(delivery.address){
        deliveryAddress = (
          <AddressDetailsCard
            contact={contactInfo}
            address={delivery.address}
          />
        );
      }

    break;

    case EDeliveryMethod.Pickup:
      location = delivery.deliveryPoint?.alias;
      locationLabel = t('g.products_to_pick_up_from');
      deliveryTag = delivery.deliveryPoint?.alias;

      if(delivery.deliveryPoint && delivery.deliveryPoint.address){
        deliveryAddress = (
          <AddressDetailsCard
            contact={contactInfo}
            address={delivery.deliveryPoint.address}
          />
        );
      }
    break;
  }

  const daysAmount = moment(delivery.deliveryDate).endOf('day').diff(moment().startOf('day'), 'days');
  
  let daysToDeliver: string = t('g.recieve_it_today');

  if (daysAmount > 1) {
    daysToDeliver = t('g.recieve_it_in', { days: daysAmount + 1 });
  } 
  
  if (daysAmount === 1) {
    daysToDeliver = t('g.recieve_it_in_one_day');
  }

  const deliveryItems: IDeliveryItem[] = deliveryWithProduct.map(deliveryProduct => {
    let previewImage = NotFoundImage;

    if((deliveryProduct.product.imgs && deliveryProduct.product.imgs[0]?.url)){
      previewImage = deliveryProduct.product.imgs[0].url;
    }

    return {...deliveryProduct, previewImage};
  });

  return (
    <Card
      size='small'
      title={
        <Row>
          <Col span={24}>
            <Typography.Text>
              {`${locationLabel}: ${location}`}
            </Typography.Text>
            <Row>
              <Col>
                <DeliveryMethod deliveryMethod={delivery.deliveryType} />
              </Col>
              <Col>
                <Tag>{deliveryTag}</Tag>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          {deliveryAddress}
          <Row className='p-1'>
            <Typography.Text>
              {`${daysToDeliver}: ${moment(delivery.deliveryDate).format('L')}`}
            </Typography.Text>
          </Row>
          <Row className='p-2'>
            <List
              bordered={false}
              className='w-full'
              dataSource={deliveryItems}
              renderItem={(item) => (
                <List.Item className='p-0 mb-4'>
                  <ItemCard
                    cols={24}
                    layout='horizontal'
                    thumb={
                      <Image
                        preview={false}
                        fallback={NotFoundImage}
                        src={item.previewImage}
                        width={100}
                      />
                    }
                    title={
                      <ItemDetails
                        deliveryMethods={[delivery.deliveryType]}
                        priceSection={
                          <Price
                            price={item.price}
                            priceFormat={EPriceFormats.Regular}
                          />
                        }
                        item={item.product}
                      />
                    }
                    content={
                      <Row>
                        <Typography.Text type='secondary'>
                          {t('g.quantity')}: {item.qty}
                        </Typography.Text>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DeliveryCard;
