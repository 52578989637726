import CategoriesList from '@/components/General/CategoriesList';
import { IUseModal } from '@/hooks/useModal';
import ICategory from '@/interfaces/ICategory';
import Optional from '@/types/Optional';
import { Row, Col, Typography, Spin, Drawer, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

interface HomeCategoriesListProps {
  partialCategories: Optional<ICategory[]>;
  fullCategories: Optional<ICategory[]>;
  onSelectCategory: (c: ICategory) => void;
  categoriesDrawer: IUseModal;
  selectedCategoryId: Optional<ICategory['id']>;
  isLoadingCategories: boolean;
}

const HomeCategoriesList = ({
  fullCategories,
  partialCategories,
  onSelectCategory,
  categoriesDrawer,
  selectedCategoryId,
  isLoadingCategories,
}: HomeCategoriesListProps) => {
  const { t } = useTranslation('common');

  return (
    <Row>
      <Drawer
        title={<Typography.Title level={5} className='text-blue-500'>{t('g.categories')}</Typography.Title>}
        placement='bottom'
        onClose={categoriesDrawer.hide}
        open={categoriesDrawer.visible}
        closeIcon={null}
      >
        <Row justify='center'>
          <Col>
            {!fullCategories || isLoadingCategories ? (
              <Row justify='center'>
                <Col>
                  <Spin size='large' className='m-4' />
                </Col>
              </Row>
            ) : (
              <CategoriesList
                layout='horizontal'
                selected={selectedCategoryId ?? ''}
                onChange={onSelectCategory}
                options={fullCategories}
              />
            )}
          </Col>
        </Row>
      </Drawer>
      <Col span={24}>
        <Divider orientation='left' className='m-0'>
          <Typography.Title level={5} className='text-blue-500'>
            {t('g.featured_categories')}
          </Typography.Title>
        </Divider>
        <Row justify='center' className='bg-gray-100 bg-opacity-75 pt-4'>
          {!partialCategories || isLoadingCategories ? (
            <Col>
              <Spin size='large' className='m-4' />
            </Col>
          ) : (
            <Col>
              <CategoriesList
                layout='horizontal'
                selected={selectedCategoryId ?? ''}
                onChange={onSelectCategory}
                options={partialCategories}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default HomeCategoriesList;
