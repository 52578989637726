import React, { ReactNode, useEffect, useState } from 'react';
import IPostalCode from '@/interfaces/IPostalCode';
import IWarehouse from '@/interfaces/IWarehouse';
import {
  getWarehousesByPostalCode,
} from '@/services/WarehouseService';
import { getPostalCodeByCode } from '@/services/PostalCode';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

interface ILocationContext {
  warehouses: IWarehouse[];
  selectedWarehouse?: IWarehouse['id'];
  postalCode?: IPostalCode;
  loading: boolean;
  onChangePostalCode: (postalCode: IPostalCode['code']) => void;
  onSelectWarehouse: (warehouseId: IWarehouse['id']) => void;
}

interface IChildren {
  children: ReactNode;
}

const LocationContext = React.createContext<ILocationContext>(
  {} as ILocationContext
);

function useLocation() {
  let initWh = undefined;
  let initPostalCode = undefined;

  if ('warehouse' in localStorage) {
    initWh = localStorage.getItem('warehouse');
    initWh = initWh === null ? undefined : JSON.parse(initWh).id;
  }

  if ('postalCode' in localStorage) {
    initPostalCode = localStorage.getItem('postalCode');
    initPostalCode = initPostalCode === null ? undefined : JSON.parse(initPostalCode);
  }

  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [postalCode, setPostalCode] = useState<IPostalCode | undefined>(initPostalCode);
  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouse['id'] | undefined>(initWh);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const onChangePostalCode = async (selectedPostalCode: IPostalCode['code']) => {
    if (selectedPostalCode === postalCode?.code) return;
    setLoading(true);
    const data = await getPostalCodeByCode({ code: selectedPostalCode });

    if (!data) {
      message.warning({
        content: t('g.invalid_postal_code'),
        duration: 2,
        key: 'PostalCodeKey',
      });
      
      setLoading(false);
      return;
    }

    localStorage.setItem('postalCode', JSON.stringify(data));
    setPostalCode(data);
    setLoading(false);
  };

  const onSelectWarehouse = async (sWarehouse: IWarehouse['id']) => {
    if (sWarehouse === selectedWarehouse) return;

    localStorage.setItem('warehouse', JSON.stringify({ id: sWarehouse }));
    setSelectedWarehouse(sWarehouse);
  };


  const getWarehouses = async () => {
    if (!postalCode) return;
    setLoading(true);

    try {
      const warehouses = await getWarehousesByPostalCode(postalCode.code);
      setWarehouses(warehouses);

      if (!warehouses || warehouses.length === 0) {
        message.warning({
          content: t('g.no_warehouses_available'),
          duration: 2,
          key: 'PostalCodeKey',
        });
        
        setPostalCode(undefined);
      }

      if (warehouses.length === 1) {
        onSelectWarehouse(warehouses[0].id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWarehouses();
    // eslint-disable-next-line
  }, [postalCode]);

  return {
    selectedWarehouse,
    warehouses,
    postalCode,
    loading,
    onChangePostalCode,
    onSelectWarehouse,
  };
}

export const LocationProvider = ({ children }: IChildren) => {
  const location = useLocation();

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export default function LocationConsumer() {
  return React.useContext(LocationContext);
}
