import React from 'react';
import { Row, Col, Input, Image, Button, Modal } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import SiderButton from '@/components/TopBar/SiderButton';
import useScroll from '@/hooks/useScroll';
import WarehouseLocation from '@/components/WarehouseLocation';
import BadgeCounter from '@/components/BadgeCounter';
import CustomerLocation from '@/components/CustomerLocation';
import { useTranslation } from 'react-i18next';
import useLocation from '@/hooks/useLocation';
import getCurrentData from '@/helpers/getCurrentData';
import SelectWarehouse from '@/components/SelectWarehouseModal/SelectWarehouse';
import useModal from '@/hooks/useModal';
import useBreakpoints from '@/hooks/useBreakpoint';
import useShoppingCart from '@/hooks/useShoppingCart';
import IWarehouse from '../../interfaces/IWarehouse';
import DebounceSearch from '../General/DebounceSearch';
import useFilter from '@/hooks/useFilter';
import { LogoMenuImage } from '@/assets/images';

interface ITopbar {
  collapsed: boolean;
  updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  hideSearch?: boolean;
}

const Topbar = ({ collapsed, updateCollapsed, hideSearch = false }: ITopbar) => {
  const { hiddenExtra } = useScroll();
  const warehouseModal = useModal();
  const history = useNavigate();
  const { shoppingCart } = useShoppingCart();
  const { 
    warehouses, 
    selectedWarehouse,
    postalCode,
    onChangePostalCode,
    onSelectWarehouse,
    loading
  } = useLocation();

  const { 
    search, 
    setSearch,
  } = useFilter();
  
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const LOGO = process.env.REACT_APP_LOGO_MENU || LogoMenuImage;
  const customTextColor = process.env.REACT_APP_THEME_LAYOUT_HEADER_COLOR_TEXT;
  const customTextLink = process.env.REACT_APP_THEME_LAYOUT_HEADER_COLOR_LINK;

  function onChangeWarehouse(warehouseId: IWarehouse['id']){
    onSelectWarehouse(warehouseId);
    warehouseModal.hide();
  }

  const MAX_WIDTH = '1200px';
  const BREAKPOINT = 'md';

  const defaultStyles = {
    rowContainer: {
      style: {
        maxWidth: MAX_WIDTH, 
        margin: '0 auto',
        alignItems: 'center',
      },
      wrap: true,
    },
    logo: {
      flex: 'none',
    },
    searchBar: {
      flex: '1',
    },
    shoppingCartIcon: {
      flex: 'none',
    },
    menuIcon: {
      flex: 'none',
    },
    warehouseLocation: {
      flex: '1',
      style: {
        paddingBottom: '8px',
      },
    },
    userLocation: {
      flex: '1',
      style: {
        paddingBottom: '8px',
      },
    },
  };

  const mobileStyle = {
    rowContainer: {
      ...defaultStyles.rowContainer,
    },
    logo: {
      ...defaultStyles.logo,
    },
    searchBar: {
      ...defaultStyles.searchBar,
    },
    shoppingCartIcon: {
      ...defaultStyles.shoppingCartIcon,
    },
    menuIcon: {
      ...defaultStyles.menuIcon,
    },
    warehouseLocation: {
      ...defaultStyles.warehouseLocation,
    },
    userLocation: {
      ...defaultStyles.warehouseLocation,
    },
  };

  const desktopStyle = {    
    rowContainer: {
      ...defaultStyles.rowContainer,
      wrap: false,
    },
    logo: {
      ...defaultStyles.logo,
      order: 1,
    },
    menuIcon: {
      ...defaultStyles.menuIcon,
      order: 2,
    },
    searchBar: {
      ...defaultStyles.searchBar,
      order: 4,
      style: {
        padding: '0 32px 0 0'
      },
    },
    userLocation: {
      ...defaultStyles.userLocation,
      flex: '220px',
      order: 3,
      style: {
        padding: '8px 0'
      },
    },
    warehouseLocation: {
      ...defaultStyles.warehouseLocation,
      flex: '220px',
      order: 5,
      style: {
        padding: '8px 0'
      },
    },
    shoppingCartIcon: {
      ...defaultStyles.shoppingCartIcon,
      order: 6,
    },
  };

  const getElementProps = (elementKey: keyof typeof defaultStyles) => {
    return isBreakpointEqualsAndBelow(BREAKPOINT) ? mobileStyle[elementKey] : desktopStyle[elementKey]
  };

  const MenuIconElement = (
    <Col {...getElementProps('menuIcon')}>
      <Row align="middle" justify="center" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Col>
          <SiderButton
            collapsed={collapsed}
            updateCollapsed={updateCollapsed}
          />
        </Col>
      </Row>
    </Col>
  );
  const WarehouseLocationElement = (
    <Col {...getElementProps('warehouseLocation')}>
      <div style={{ lineHeight: 'normal' }}>
        <WarehouseLocation
          showHint
          showIconHint
          showSchedule
          miniSchedule
          warehouse={getCurrentData(warehouses, selectedWarehouse)}
          extra={
            <Button
              type="link"
              onClick={() => warehouseModal.show()}
              className="p-0 h-3.5 leading-normal text-xs"
              style={{ color: customTextLink }}
            >
              {t('g.other_warehouses')}
            </Button>
          }
        />
      </div>
    </Col>
  );
  const UserLocationElement = (
    <Col {...getElementProps('userLocation')}>
      <div style={{ lineHeight: 'normal', paddingRight: '7px' }}>
        <CustomerLocation
          postalCode={postalCode}
          extra={
            <Button
              type="link"
              onClick={() => warehouseModal.show()}
              className="p-0 h-3.5 leading-normal text-xs butoonttt"
              style={{ color: customTextLink }}
            >
              <p>
                {t('g.change_location')}
              </p>
            </Button>
          }
        />
      </div>
    </Col>
  );

  return (
    <Row {...getElementProps('rowContainer')}>
      <Modal
        title={t('g.select_a_postal_code')}
        open={warehouseModal.visible}
        onCancel={() => warehouseModal.hide()}
        footer={null}
      >
        <SelectWarehouse 
          loading={loading}
          initialValue={selectedWarehouse}
          onChangePostalCode={onChangePostalCode}
          onChangeWarehouse={onChangeWarehouse}
          options={warehouses}
        />
      </Modal>

      <Col {...getElementProps('logo')}>
        <div>
          {/* {!hiddenExtra ? ( */}
            <Link className='center_children' to='/'>
              <Image 
                // width={isBreakpointEqualsAndBelow('sm') ? "1.25rem" : "1.5rem"} 
                style={{ 
                  maxHeight: '40px', 
                  width: 'auto', 
                  paddingLeft: '16px', 
                  paddingRight: '16px', 
                  objectFit: 'contain',
                }}
                preview={false} 
                src={LOGO} 
              />
            </Link>
          {/* ) : (
            <div className='center_children' style={{ paddingLeft: '16px', paddingRight: '16px' }}>
              <SiderButton
                collapsed={collapsed}
                updateCollapsed={updateCollapsed}
              />
            </div>
          )} */}
        </div>
      </Col>
      <Col {...getElementProps('searchBar')}>
        <Col span={hideSearch ? 0 : 24 }>
          <Row align="middle">
            <DebounceSearch 
              allowClear
              searchValue={search}
              setSearchValue={setSearch}
              placeholder={t('g.search')}
              className=""
            />
          </Row>  
        </Col>
      </Col>
      <Col {...getElementProps('shoppingCartIcon')}>
        <Row style={{ paddingLeft: '8px', paddingRight: '16px' }}>
          <BadgeCounter
            element={
              <ShoppingCartOutlined
                className={"icon-large text-white"}
                style={{ color: customTextColor }}
              />
            }
            count={!shoppingCart ? 0 : shoppingCart?.items?.length || 0}
            countLimit={10}
            onClick={() => history('/shopping_cart')}
            showZero
          />
        </Row>
      </Col>
      
      {!isBreakpointEqualsAndBelow(BREAKPOINT) && MenuIconElement}
      {!isBreakpointEqualsAndBelow(BREAKPOINT) && UserLocationElement}
      {!isBreakpointEqualsAndBelow(BREAKPOINT) && WarehouseLocationElement}
      
      <Col id="zel-hidden-topbar" span={(hiddenExtra || !isBreakpointEqualsAndBelow(BREAKPOINT))? 0 : 24}>
        <Row justify="space-between">
          {MenuIconElement}
          {UserLocationElement}
          {WarehouseLocationElement}
        </Row>
      </Col>
    </Row>
  );
};

export default Topbar;
