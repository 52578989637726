import axiosApiConfig from '@/config/axiosConfig';
import Optional from '@/types/Optional';

export enum BannerLocation {
	Main = 'main',
	Secondary = 'secondary',
}
export interface IBanner {
	id: string;
	locationCode: BannerLocation;
	value: string;
	code: string;
	bgColor: string;
	fontColor: string;
	url: Optional<string>;
	urlLabel: Optional<string>;
}

export const getBanner = async (location: BannerLocation): Promise<IBanner | null> => {
	const result = await axiosApiConfig.get<IBanner>(`/banner_location?locationCode=${location}`);

	if (result.data?.id) {
		return result.data
	}
	return null;
};

const BannersService = {
	getBanner,
}

export default BannersService;
