import React, { ReactElement } from 'react';
import useAuth from '@/hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import usePermissions from '@/hooks/usePermissions';
import EBasicActions from '@/enums/EBasicActions';
import EPage from '../../../enums/EPages';

interface IPrivateStaffRoute {
  children: ReactElement ;
}

const PrivateStaffRoute = ({
  children,
}: IPrivateStaffRoute) => {
  const { authenticated, customer } = useAuth();
  const { isValidAction } = usePermissions();

  if (!authenticated && !customer?.isStaff) return (<Navigate to='/403' replace />);
  if (!isValidAction(EBasicActions.Create, EPage.ExtendedCheckout)) return (<Navigate to='/403' replace />);
  return children ? children : <Outlet />;
};

export default PrivateStaffRoute;
