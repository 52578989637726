import { memo } from 'react';
import { useTranslation } from "react-i18next";
import SortFilter, { ISortOption } from "@/containers/views/Home/Filters/SortFilter";
import IAttribute from "@/interfaces/IAttribute";
import IAttributeOption from "@/interfaces/IAttributeOption";
import AttributeFilter from "@/containers/views/Home/Filters/AttributeFilter";
import { Button, Col, Row } from "antd";
import useModal from "@/hooks/useModal";
import { MenuFoldOutlined, SortAscendingOutlined } from "@ant-design/icons";
import _, { isEqual } from "lodash";
import useFilter from '@/hooks/useFilter';

interface IHomeFilters {
  attributes: IAttribute[];
  sortOptions: ISortOption[];
}

const HomeFilters = memo(({
  attributes = [],
  sortOptions,
}: IHomeFilters) => {
  const { t } = useTranslation('common');
  const { 
    sort,
    attributeOptions: attributeOptionSelected,
    setSort, 
    setAttributeOptions, 
  } = useFilter();

  const filterDrawerHandle = useModal();
  const sortDrawerHandle = useModal();

  const getAttributeOptionsSelected = () => {
    let attributeOptionshasMap: {
      [key: string]: IAttributeOption[];
    } = {};
    
    if (attributeOptionSelected.length === 0) return attributeOptionshasMap;

    attributes.forEach(attr => {
      const foundAttrOptSelect = attr.attributeOptions.filter(attrOpt => attributeOptionSelected.includes(attrOpt.id.toString()));

      attributeOptionshasMap[attr.id] = foundAttrOptSelect;
    });
    
    return attributeOptionshasMap;
  };

  const attributeOptionsSelected = getAttributeOptionsSelected();

  const onChangeAttributesSelected = (newAttrs: {[key: string]: IAttributeOption[]}) => {
    const attributesOptionsId = Object.values(newAttrs).reduce((acc: string[] = [], attributesOptions) => {
      const attributeOptionsId = attributesOptions.map(attrsOp => attrsOp.id.toString());
      return acc.concat(attributeOptionsId);
    }, []);

    setAttributeOptions(attributesOptionsId);
  };

  return (
    <>
      <AttributeFilter 
        drawerHandle={filterDrawerHandle} 
        attributes={attributes} 
        attributesSelected={attributeOptionsSelected} 
        onChangeAttributesSelected={onChangeAttributesSelected} 
      />
      <SortFilter 
        drawerHandle={sortDrawerHandle} 
        sortOptions={sortOptions} 
        sortSelected={sortOptions.find(opt => isEqual(opt.value, sort)) ?? sortOptions[0]} 
        onChangeSortSelected={(opt) => setSort(opt.value)} 
      />
      <Row gutter={16} justify="start" style={{ width: '100%' }}>
        <Col>
          <Button 
            onClick={() => sortDrawerHandle.show()}
            icon={<SortAscendingOutlined className="inline-flex"/>}
          >
            {t('g.sort')}
          </Button>
        </Col>
        <Col>
          <Button 
            onClick={() => filterDrawerHandle.show()}
            icon={<MenuFoldOutlined className="inline-flex"/>}
          >
            {t('g.filters')}
          </Button>
        </Col>
      </Row>
    </>
  );
});

export default HomeFilters;
