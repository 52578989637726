import React from 'react';
import { 
  Col, 
  List, 
  Popover, 
  Row,  
  Image,
  Button,
} from 'antd';
import { v4 as uuid } from 'uuid';  
import { useTranslation } from 'react-i18next';
import './TagPreviewFiles.less';
import { DefaultFileImage } from '@/assets/images';

interface TagPreviewFilesProps<T>{
  files: T[] | number,
  getUrl: ((file: T) => string) | null; // TODO: use keyof T
  getLabel: ((file: T) => string) | null; // TODO: use keyof T
  prefix?: React.ReactNode,
  subfix?: React.ReactNode,
  trigger?: 'click' | 'focus' | 'hover',
  title?: React.ReactNode,
}

const TagPreviewFiles = <T, >({
  files,
  getUrl,
  getLabel,
  prefix,
  subfix,
  trigger = 'click',
  title,
}: TagPreviewFilesProps<T>) => {
  const { t } = useTranslation('common');

  function listItemRender(file: T) {
    /* 
      TODO: implementation of IFilesPreviewTooltipProps with keyof T
      const url = (urlKey && typeof file[urlKey] === 'string') ? file[urlKey] as string : t('not_found');
      const label = (labelKey && typeof file[labelKey] === 'string') ? file[labelKey] as string : url;
    */
    const url = getUrl ? getUrl(file) : t('not_found');
    const label = getLabel ? getLabel(file) : url;

    return (
      <List.Item className="file-list-item" key={uuid()}>
        <Row 
          align="middle" 
          className="container" 
          onClick={() => window.open(url, '_blank')}
        >
          <Col className="container-image">
            <Image src={url} fallback={DefaultFileImage} preview={false} />
          </Col>
          <Col span="none" className="container-label">
            {label}
          </Col>
        </Row>
      </List.Item>
    );
  }

  const filesTypoIsNumber = typeof files === 'number';
  const filesCount = filesTypoIsNumber ? files : files.length;
  const filesHasList = (!filesTypoIsNumber && filesCount !== 0);

  const FilesPreviewButton = (
    <Button>
      {prefix}{`(${filesCount}) ${t('g.files')}`}{subfix}
    </Button>
  );
    
  return (
    <>
      {filesHasList ? (
        <Popover 
          title={title}
          trigger={trigger}
          content={(
            <List
              size="small"
              split={false}
              dataSource={files}
              renderItem={listItemRender}
            />
          )} 
        >
          { FilesPreviewButton }
        </Popover>
      ) : FilesPreviewButton}
    </>
  );
};

export default TagPreviewFiles;
