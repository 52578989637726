import IDelivery from "@/interfaces/IDelivery";
import IDeliveryDto from "@/interfaces/IDeliveryDto";
import { adapterDtoDeliveryAddressToAddress } from '@/adapters/AddressAdapter';
import { adapterDtoToDeliveryProduct } from "@/adapters/DeliveryProductAdapter";
import { adapterDtoToDeliveryPoint } from '@/adapters/DeliveryPointAdapter';

export const adapterDtoToDelivery = (delivery: IDeliveryDto): IDelivery => {
  const { 
    id,
    status,
    orderId,
    deliveryDate,
    shippingDate,
    deliveryType,
    shippingCost,
    shipping_type,
    assignedTo,
    createdAt,
    updatedAt,
  } = delivery;

  const addressDto = delivery.address;
  const productsDto = delivery.products;
  const deliveryPointDto = delivery.deliveryPoint;

  const address = addressDto && adapterDtoDeliveryAddressToAddress(addressDto);
  const products = productsDto.map(product => adapterDtoToDeliveryProduct(product));
  const deliveryPoint = deliveryPointDto && adapterDtoToDeliveryPoint(deliveryPointDto);
  return {
    id,
    status,
    orderId,
    deliveryDate,
    shippingDate,
    deliveryType,
    shippingCost,
    shippingType: shipping_type,
    address,
    deliveryPoint,
    products,
    assignedTo,
    createdAt,
    updatedAt,
  };
};

