import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '@/containers/views/Home/HomePage';
import Login from '@/containers/views/Login/Login';
import PageNotFound from '@/containers/views/PageNotFound';
import MainLayout from '@/containers/_layouts/MainLayout';
import Components from '@/containers/views/Components';
import ItemDetailsPage from '@/containers/views/ItemDetails/ItemDetailsPage';
import MyOrdersPage from '@/containers/views/MyOrders/MyOrdersPage';
import OrderDetailsPage from '@/containers/views/OrderDetails/OrderDetailsPage';
import AccessPinOrderPage from '@/containers/views/AccessPin/AccessPinOrder/AccessPinOrderPage';
import MyAddresses from '@/containers/views/MyAddresses';
import NewUserShippingContact from '@/containers/views/UserShippingContacts/NewUserShippingContact';
import EditUserShippingContact from '@/containers/views/UserShippingContacts/EditUserShippingContact';
import CheckoutLayout from '@/containers/_layouts/CheckoutLayout';
import CheckoutPage from '@/containers/views/Checkout/CheckoutPage';
import ShoppingCart from '@/containers/views/ShoppingCart';
import ShoppingCartGetter from '@/containers/_layouts/ShoppingCartGetter';
import PrivateStaffRoute from '@/containers/app/Router/PrivateStaffRoute';
import ShoppingCartPage from '@/containers/views/ShoppingCart/ShoppingCartPage';
import CheckoutPOS from '@/containers/views/Checkout/CheckoutPOS';
import SignIn from '@/containers/views/SignIn/SignIn';
import BillingPage from '@/containers/views/Billing/BillingPage';
import PaymentSourcesPage from '@/containers/views/PaymentSources/PaymentSourcesPage';
import MainLayoutNoSearch from '@/containers/_layouts/MainLayoutNoSearch';
import ValidateShoppingCart from './ValidateShoppingCart';
import TransactionStatusPage from '@/containers/views/TransactionStatus/TransactionStatusPage';
import PasswordRecoveryRequest from '@/containers/views/PasswordRecoveryRequest/PasswordRecoveryRequest';
import PasswordRecovery from '@/containers/views/PasswordRecovery/PasswordRecovery';
import ContactAssociatePage from '@/containers/views/ContactAssociate/ContactAssociatePage';
import ContactAssociateThankYouPage from '@/containers/views/ContactAssociateThankYou/ContactAssociateThankYouPage';

const Router = () => (
  <Routes>
    <Route path='/' element={<ShoppingCartGetter />}>
      <Route path='/' element={<MainLayout />}>
        <Route path='/' element={<HomePage />}/>
      </Route>
      <Route path='/' element={<MainLayoutNoSearch />}>
        <Route path='/item/:itemType/:itemId' element={<ItemDetailsPage />} />
        <Route path='/components' element={<Components />} />
        <Route path='/orders' element={<MyOrdersPage />} />
        <Route path='/orders/details/:orderId' element={<OrderDetailsPage />} />
        <Route path='/orders/details/:orderId/access_pin' element={<AccessPinOrderPage />} />

        <Route path='/addresses' element={<MyAddresses />} />
        <Route
          path='/user_shipping_contacts/new'
          element={<NewUserShippingContact />}
        />
        <Route
          path='/user_shipping_contacts/:id/edit'
          element={<EditUserShippingContact />}
        />
        <Route path='/shopping_cart' element={<ShoppingCart />} />

        <Route path='/shopping_carts' element={
          <PrivateStaffRoute>
            <ShoppingCartPage />
          </PrivateStaffRoute>
        }/>
        <Route path='/payment_sources' element={<PaymentSourcesPage />} />
        <Route path='/transaction_status' element={<TransactionStatusPage />} />
        <Route path='/contact_associate' element={<ContactAssociatePage />}/>
        <Route path='/contact_associate_thank_you' element={<ContactAssociateThankYouPage />}/>
      </Route>

      <Route path='/checkout' element={<CheckoutLayout />}>
        <Route
          path='/checkout/pos'
          element={
            <PrivateStaffRoute>
              <ValidateShoppingCart>
                <CheckoutPOS />
              </ValidateShoppingCart>
            </PrivateStaffRoute>
          }
        />
        <Route
          path='/checkout'
          element={
            <ValidateShoppingCart>
              <CheckoutPage />
            </ValidateShoppingCart>
          }
        />
      </Route>

      <Route path='/billing' element={<BillingPage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signin' element={<SignIn />} />
      <Route path='/password_recovery_request' element={<PasswordRecoveryRequest />} />
      <Route path='/password_recovery' element={<PasswordRecovery />} />
      <Route path='/404' element={<PageNotFound />} />
      <Route path='/403' element={<PageNotFound />} />
      <Route path='*' element={<Navigate to='/404' replace />} />
    </Route>
  </Routes>
);

export default Router;
