import axiosApiConfig from '@/config/axiosConfig';

interface IManagedSaleRequest {
  name: string;
  phone: string;
  email?: string;
  productId: string;
  whareouseId: string;
}

export const managedSaleRequest = async (params: IManagedSaleRequest): Promise<void> => {
  const result = await axiosApiConfig.post<void>('/managed_sales', params);

  return result.data;
};