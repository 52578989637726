import CustomCheckboxSelect from '@/components/CustomCheckboxSelect';
import { Drawer, Typography, Grid, Divider, Row, Col } from 'antd';
import IAttribute from '@/interfaces/IAttribute';
import getTranlation from '@/helpers/getTranslation';
import { useTranslation } from 'react-i18next';
import { IUseModal } from '@/hooks/useModal';
import IAttributeOption from '@/interfaces/IAttributeOption';
import _ from 'lodash';

const { Text } = Typography;
const { useBreakpoint } = Grid;

interface IAttributeFilterProps{
  drawerHandle: IUseModal,
  attributes: IAttribute[],
  attributesSelected: {[key: string]: IAttributeOption[]},
  onChangeAttributesSelected: (newAttr: {[key: string]: IAttributeOption[]}) => void,
}

const AttributeFilter = ({
  drawerHandle,
  attributes,
  attributesSelected,
  onChangeAttributesSelected,
}: IAttributeFilterProps) => {
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const DRAWER_WIDTH = screens.xs ? '80%' : '378px';

  const HeaderFilter = () => (
    <Text strong className="text-xl block">
      {t('g.filter_by')}:
    </Text>
  );

  const HeaderAttribute = ({ attribute }: { attribute: IAttribute }) => {
    const attributeLabel = getTranlation(attribute.label);

    return (
      <Text strong>{attributeLabel}</Text>
    );
  };

  const OptionRenderItem = ({ 
    attribute,
    attributeOption,
  }: {
    attribute: IAttribute , 
    attributeOption: IAttributeOption,
  }) => {
    const attrOpsSelected = attributesSelected[attribute.id];
    const isSelected = attrOpsSelected?.filter(attrOp => attrOp.id === attributeOption.id).length === 1;
    const className = isSelected ? 'text-blue-500' : '';

    return (
      <Text className={className}>
        {getTranlation(attributeOption.label)}
      </Text>
    );
  }

  return (
    <Drawer
      width={DRAWER_WIDTH}
      title={<HeaderFilter />}
      placement="right"
      closable={false}
      onClose={() => drawerHandle.hide()}
      open={drawerHandle.visible}
      bodyStyle={{ padding: '24px' }}
    >
      <Row gutter={[16,32]}>
        {attributes.map(attribute => {
          return (
            <Col span={24} key={attribute.id}>
              <CustomCheckboxSelect
                key={`CustomCheckboxSelect-${attribute.id}`}
                rowSelectable
                header={<HeaderAttribute attribute={attribute} />}
                options={attribute.attributeOptions}
                optionsSelected={attributesSelected[attribute.id] || []}
                bordered={false}
                renderOption={(attributeOption) => (
                  <OptionRenderItem 
                    attribute={attribute} 
                    attributeOption={attributeOption} 
                  />
                )}
                onChange={(attributeOptionsSelected) => {
                  const attributesState = _.omit(attributesSelected, [attribute.id]);

                  const newAttributeOptionsSelected = (attributeOptionsSelected.length > 0) 
                    ? {[attribute.id]: attributeOptionsSelected} 
                    : {};

                    onChangeAttributesSelected({
                    ...attributesState, 
                    ...newAttributeOptionsSelected,
                  })
                }}
              />
            </Col>
          )
        })}
      </Row>
    </Drawer>
  );
};

export default AttributeFilter;
