import NotAuthenticatedSign from '@/components/General/NotAuthenticatedSign';
import UserShippingContactFormContainer from '@/components/MyAddresses/UserShippingContactFormContainer';
import useAuth from '@/hooks/useAuth';
import useGoToBackPage from '@/hooks/useGoToBackPage';
import useService from '@/hooks/useService';
import IAddressFormData from '@/interfaces/IAddressFormData';
import IContactFormData from '@/interfaces/IContactFormData';
import ICountry from '@/interfaces/ICountry';
import IShippingContact from '@/interfaces/IShippingContact';
import IUserShippingContactFormContainerData from '@/interfaces/IUserShippingContactFormContainerData';
import getCountries from '@/services/Country';
import {
  editCompleteShippingContact,
  getSingleShippingContact,
} from '@/services/ShippingContact';
import { Card, Row, Col, PageHeader, Form, message, Button } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const EditUserShippingContact = () => {
  const { t } = useTranslation('common');
  const { redirectToBackPage } = useGoToBackPage();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const auth = useAuth();

  const [containerForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [contactForm] = Form.useForm();

  const countriesState = useService<ICountry[]>({
    fetchData: getCountries,
    params: {},
  });

  const userShippingContactsState = useService<IShippingContact>({
    fetchData: getSingleShippingContact,
    params: { id: id },
  });

  useEffect(() => {
    const addressFormData: Partial<IAddressFormData> = {
      postalCodeId:
        userShippingContactsState.data?.address?.postalCode?.id?.toString(),
      countryId:
        userShippingContactsState.data?.address?.postalCode?.city?.state?.country?.id.toString() ??
        '',
      postalCode:
        userShippingContactsState.data?.address?.postalCode?.code.toString() ??
        '',
      extNum: userShippingContactsState.data?.address?.extNum?.toString() ?? '',
      intNum: userShippingContactsState.data?.address?.intNum?.toString() ?? '',
      street: userShippingContactsState.data?.address?.street?.toString() ?? '',
      suburbId:
        userShippingContactsState.data?.address?.suburb?.id.toString() ?? '',
    };
    addressForm.setFieldsValue(addressFormData);

    const contactFormData: Partial<IContactFormData> = {
      addressId: userShippingContactsState.data?.address?.id.toString() ?? '',
      alias: userShippingContactsState.data?.alias ?? '',
      name: userShippingContactsState.data?.name ?? '',
      email: userShippingContactsState.data?.email ?? '',
      phone: userShippingContactsState.data?.phone ?? '',
      userId: userShippingContactsState.data?.user?.id.toString() ?? '',
    };
    contactForm.setFieldsValue(contactFormData);

    const containerFormData: IUserShippingContactFormContainerData = {
      isDefault: userShippingContactsState.data?.isDefault ?? false,
    };

    containerForm.setFieldsValue(containerFormData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userShippingContactsState.loading]);

  const editUserShippingContact = async () => {
    try {
      const addressData: IAddressFormData = await addressForm.validateFields();
      const contactData: IContactFormData = await contactForm.validateFields();
      const containerData: IUserShippingContactFormContainerData =
        await containerForm.validateFields();

      await editCompleteShippingContact({
        addressData: {
          id: contactData.addressId,
          extNum: addressData.extNum,
          intNum: addressData.intNum,
          street: addressData.street,
          suburbId: addressData.suburbId,
          postalCodeId: addressData.postalCodeId,
        },
        contactData: {
          id: id ?? '',
          email: contactData.email,
          alias: contactData.alias,
          name: contactData.name,
          phone: contactData.phone,
          isDefault: containerData.isDefault,
          //TODO: Get current user id
          userId: auth.customer?.id ?? '',
          addressId: contactData.addressId,
        },
      });

      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'editShippingContact',
      });
      navigate('/addresses');
    } catch (e) {
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'editShippingContact',
      });
    }
  };

  if (!auth.authenticated) {
    return <NotAuthenticatedSign />;
  }

  return (
    <Card className='md:pb-96'>
      <Row>
        <Col span={24}>
          <Row>
            <PageHeader
              title={t('g.edit_contact')}
              onBack={() => redirectToBackPage('')}
            />
          </Row>
          <Row justify='center'>
            <Col span={24}>
              <UserShippingContactFormContainer
                form={containerForm}
                addressForm={addressForm}
                contactForm={contactForm}
                countriesState={countriesState}
                initialSuburbs={
                  userShippingContactsState.data?.address.suburb
                    ? [userShippingContactsState.data?.address.suburb]
                    : []
                }
              />
            </Col>
          </Row>
          <Row justify='end'>
            <Col>
              <Button onClick={editUserShippingContact}>
                {t('g.confirm')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default EditUserShippingContact;
