import { useState } from 'react';
import { IItemAvailability } from '@/components/Item/ItemAvailability';
import EPriceFormat from '@/enums/EPriceFormat';
import IProduct from '@/interfaces/IProduct';
import ECanAddToCart from '@/enums/ECanAddToCart';
import getEInventoryStatus from '@/helpers/getEInventoryStatus';
import IWarehouse from '@/interfaces/IWarehouse';
import getTranslation from '@/helpers/getTranslation';
import IProductRelatedToList from '@/interfaces/IProductRelatedToList';
import { getProductsRelatedToItem } from '@/services/RelatedProduct';
import ItemType from '@/types/ItemType';
import IVariant from '@interfaces/IVariant';
import EVisibility from '@/enums/EVisibility';
import Optional from '@/types/Optional';

export interface IConfiguration {
  isManagedSale: boolean,
  priceConfig: {
    price: number;
    priceFormat: EPriceFormat;
    comparativePrice?: number;
  };
  visibility: EVisibility;
  unit?: string;
  itemAvailability?: IItemAvailability;
  disableAddToCart?: boolean;
}

interface IUseProductConfgiguration {
  loading: boolean;
  relatedItems: IProductRelatedToList[];
  configuration: IConfiguration;
  getProductConfig: (product: IProduct) => Promise<void>;
  getRelatedProducts: (
    productId: IProduct['id'], 
    warehouseId: Optional<IWarehouse['id']>,
    entityType: ItemType,
  ) => Promise<void>;
  getWarehouseProductConfig: (
    product: IProduct,
    warehouseId: IWarehouse['id']
  ) => Promise<void>;
}

const useProductConfgiguration = (): IUseProductConfgiguration => {
  const [loading, setLoading] = useState<boolean>(true);
  const [relatedItems, setRelatedItems] = useState<IProductRelatedToList[]>([]);
  const [configuration, setConfiguration] = useState<IConfiguration>({
    isManagedSale: false,
    priceConfig: {
      price: 0,
      priceFormat: EPriceFormat.FromBase,
    },
    disableAddToCart: true,
    visibility: EVisibility.Always,
  });

  const getProductConfig = async (product: IProduct) => {
    setLoading(true);
    // console.log({ productConfigGlobal: product });

    const price = await product.getPrice();

    const productsConfigData = await product.getProductsConfig();
    const productGlobalConfig = productsConfigData[0];

    const newConfig: IConfiguration = {
      isManagedSale: productGlobalConfig.isManagedSale,
      priceConfig: {
        price,
        priceFormat: EPriceFormat.FromBase,
      },
      unit: getTranslation(product.unit.label),
      visibility: productGlobalConfig.visibility,
    };

    setConfiguration(newConfig);
    setLoading(false);
  };

  const getWarehouseProductConfig = async (
    product: IProduct,
    warehouseId: IWarehouse['id']
  ) => {
    // console.log({ productConfigWarehouse: product });
    setLoading(true);

    const newPrice = await product.getPrice({ warehouseId });

    const currentQty = await product.getInventory({ warehouseId });

    const warehouseProductsConfigData = await product.getProductsConfig({
      warehouseId: warehouseId,
    });
    const newWarehouseConfig = warehouseProductsConfigData[0];

    const productsConfigData = await product.getProductsConfig();
    const productGlobalConfig = productsConfigData[0];

    const minInventory = newWarehouseConfig.inventory.min || product.stepper;

    const newConfig: IConfiguration = {
      isManagedSale: productGlobalConfig.isManagedSale,
      priceConfig: {
        price: newPrice,
        priceFormat: newWarehouseConfig.formats.price,
        comparativePrice: newWarehouseConfig.formats.comparativeValue,
      },
      unit: getTranslation(product.unit.label),
      disableAddToCart: newWarehouseConfig.canAddToCart === ECanAddToCart.Never,
      visibility: newWarehouseConfig.visibility,
      itemAvailability: {
        allowSaleWithoutInventory:
          newWarehouseConfig.inventory.allowSaleWithoutInventory,
        currentQty,
        withInventory: getEInventoryStatus(
          currentQty,
          minInventory,
        ),
      },
    };

    setConfiguration(newConfig);
    setLoading(false);
  };

  const getRelatedProducts = async (
    productId: IVariant['id'],
    warehouseId: Optional<IWarehouse['id']>,
    entityType: ItemType,
  ) => {
    try {
      setLoading(true);
      const relatedProducts = await getProductsRelatedToItem({
        warehouseId,
        skip: 0,
        limit: 10,
        productsData: [ { id: productId, entityType, }],
      });

      setRelatedItems(relatedProducts.data);
    } catch (error) {
      setRelatedItems([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    relatedItems,
    configuration,
    getProductConfig,
    getRelatedProducts,
    getWarehouseProductConfig,
  };
};

export default useProductConfgiguration;
