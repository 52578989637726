import React from 'react';
import { Checkbox, Col, Radio, Row, Space, Tag, Typography } from 'antd';
import IMixSection from '@/interfaces/IMixSection';
import IMixTopping from '@/interfaces/IMixTopping';
import ToppingType from '@/types/ToppingType';
import CustomCheckboxSelect from '@/components/CustomCheckboxSelect';
import CustomRadioSelect from '@/components/CustomRadioSelect';
import CustomQtySelect from '@/components/General/CustomQtySelect';
import getTranslation from '@/helpers/getTranslation';
import { LoadingOutlined } from '@ant-design/icons';
import formatCurrency from '@/utils/formatCurrency';
import { SelectedToppingsType } from '@/containers/views/ItemDetails/MixDetailsView';
import TypeWithSelectedQuantity from '@/types/TypeWithSelectedQuantity';
import { omit } from 'lodash'
import { SelectedValueType } from '@/containers/views/ItemDetails/useMixConfiguration';
import getInputType from '@/helpers/getInputType';
import { useTranslation } from 'react-i18next';
import QtyInput from '@/components/General/QtyInput';

interface IMixToppingsProp {
  toppings: IMixTopping[];
  sectionRestrictions: IMixSection['restrictions'];
  sectionId: IMixSection['id'],
  loading?: boolean;
  selectedOptions: SelectedToppingsType[];
  onChange: (
    topping: IMixTopping, 
    sectionId: IMixSection['id'], 
    type: ToppingType, 
    value: SelectedValueType
  ) => void;
}

const MixToppings = ({
  toppings,
  sectionRestrictions,
  loading = false,
  selectedOptions,
  sectionId,
  onChange,
}: IMixToppingsProp) => {
  const { t } = useTranslation('common');

  const inputType = getInputType(sectionRestrictions, toppings);

  const formatQtyOptions = (options: SelectedToppingsType[]): TypeWithSelectedQuantity<IMixTopping>[] => {
    const newOptions: TypeWithSelectedQuantity<IMixTopping>[] = options.map(option => {
      const newOption = omit(option, ['sectionId']);

      return {
        ...newOption,
        qtySelected: newOption.qtySelected ? newOption.qtySelected : 0,
      };
    });

    return newOptions;
  };

  const formatRadioOption = (options: SelectedToppingsType[]): IMixTopping => {
    const newOptions: IMixTopping[] = options.map(option => {
      const newOption = omit(option, ['sectionId', 'qtySelected']);

      return newOption;
    });

    return newOptions[0];
  };

  const formatCheckBoxOption = (options: SelectedToppingsType[]): IMixTopping[] => {
    const newOptions: IMixTopping[] = options.map(option => {
      const newOption = omit(option, ['sectionId', 'qtySelected']);

      return newOption;
    });

    return newOptions;
  };

  const getMixRow = (mixTopping: IMixTopping) => (
    <Row>
      <Col span={24}>
        <Typography.Text>
          {getTranslation(mixTopping.name)} 
          {
            (mixTopping.restrictions.max &&
            mixTopping.restrictions.max > 1) ? (
              <Tag style={{ marginLeft: "8px" }}>
                {t('g.max')}. {mixTopping.restrictions.max}
              </Tag>
            ) : null
          }
        </Typography.Text>
      </Col>
      {
        (mixTopping.priceRestrictions.price !== 0 && !loading) && (
          <Col span={24}>
            <Typography.Title level={5}>
              {`+${formatCurrency(mixTopping.priceRestrictions.price)} /${mixTopping.product.unit.code}`}
            </Typography.Title>
          </Col>
        )
      }
      {
        (loading) && (
          <Col span={24}>
            <LoadingOutlined />
          </Col>
        )
      }
    </Row>
  );

  const getMixQtyInput = (
    option: IMixTopping & { qtySelected: number }, 
    onChange: (option: IMixTopping, qtySelected: number) => void
  ) => {
    const { qtySelected, isAvailable } = option;
    const stepper = option.restrictions.multiplier !== 0 ? option.restrictions.multiplier : 1;
    
    if (!isAvailable) return (
      <Tag>
        {t('g.not_available') }
      </Tag>
    );

    // const selections = qtySelected === 0 ? 0 : ((qtySelected - option.restrictions.min) / stepper) + 1;
    const qty = option.qtySelected ?? 0;
    const { min } = option.restrictions;
    const { multiplier } = option.restrictions;
    const selections = qty === 0 ? 0 : Math.round(((qty - min) / multiplier) + 1);
    // const netQty = qtySelected > 0 ? option.restrictions.min + (stepper * (selections - 1)) : 0;
    const decrease = selections - 1 === 0 ? 0 : option.restrictions.min + (stepper * (selections - 2));

    return (
      <Col>
        <QtyInput
          value={selections}
          label={`${qtySelected} ${option.product.unit.code} *`}
          max={option.restrictions.max}
          min={0}
          onDecrease={() => onChange(option, decrease < 0 ? 0 : decrease)}
          onIncrease={() => onChange(option, option.restrictions.min + (stepper * selections))}
        />
      </Col>
    )
  };

  const getMixCheckboxInput = (option: IMixTopping) => {
    const { isAvailable } = option;

    if (!isAvailable) return (
      <Tag>
        {t('g.not_available') }
      </Tag>
    );

    const isSelected = selectedOptions.find(selOpt => selOpt.id === option.id);

    return (
      <Space 
        direction="vertical" 
        size={1}
        align="center"
      >
        <Checkbox 
          id={option.id} 
          value={JSON.stringify(option)} 
          onClick={(event: any) => {
            const optionString: string = event.target.value;
            const optionParse: IMixTopping = JSON.parse(optionString);

            const checked: boolean = event.target.checked;

            onChange(optionParse, sectionId, "checkbox", {
              checked,
            });
          }}
        />
        <Typography.Text style={{ fontSize: "10px" }}>
          {`${isSelected ? option.restrictions.min : 0} ${option.product.unit.code}`} *
        </Typography.Text>
      </Space>              
    );
  };

  const getMixRadioInput = (option: IMixTopping) => {
    const { isAvailable } = option;

    if (!isAvailable) return (
      <Tag>
        {t('g.not_available') }
      </Tag>
    );
    
    const isSelected = selectedOptions.find(selOpt => selOpt.id === option.id);

    return (
      <Space 
        direction="vertical" 
        size={1}
        align="end"
      >
        <Radio 
          id={option.id} 
          className="m-0"
          value={JSON.stringify(option)} 
          onClick={(event: any) => {
            const optionString: string = event.target.value;
            const optionParse = JSON.parse(optionString);
            
            const checked: boolean = event.target.checked;
            console.log('aa', event.target.checked);

            onChange(optionParse, sectionId, "radio", {
              checked,
            });
          }} 
        />
        <Typography.Text style={{ fontSize: "10px" }}>
          {`${isSelected ? option.restrictions.min : 0} ${option.product.unit.code}`} *
        </Typography.Text>
      </Space>
    );
  };

  return (
    <Row className="mt-5">
      <Col span={24}>
        {
          inputType === 'checkbox' && (
            <CustomCheckboxSelect 
              bordered={false}
              options={toppings}
              optionsSelected={formatCheckBoxOption(selectedOptions)}
              renderOption={getMixRow}
              renderCheckbox={getMixCheckboxInput}
              onClick={(opt, chk) => onChange(
                opt, 
                sectionId, 
                "checkbox",
                {
                  checked: chk,
                } 
              )}
            />
          )
        }
         {
          inputType === 'radio' && (
            <CustomRadioSelect
              bordered={false}
              options={toppings}
              optionSelected={formatRadioOption(selectedOptions)}
              renderOption={getMixRow}
              renderRadio={getMixRadioInput}
              onChange={(opt, chk) => onChange(
                opt, 
                sectionId, 
                "radio",
                {
                  checked: chk,
                } 
              )}
            />
          )
        }
        {
          inputType === 'qty' && (
            <CustomQtySelect 
              options={formatQtyOptions(selectedOptions)}
              renderItem={getMixRow}
              renderQty={getMixQtyInput}
              onChange={(opt, qty) => onChange(
                opt, 
                sectionId, 
                "qty",
                {
                  qty,
                } 
              )}
            />
          )
        
        }
      </Col>
    </Row>
  );
};

export default MixToppings;
