import React, { useEffect, useState } from 'react';
import { 
  Col,
  Drawer, 
  Grid,
  List,
  Modal,
  Row,
} from 'antd';
import Menu from '@/containers/_layouts/Menu';
import SiderFooter from '@/containers/_layouts/SiderFooter';
import { useTranslation } from 'react-i18next';
import { CalculatorOutlined } from '@ant-design/icons';
import IQuantifier from '@/interfaces/IQuantifier';
import useModal from '@/hooks/useModal';
import QuantifiersList from '@/components/Quantifier/QuantifiersRadioSelect';
import Quantifier from '@/components/Quantifier/Quantifier';
import useService from '@/hooks/useService';
import getQuantifiers from '@/services/QuantifierService';

const { useBreakpoint } = Grid;

interface ISider {
  collapsed: boolean;
  updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISiderOption {
  key: string,
  label: string,
  icon: React.ReactNode,
  onClick: () => void,
}

const Sider = ({
  collapsed,
  updateCollapsed,
}: ISider) => {
  const { t } = useTranslation('common');
  const onClose = () => updateCollapsed(!collapsed);
  const screens = useBreakpoint();

  const DRAWER_WIDTH = screens.xs ? '80%' : '378px';

  const quantifierListModal = useModal();
  const quantifierDetailModal = useModal();

  const [quantifiers, setQuantifiers] = useState<IQuantifier[]>([]);
  const [quantifierSelected, setQuantifierSelected] = useState<IQuantifier | undefined>();

  const quantifiersState = useService<IQuantifier[]>({
    fetchData: getQuantifiers,
    params: {
      isActive: 1,
    },
  });

  useEffect(() => {
    if(quantifiersState.data){
      setQuantifiers(quantifiersState.data);
      setQuantifierSelected(quantifiersState.data[0]);
    }
    
  }, [quantifiersState.data]);

  const quantifierMenuOption = quantifiers.length > 0 ? [{
    key: 'quantifiers',
    label: t('g.quantifiers'),
    icon: <CalculatorOutlined style={{ fontSize: 24 }} />,
    onClick: () => quantifierListModal.show(),
  }] : [];

  const menuOptions: ISiderOption[] = [
    ...quantifierMenuOption,
  ];

  return (
    <>
      <Modal
        destroyOnClose
        bodyStyle={{ maxHeight: 450, overflow: 'auto' }}
        open={quantifierListModal.visible}
        confirmLoading={quantifierListModal.isLoading}
        title={t('g.select_your_option_of_interest')}
        okText={t('g.continue')}
        cancelText={t('g.cancel')}
        onCancel={() => quantifierListModal.hide()}
        onOk={() => {
          quantifierListModal.hide();
          quantifierDetailModal.show();
        }}
      >
        <QuantifiersList 
          quantifiers={quantifiersState.data || []}
          selected={quantifierSelected?.id}
          onChange={(quantifierId) => {
            setQuantifierSelected(quantifiers.filter(quantifier => quantifier.id === quantifierId)[0]);
          }} 
        />
      </Modal>
      <Modal
        width={1000}
        footer={[]}
        destroyOnClose
        open={quantifierDetailModal.visible}
        confirmLoading={quantifierDetailModal.isLoading}
        onCancel={() => {
          quantifierDetailModal.hide();
          quantifierListModal.show();
        }}
      >
        {quantifierSelected && (
          <Quantifier quantifier={quantifierSelected} />
        )}
      </Modal>
      <Drawer
        title={<Menu onCloseDrawer={onClose} />}
        footer={<SiderFooter  onCloseDrawer={onClose} />}
        closable={false}
        placement="left"
        open={!collapsed}
        onClose={onClose}
        width={DRAWER_WIDTH}
        bodyStyle={{ padding: 0 }}
        drawerStyle={{  
          padding: '0', 
        }}
      >
        {menuOptions.length > 0 && (
          <List
            size="large"
            itemLayout="horizontal"
            dataSource={menuOptions}
            renderItem={item => (
              <List.Item onClick={item.onClick} key={item.key} style={{ cursor: 'pointer' }}>
                <List.Item.Meta
                  avatar={item.icon}
                  title={item.label}
                />
              </List.Item>
            )}
          />
        )}
      </Drawer>
    </>
  );
};

export default Sider;
