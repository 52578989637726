import React, { useState } from 'react';
import { Button, Card, Col, Image, Row, Space, Typography } from 'antd';
import { useForm } from "antd/lib/form/Form";
import useBreakpoints from '@/hooks/useBreakpoint';
import PasswordRecoveryRequestForm, { 
  IPasswordRecoveryRequestValues,
} from '@/components/Forms/PasswordRecoveryRequestForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { validatePasswordRecovery } from '@/services/PasswordRecoveryService';
import { BgPatternImage, LogoImage } from '@/assets/images';
import isUrl from '@/helpers/isUrl';

const { Title, Paragraph } = Typography;

const PasswordRecoveryRequest = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const [passwordRecoveryRequestForm] = useForm<IPasswordRecoveryRequestValues>();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [emailSend, setEmailSend] = useState<boolean>(false);

  const customImage = process.env.REACT_APP_BG_LOGIN_IMG;
  
  const backgroundImage = customImage 
    ? isUrl(customImage) ?  `URL(${customImage})` : customImage
    : `URL(${BgPatternImage})`;

  const onSendEmail = async () => {
    try {
      setLoading(true);
      const { email } = await passwordRecoveryRequestForm.validateFields();
      const result = await validatePasswordRecovery(email);

      if (result.code === 'emailNotValid') {
        setEmailError(true);
        return;
      }

      setEmailSend(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      className="min-h-screen"
      style={{ backgroundImage }}
      justify="center"
      align="middle"
    >
      <Col 
        flex={isBreakpointEqualsAndBelow('md') ? "70%" : "35%"}
      >
        <Card className="px-6 min-h-full">
          <Row justify="center">
            <Col>
              <Image 
                preview={false}  
                width="100%"
                src={process.env.REACT_APP_LOGO_URL || LogoImage}
              />
            </Col>
          </Row>
          { emailSend ? (
            <Row>
              <Col span={24}>
                <Title level={5} className="text-center mt-2">
                  {t('g.the_email_was_sent_successfully')}
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph className="text-center">
                  {t('g.we_have_sent_you_an_email_with_everything_you_need_to_reset_your_password')}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    <Button 
                      type="link"
                      onClick={() => navigate('/')}

                    >
                      {t('g.back')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
             <Row>
              <Col span={24}>
                <Title level={5} className="text-center mt-2">
                  {t('g.password_recovery')}
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph className="text-center">
                  {t('g.we_will_send_an_email_to_your_email_with_what_is_necessary_to_reset_your_password')}
                </Paragraph>
              </Col>
              <Col span={24}>
                <PasswordRecoveryRequestForm 
                  error={emailError}
                  setError={setEmailError}
                  form={passwordRecoveryRequestForm}
                />
              </Col>
              <Col span={24}>
                <Space style={{ float: "right" }}>
                  <Button 
                    type="text"
                    onClick={() => navigate('/')}
                  >
                    {t('g.back')}
                  </Button>
                  <Button 
                    type="primary"
                    onClick={onSendEmail}
                    loading={loading}
                  >
                    {t('g.send_email')}
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordRecoveryRequest;
