import useService from "@/hooks/useService";
import ICustomer from "@/interfaces/ICustomer";
import IOrder from "@/interfaces/IOrder";
import { getOrders } from "@/services/OrdersService";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

interface IMyOrdersRes {
  myOrders: IOrder[];
  isLoading: boolean;
  listParams: { [k: string]: any };
  setListParams: Dispatch<SetStateAction<{[k: string]: any;}>>;
  goBack: () => void;
}

const MyOrders = (customerId: ICustomer['id']): IMyOrdersRes => {
  const navigate = useNavigate(); 
  const [listParams, setListParams] = useState<{ [k: string]: any }>({});

  const myOrdersState = useService<IOrder[]>({
    fetchData: getOrders,
    params: {
      customerId,
      ...listParams,
    }
  });

  useEffect(() => {
    myOrdersState.setCurrentParams({
      customerId, 
      params: listParams,
    });
  }, [listParams]);

  function goBack(){
    navigate('/');
  }

  return {
    myOrders: myOrdersState.data || [],
    isLoading: myOrdersState.loading,
    listParams,
    setListParams,
    goBack,
  };
};

export default MyOrders;
