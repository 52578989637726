import IAttributeDto from '@/interfaces/IAttributeDto';
import IAttribute from '@/interfaces/IAttribute';
import { adapterDtoToAttributeOption } from './AttributeOptionAdapter';

export const adapterDtoToAttribute = (attribute: IAttributeDto): IAttribute => {
  const { 
    id,
    labelEn,
    labelEs,
    attribute_options,
  } = attribute;

  const attributeOptions = attribute_options.map(adapterDtoToAttributeOption);

  return {
    id,
    label: {
      en: labelEn,
      es: labelEs,
    },
    attributeOptions,
  };
};
