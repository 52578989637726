import { Col, List, Row, Spin, Divider, Typography, Image, Space } from 'antd';
import { ItemExtra } from "@/services/FilteredItem";
import { ListGridType } from 'antd/lib/list';
import 'react-lazy-load-image-component/src/effects/blur.css';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import Price from '@/components/General/Price';
import EPriceFormat from '@/enums/EPriceFormat';
import isProduct from '@/helpers/isProduct';
import ItemCardRefactor from '@/components/ItemCardRefactor';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import DeliveryMethod from '@/components/DeliveryMethod';
import getTranlation from '@/helpers/getTranslation';
import goToItemDetails from '@/helpers/goToItemDetails';
import isMix from '@/helpers/isMix';
import isVariant from '@/helpers/isVariant';
import { NotFoundImage } from '@/assets/images';

const { Title } = Typography;

interface IHomeItemsListProps{
  elements: ItemExtra[],
  hasMore: boolean,
  isLoading: boolean,
  loadMoreData: () => void,
  showProductsPrices?: boolean,
  showMixesPrices?: boolean,
  notFoundImage: string;
  isEmpty: boolean;
}

const HomeItemsList = ({
  elements,
  hasMore,
  isLoading,
  loadMoreData,
  showProductsPrices = true,
  showMixesPrices = true,
  notFoundImage,
  isEmpty,
}: IHomeItemsListProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const gridRules: ListGridType = { gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 };

  const ItemListThumbnail = ({ item }: { item: ItemExtra }) => {
    const itemImages = item.imgs;
    const previewImage = (itemImages && itemImages[0]?.url) ? itemImages[0].url : NotFoundImage;

    return (
      <Image
        alt={item.name.es}
        fallback={NotFoundImage}
        preview={false}
        src={previewImage}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          aspectRatio: 1,
          marginBottom: '8px',
          backgroundColor: '#f3f3f3',
        }}
      />
    );
  };

  const ItemListTitle = ({ item }: { item: ItemExtra }) => {
    return (
      <Row>
        <Col span={24}>
          <Title level={5}>{getTranlation(item.name)}</Title>
        </Col>
        {/* <Divider /> */}
        <Col span={24}>
          <Space direction="vertical" size={2}>
            {
              item.isProductWithShippingType ? (
                <DeliveryMethod deliveryMethod={EDeliveryMethod.Shipping} />
              ): null
            }
            {
              item.isProductWithDeliveryPoint ? (
                <DeliveryMethod deliveryMethod={EDeliveryMethod.Pickup} />
              ): null
            }
          </Space>
        </Col>
      </Row>
    );
  };

  const ItemListContent = ({ item }: { item: ItemExtra }) => {
    const priceFormat = isProduct(item) ? EPriceFormat.Regular : EPriceFormat.FromBase;

    if(item.key === 'product' && !showProductsPrices){
      return <></>;
    }

    if(item.key === 'mix' && !showMixesPrices){
      return <></>;
    }

    return(
      <Row gutter={[16, 16]} className="">
        <Col span={24} className="">
          <Price
            price={item.calculatedCost || 0}
            priceFormat={priceFormat}
            stylePrice={{
              fontSize: 28,
              fontWeight: 500,
            }}
          />
        </Col>
      </Row>
    );
  };

  const getRowKey = (item: ItemExtra) => {
    if (isMix(item)) return `mix-${item.id}`;
    if (isVariant(item)) return `variant-${item.id}`;
    return `product-${item.id}`;
  };

  if (isEmpty) return (
    <Row align="middle" justify="center" >
      <Col>
        <Image 
          preview={false}
          alt='items not found' 
          src={notFoundImage} 
        />
      </Col>
    </Row>
  );

  return (
    <InfiniteScroll
      dataLength={elements.length}
      next={() => loadMoreData()}
      hasMore={hasMore}
      loader={<Spin style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />}
      endMessage={!isLoading 
        ? (<Divider plain>{t('hints.no_more_data')}</Divider>)
        : null
      }
      scrollableTarget="mainContent"
      style={{
        overflowX: 'hidden',
        padding: '0 8px'
      }}
    >
      <List
        grid={gridRules}
        loading={isLoading}
        dataSource={elements}
        rowKey={getRowKey}
        renderItem={(item: ItemExtra) => (
          <List.Item 
            className='item-card' 
            onClick={() => goToItemDetails(item, navigate)}
          >
            <ItemCardRefactor
              hideBorder
              layout='vertical'
              thumb={<ItemListThumbnail item={item} />}
              title={<ItemListTitle item={item} />}
              content={<ItemListContent item={item} />}
            />
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

export default HomeItemsList;
