import React, { useEffect, useState } from 'react';
import { 
  ArrowLeftOutlined, 
  ArrowRightOutlined,
  CarOutlined,
  ShopOutlined, 
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Form, message, Radio, Row, Spin, Typography, Checkbox } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import CheckoutDeliveryPagePOS from '@/containers/views/Checkout/CheckoutDeliveryPagePOS';
import CheckoutGroupPickUpPage from '@/containers/views/Checkout/CheckoutGroupPickUpPage';
import CheckoutGroupDeliveriesPage from '@/containers/views/Checkout/CheckoutGroupDeliveriesPage';
import CheckoutPaymentServicePage from '@/containers/views/Checkout/CheckoutPaymentServicePage';
import CheckoutSalesOriginPage, { ISalesOriginForm } from '@/containers/views/Checkout/CheckoutSalesOriginPage';
import EDeliveryType from '@/enums/EDeliveryType';
import ECheckoutStep from '@/enums/ECheckoutStep';
import IContactFormData from '@/interfaces/IContactFormData';
import IAddressFormData from '@/interfaces/IAddressFormData';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IPaymentService from '@/interfaces/IPaymentService';
import IPreCheckout from '@/interfaces/IPreCheckout';
import useAuth from '@/hooks/useAuth';
import useLocation from '@/hooks/useLocation';
import CheckoutPayPage from './CheckoutPayPage';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import IOpenpayToken from '@/interfaces/IOpenpayToken';
import useShoppingCartItemsList from '@/hooks/useShoppingCartItemsList';
import ShoppingCartDrawer from '@/components/ShoppingCart/ShoppingCartDrawer';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';
import { checkout } from '@/services/CheckoutService';
import validatePreCheckoutData from '@/helpers/validatePreCheckoutData';
import { useNavigate } from 'react-router-dom';
import useShoppingCart from '@/hooks/useShoppingCart';
import EPaymentStatus from '@/enums/EPaymentStatus';
import { getSalesOrigins } from '@/services/SalesOriginsService';
import ISaleOrigin from '@/interfaces/ISaleOrigin';
import ContactForm from '@/components/MyAddresses/ContactForm';
import useGroupDeliveries from '@/hooks/useGroupDeliveries';
import styled from 'styled-components';
import { BreakpointMap } from 'antd/lib/_util/responsiveObserve';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CheckoutDiscountSection from './CheckoutDiscountSection';

const RadioGroupPrimary = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    height: auto;
    width: 275px;
    padding: 3px;
    border-radius: 3px;

    .radio-group-label {
      color: inherit;
      display: flex;
      align-items: center;

      .anticon {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    &:hover .radio-group-label {
      text-decoration: underline;
    }
  }
`;

export interface IOnPay {
  tokeId?: IOpenpayToken['id'];
  deviceSessionId?: string;
  paymentStatus?: EPaymentStatus;
  paymentStrategy?: EPaymentStrategy | string | number;
}

const CheckoutPOS = () => {
  const { t } = useTranslation('common');
  const { authenticated, customer } = useAuth();
  const { selectedWarehouse } = useLocation();
  const navigate = useNavigate();

  const { shoppingCart } = useShoppingCart();

  const { 
    active,
    productsPrice,
    shippingPrice,
    totalDiscount,
    setActive, 
    setTotalDiscount,
    setProductsPrice,
    setShippingPrice
  } = useCheckoutDrawer();

  const {
    loadingCart,
    shoppingCartItems,
    totalPrice,
  } = useShoppingCartItemsList();

  const { 
    Contact, 
    Delivery, 
    Loading, 
    GroupDeliveries,
    GroupPickUps,
    PaymentService,
    CheckoutPay
  } = ECheckoutStep;

  const [step, setStep] = useState<ECheckoutStep>(Loading);
  const [couponCode, setCouponCode] = useState<string>('');
  const [checkoutData, setCheckoutData] = useState<Partial<IPreCheckout>>();
  const [deliveryType, setDeliveryType] = useState<SegmentedValue>(EDeliveryType.Shipping);
  const [deliveryPointId, setDeliveryPointId] = useState<IDeliveryPoint['id']>();
  const [paymentServiceCode, setPaymentServiceCode] = useState<IPaymentService['code']>();
  const [paymentStrategy, setPaymentStrategy] = useState<SegmentedValue>(EPaymentStrategy.Card);
  const [salesOrigins, setSalesOrigins] = useState<ISaleOrigin[]>([]);
  const [checkoutBreakpoints, setCheckoutBreakpoints] = useState<BreakpointMap>({
    xs: 'auto',
    sm: 'auto',
    md: 'auto',
    lg: 'auto',
    xl: 'auto',
    xxl: 'auto',
  });

  const deliveryGroupsHook = useGroupDeliveries({
    shoppingCartItems: shoppingCartItems ?? undefined,
    destAddress: checkoutData?.destAddress ?? undefined,
    postalCode: checkoutData?.destAddress?.postalCode ?? undefined,
    warehouseId: selectedWarehouse ?? undefined,
  });
  
  const [salesOriginCodeForm] = Form.useForm<ISalesOriginForm>();
  const [addressForm] = Form.useForm<IAddressFormData>();
  const [contactForm] = Form.useForm<IContactFormData>();

  const onSavePartialCheckoutData = (data: Partial<IPreCheckout>) => {
    setCheckoutData(prev => ({ ...prev, ...data }));
  };

  const goToDeliveries = async () => {
    const id = customer?.id;
    
    const contactFormValues = await contactForm.validateFields();
    const { email, name, phone } = contactFormValues;

    onSavePartialCheckoutData({ customer: { id, email, name, phone } });
    setStep(Delivery);
  };

  const goToGroupDeliveriesNotAuth = async () => {
    const values = await addressForm.validateFields();
    const { street, extNum, intNum, postalCode, suburbId } = values;

    onSavePartialCheckoutData({ 
      destAddress: { 
        phone: checkoutData?.customer?.phone ?? '',
        street, 
        extNum, 
        intNum, 
        postalCode, 
        suburbId, 
      },
      deliveryType: EDeliveryType.Shipping, 
    });
    setStep(GroupDeliveries);
  };

  const goToGroupPickUp = async () => {
    if (!deliveryPointId) {
      message.error({
        content: t('g.select_delivery_point'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    };

    onSavePartialCheckoutData({ 
      deliveryPointId,
      deliveryType: EDeliveryType.PickUp,
    });
    setStep(GroupPickUps);
  };

  const onPay = async (props: IOnPay) => {
    if (!checkoutData) return;

    try {
      const vaidatePreCheckoutData = validatePreCheckoutData(checkoutData);
      const salesOriginCodeFormValues = await salesOriginCodeForm.validateFields();
  
      const { tokeId, deviceSessionId, paymentStatus } = props;
      
      const totalAmount = parseFloat((productsPrice + shippingPrice - totalDiscount).toFixed(2));

      const checkoutRequest = {
        amount: totalAmount,
        couponCode: totalDiscount > 0 ? couponCode : undefined,
        token: tokeId ?? '',
        deviceSessionId: deviceSessionId ?? '',
        paymentStrategy,
        paymentStatus: paymentStatus ?? EPaymentStatus.Pending,
        salesOriginCode: salesOriginCodeFormValues.salesOriginCode,
        ...vaidatePreCheckoutData,
      }

      const isCheckout = await checkout(checkoutRequest);
  
      if (!isCheckout || !isCheckout.success || !isCheckout.orderId) {
        message.error({
          content: t(`checkout.${isCheckout.errorCode}`),
          duration: 6,
          key: 'CheckoutKey',
        });
  
        navigate('/shopping_cart');
  
        return;
      }
  
      navigate(`/orders/details/${isCheckout.orderId}`);
    } catch (error) {
      console.error('on pay error:',error);
    }
  };

  const onPayError = async (error: IOpenpayTokenError) => {
    message.error({
      content: t('g.error'),
      duration: 2,
      key: 'CheckoutKey',
    });

    console.error(error.description);
  };

  const continueStep = async () => {
    // continue with deliveries
    if (step === Contact) {
      await goToDeliveries();
    }

    // continue with group deliveries not auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.Shipping
      && !authenticated
    ) {
      await goToGroupDeliveriesNotAuth();
    }
    
    // continue with group deliveries auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.Shipping
      && authenticated
      ) {
        // await goToGroupDeliveriesAuth();
      await goToGroupDeliveriesNotAuth();
    }

    // continue with group pick up
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.PickUp
    ) {
      goToGroupPickUp();
    }

    // continue with payment service
    if (step === GroupPickUps) {
      setStep(PaymentService);
    }

    // continue with payment service
    if (step === GroupDeliveries) {
      if (deliveryGroupsHook.hasEmptyDeliveryData) {
        return;
      }
      onSavePartialCheckoutData({ shippingTypeOrder: deliveryGroupsHook.shippingTypeOrder });
      setStep(PaymentService);
    }

    if (step === PaymentService) {
      if (!paymentServiceCode) {
        message.error({
          content: t('g.select_payment_service'),
          duration: 2,
          key: 'CheckoutKey',
        });

        return;
      }

      onSavePartialCheckoutData({ paymentServiceCode });
      setStep(CheckoutPay);
    }
  };

  const previousStep = () => {
    if (step === Contact && !authenticated) return navigate('/shopping_cart');
    if (step === Delivery && authenticated) return navigate('/shopping_cart');
    if (step === Delivery && !authenticated) setStep(Contact);
    if (step === GroupDeliveries || step === GroupPickUps) {
      setStep(Delivery);
      setShippingPrice(0);
    };
    if (step === PaymentService && deliveryType === EDeliveryType.Shipping) setStep(GroupDeliveries);
    if (step === PaymentService && deliveryType === EDeliveryType.PickUp) setStep(GroupPickUps);
  };

  const continueText = () => {
    if (
      step === Contact 
      || step === Delivery
      || step === GroupDeliveries
      || step === GroupPickUps
    ) return t('g.continue');
    
    if (step === PaymentService) return t('g.go_to_pay')
  };

  const previousText = () => {
    if (step === Contact && !authenticated) return t('g.back_to_cart');
    if (step === Delivery && authenticated) return t('g.back_to_cart');
    if (step === Delivery && !authenticated) return t('g.back_to_contact');
    if (step === GroupDeliveries) return t('g.back_to_delivery_types');
    if (step === GroupPickUps) return t('g.back_to_delivery_points');
    if (
      step === PaymentService
      && deliveryType === EDeliveryType.Shipping
    ) return t('g.back');
    if (
      step === PaymentService
      && deliveryType === EDeliveryType.PickUp
    ) return t('g.back');
  };

  const getFirstPage = () => {
    setStep(Contact);
  };

  useEffect(() => {
    getFirstPage();
    // eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    if (loadingCart) {
      setStep(Loading);
      return;
    }

    getFirstPage();
    // eslint-disable-next-line
  }, [loadingCart]);

  useEffect(() => {
    setProductsPrice(totalPrice);

    // eslint-disable-next-line
  }, [totalPrice]);

  useEffect(() => {
    getSalesOrigins().then(salesOriginsArr => {
      setSalesOrigins(salesOriginsArr);
    });
  }, []);

  useEffect(() => {
    setShippingPrice(0);
    setTotalDiscount(0);
    setCouponCode('');
  }, []);

  useEffect(() => {
    if (!selectedWarehouse) {
      onSavePartialCheckoutData({ 
        ...checkoutData,
        warehouseId: undefined,
      });

      return;
    }

    onSavePartialCheckoutData({ 
      ...checkoutData,
      warehouseId: selectedWarehouse,
    });
    // eslint-disable-next-line
  }, [selectedWarehouse]);

  useEffect(() => {
    if (!shoppingCart) {
      onSavePartialCheckoutData({
        ...checkoutData,
        shoppingCartId: undefined
      });

      return;
    }

    onSavePartialCheckoutData({
      ...checkoutData,
      shoppingCartId: shoppingCart.id
    });
  }, [shoppingCart]);

  useEffect(() => {
    switch (step) {
      case Contact:  
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
      case Delivery:  
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'});
      break;
      case Loading:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
      case GroupDeliveries:
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'});
      break;
      case GroupPickUps:
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'}); 
      break;
      case PaymentService:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});  
      break;
      case CheckoutPay:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
    }

  }, [step]);

  useEffect(() => {
    if(step !== ECheckoutStep.GroupDeliveries && step !== ECheckoutStep.GroupPickUps) return;

    setStep(ECheckoutStep.Delivery);
  }, [deliveryType]);

  const onChangeSaleToPublic = (e: CheckboxChangeEvent) => {
    const isCheck = e.target.checked;
    contactForm.resetFields();
    
    if(isCheck){
      contactForm.setFieldsValue({
        alias: t('g.sale_to_public'),
        name: t('g.public_on_general'),
        email: customer?.email || 'email@no-aply.com',
      });
    }
  };

  const deliveryMethodOptions = [
    {
      label: (
        <Typography.Text className="radio-group-label">
          <CarOutlined /> {t('g.home_delivery')}
        </Typography.Text>
      ),
      value: EDeliveryType.Shipping,
    },
    {
      label: (
        <Typography.Text className="radio-group-label">
          <ShopOutlined /> {t('g.pick_up')}
        </Typography.Text>
      ),
      value: EDeliveryType.PickUp,
    }
  ];

  return (
    <Card className="h-full" bordered={false}>
      <ShoppingCartDrawer 
        active={active}
        onClose={() => setActive(false)}
        shoppingCartItems={shoppingCartItems}
        subtotal={productsPrice}
        shippingTotal={shippingPrice}
        discount={totalDiscount}
      />
      <Row gutter={[0, 20]} justify="center">
        <Col 
          xs={{ span: checkoutBreakpoints.xs }}
          sm={{ span: checkoutBreakpoints.sm }}
          md={{ span: checkoutBreakpoints.md }}
          lg={{ span: checkoutBreakpoints.lg }}
          xl={{ span: checkoutBreakpoints.xl }}
          xxl={{ span: checkoutBreakpoints.xxl }}
        >
          {
            step === Loading && (
              <Row justify="center">
                <Col>
                  <Spin size="large" className="m-4" />
                </Col>
              </Row>
            )
          }
          {
            step === Contact && (
              <Row align="middle">
                <Col span={24}>
                  <Row>
                    <Typography.Title level={2}>
                      {t('g.client')}
                    </Typography.Title>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col flex="auto">
                      <Typography.Paragraph>
                        {t('hints.complete_the_following_form')}
                      </Typography.Paragraph>
                    </Col>
                    <Col flex="none" className="text-right">
                      <Checkbox onChange={onChangeSaleToPublic} style={{ marginBottom: '14px' }}>
                        {t('g.sale_to_public')}
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <ContactForm 
                    form={contactForm}
                  />
                </Col>
              </Row>
            )
          }
          {
            (
              (
                step === Delivery
                && selectedWarehouse
              )
              || (
                step === GroupDeliveries
                && shoppingCartItems
                && checkoutData?.destAddress
                && selectedWarehouse
              )
              || (
                step === GroupPickUps
                && shoppingCartItems
                && selectedWarehouse
                && deliveryPointId
              )
            ) && (
              <Row>
                <Col span={24} className="text-center">
                  <Typography.Title level={3} className="underline">
                    {t('g.merchandise_delivery')}
                  </Typography.Title>
                  <Typography.Paragraph>
                    {t('hints.select_a_delivery_method')}
                  </Typography.Paragraph>
                </Col>
                <Col span={24} className="text-center">
                  <RadioGroupPrimary
                    value={deliveryType} 
                    onChange={(e: any) => setDeliveryType(e.target.value)} 
                    optionType="button"
                    options={deliveryMethodOptions} 
                    size="large"
                    buttonStyle="solid"
                    style={{ padding: 10 }}
                  />
                </Col>
              </Row>
            )
          }
          {
            (
              step === Delivery
              && selectedWarehouse
              && shoppingCart
            ) && (
              <CheckoutDeliveryPagePOS
                shoppingCartId={shoppingCart.id}
                addressForm={addressForm}
                deliveryType={deliveryType}
                deliveryPointId={deliveryPointId}
                warehouseId={selectedWarehouse}
                onChangeDeliveryPointId={setDeliveryPointId}
              />
            )
          }
          {
            (
              step === GroupDeliveries
              && shoppingCartItems
              && checkoutData?.destAddress
              && selectedWarehouse
            ) && (
              <CheckoutGroupDeliveriesPage 
                groupDelivery={deliveryGroupsHook.groupDelivery}
                hasEmptyDeliveryData={deliveryGroupsHook.hasEmptyDeliveryData}
                isSameGroupDelivery={deliveryGroupsHook.isSameGroupDelivery}
                getGroupDeliveryDate={deliveryGroupsHook.getGroupDeliveryDate}
                sortGroupDeliveries={deliveryGroupsHook.sortGroupDeliveries}
                getShoppingCartItemsByGroup={deliveryGroupsHook.getShoppingCartItemsByGroup}
                getLastGroupDeliveryDate={deliveryGroupsHook.getLastGroupDeliveryDate}
                getGroupDeliveryPrice={deliveryGroupsHook.getGroupDeliveryPrice}
                loading={deliveryGroupsHook.loading}
                shippingTypeOrder={deliveryGroupsHook.shippingTypeOrder}
                onChageShippingTypeOrder={deliveryGroupsHook.setShippingTypeOrder}
              />
            )
          }
          {
            (
              step === GroupPickUps
              && shoppingCartItems
              && selectedWarehouse
              && deliveryPointId
            ) && (
              <CheckoutGroupPickUpPage 
                groupPickUpRequest={{
                  deliveryPointId,
                  shoppingCartItems: shoppingCartItems,
                  warehouseId: selectedWarehouse,
                }}
              />
            )
          }
          {
            (
              step === PaymentService
              && selectedWarehouse
            ) && (
              <>
                <CheckoutSalesOriginPage 
                  salesOrigins={salesOrigins}
                  form={salesOriginCodeForm}
                />
                <CheckoutPaymentServicePage 
                  warehouseId={selectedWarehouse}
                  paymentServiceId={paymentServiceCode}
                  onChangePaymentServiceId={setPaymentServiceCode}
                />
              </>
            )
          }
          {
            (
              step === CheckoutPay
              && selectedWarehouse
              && paymentServiceCode
            ) && (
              <CheckoutPayPage 
                warehouseId={selectedWarehouse}
                paymentServiceCode={paymentServiceCode}
                paymentStrategy={paymentStrategy}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                setPaymentStrategy={setPaymentStrategy}
                onPay={onPay}
                onPayError={onPayError}
                onClickAlert={() => setStep(ECheckoutStep.PaymentService)}
              />
            )
          }
          {
            step !== CheckoutPay && (
              <Row className="mt-4" gutter={[0, 24]}>
                <Col sm={{ span: 12, order: 1}} xs={{ span: 24, order: 2}} className="content-center">
                  <Button type="text" className="text-button" onClick={previousStep}>
                    {previousText()}
                  </Button>
                </Col>
                <Col sm={{ span: 12, order: 2}} xs={{ span: 24, order: 1}} className="content-center">
                  <Button 
                    onClick={continueStep}
                    disabled={(step === GroupDeliveries && (deliveryGroupsHook.hasEmptyDeliveryData || deliveryGroupsHook.loading))}
                    type="primary" 
                    className="primary-button"
                  >
                    {continueText()} 
                  </Button>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </Card>
  );
};

export default CheckoutPOS;
