import EMixLimit from "@/enums/EMixLimit";
import IMixSection from "@/interfaces/IMixSection";
import IMixTopping from "@/interfaces/IMixTopping";
import ToppingType from "@/types/ToppingType";

const getInputType = (
  sectionRestrictions: IMixSection['restrictions'],
  toppings: IMixTopping[],
) : ToppingType => {
  const isSectionMaximumIsGreaterThanOne = sectionRestrictions.max > 1;
  const isToppingsMaximumIsGreaterThanOne = toppings.some(
    topping => topping.restrictions.max > 1
  );

  if (
    isToppingsMaximumIsGreaterThanOne
  ) return 'qty';

  if (
    !isSectionMaximumIsGreaterThanOne 
    && sectionRestrictions.limit === EMixLimit.Required
  ) return 'radio';
    
  if (
    !isSectionMaximumIsGreaterThanOne 
    && sectionRestrictions.limit === EMixLimit.Optional
  ) return 'checkbox';

  if (
    isSectionMaximumIsGreaterThanOne 
    && !isToppingsMaximumIsGreaterThanOne
  ) return 'checkbox';

  return 'qty';
};

export default getInputType;
