import { Badge, BadgeProps } from 'antd';

interface ICustomBadgeProps extends BadgeProps {
  hidden?: boolean;
  [key: string]: any;
}

const CustomBadge = ({hidden = false, ...props}: ICustomBadgeProps) => {
  if (hidden) {
    return <>{props.children}</>;
  }
  return <Badge {...props}>{props.children}</Badge>;
};

export default CustomBadge;
