import IAttributeOptionDto from '@interfaces/IAttributeOptionDto';
import IAttributeOption from '@/interfaces/IAttributeOption';

export const adapterDtoToAttributeOption = (warehouse: IAttributeOptionDto): IAttributeOption => {
  const { 
    id,
    labelEs,
    labelEn,
    order,
  } = warehouse;

  return {
    id,
    label: {
      en: labelEn,
      es: labelEs,
    },
    sort: order,
  };
};
