enum ECheckoutStep {
  Contact = 'contact',
  Delivery = 'delivery',
  Loading = 'loading',
  GroupDeliveries = 'group_deliveries',
  GroupPickUps = 'group_pick_ups',
  PaymentService = 'payment_service',
  CheckoutPay = 'checkout_pay',
}

export default ECheckoutStep;
