import IProduct from "@/interfaces/IProduct";
import ISelectionMix from "@/interfaces/ISelectionMix";
import IVariant from "@/interfaces/IVariant";

const getAttributesFromSelectedProduct = (
    variant: IVariant,
    selection: ISelectionMix | IProduct['id']
  ) => {
    const product = variant.products.find((p) => p.id === selection);
    if (!product) {
      return [];
    }
    return product.attributeOptions;
  };

  export default getAttributesFromSelectedProduct