import { Avatar, Col, Row, Typography } from 'antd';
import { ReactNode } from 'react';

interface ICaregoryProps {
  label: string;
  onClick?: () => void;
  icon?: ReactNode;
  hideIcon?: boolean;
  isSelected?: boolean;
  layout?: 'vertical' | 'horizontal';
}

const Caregory = ({
  label,
  hideIcon = false,
  icon,
  layout = 'horizontal',
  onClick,
  isSelected = false,
}: ICaregoryProps) => {
  const iconColSpan = layout === 'vertical' ? 24 : undefined;
  const avatarSize = layout === 'vertical' ? 64 : 32;
  const justifyAlignment = layout === 'vertical' ? 'center' : 'start';

  const widthValue = layout === 'vertical' ? { width: 100 } : undefined;

  return (
    <div style={{ ...widthValue }} className={`category-item ${isSelected && 'active'}`} >
      <Row
        onClick={onClick}
        align='middle'
        justify={justifyAlignment}
        gutter={12}
        style={{ cursor: 'pointer' }}
      >
        {!hideIcon && (
          <Col span={iconColSpan}>
            <Row justify='center'>
            <Avatar className={`category-item-icon`} size={avatarSize} icon={icon} />
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row justify='center'>
          <Typography.Paragraph
            type={!isSelected ? 'secondary' : undefined}
            // style={{ color: selectedColor, marginBottom: 0 }}
            className={`text-center category-item-label`}
            // className='font-semibold text-center'
            ellipsis={{
              rows: 2
            }}
          >
            {label}
          </Typography.Paragraph>
        </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Caregory;
