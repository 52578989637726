import ICustomer from "@/interfaces/ICustomer";
import IShoppingCart from "@/interfaces/IShoppingCart";
import { updateShoppingCart } from "@/services/ShoppingCart";

const onLoginEditShoppingCart = async (customerId: ICustomer['id']) => {
  const shoppingCart = localStorage.getItem('shoppingCart');

  if (shoppingCart && customerId) {
    const shoppingCartParse: IShoppingCart = JSON.parse(shoppingCart);

    await updateShoppingCart(shoppingCartParse.id, {
      userId: customerId
    });
  }
}

export default onLoginEditShoppingCart;
