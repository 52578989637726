import React from 'react';
import Description from '@/components/General/Description';
import Title from '@/components/General/Title/Title';
import ItemAvailability from '@/components/Item/ItemAvailability';
import EInventoryStatus from '@/enums/EInventoryStatus';
import Price from '@/components/General/Price';
import EPriceFormat from '@/enums/EPriceFormat';
import QtyInput from '@/components/General/QtyInput';

const Components = () => {
  return (
    <div>
      <Title title="Título" subTitle="Subtítulo" />
      <Description
        beforeExtra="React Node"
        afterExtra="React Node"
        decription="<div><h1>Holaaa</h1></div>"
      />
      <ItemAvailability 
        allowSaleWithoutInventory
        withInventory={EInventoryStatus.WithoutInventory}
      />
      <Price 
        price={2000.00}
        priceFormat={EPriceFormat.FromBase}
      />
      <QtyInput 
        size="small"
        value={2}
        label="unidades"
      />
      <div>Home</div>
      <div>Home</div>
      <div>Home</div>
      <div>Home</div>
    </div>
  );
};

export default Components;
