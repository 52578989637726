import IShoppingCart from "@/interfaces/IShoppingCart";
import IShoppingCartDto from "@/interfaces/IShoppingCartDto";
import formatShoppingCartItem from "./formatShoppingCartItems";

const formatShoppingCart = (sc: IShoppingCartDto): IShoppingCart => {
    return {
      items: (sc.shopping_cart_items && sc.shopping_cart_items.length) > 0 ? sc.shopping_cart_items.map(formatShoppingCartItem) : [],
     ...sc
    };
  };

  export default formatShoppingCart;