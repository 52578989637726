import { Button, Card, Col, Row, Typography } from 'antd';
import React, { ReactNode } from 'react';

interface IAddButtonProps {
  onClick: () => void;
  text?: string;
  icon: ReactNode;
}

const AddButton = ({ icon, onClick, text }: IAddButtonProps) => {
  return (
    <Button style={{ height: 'auto', padding: 10 }} onClick={onClick}>
      <Col>
        <Row justify="center">{icon}</Row>
        <Row justify="center">
          {text ?? <Typography.Text>{text}</Typography.Text>}
        </Row>
      </Col>
    </Button>
  );
};

export default AddButton;
