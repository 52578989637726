import React from 'react';
import {
  Document, Page, Text, StyleSheet, View
} from '@react-pdf/renderer';
import IQuantifier from '@/interfaces/IQuantifier';
import getTranlation from '@/helpers/getTranslation';
import formatNumberQty from '@/helpers/formatters/formatNumberQty';
import { IQuantifierMultipliersObj } from './Quantifier';
import IQuantifierRequirement from '@/interfaces/IQuantifierRequirement';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    padding: '10px 0px',
  },
  row:{
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: '3px 0px',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: '3px 0px',
  },
  text: {
    fontSize: 12,
    fontWeight: 'normal',
    padding: '3px 0px',
  },  
  th: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: '5px 10px'
  },
  td: {
    fontSize: 12,
    fontWeight: 'normal',
    padding: '5px 10px'
  },  
  tdHighlight: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center', 
    backgroundColor: '#f5f5f5', 
  },  
  referenceValues: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '5px',
    textAlign: 'left', 
    backgroundColor: '#f5f5f5', 
    textTransform: 'capitalize',
    border: '1px solid #000',
  },  
});

interface QuantifierExportProps{
  quantifier: IQuantifier,
  stepper: number,
  multipliers: IQuantifierMultipliersObj,
}

const QuantifierExport = ({ 
  quantifier,
  stepper,
  multipliers,
}: QuantifierExportProps) => {
  const relation = stepper / quantifier.stepper;
  const projectName = process.env.REACT_APP_TITLE;

  function getRequirementQty(requirement: IQuantifierRequirement) {
    const requiredQty = requirement.qty * relation;
    let addition = 0;

    Object.keys(multipliers).forEach(multiplierId => {
      const multiplierObj = multipliers[multiplierId];
      const multiplierProducts = multiplierObj.products.map(product => product.productId);

      if(multiplierProducts.includes(requirement.product.id)){
        const newAddition = (requiredQty * multiplierObj.qty) - requiredQty;
        addition = addition + newAddition;
      }
    });

    const qty = formatNumberQty(requiredQty + addition);

    return qty;
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.section}>
          <Text style={styles.title}>{projectName}</Text>
        </View>
        <View style={styles.section}>
          <Text style={{...styles.subTitle, textTransform: 'uppercase' }}>
            {`${getTranlation(quantifier.name)}: `}
          </Text>
          <Text style={styles.text}>
            {getTranlation(quantifier.desc)}
          </Text>
        </View>
        <View style={{...styles.section, paddingTop: '0px'}}>
          <View>
            <Text style={styles.referenceValues}>
              {`${quantifier.unit}: `}
              <Text style={{ textDecoration: 'underline' }}>
                {`${stepper}`}
              </Text>
            </Text>
          </View>
          {Object.keys(multipliers).map(multiplierId => (
            <View>
              <Text style={styles.referenceValues}>
                {`${multipliers[multiplierId].text}: `}
                <Text style={{ textDecoration: 'underline' }}>
                  {`${multipliers[multiplierId].qty}`}
                </Text>
              </Text>
            </View>
          ))}
        </View>
        <View style={{...styles.row, backgroundColor: "#000", color: "#fff"}}>
          <Text style={{...styles.th, width: '10%'}}>SKU</Text>
          <Text style={{...styles.th, width: '50%'}}>Artículo</Text>
          <Text style={{...styles.th, width: '10%', textAlign: 'center'}}>Unidad</Text>
          <Text style={{...styles.th, width: '10%', textAlign: 'center'}}>SPQ</Text>
          <Text style={{...styles.th, width: '20%', textAlign: 'center' }}>Cantidad</Text>
        </View>
        {quantifier.requirements.map(requirement => (
          <View style={{...styles.row, border: '1px solid #000'}}>
            <Text style={{...styles.td, width: '10%'}}>{requirement.product.sku}</Text>
            <Text style={{...styles.td, width: '50%'}}>{getTranlation(requirement.product.name)}</Text>
            <Text style={{...styles.td, width: '10%', textAlign: 'center'}}>{requirement.product.unit.code}</Text>
            <Text style={{...styles.td, width: '10%', textAlign: 'center'}}>{requirement.product.stepper}</Text>
            <Text style={{...styles.tdHighlight, width: '20%', textAlign: 'center'}}>{getRequirementQty(requirement)}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default QuantifierExport;
