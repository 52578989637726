import React, { CSSProperties, FC } from 'react';
import { 
  LoadingOutlined,  
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EPriceFormat from '@/enums/EPriceFormat';
import formatCurrencyMX from '@/utils/formatCurrency';
import { Col, Row, Typography } from 'antd';

export interface IPrice {
  price: number;
  priceFormat: EPriceFormat;
  loading?: boolean;
  comparativePrice?: number;
  stylePrice?: CSSProperties;
  styleBaseLabel?: CSSProperties;
  styleComparativeLabel?: CSSProperties;
  display?: 'vertical' | 'horizontal'
}

const Price = ({
  price,
  priceFormat,
  comparativePrice,
  stylePrice,
  styleBaseLabel,
  styleComparativeLabel,
  loading = false,
  display = 'vertical',
}: IPrice) => {
  const { t } = useTranslation('common');
  const splitPrice = price.toFixed(2).toString().split('.');

  const moneyFormat = new Intl.NumberFormat('en-US');

  const priceWithoutDecimals = moneyFormat.format(Number(splitPrice[0]));
  const decimals = splitPrice[1];

  if (loading) {
    return (
      <LoadingOutlined />
    );
  }

  if (priceFormat === EPriceFormat.Pending || price === 0) {
    return (
      <div>
        <Typography.Text type="secondary">
          {t('g.pending_price')}
        </Typography.Text>
      </div>
    );
  }

  const ComparativePrice = () => (
    <Col
      span={display === 'vertical' ? 24 : 12}
      className="text-xs"
      style={{ lineHeight: '5px' }}
    >
      <span
        className="line-through mr-1"
        style={{
          color: '#aeaeae',
          ...styleComparativeLabel,
        }}
      >
        {comparativePrice ? formatCurrencyMX(comparativePrice) : ''}
      </span>
    </Col>
  )

  return (
    <Row gutter={10}>
      {priceFormat === EPriceFormat.FromBase && (
        <Col
          span={display === 'vertical' ? 24 : 12}
          className="text-xs"
        >
          <span style={{ ...styleBaseLabel }}>
            {t('g.since')}
          </span>
        </Col>
      )}

      <Col
        span={display === 'vertical' ? 24 : 12}
        style={{ ...stylePrice }}
      >
        <span>
          <sup>$</sup>
          {priceWithoutDecimals}
        </span>
        <sup>{decimals}</sup>
      </Col>

      {priceFormat === EPriceFormat.Comparative && (
        <ComparativePrice />
      )}  
    </Row>
  );
};

export default Price;
