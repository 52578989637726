import React from 'react';
import { Layout } from 'antd';

const Footer = () => {
  const { Footer } = Layout;
  
  return (
    <Footer 
      id="zel-footer"
      style={{ textAlign: 'center' }}
    >
      {process.env.REACT_APP_FOOTER}
    </Footer>
  );
};

export default Footer;
