import OrderStatusTag from "@/components/ThankYou/OrderStatusTag";
import IOrder from "@/interfaces/IOrder";
import formatCurrencyMX from "@/utils/formatCurrency";
import { Button, Row, Table, Tooltip, Typography } from "antd";
import i18next from "i18next";
import { capitalize } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IOrdersListProps{
  orders: IOrder[],
}

const OrdersList = ({
  orders,
}: IOrdersListProps) => {
  const lang = i18next.language;
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const getTitle = (title: string) => (
    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
      {title}
    </Typography.Text>
  );

  const columns = [
    {
      title: getTitle(t('g.order_number')),
      sorter: {
        compare: (a: IOrder, b: IOrder) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        },
      },
      render: (order: IOrder) => (
        <Button 
          type="link" 
          onClick={() => {
            navigate(`/orders/details/${order.id}`)
          }}
        >
          <Typography.Title level={5} style={{ color: 'inherit' }}>
            {order.id}
          </Typography.Title>
        </Button>
      ),
    },
    {
      title: getTitle(t('g.created_at')),
      dataIndex: 'createdAt',
      sorter: {
        compare: (a: IOrder, b: IOrder) => {
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return -1;
          }
          if (moment(b.createdAt).isBefore(moment(a.createdAt))) {
            return 1;
          }
          return 0;
        },
      },
      render: (text: any) => {
        const diff = moment().to(text);
        const date = moment(text).lang(lang).format('LLLL');
        return (
          <Row justify="start">
            <Tooltip title={capitalize(date)} trigger={['click', 'hover']}>
              <p style={{ whiteSpace: 'nowrap' }}>
                {capitalize(diff)}
              </p>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: getTitle(t('g.total')),
      dataIndex: ['amounts', 'total'],
      sorter: {
        compare: (a: IOrder, b: IOrder) => {
          if (a.amounts.total < b.amounts.total) {
            return -1;
          }
          if (a.amounts.total > b.amounts.total) {
            return 1;
          }
          return 0;
        },
      },
      render: (value: number) => (
        <Row justify="end">
          <p style={{ textAlign: 'right' }}>
            {formatCurrencyMX(value)}
          </p>
        </Row>
      ),
    },
    {
      title: getTitle(t('g.status')),
      sorter: {
        compare: (a: IOrder, b: IOrder) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        },
      },
      render: ({ status }: IOrder) => (
        <Row justify="center">
          <OrderStatusTag status={status} />
        </Row>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={orders}
      style={{ overflowX: 'auto' }}
    />
  );
};

export default OrdersList;
