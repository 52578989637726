import React, { useEffect, useState } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import 'moment/locale/es';
import { 
  Row, 
  Col, 
  Spin, 
  Space, 
  Collapse, 
  message, 
  Drawer,
  Button, 
} from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IGroupPickUpRequest from '@/interfaces/IGroupPickUpRequest';
import { getGroupPickUp } from '@/services/CheckoutService';
import IGroupPickUpSuccessResponse from '@/interfaces/IGroupPickUpSuccessResponse';
import isGroupPickUp from '@/helpers/isGroupPickUp';
import GroupShoppingCartItemsCard from '@/components/Delivery/GroupShoppingCartItemsCard';
import useService from '@/hooks/useService';
import WarehouseSchedule from '@/components/WarehouseSchedule';
import { getDeliveryPoint } from '@/services/DeliveryPointService';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SpaceWrapper = styled(Space)`
  {
    & > .ant-space-item > .ant-card {
      border: 0px;

      & > .ant-card-body {
        padding: 0px;
      }
    }
  }
`;

interface ICheckoutGroupPickUpPage {
  groupPickUpRequest: IGroupPickUpRequest
}

const CheckoutGroupPickUpPage = ({
  groupPickUpRequest,
}: ICheckoutGroupPickUpPage) => {
  const { t } = useTranslation('common');
  const history = useNavigate();
  const [scheduleVisible, setScheduleVisible] = useState<boolean>(false);

  const { Panel } = Collapse;

  const deliveryPointState = useService({
    fetchData: getDeliveryPoint,
    params: {
      deliveryPointId: groupPickUpRequest.deliveryPointId
    },
  });

  const groupDeliveryState = useService({
    fetchData: getGroupPickUp,
    params: groupPickUpRequest,
  });

  const sortGroupPickUps = (
    pickUps: IGroupPickUpSuccessResponse[],
  ) => pickUps.sort((a, b) => a.delayTime  - b.delayTime);
  
  const getGroupDeliveryDate = (pickUp: IGroupPickUpSuccessResponse) => {
    moment.locale(i18next.language === 'en' ? 'en' : 'es');
    return moment().add(pickUp.delayTime, 'hours').format('LL hh:mm a');
  };

  const getShoppingCartItemsByGroup = (
    pickUp: IGroupPickUpSuccessResponse,
  ) => {

    const shoppingCartItemIds: string[] = [];

    pickUp.items.forEach(
      deliveryItem => shoppingCartItemIds.push(deliveryItem.shoppingCartItemId),
    );

    const shoppingcartItems = groupPickUpRequest.shoppingCartItems.filter(
      item => shoppingCartItemIds.includes(item.id)
    );

    return shoppingcartItems;
  };

  useEffect(() => {
    if (groupDeliveryState.data && !isGroupPickUp(groupDeliveryState.data)) {
      message.error({
        content: t('g.products_without_inventory'),
        duration: 5,
        key: 'CheckoutKey',
      });

      history('/shopping_cart');
    }
    // eslint-disable-next-line
  }, [groupDeliveryState.data]);

  if (!groupDeliveryState.data || !deliveryPointState.data) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Drawer
          title={t('hints.pickup_schedules')}
          placement="bottom"
          height="80%"
          open={scheduleVisible}
          onClose={() => setScheduleVisible(false)}
          closable
        >
          <WarehouseSchedule schedule={deliveryPointState.data.schedule}/>
        </Drawer>
        <Button
          type="text"
          icon={<ClockCircleOutlined />}
          onClick={() => setScheduleVisible(true)}
        >
          {t('hints.pickup_schedules')}
        </Button>
      </Col>
      <Col span={24}>
        <SpaceWrapper 
          direction="vertical" 
          size="middle"
          className="flex"
        >
          {
            (isGroupPickUp(groupDeliveryState.data)) && (
              <>
                {
                  sortGroupPickUps(groupDeliveryState.data.data).map((d, index) => (
                    <GroupShoppingCartItemsCard 
                      key={`group-delivery-${index}`}
                      title={`${t('g.delivery')} ${index + 1}`}
                      date={getGroupDeliveryDate(d)}
                      shoppingcartItemsParams={getShoppingCartItemsByGroup(d)}
                      disclaimer={t('hints.available_from_date_during_warehouse_hours_once_your_order_is_confirmed', { date: getGroupDeliveryDate(d) })}
                    />
                  ))
                }
              </>
            )
          }
        </SpaceWrapper>
      </Col>
    </Row>
  );
};

export default CheckoutGroupPickUpPage;
