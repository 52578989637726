import { AxiosResponse } from 'axios';
import axiosApiConfig from '@/config/axiosConfig';
import EPaymentService from '@/enums/EPaymentService';
import IWarehouse from '@/interfaces/IWarehouse';
import ICharge from '@/interfaces/ICharge';

interface PaymentRequest {
  amount: number;
  token: string;
  deviceSessionId: string;
}

interface PaymentResponse {
  message: string;
}
interface IGetTransaction {
  transactionId: string,
  paymentServiceCode: EPaymentService, 
  warehouseId: IWarehouse['id'],
}

export const pay = async (data: PaymentRequest) => {
  const result = await axiosApiConfig.post<PaymentRequest, AxiosResponse<PaymentResponse>>('/pay', data)
  // const result = await axiosApiConfig.post('/payment', data);
  return result.data;
};

export const getTransaction = async (data: IGetTransaction) => {
  const res = await axiosApiConfig.post<ICharge>('/transaction', data);

  return res.data;
};

// interface PaymentService {
//     code: string;
//     init: () => void;
//     makePayment: () => void;
// }

// export PaymentService;


// interface Openpay extends PaymentService {

// }


// export default class PaymentService {
//     private instance;
  
//     public static async init(params?: any) {}
  
//     public static get makePayment() {
//       return this.instance.authentication.authenticated;
//     }
  
//     public static async logout() {
//       return await this.instance.logout();
//     }
  
//     public static async register(request: any) {
//       return this.instance.service('users').create(request);
//     }
  
//     public static async reAuthenticate() {
//       return await this.instance.reAuthenticate();
//     }
// }