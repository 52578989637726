import IPaymentStrategy from '@/interfaces/IPaymentStrategy';
import IPaymentStrategyDto from '@/interfaces/IPaymentStrategyDto';

export const adapterDtoToPaymentStrategy = (
  paymentService: IPaymentStrategyDto
): IPaymentStrategy => {
  const { 
    code,
    labelEn,
    labelEs,
  } = paymentService;

  return {
    code,
    label: {
      en: labelEn,
      es: labelEs,
    },
  };
};
