import ISchedule from '@/interfaces/ISchedule';
import moment from 'moment';
import { Moment } from 'moment';
import equalizeDate from '@/helpers/equalizeDate';
import getScheduleDayByDate from '@/helpers/getScheduleDayByDate';
import IScheduleDay from '@/interfaces/IScheduleDay';
import Day from '@/enums/Day';

function isOpen(schedule: ISchedule, referenceDate: Moment = moment()): boolean {
  const scheduleDayToCompare: IScheduleDay<Day> = getScheduleDayByDate(schedule, referenceDate);

  if (scheduleDayToCompare?.isClosed) return false;

  if (scheduleDayToCompare?.isOpen24hr) return true;

  if (
    scheduleDayToCompare?.rangeSchedule 
    && scheduleDayToCompare.rangeSchedule.length > 0
  ) {
    const dateCompareResult: boolean[] = scheduleDayToCompare.rangeSchedule.map(rangeSchedule => {
      const startDateTime: Moment = equalizeDate(rangeSchedule.startTime, referenceDate);
      const endDateTime: Moment = equalizeDate(rangeSchedule.endTime, referenceDate);

      if (!referenceDate.isBetween(startDateTime, endDateTime)) return false;

      return true;
    });

    if (!dateCompareResult.includes(true)) return false;

    return true;
  }

  return false;
}

export default isOpen;
