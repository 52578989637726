import axiosApiConfig from '@/config/axiosConfig';
import IWarehouseDeliveryPoint from '@/interfaces/IWarehouseDeliveryPoint';
import IWarehouseDeliveryPointDto from '@/interfaces/IWarehouseDeliveryPointDto';
import IWarehouse from '@interfaces/IWarehouse';
import { adapterDtoToWarehouseDeliveryPoint } from '@/adapters/WarehouseDeliveryPointAdapter';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IDeliveryPointDto from '@/interfaces/IDeliveryPointDto';
import { adapterDtoToDeliveryPoint } from '../adapters/DeliveryPointAdapter';
import IProduct from '@/interfaces/IProduct';

export const getWarehouseDeliveryPoints = async (params?: { 
  warehouseId: IWarehouse['id']
}): Promise<IWarehouseDeliveryPoint[]> => {
  const result = await axiosApiConfig.get<IWarehouseDeliveryPointDto[]>('/warehouse_delivery_points', {
    params: { ...params },
  });

  return result.data.map(d => adapterDtoToWarehouseDeliveryPoint(d));
};

interface IGetDeliveryPoint {
  deliveryPointId: IDeliveryPoint['id'];
}

export const getDeliveryPoint = async ({ deliveryPointId }: IGetDeliveryPoint): Promise<IDeliveryPoint> => {
  const result = await axiosApiConfig.get<IDeliveryPointDto>(`/delivery_points/${deliveryPointId}`);

  return adapterDtoToDeliveryPoint(result.data);
};

interface IIsProductHaveDeliveryTypeInWarehouse {
  warehouseId: IWarehouse['id'];
  productId: IProduct['id'];
}

interface IMixesWarehouseDeliveryPoint {
  warehouseDeliveryPointId: string;
  productId: IProduct['id'];
}

export const isProductHaveDeliveryTypeInWarehouse = async (
  { 
    warehouseId, productId,
  }: IIsProductHaveDeliveryTypeInWarehouse
): Promise<boolean> => {
  const warehouseDeliveryPoints = await axiosApiConfig.get<IWarehouseDeliveryPoint[]>(
    '/warehouse_delivery_points', 
    { params: { warehouseId } },
  );

  const warehouseDeliveryPointIds = warehouseDeliveryPoints.data.map(item => item.id);

  const result = await axiosApiConfig.get<IMixesWarehouseDeliveryPoint[]>(
    '/products_warehouse_delivery_points', 
    { params: { 
      'warehouseDeliveryPointId[$in]': warehouseDeliveryPointIds,
      productId 
    } },
  );

  return result.data.length > 0;
};

interface IIsMixHaveDeliveryTypeInWarehouse {
  warehouseId: IWarehouse['id'];
  mixId: IProduct['id'];
}

interface IMixesWarehouseDeliveryPoint {
  warehouseDeliveryPointId: string;
  mixId: IProduct['id'];
}

export const isMixHaveDeliveryTypeInWarehouse = async (
  { 
    warehouseId, mixId,
  }: IIsMixHaveDeliveryTypeInWarehouse
): Promise<boolean> => {
  const warehouseDeliveryPoints = await axiosApiConfig.get<IWarehouseDeliveryPoint[]>(
    '/warehouse_delivery_points', 
    { params: { warehouseId } },
  );

  const warehouseDeliveryPointIds = warehouseDeliveryPoints.data.map(item => item.id);

  const result = await axiosApiConfig.get<IMixesWarehouseDeliveryPoint[]>(
    '/mixes_warehouse_delivery_points', 
    { params: { 
      'warehouseDeliveryPointId[$in]': warehouseDeliveryPointIds,
      mixId 
    } },
  );

  return result.data.length > 0;
};

interface IShoppingCartDevliveryPoints {
  warehouseId: string;
  shoppingCartId: string;
}

export const getShoppingCartDevliveryPoints = async (
  {
    warehouseId,
    shoppingCartId,
  }: IShoppingCartDevliveryPoints
): Promise<IWarehouseDeliveryPoint[]> => {
  const warehouseDeliveryPoints = await getWarehouseDeliveryPoints({
    warehouseId
  });

  const deliveryPoints: IWarehouseDeliveryPoint[] = [];

  await Promise.all(
    warehouseDeliveryPoints.map(async warehouseDeliveryPoint => {
      const res = await axiosApiConfig.post<{isValid: boolean}>(
        '/validate_shopping_cart_delivery_points', 
        {
          warehouseId,
          deliveryPointId: warehouseDeliveryPoint.deliveryPointId,
          shoppingCartId,
        },
      );
      
      if (res.data.isValid) {
        deliveryPoints.push(warehouseDeliveryPoint);
      };

      return;
    })
  );


  return deliveryPoints;
};