import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Result, Row, Spin, Typography } from 'antd';

import useLocation from '@/hooks/useLocation';
import EPaymentService from '@/enums/EPaymentService';
import { getTransaction } from '@/services/PaymentService';
import ETransactionStatus from '@/enums/ETransactionStatus';

const TransactionStatusPage = () => {
  const { Null, Done, Cancelled } = ETransactionStatus;
  
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { selectedWarehouse } = useLocation();
  const [transactionStatus, setTransactionStatus] = useState<ETransactionStatus>(Null);
  const [orderId, setOrderId] = useState<string | null>(null);
  
  const [ searchParams ] = useSearchParams();
  const transactionId = searchParams.get('id');
  
  const validateTransaction = async (transactionId: string) => {
    try {
      const res = await getTransaction({
        paymentServiceCode: EPaymentService.Openpay,
        transactionId,
        warehouseId: selectedWarehouse ?? '1',
      });

      if (res.status === 'completed' || res.status === 'in_progress') {
        setTransactionStatus(Done);
        setOrderId(res.orderId);
        return;
      }

      setTransactionStatus(Cancelled);
    } catch (error) {
      console.error(error);
      setTransactionStatus(Cancelled);
    }
  };

  useEffect(() => {
    if (!transactionId) return;
    validateTransaction(transactionId);
  }, [transactionId]);

  return (
    <Row 
      justify='center' 
      align='middle' 
      style={{ 
        height: '85vh',
        flexDirection: 'column'
      }}
    >
      {
        transactionStatus === Null ? (
          <>
            <Col>
              <Spin size='large' />
            </Col>
            <Col>
              <Typography.Title level={3}>
                {t('g.validating_transaction')}
              </Typography.Title>
            </Col>
          </>
        ) : null
      }
      {
        transactionStatus === Cancelled ? (
          <Col>
            <Result
              status="error"
              title={t('transaction.error.title')}
              subTitle={t('transaction.error.subtitle')}
              extra={
                <Button 
                  type="primary" 
                  onClick={() => navigate('/')}
                >
                  {t('transaction.error.button')}
                </Button>
              }
            />
          </Col>
        ) : null
      }
      {
        transactionStatus === Done ? (
          <Col>
            <Result
              status="success"
              title={t('transaction.success.title')}
              subTitle={t('transaction.success.subtitle')}
              extra={
                <Button 
                  type="primary" 
                  onClick={() => navigate(`/orders/details/${orderId}`)}
                >
                  {t('transaction.success.button')}
                </Button>
              }
            />
          </Col>
        ) : null
      }
    </Row>
  );
};

export default TransactionStatusPage;
