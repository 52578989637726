import AddButton from '@/components/MyAddresses/AddButton';
import ShippingContactCard from '@/components/MyAddresses/ShippingContactCard';
import useGoToBackPage from '@/hooks/useGoToBackPage';
import useService from '@/hooks/useService';
import IShippingContact from '@/interfaces/IShippingContact';
import getShippingContacts, {
  deleteShippingContact,
  editShippingContact,
} from '@/services/ShippingContact';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import useAuth from '@/hooks/useAuth';
import {
  Button,
  Card,
  Col,
  List,
  message,
  PageHeader,
  Row,
  Typography,
  Modal,
  Spin,
  Empty,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NotAuthenticatedSign from '@/components/General/NotAuthenticatedSign';

// interface MyAddressesProps {

// }

const MyAddresses = () => {
  const { t } = useTranslation('common');
  const { redirectToBackPage } = useGoToBackPage();
  const navigate = useNavigate();
  const auth = useAuth();

  const userShippingContactsState = useService<IShippingContact[]>({
    fetchData: getShippingContacts,
    params: { userId: auth.customer?.id },
  });

  const setShippingContactAsDefault = async (id: IShippingContact['id']) => {
    try {
      await editShippingContact({ id: id, isDefault: true });
      userShippingContactsState.reload();
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'setShippingContactAsDefault',
      });
    } catch (e) {
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'setShippingContactAsDefault',
      });
    }
  };

  const deleteUserShippingContact = async (id: IShippingContact['id']) => {
    try {
      await deleteShippingContact({ id: id });
      userShippingContactsState.reload();
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'deleteShippingContact',
      });
    } catch (e) {
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'deleteShippingContact',
      });
    }
  };

  const confirmDeleteShippingContact = (
    shippingContactId: IShippingContact['id']
  ) => {
    Modal.confirm({
      title: t('g.delete_shipping_contact'),
      icon: <ExclamationCircleOutlined />,
      content: `${t('g.do_you_want_to_delete_this_shipping_contact?')}`,
      okText: t('g.confirm'),
      cancelText: t('g.cancel'),
      async onOk() {
        try {
          await deleteUserShippingContact(shippingContactId);
          return true;
        } catch {
          message.error({
            content: t('g.error'),
            duration: 2,
            key: 'deleteShippingContact',
          });
          return console.error('Oops errors!');
        }
      },
      onCancel() {},
    });
  };

  const confirmSetDefaultShippingContact = (
    shippingContactId: IShippingContact['id']
  ) => {
    Modal.confirm({
      title: t('g.set_default_shipping_contact'),
      icon: <ExclamationCircleOutlined />,
      content: `${t('g.do_you_want_to_set_this_shipping_contact_as_default?')}`,
      okText: t('g.confirm'),
      cancelText: t('g.cancel'),
      async onOk() {
        try {
          await setShippingContactAsDefault(shippingContactId);
          return true;
        } catch {
          message.error({
            content: t('g.error'),
            duration: 2,
            key: 'setShippingContactAsDefault',
          });
          return console.error('Oops errors!');
        }
      },
      onCancel() {},
    });
  };

  if (!auth.authenticated) {
    navigate('/login')
  }

  return (
    <Card className='pb-80'>
      <Row>
        <Col span={24}>
          <Row>
            <PageHeader
              title={t('g.my_addresses')}
              onBack={() => redirectToBackPage('')}
            />
          </Row>
          <Row justify='center'>
            <Col span={24}>
              <Row className='mb-2'>
                <Col>
                  <Typography.Text>
                    {t(
                      'g.the_options_and_delivery_speeds_may_vary_accoording to the address'
                    )}
                  </Typography.Text>
                </Col>
              </Row>
              <Row justify='center'>
                <Col span={20}>
                  {!userShippingContactsState.data ? (
                    <Row justify='center'>
                      <Col>
                        <Spin size='large' className='m-4' />
                      </Col>
                    </Row>
                  ) : (
                    <List
                      className='w-full'
                      grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 3,
                      }}
                      dataSource={userShippingContactsState.data}
                      locale={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t('g.there_are_no_contacts')}
                          />
                        ),
                      }}
                      renderItem={(item) => (
                        <List.Item>
                          <ShippingContactCard
                            shippingContact={item}
                            isDefault={item.isDefault}
                            actions={
                              <Row className='p-2' gutter={[12, 12]}>
                                <Col>
                                  <Button
                                    icon={<EditOutlined />}
                                    onClick={() =>
                                      navigate(
                                        `/user_shipping_contacts/${item.id}/edit`
                                      )
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      confirmDeleteShippingContact(item.id)
                                    }
                                  />
                                </Col>
                                {!item.isDefault && (
                                  <Col>
                                    <Button
                                      icon={<HomeOutlined />}
                                      onClick={() =>
                                        confirmSetDefaultShippingContact(
                                          item.id
                                        )
                                      }
                                      className='whitespace-normal flex align-middle'
                                    >
                                      {t('g.default')}
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </Col>
              </Row>
              <Row justify='center'>
                <AddButton
                  icon={<PlusOutlined />}
                  text={t('g.add_address')}
                  onClick={() => navigate(`/user_shipping_contacts/new`)}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default MyAddresses;
