import IOrder from "@/interfaces/IOrder";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface IAccessPinOrderRes{
  orderId: IOrder['id'], 
  showAuthFail: boolean,
  setOrderAccessKey: (key: string) => void,
}

const AccessPinOrder = (): IAccessPinOrderRes => {
  const navigate = useNavigate();
  const params = useParams<{ orderId: IOrder['id'] }>()

  const [ searchParams ] = useSearchParams();
  const showAuthFail = searchParams.get('fail') !== null ?? false;

  const { 
    orderId = '0',
  } = params;

  function setOrderAccessKey(key: string){
    const ordersAccessItem = localStorage.getItem('orders_access');
    const ordersAccessStorage = JSON.parse(ordersAccessItem || '{}');

    ordersAccessStorage[orderId] = key;
    localStorage.setItem('orders_access', JSON.stringify(ordersAccessStorage));
    navigate(`/orders/details/${orderId}`);
  }

  return {
    orderId,
    showAuthFail,
    setOrderAccessKey,
  }
}

export default AccessPinOrder;
