import NotAuthenticatedSign from '@/components/General/NotAuthenticatedSign';
import UserShippingContactFormContainer from '@/components/MyAddresses/UserShippingContactFormContainer';
import useAuth from '@/hooks/useAuth';
import useGoToBackPage from '@/hooks/useGoToBackPage';
import useService from '@/hooks/useService';
import IAddressFormData from '@/interfaces/IAddressFormData';
import IContactFormData from '@/interfaces/IContactFormData';
import ICountry from '@/interfaces/ICountry';
import IUserShippingContactFormContainerData from '@/interfaces/IUserShippingContactFormContainerData';
import getCountries from '@/services/Country';
import { createCompleteShippingContact } from '@/services/ShippingContact';
import { Card, Row, Col, PageHeader, Form, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NewUserShippingContact = () => {
  const { t } = useTranslation('common');
  const { redirectToBackPage } = useGoToBackPage();
  const navigate = useNavigate();
  const auth = useAuth();

  const [containerForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [contactForm] = Form.useForm();

  const countriesState = useService<ICountry[]>({
    fetchData: getCountries,
    params: {},
  });

  const createNewUserShippingContact = async () => {
    try {
      const addressData: IAddressFormData = await addressForm.validateFields();
      const contactData: IContactFormData = await contactForm.validateFields();
      const containerData: IUserShippingContactFormContainerData =
        await containerForm.validateFields();

      await createCompleteShippingContact({
        addressData: {
          extNum: addressData.extNum,
          intNum: addressData.intNum,
          street: addressData.street,
          suburbId: addressData.suburbId,
          postalCodeId: addressData.postalCodeId,
        },
        contactData: {
          email: contactData.email,
          alias: contactData.alias,
          name: contactData.name,
          phone: contactData.phone,
          isDefault: containerData.isDefault,
          //TODO: Get current user id
          userId: auth.customer?.id ?? '',
        },
      });
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'createNewShippingContact',
      });
      navigate('/addresses');
    } catch (e) {
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'createNewShippingContact',
      });
    }
  };

  if (!auth.authenticated) {
    return <NotAuthenticatedSign />;
  }

  return (
    <Card className='md:pb-96'>
      <Row>
        <Col span={24}>
          <Row>
            <PageHeader
              title={t('g.create_contact')}
              onBack={() => redirectToBackPage('')}
            />
          </Row>
          <Row justify='center'>
            <Col span={24}>
              <UserShippingContactFormContainer
                form={containerForm}
                addressForm={addressForm}
                contactForm={contactForm}
                countriesState={countriesState}
                initialSuburbs={[]}
              />
            </Col>
          </Row>
          <Row justify='end'>
            <Col>
              <Button onClick={createNewUserShippingContact}>
                {t('g.confirm')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default NewUserShippingContact;
