import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import useLocation from '@/hooks/useLocation';
import getTranlation from '@/helpers/getTranslation';
import getCurrentData from '@/helpers/getCurrentData';
import VariantAttributes from '@/components/Variant/VariantAttributes';
import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import SelectionMix from '@/interfaces/ISelectionMix';
import IAttributeOption from '@/interfaces/IAttributeOption';
import PriceDetails from '@/containers/views/ItemDetails/PriceDetails';
import useProductConfiguration from '@/containers/views/ItemDetails/useProductConfiguration';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import { isProductHaveShippingTypeInWarehouse } from '@/services/ShippingTypeInWarehouse';
import useBreakpoints from '@/hooks/useBreakpoint';
import Title from '@/components/General/Title/Title';
import BadgesList from '@/components/General/BadgesList';
import ProductAttributeOptions from '@/components/General/ProductAttributeOptions';
import Description from '@/components/General/Description';
import EAttachmentTypes from '@/enums/EAttachmentTypes';
import ItemAttachmentsSection from '@/components/Item/ItemAttachmentsSection';
import RelatedProducts from '@/components/RelatedProducts';
import ImagesPreview from '@/components/ImagesPreview';

interface IVariantDetailsView {
  variant: IVariant;
  qty: number;
  hasMoreOptions?: boolean;
  onIncreaseItemToAdd: ( ) => void; 
  onDecreaseItemToAdd: () => void; 
  onClickMoreOptions?: () => void;
  onAddToCart?: (qtyToAdd?: number) => void;
  onBuyNow?: (qtyToAdd?: number) => void;
  onChangeSelection: React.Dispatch<React.SetStateAction<string | SelectionMix | undefined>>
}

const VariantDetailsView = ({
  variant,
  qty,
  hasMoreOptions,
  onClickMoreOptions,
  onAddToCart = () => {},
  onBuyNow = () => {},
  onIncreaseItemToAdd,
  onDecreaseItemToAdd,
  onChangeSelection,
}: IVariantDetailsView) => {
  const {
    loading,
    relatedItems,
    configuration,
    getProductConfig,
    getRelatedProducts,
    getWarehouseProductConfig,
  } = useProductConfiguration();

  const { selectedWarehouse } = useLocation();
  const { t } = useTranslation('common');
  const { 
    isBreakpointEqualsAndBelow,
    isBreakpointEqualsAndAbove,
  } = useBreakpoints();
  
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [hasShippingType, setHasShippingType] = useState(false);

  const isScreenEqualsAndBelowMd = isBreakpointEqualsAndBelow('md');
  const isScreenEqualsAndBelowSm = isBreakpointEqualsAndBelow('sm');
  const isScreenEqualsAndAboveLg = isBreakpointEqualsAndAbove('lg');
  const variantName = getTranlation(variant.name);
  const variantDesc = getTranlation(variant.desc);
  const variantSku = `SKU: ${selectedProduct?.sku}`;

  const [attributeForm] = Form.useForm();

  const attachments = selectedProduct ? selectedProduct.productAttachments : [];

  const datasheets = attachments.filter(
    attac => attac.type === EAttachmentTypes.Datasheet
  );

  const guarantees = attachments.filter(
    attac => attac.type === EAttachmentTypes.Guarantee
  );

  const disclaimers = attachments.filter(
    attac => attac.type === EAttachmentTypes.Disclaimer
  );

  const netQty = (selectedProduct?.stepper ?? 1) * qty;
  const deliveryMethodsAllowed: EDeliveryMethod[] = [];

  if (selectedWarehouse) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Pickup)
  }
  if (hasShippingType) {
    deliveryMethodsAllowed.push(EDeliveryMethod.Shipping)
  }

  const VariantPriceDetails = ({ showTitle = true }: { showTitle?: boolean }) => (
    <PriceDetails
      title={variantName}
      subtitle={variantSku}
      showTitle={showTitle}
      qtyInputConfig={{ 
        value: netQty,
        label: selectedProduct?.unit.label && getTranlation(selectedProduct.unit.label),
        disableIncrease: loading,
        disableDecrease: loading,
      }}
      priceConfig={configuration?.priceConfig && {
        ...configuration.priceConfig,
        price: configuration.priceConfig.price * qty,
        comparativePrice: configuration.priceConfig?.comparativePrice && configuration.priceConfig.comparativePrice * qty,
      }}
      itemAvailabilityConfig={configuration?.itemAvailability}
      deliveryMethods={deliveryMethodsAllowed}
      hasMoreOptions={hasMoreOptions || !selectedWarehouse}
      onClickMoreOptions={onClickMoreOptions}

      onAddToCart={() => onAddToCart(netQty)}
      disableAddToCart={selectedWarehouse ? (configuration.disableAddToCart) : loading}
      onBuyNow={() => onBuyNow(netQty)}

      onIncreaseItemToAdd={onIncreaseItemToAdd}
      onDecreaseItemToAdd={onDecreaseItemToAdd}
      
      loading={loading}
    />
  );

  const VariantRelatedSection = () => (
    <RelatedProducts 
      isLoading={loading}
      gridRules={ isScreenEqualsAndAboveLg
        ? { gutter: 10, lg: 1, xl: 2, xxl: 2}
        : { gutter: 10, xs: 1, sm: 1, md: 1 }
      }
      relatedProducts={relatedItems} 
    />
  );

  const onChangeAttributes = () => {
    const attributes = attributeForm.getFieldsValue(true);
    initSelectedProduct(attributes.attributeOptions);
  };

  const getProductByAttributes = (
    attrOptsToCompare: IAttributeOption['id'][],
    products: IProduct[],
  ): IProduct['id'] => {
    const selectedProductFound = products.find(product => {
      const productAttributesOptions = product.attributeOptions.map(attribute => attribute.id);

      const isEqual = attrOptsToCompare.every(
        attrOptToCompare => productAttributesOptions.includes(attrOptToCompare),
      );

      return isEqual;
    });

    setSelectedProduct(selectedProductFound ? selectedProductFound : products[0]);
    return selectedProductFound?.id ? selectedProductFound.id : products[0].id;
  };

  const initSelectedProduct = (selectedAttributes: IAttributeOption['id'][]) => {
    const productId = getProductByAttributes(selectedAttributes, variant.products);
    const product = getCurrentData(variant.products, productId);

    onChangeSelection(productId);

    if (selectedWarehouse && product) {
      getWarehouseProductConfig(product, selectedWarehouse);
      return;
    }

    if (product) getProductConfig(product);
  };

  const initVariant = () => {
    const initialAttributes = variant.attributes.map(attr => attr.attributeOptions[0].id);

    attributeForm.setFieldsValue({
      attributeOptions: initialAttributes,
    });

    initSelectedProduct(initialAttributes);
  };

  useEffect(() => {
    initVariant();
  }, []);

  useEffect(() => {
    if (!variant) return;
    getRelatedProducts(
      variant.id,
      selectedWarehouse ?? null,
      'variant'
    );
  }, [variant, selectedWarehouse]);

  useEffect(() => {
    if (selectedWarehouse && selectedProduct?.id) {
      isProductHaveShippingTypeInWarehouse({
        productIds: [selectedProduct.id],
        warehouseId: selectedWarehouse,
      }).then(res => setHasShippingType(res));
    }
  }, [selectedWarehouse, selectedProduct?.id]);

  return (
    <Row gutter={[20, 20]}>
      {
        isScreenEqualsAndBelowMd ? (
          <Col flex="100%">
            <Title title={variantName} subTitle={variantSku}/>
          </Col>
        ): null
      }
      <Col style={{ width: !isScreenEqualsAndBelowMd ? "70%" : "100%" }}>
        <ImagesPreview images={variant.imgs} />
      </Col>
      <Col flex={!isScreenEqualsAndBelowMd ? "30%" : "100%"}>
        {!isScreenEqualsAndBelowMd ? (
          <Card>
            <VariantPriceDetails/>
          </Card>
        ): (
          <VariantPriceDetails showTitle={false}/>
        )}
      </Col>
      <Col flex={!isScreenEqualsAndBelowMd ? "70%" : "100%"}>
        <Row>
          {
            variant.badges.length > 0 ? (
              <Col span={24}>
                <BadgesList badges={variant.badges}/>
              </Col>
            ) : null
          }
          <Col span={24}>
            <Divider />
            <VariantAttributes 
              form={attributeForm}
              options={variant.attributes}
              onChangeAttributes={onChangeAttributes}
            />
          </Col>
          {
            variantDesc !== '' ? (
              <>
                <Divider />
                <Col span={24}>
                  <Description 
                    maskImage
                    title={t('g.description')}
                    heightLimit={200}
                    decription={variantDesc}
                  />
                </Col>
              </>
            ) : null
          }
          {
            attachments.length > 0 ? (
              <>
                <Divider />
                <Col span={24}>
                  <ItemAttachmentsSection 
                    datasheets={datasheets} 
                    guarantees={guarantees} 
                    disclaimers={disclaimers} 
                  />
                </Col>
              </>
            ): null
          }
          { isScreenEqualsAndBelowSm ? <Divider /> : null }
        </Row>
      </Col>
      {
        relatedItems.length > 0 && 
        (
          <Col flex={isScreenEqualsAndBelowMd ? '100%': '30%'}>
            {
              isScreenEqualsAndAboveLg ? (
                <Card style={{ 
                  maxHeight: '50rem',
                  overflowY: 'scroll', 
                  padding: '8px',
                }}>
                  <VariantRelatedSection />
                </Card>
              ): (
                <VariantRelatedSection />
              )
            }
          </Col>
        )
      }
    </Row>
  );
};

export default VariantDetailsView;
