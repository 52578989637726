import {
  Row, 
  Col,
  Card,
  Typography,
  Skeleton,
} from 'antd';
import { useTranslation } from 'react-i18next';
import OrderDetails from '@/containers/views/OrderDetails/OrderDetails';
import OrderRecipt from '@/components/ThankYou/OrderRecipt';
import { CheckCircleFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import IOrder from '@/interfaces/IOrder';
import Optional from '@/types/Optional';

const { Text } = Typography;


const OrderDetailsPage = () => {
  const { t } = useTranslation('common');
  const { orderId = '0' } = useParams<{orderId: IOrder['id']}>();

  const {
    order,
    isLoading,
    showThankYouMessage,
  } = OrderDetails(orderId);

  const ThankYouMessage = () => (
    <Card size="small" className="shadow-lg rounded-md">
      <Row>
        <Col span={24} className="text-lg p-1">
          <Text type="success">
            <CheckCircleFilled className="inline-flex mr-1"/>
            <div className="inline-flex">
              {t('g.thank_you')},
            </div>
            <div>
              {t('g.your_order_has_been_received_successfully')}
            </div>
          </Text>
        </Col>
        <Col span={24} className="px-2 py-4 bg-gray-100 border-t bg-opacity-50">
          <Text className="font-medium">
            {t('g.we_will_send_you_a_confirmation_email_at_the_email_registered_in_your_order')}
          </Text>
        </Col>
      </Row>
    </Card>
  );

  const PageContent = (props: {isLoading: boolean, order: Optional<IOrder>}) => {
    if(isLoading){
      return <Skeleton active />;
    }
    
    if(!order){
      return <>{t('g.order_not_found')}</>;
    }

    return <OrderRecipt order={order}/>
  }

  return (
    <Row gutter={[0, 12]} className="p-4">
      {showThankYouMessage && (
        <Col span={24}>
          <ThankYouMessage />
        </Col>
      )}
      <Col span={24}>
        <Card size="small" className="shadow-lg rounded-md">
          <Row>
            <Col span={24}>
              <PageContent isLoading={isLoading} order={order} />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default OrderDetailsPage;
