import axiosApiConfig from '@/config/axiosConfig';
import IParams from '@/interfaces/IParams';
import IPostalCode from '@/interfaces/IPostalCode';

const getPostalCodes = async (params?: IParams): Promise<IPostalCode[]> => {
  const result = await axiosApiConfig.get<IPostalCode[]>('/postal_codes', {
    params: { ...params },
  });
  return result.data;
};

interface IgetPostalCodesByCode {
  code: IPostalCode['code'];
}

export const getPostalCodeByCode = async ({ code }: IgetPostalCodesByCode): Promise<IPostalCode> => {
  const result = await axiosApiConfig.get<IPostalCode[]>('/postal_codes', {
    params: { code },
  });
  return result.data[0];
};

export const getSinglePostalCode = async (data:{id: IPostalCode['id']}): Promise<IPostalCode> => {
  const result = await axiosApiConfig.get<IPostalCode>(`/postal_codes/${data.id}`, {
  });
  return result.data;
};

export const createPostalCode = async (
  data: Omit<IPostalCode, 'id'>
): Promise<IPostalCode> => {
  const result = await axiosApiConfig.post<IPostalCode>('/postal_codes/', data);
  return result.data;
};

export const editPostalCode = async (
  data: Partial<IPostalCode>
): Promise<IPostalCode> => {
  const result = await axiosApiConfig.patch<IPostalCode>(
    `/postal_codes/${data.id}`,
    data
  );
  return result.data;
};

export default getPostalCodes;
