import EDeliveryMethod from '@/enums/EDeliveryMethod';
import { CarOutlined, InboxOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface IDeliveryMethodProps {
  deliveryMethod: EDeliveryMethod;
  hideText?: boolean;
}

const DeliveryMethod = ({
  deliveryMethod,
  hideText = false,
}: IDeliveryMethodProps) => {
  const { t } = useTranslation('common');

  const getTextValue = (deliveryMethod: EDeliveryMethod) => {
    if (deliveryMethod === EDeliveryMethod.Pickup) {
      return {
        icon: <InboxOutlined />,
        text: t('g.store_pickup'),
      };
    }
    if (deliveryMethod === EDeliveryMethod.Shipping) {
      return {
        icon: <CarOutlined />,
        text: t('g.address_shipping'),
      };
    }
  };

  return (
    <Typography.Text>
      {getTextValue(deliveryMethod)?.icon}{' '}
      {!hideText && getTextValue(deliveryMethod)?.text}
    </Typography.Text>
  );
};

export default DeliveryMethod;
