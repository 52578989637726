import IOperativePaymentService from '@/interfaces/IOperativePaymentService';
import IOperativePaymentServiceDto from '@/interfaces/IOperativePaymentServiceDto';
import IPaymentService from '@/interfaces/IPaymentService';
import IPaymentServiceDto from '@/interfaces/IPaymentServiceDto';

export const paymentServicesByWarehouseAdapter = (
  paymentService: IPaymentServiceDto
): IPaymentService => {
  const { 
    id,
    code,
    name,
    imageUrl,
    payment_service_strategies: strategies,
  } = paymentService;

  return {
    id,
    code,
    name,
    imageUrl,
    strategies,
  };
};

export const operativePaymentServiceAdapter = (
  paymentService: IOperativePaymentServiceDto['payments_service']
): IOperativePaymentService['paymentService'] => {
  const { 
    id,
    code,
    name,
    imageUrl,
  } = paymentService;

  return {
    id,
    code,
    name,
    imageUrl,
  };
};
