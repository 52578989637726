import axiosApiConfig from '@/config/axiosConfig';

interface IValidPasswordRecovery {
  code: 'success',
  token: string;
}

interface IInvalidPasswordRecovery {
  code: 'emailNotValid',
}

export interface IValidatePasswordRecoveryToken {
  token: string;
  email: string;
}

export interface IPasswordRecovery {
  token: string;
  email: string;
}

export const validatePasswordRecovery = async (
  email: string,
): Promise<IValidPasswordRecovery | IInvalidPasswordRecovery> => {
  const result = await axiosApiConfig.post<IValidPasswordRecovery | IInvalidPasswordRecovery>('/password_recovery_request', { 
    email, 
    project: 'store',
  });

  return result.data;
};

export const validatePasswordRecoveryToken = async (
  token: string,
): Promise<IValidatePasswordRecoveryToken> => {
  const result = await axiosApiConfig.post<IValidatePasswordRecoveryToken>('/validate_password_recovery_token', { 
    token,
    project: 'store', 
  });

  return result.data;
};

export const passwordRecovery = async (
  token: string,
  password: string,
): Promise<void> => {
  await axiosApiConfig.post<void>('/password_recovery', { 
    token, 
    password,
    project: 'store',
  });
};
