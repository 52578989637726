import { adapterDtoToMix } from '@/adapters/MixAdapter';
import { adapterDtoToProduct } from '@/adapters/ProductAdapter';
import { adapterDtoToVariant } from '@/adapters/VariantAdapter';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import IShoppingCartItemDto from '@/interfaces/IShoppingCartItemDto';

const formatShoppingCartItem = (
  sci: IShoppingCartItemDto
): IShoppingCartItem => {
  let itemProduct = sci.product ?? null;
  let itemVariant = sci.variant ?? null;
  let itemMix = sci.mix ?? null;

  let item = null;
  if (itemProduct) {
    item = adapterDtoToProduct(itemProduct);
  }
  if (itemVariant) {
    item = adapterDtoToVariant(itemVariant);
  }
  if (itemMix) {
    item = adapterDtoToMix(itemMix);
  }

  return {
    item: item,
    ...sci,
  };
};

export default formatShoppingCartItem;
