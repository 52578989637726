import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Image, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import IOpenpayToken from '@/interfaces/IOpenpayToken';
import IOpenpayResponse from '@/interfaces/IOpenpayResponse';
import { IOnPay } from '@/containers/views/Checkout/CheckoutPOS';
import { CreditCardOutlined } from '@ant-design/icons';
import { 
  AmericanExpressLogo, 
  BanorteLogo, 
  BanregioLogo, 
  BbvaLogo, 
  CarnetLogo, 
  CitibanamexLogo, 
  HsbcLogo, 
  InbursaLogo, 
  MasterCardLogo, 
  OpenpayLogo, 
  SantanderLogo, 
  ScotiabankLogo, 
  VisaLogo, 
} from '@/assets/images';

interface IOpenpayCardForm {
  merchantId: string;
  publicKey: string;
  fiscalAddress: string;
  phone: string;
  mailSupport: string;
  isPaymentBlock?: boolean;
  onSuccess: (props: IOnPay) => Promise<void>;
  onError: (response: IOpenpayTokenError) => void;
}

const OpenpayCardForm = ({
  merchantId,
  publicKey,
  fiscalAddress,
  phone,
  mailSupport,
  isPaymentBlock = false,
  onSuccess,
  onError,
}: IOpenpayCardForm) => {
  const { Text, Link } = Typography;
  const TERMS_AND_CONDITIONS_URL = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL;

  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceSessionId, setDeviceSessionId] = useState<string>('');
  const [cardForm] = Form.useForm();

  const PAYMENT_LIVEMODE = process.env.REACT_APP_PAYMENT_LIVEMODE === 'true' ? true : false;
  const IS_SANDBOX = !PAYMENT_LIVEMODE;

  let OpenPay = window?.OpenPay;


  const onSuccessCallback = async (token: IOpenpayResponse<IOpenpayToken>) => {
    await onSuccess({
      tokeId: token.data.id,
      deviceSessionId: deviceSessionId,
    });
    setLoading(false);
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const validValues = await cardForm.validateFields()
      console.log('valid values', { validValues });
      validValues.card_number = validValues.card_number.toString().replace(/[\s]/g, '');
      OpenPay.token.create(validValues, onSuccessCallback, onError);
    } catch (error) {
      console.error(error);
    }
  };

  const cardnumberFormat = ((value: string | undefined, info: {
    userTyping: boolean;
    input: string;
  }) => {
    console.log({ value, info });
    if (!value) return '';

    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;

    const cardnumber = info.input
      .replace(/[^\d]/g, '')
      .replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter(group => !!group).join(' ')
      );

    return cardnumber;
  });

  const handleChange = (e: any) => {
    const { target } = e;
    const { value, maxLength } = target;

    let newValue = value.replace(/[^\d]/g, '');
    
    cardForm.setFieldsValue({
      [e.target.name]: newValue.substr(0, maxLength)
    });

  };

  useEffect(() => {
    OpenPay = window?.OpenPay;

    if (!OpenPay) {
      return;
    }

    OpenPay.setId(merchantId);
    OpenPay.setApiKey(publicKey);
    OpenPay.setSandboxMode(IS_SANDBOX);

    setDeviceSessionId(OpenPay.deviceData.setup('payment-form', 'deviceSessionId'));
  }, [window?.OpenPay]);

  const logosStyle = {
    height: '50px', 
    width: 'auto',
  };
  const cardLogosStyle = {
    height: '50px', 
    width: 'auto',
  };
  const mainLogoStyle = {
    height: '50px', 
    width: 'auto',
  };

  return (
    <Row>
      <Col span={24} style={{background: '#d7d7d7a8', padding: 10}}>
        <Image 
          style={mainLogoStyle} 
          preview={false}
          src={OpenpayLogo} 
          alt={`OpenPay`}
          height={50}
        />
      </Col>
      <Col span={24} style={{background: '#f0f2f5a8', padding: 20}}>
        <Row className="my-4">
          <Col span={24}>
            <Form form={cardForm} action="#" id="payment-form">
              <Col span={24}>
                <Form.Item name="deviceSessionId" id="deviceSessionId" hidden>
                  <Input name="deviceSessionId" />
                </Form.Item>
                <Form.Item name="token_id" id="token_id" hidden>
                  <Input name="token_id" />
                </Form.Item>
                <Form.Item name="use_card_points" id="use_card_points" initialValue={false} hidden>
                  <Input name="use_card_points" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="holder_name" id="holder_name"
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <Input
                    size="large"
                    name="holder_name"
                    className="w-full"
                    placeholder={t('g.card_name')}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="card_number" id="card_number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    name="card_number"
                    className="w-full"
                    inputMode="numeric"
                    placeholder="XXXX XXXX XXXX XXXX"
                    // type="number"
                    maxLength={16}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item name="expiration_month" id="expiration_month"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          len: 2,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="expiration_month"
                        className="w-full"
                        placeholder="MM"
                        inputMode="numeric"
                        max={12}
                        type="number"
                        onChange={handleChange}
                        maxLength={2}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="expiration_year" id="expiration_year"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          len: 2,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="expiration_year"
                        className="w-full"
                        placeholder="YY"
                        inputMode="numeric"
                        onChange={handleChange}
                        type="number"
                        maxLength={2}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="cvv2" id="cvv2"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          max: 4,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        name="cvv2"
                        className="w-full"
                        placeholder="CVC"
                        inputMode="numeric"
                        maxLength={4}
                        type="number"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
        <Row gutter={24} className="my-4">
          {
            // TODO: Imagenes sin paddings o fijar containers
          }
          <Col span={24}>
            <Row>
              <Typography.Text children={t('g.credit_card')} className="mb-2" strong />
            </Row>
            <Space size={[8, 16]} wrap>
              <Image src={VisaLogo} alt={`Visa`} style={{ ...cardLogosStyle, padding: '8px' }} preview={false} />
              <Image src={MasterCardLogo} alt={`MasterCard`} style={{ ...cardLogosStyle, padding: '8px' }} preview={false} className="px-2" />
              <Image src={AmericanExpressLogo} alt={`AmericanExpress`} style={{ ...cardLogosStyle, padding: '8px' }} preview={false} className="pl-3" />
              <Image src={CarnetLogo} alt={`Carnet`} style={cardLogosStyle} preview={false} />
            </Space>
          </Col>
          <Col span={24}>
            <Row>
              <Typography.Text children={t('g.debit_card')} className="mt-2" strong />
            </Row>
            <Space size={[8, 16]} wrap>
              <Image src={BbvaLogo} alt={`Bbva`} style={{ ...logosStyle, padding: '16px' }} preview={false} />
              <Image src={SantanderLogo} alt={`Santander`} preview={false} className="px-2" style={logosStyle} />
              <Image src={BanorteLogo} alt={`Banorte`} style={logosStyle} preview={false} />
              <Image src={BanregioLogo} alt={`Banregio`} style={logosStyle} preview={false} />
              <Image src={CitibanamexLogo} alt={`Citibanamex`} style={logosStyle} preview={false} />
              <Image src={InbursaLogo} alt={`Inbursa`} preview={false} style={logosStyle} />
              <Image src={ScotiabankLogo} alt={`Scotiabank`} style={{ ...logosStyle, padding: '4px' }} preview={false} />
              <Image src={HsbcLogo} alt={`Hsbc`} style={{ ...logosStyle, padding: '4px' }} preview={false} className="px-2" />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="my-4">
            <Button
              block
              size="large"
              type="primary"
              className="bg-blue-500"
              icon={<CreditCardOutlined />}
              onClick={handlePayment}
              loading={loading}
              disabled={loading || isPaymentBlock}
            >
              {t('g.pay')}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: 16 }}>
        <Space direction="vertical">
          <Text type="secondary">{fiscalAddress}</Text>
          <Text type="secondary">{t('g.phone')} <Link href={`tel:${phone}`} target="_blank">{phone}</Link></Text>
          <Text type="secondary">{t('g.email')} <Link href={`mailto:${mailSupport}`} target="_blank">{mailSupport}</Link></Text>
          {
            TERMS_AND_CONDITIONS_URL &&
            <Link href={TERMS_AND_CONDITIONS_URL} target="_blank" type="secondary">
              {t('g.terms_and_cond')}
            </Link>
          }
        </Space>
      </Col>
    </Row>
  );
};

export default OpenpayCardForm;