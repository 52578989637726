import ICategory from '@/interfaces/ICategory';
import ICategoryDto from '@/interfaces/ICategoryDto';

export const adapterDtoToCategory = (category: ICategoryDto): ICategory => {
  const {
    id,
    deleted,
    isVisible,
    items,
    img,
    labelEn,
    labelEs,
    more,
    sideScroll,
    order,
  } = category;

  return {
    id,
    deleted,
    icon: img,
    isVisible,
    items,
    more,
    order,
    sideScroll,
    label: {
      en: labelEn,
      es: labelEs,
    },
  };
};
