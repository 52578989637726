import { paymentServicesByWarehouseAdapter } from '@/adapters/PaymentServiceAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import IPaymentService from '@/interfaces/IPaymentService';
import IPaymentServiceDto from '@/interfaces/IPaymentServiceDto';
import IParams from '@/interfaces/IParams';
import IOperativePaymentService from '@/interfaces/IOperativePaymentService';
import IOperativePaymentServiceDto from '@/interfaces/IOperativePaymentServiceDto';
import { adapterDtoToOperativePaymentService } from '@/adapters/OperativePaymentServiceAdapter';

export const getPaymentServiceByWarehouse = async (params: IParams): Promise<IPaymentService[]> => {
  const result = await axiosApiConfig.get<IPaymentServiceDto[]>('/payment_services_by_warehouse', {
    params: { ...params },
  });

  return result.data.map(d => paymentServicesByWarehouseAdapter(d));
};


export const getOperativePaymentServiceByWarehouse = async (params: IParams): Promise<IOperativePaymentService[]> => {
  const result = await axiosApiConfig.get<IOperativePaymentServiceDto[]>('/operative_payment_services_by_warehouse', {
    params: { ...params },
  });

  return result.data.map(d => adapterDtoToOperativePaymentService(d));
};
