import IOrder from "@/interfaces/IOrder";
import IOrderDto from "@/interfaces/IOrderDto";
import { adapterDtoToDelivery } from './DeliveryAdapter';

export const adapterDtoToOrder = async (order: IOrderDto): Promise<IOrder> => {
  const { 
    id,
    status,
    archived,
    customer,
    payments,
    salesOriginCode,
    shoppingCart,
    amounts,
    responsableTo,
    issues,
    attachments,
    comments,
    seenAt,
    createdAt,
    updatedAt,
    warehouseId,
  } = order;

  const deliveriesDto = order.deliveries;

  const deliveries = deliveriesDto.map(deliveryDto => adapterDtoToDelivery(deliveryDto));
  
  return {
    id,
    status,
    archived,
    customer,
    deliveries,
    payments,
    salesOriginCode,
    shoppingCart,
    amounts,
    responsableTo,
    issues,
    attachments,
    comments,
    seenAt,
    createdAt,
    updatedAt,
    warehouseId,
  };
};

