import DirectionType from '@/types/DirectionType';
import React, { 
  ReactNode,
  useLayoutEffect,
  useState, 
} from 'react';


interface ScrollContext {
  hiddenExtra: boolean;
  isScrollable: boolean;
  currentScroll: number;
  direction: DirectionType;
  handleScroll: (e: React.UIEvent<HTMLDivElement>) => void;
}
interface IChildren {
  children : ReactNode;
}

const scrollContext = React.createContext<ScrollContext>({} as ScrollContext);

function useScroll() {
  const [currentScroll, setCurrentScroll] = useState<number>(0);
  const [direction, setDirection] = useState<DirectionType>('up');
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [hiddenExtra, setHiddenExtra] = useState<boolean>(false);

  const headerHeight = document?.getElementById('zel-header')?.clientHeight ?? 0;
  const hiddenHeaderHeight = document?.getElementById('zel-hidden-topbar')?.clientHeight ?? 0;
  const minHeaderHeight = headerHeight - hiddenHeaderHeight;
  const executionRangePercentage = 80;

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const newScroll = e.currentTarget.scrollTop;
    if (newScroll === 0) {
      setDirection('up');
      return;
    } 
    
    const totalContentHeight = e.currentTarget.scrollHeight;
    const viewContentHeight = e.currentTarget.clientHeight;

    const isContentScrollable = totalContentHeight - minHeaderHeight > viewContentHeight;

    const executionRange = ((totalContentHeight - viewContentHeight) * executionRangePercentage) / 100;

    if (executionRange <= newScroll) return;

    setIsScrollable(isContentScrollable);
    setDirection(currentScroll < newScroll ? 'down' : 'up');
    setCurrentScroll(newScroll);
  };

  useLayoutEffect(() => {
    if (!isScrollable) {
      setHiddenExtra(false);
      return;
    }
    
    const isScrollingDown = direction === 'down';
    setHiddenExtra(isScrollingDown);
  }, [direction]);

  return {
    hiddenExtra,
    isScrollable,
    currentScroll,
    direction,
    handleScroll,
  };
};

export const ScrollProvider = ({ children }: IChildren) => {
  const scroll = useScroll();

  return <scrollContext.Provider value={scroll}>{children}</scrollContext.Provider>;
};

export default function AuthConsumer() {
  return React.useContext(scrollContext);
}

