import React from 'react';
import { Col, Row } from 'antd';
import FileDropdown from '../General/FileDropdown';
import { useTranslation } from 'react-i18next';
import IAttachment from '@/interfaces/IAttachment';

interface IItemAttachmentsSection {
  datasheets: IAttachment[];
  guarantees: IAttachment[];
  disclaimers:  IAttachment[];
}

const ItemAttachmentsSection = ({
  datasheets = [],
  guarantees = [],
  disclaimers = []
}: IItemAttachmentsSection) => {
  const { t } = useTranslation('common');
  
  if (
    datasheets.length <= 0 
    && guarantees.length <= 0 
    && disclaimers.length <= 0
  ) return null;

  return (
    <Row gutter={20}>
      {
        datasheets.length > 0 ? (
          <Col>
            <FileDropdown files={datasheets} placeholder={t('g.datasheets')} />
          </Col>
        ) : null
      }
      {
        guarantees.length > 0 ? (
          <Col>
            <FileDropdown files={guarantees} placeholder={t('g.guarantees')} />
          </Col>
        ) : null
      }
      {
        disclaimers.length > 0 ? (
          <Col>
            <FileDropdown files={disclaimers} placeholder={t('g.disclaimers')} />
          </Col>
        ) : null
      }
    </Row>
  );
};

export default ItemAttachmentsSection;