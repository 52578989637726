import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import useScroll from '@/hooks/useScroll';

interface IContent {
  children: ReactNode;
}

const Content = ({ children }: IContent) => {
  const { Content } = Layout;

  const { handleScroll } = useScroll();
  
  return (
    <Content 
      id="mainContent"
      onScroll={handleScroll}
      style={{
        overflowY: 'auto',
      }}
    >
      <div 
        style={{ 
          width: '100%',
          margin: '0 auto',
        }}
      > 
        {children}
      </div>
    </Content>
  );
};

export default Content;
