import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Select, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import IOpenpayToken from '@/interfaces/IOpenpayToken';
import EPaymentStatus from '@/enums/EPaymentStatus';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import { IOnPay } from '@/containers/views/Checkout/CheckoutPOS';

const { Option } = Select;

interface IInternalPaymentForm {
  paymentStrategy: string | number; // TODO: replace wt EPaymentStrategy
  isPaymentBlock: boolean,
  onSuccess: (props: IOnPay) => Promise<void>;
}

const InternalPaymentForm = ({
  onSuccess,
  isPaymentBlock = false,
  paymentStrategy,
}: IInternalPaymentForm) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);
  const [internalPaymentForm] = Form.useForm();

  const handlePayment = async () => {
    setLoading(true);
    const values = await internalPaymentForm.validateFields();

    onSuccess({
      paymentStatus: values.paymentStatus,
      paymentStrategy,
    })
  };

  const PaymentStatusOptions = Object.entries(EPaymentStatus).map(entry => {
    const [label, code] = entry;
    return <Option value={code} key={code}>{t(`g.${label.toLowerCase()}`)}</Option>
  })

  return (
    <Row>
      <Col span={24}>
        <Form form={internalPaymentForm} action="#" id="payment-form" layout="vertical">
          <Row>
            <Form.Item
              id="paymentStatusSelector" 
              name="paymentStatus" 
              label={t('g.payment_status')}
              rules={[{ required: true }]}
            >
              <Select placeholder={t('g.select')}>
                {PaymentStatusOptions}
              </Select>
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        <Button 
          type="primary"
          block
          className="bg-blue-500"
          onClick={handlePayment}
          loading={loading}
          disabled={isPaymentBlock}
        >
          {t('g.pay')}
        </Button>
      </Col>
    </Row>
  );
};

export default InternalPaymentForm;