import ISchedule from '@/interfaces/ISchedule';
import moment from 'moment';
import { Moment } from 'moment';
import getScheduleDayByDate from '@/helpers/getScheduleDayByDate';

function isValidScheduleDay(schedule: ISchedule, referenceDate: Moment = moment()): Boolean{
  const scheduleDay = getScheduleDayByDate(schedule, referenceDate);

  if(scheduleDay?.isClosed){
    return true;
  }

  if(scheduleDay?.isOpen24hr){
    return true;
  }

  if(scheduleDay?.rangeSchedule && scheduleDay.rangeSchedule[0]){
    return true;
  }

  return false;
}

export default isValidScheduleDay;
