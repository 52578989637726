import React, { 
  ReactNode, 
  useEffect, 
  useState, 
} from 'react';
import AuthenticationService from '@/services/AuthService';
import { FeathersError } from '@feathersjs/errors';
import IAuthentication from '@/interfaces/IAuthentication';
import ICustomer from '@/interfaces/ICustomer';
import IRegister from '@/interfaces/IRegister';
import { useTranslation } from 'react-i18next';
import onLoginEditShoppingCart from '@/helpers/onLoginEditShoppingCart';
import IPageActions from '@/interfaces/IPageActions';
import { getStaffActions } from '@/services/ActionService';

interface AuthContext {
  customer: ICustomer | undefined;
  authenticated: boolean;
  login: (request: IAuthentication) => Promise<void>;
  logout: () => Promise<void>;
  register: (request: IRegister) => Promise<void>;
  cleanupErrors: () => void;
  loading: boolean;
  authError?: string;
  actions: IPageActions | null;
}

interface IChildren {
  children : ReactNode;
}

const authContext = React.createContext<AuthContext>({} as AuthContext);

function useAuth(): AuthContext {
  const { t } = useTranslation('common');

  let initCustomer = undefined;
  let initActions = null;

  if ('customer' in localStorage) {
    const customerItem = localStorage.getItem('customer');

    if(customerItem){
      initCustomer = JSON.parse(customerItem);
    }
  }

  if ('actions' in localStorage) {
    initActions = localStorage.getItem('actions');
    initActions = JSON.parse(initActions === null ? '' : initActions);
  }

  const [customer, setCustomer] = useState<ICustomer>(initCustomer);
  const [authenticated, setAuthenticated] = useState(
    AuthenticationService.isAuthenticated,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const [actions, setActions] = useState<IPageActions | null>(initActions);

  const setActionContext = (newActions: IPageActions) => {
    localStorage.setItem('actions', JSON.stringify(newActions));
    setActions(newActions);
  };

  const resetAuthContext = () => {
    localStorage.removeItem('customer');
    localStorage.removeItem('actions');
    localStorage.removeItem('zellship-auth-token');
    setActions(null);
  };

  const checkAuth = async () => {
    setLoading(true);

    try {
      const result = await AuthenticationService.reAuthenticate();
      if (result.authentication) {
        setAuthenticated(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAuthenticated(false);
      resetAuthContext();
    }
  };

  const login: AuthContext['login'] = async (request) => {
    setLoading(true);
    try {
      const result = await AuthenticationService.authenticate(request);
      const loggedUser: ICustomer = result.user;

      await onLoginEditShoppingCart(loggedUser.id);
      const resActions = await getStaffActions(loggedUser.id);
      setActionContext(resActions);

      localStorage.setItem('customer', JSON.stringify(loggedUser));
      setCustomer(loggedUser);

      setAuthenticated(true);
      setAuthError(undefined);
    } catch (error) {
      console.error('Unable to authenticate user', error);
      resetAuthContext();
      setAuthenticated(false);
      setAuthError(
        `${t('g.invalid_credentials')}. ${t('g.try_againg_with_different_email_or_password')}`
      );

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout: AuthContext['logout'] = async () => {
    setLoading(true);
    try {
      await AuthenticationService.logout();
      setAuthError(undefined);
      resetAuthContext();
    } catch (error) {
      console.error('Something failed during log out process');
      setAuthError(t('g.something_failed_during_logout'));

      throw error;
    } finally {
      setAuthenticated(false);
      setLoading(false);
    }
  };

  const register: AuthContext['register'] = async params => {
    setLoading(true);
    try {
      await AuthenticationService.register(params);
      setAuthError(undefined);
    } catch (error) {
      if (error instanceof FeathersError && error.code === 400) {
        setAuthError(t('g.the_email_is_already_in_use'));
      }
      console.error('Something failed during log out process');
      setAuthenticated(false);

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const cleanupErrors: AuthContext['cleanupErrors'] = () => {
    setAuthError(undefined);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return {
    customer,
    authenticated,
    authError,
    loading,
    login,
    logout,
    register,
    cleanupErrors,
    actions
  };
}

export const AuthProvider = ({ children }: IChildren) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default function AuthConsumer() {
  return React.useContext(authContext);
}
