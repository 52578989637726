const isUrl = (url: string): boolean => {
  const urlPattern = new RegExp('^([a-zA-Z]+:\\/\\/)'  
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
  + '((\\d{1,3}\\.){3}\\d{1,3}))'
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' 
  + '(\\?[;&a-z\\d%_.~+=-]*)?' 
  + '(\\#[-a-z\\d_]*)?$', 'i');

  return urlPattern.test(url);
};

export default isUrl;
