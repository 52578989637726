import React from 'react';
import { Col, Form, Select, Row, Typography, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import ISaleOrigin from '@/interfaces/ISaleOrigin';

export interface ISalesOriginForm {
  salesOriginCode: ISaleOrigin['code']
}

interface ICheckoutSalesOriginPage {
  salesOrigins: ISaleOrigin[]
  form: FormInstance<ISalesOriginForm>
}

const CheckoutSalesOriginPage = ({
  salesOrigins,
  form,
}: ICheckoutSalesOriginPage) => {
  const { t } = useTranslation('common');

  const SaleOriginOptions = salesOrigins.map(saleOrg => {
    const { code, alias } = saleOrg;
    return <Select.Option value={code}>{alias['es']}</Select.Option>
  });
  
  const initValues: ISalesOriginForm = {
    salesOriginCode: 'platform'
  }

  return (
    <Row>
      <Col span={24} className="text-center">
        <Typography.Title level={3} className="underline">
          {t('g.sales_origin')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('hints.select_sales_origin')}
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Form form={form} id="sales-origin-form" layout="vertical" initialValues={initValues}>
          <Row className="content-center w-full">
            <Form.Item
              id="salesOriginSelector" 
              name="salesOriginCode" 
              rules={[{ required: true }]}
            >
              <Select placeholder={t('g.select')}>
                {SaleOriginOptions}
              </Select>
            </Form.Item>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CheckoutSalesOriginPage;
