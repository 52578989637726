import { Col, Row } from 'antd';
import HomeFilters from '@/containers/views/Home/Filters/HomeFilters';
import Home from './Home';
import HomeItemsList from './HomeItemsList';
import useBreakpoint from '@/hooks/useBreakpoint';
import ICategory from '@/interfaces/ICategory';
import HomeFeaturedProductsList from './HomeFeaturedProductsList';
import HomeCategoriesList from './HomeCategoriesList';
import { ItemExtra } from '@/services/FilteredItem';
import IMix from '@/interfaces/IMix';
import EVisibility from '@/enums/EVisibility';

const HomePage = () => {
  const {
    items,
    itemsLoading,
    featuredItems,
    featureditemsLoading,
    showProductsPrices,
    showMixesPrices,
    categories,
    categoriesLoading,
    categoriesDrawer,
    selectedCategoryId,
    attriubutes,
    sortOptions,
    search,
    itemsNotFoundImg,
    emptySelectionImg,
    onSelectCategory,
    buildCategoriesList,
    getBreakpoints,
    loadMoreItemsData
  } = Home();

  const { currentBreakpoint } = useBreakpoint();

  const hasSearch = search && search.length > 0;
  const hasFeaturedItems = featuredItems.data.length;
  const showFeaturedItemsSection = hasFeaturedItems && !hasSearch;

  function hideMixesByVisibility(menuItems: ItemExtra[]): ItemExtra[] {
    return menuItems.filter(item => {
      if (item.key !== 'mix') {
        return true;
      }

      const itemMix = item as IMix;

      if(itemMix.restrictions.visibility !== EVisibility.Hidden){
        return true;
      }

      return false;
    });
  }

  return (
    <Col style={{maxWidth: '1200px', margin: '24px auto'}}>
      <Row gutter={[0, 12]} className="mt-4">
        <Col span={24}>
          <HomeCategoriesList 
            categoriesDrawer={categoriesDrawer}
            fullCategories={buildCategoriesList(
              categories as ICategory[], 
              'all',
            )}
            partialCategories={buildCategoriesList(
              categories as ICategory[], 
              getBreakpoints(currentBreakpoint),
            )}
            selectedCategoryId={selectedCategoryId}
            onSelectCategory={category => onSelectCategory(category.id)}
            isLoadingCategories={categoriesLoading}
          />
        </Col>
        <Col span={showFeaturedItemsSection ? 24 : 0}>
          <HomeFeaturedProductsList 
            featuredItems={featuredItems.data}
            isLoadingFeaturedItems={featureditemsLoading}
          />
        </Col>
        <Col span={24} className="">
          <HomeFilters 
            attributes={attriubutes}
            sortOptions={sortOptions}
          />
        </Col>
        <Col span={24} className="">
          <HomeItemsList
            notFoundImage={selectedCategoryId === 'empty' 
              ? emptySelectionImg 
              : itemsNotFoundImg
            }
            isEmpty={items.data.length === 0 && !itemsLoading}
            elements={hideMixesByVisibility(items.data)}
            hasMore={items.total > items.data.length}
            isLoading={itemsLoading} 
            loadMoreData={loadMoreItemsData}
            showProductsPrices={showProductsPrices}
            showMixesPrices={showMixesPrices}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default HomePage;
