import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  let token = localStorage.getItem('zellship-auth-token');

  if (!token) return config;

  config.headers = {
    'authorization': `Bearer ${token}`,
    'strategy': 'local'
  };

  return config;
});

export default instance;
