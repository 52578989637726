import React from 'react';
import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { emailPattern } from '@/utils/emailPattern';
import useBreakpoints from '@/hooks/useBreakpoint';

export interface IPasswordRecoveryRequestValues {
  email: string;
}

interface IPasswordRecoveryRequestForm {
  form: FormInstance<IPasswordRecoveryRequestValues>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordRecoveryRequestForm = ({
  form,
  error,
  setError,
}: IPasswordRecoveryRequestForm) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  
  const emailRules = [
    {
      required: true,
      message: t('validation.required'),
    },
    {
      pattern: emailPattern,
      message: t('validation.email'),
    },
  ];

  return (
    <Form 
      form={form} 
      layout="vertical"
      name="passwordRecoveryForm"
    >
      <Form.Item 
        name="email" 
        label={t('g.email')} 
        rules={emailRules}
        validateStatus={error ? 'error' : undefined}
        help={error ? t('g.email_does_not_exist') : undefined}
      >
        <Input 
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.email')} 
          onChange={() => setError(false)}
        />
      </Form.Item>
    </Form>
  )
}

export default PasswordRecoveryRequestForm;
