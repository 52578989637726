import React, { useState } from 'react';
import { 
  Card, Col, Form, message, Modal, Row,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import ShoppingCartsFilters from '@/containers/views/ShoppingCart/components/ShoppingCartsFilters';
import ShoppingCartsHeader from '@/containers/views/ShoppingCart/components/ShoppingCartsHeader';
import ShoppingCartsList from '@/containers/views/ShoppingCart/components/ShoppingCartsList';
import useService from '@/hooks/useService';
import IShoppingCart from '@/interfaces/IShoppingCart';
import { useTranslation } from 'react-i18next';
import useAuth from '@/hooks/useAuth';
import EShoppingCartsFilters from '@/enums/EShoppingCartsFilters';
import { omit } from 'lodash';
import { createShoppingCart, deleteShoppingCart, getShoppingCartsPagination, transferFromOneShoppingCartToAnother } from '@/services/ShoppingCart';
import IPaginationResponse from '@/interfaces/IPaginationResponse';
import ShoppingCartForm, { IShoppingCartData } from '@/components/Forms/ShoppingCartForm';
import useModal from '@/hooks/useModal';
import EShoppingCartStatus from '@/enums/EShoppingCartStatus';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ShoppingCartTransferForm, { IShoppingCartTransferData } from '@/containers/views/ShoppingCart/components/ShoppingCartTransferForm';
import useShoppingCart from '@/hooks/useShoppingCart';
import { useNavigate } from 'react-router-dom';

const ShoppingCartPage = () => {
  const { t } = useTranslation('common');
  const { confirm } = Modal;
  const { customer } = useAuth();
  const { selectedShoppingCart, shoppingCart } = useShoppingCart();
  const navigate = useNavigate();

  const [shoppingCartToTransfer, setShoppingCartToTransfer] = useState<IShoppingCart['id']>();

  const shoppingCartModal = useModal();
  const [shoppingCartForm] = Form.useForm<IShoppingCartData>();

  const shoppingCartTransferModal = useModal();
  const [shoppingCartTransferForm] = Form.useForm<IShoppingCartTransferData>();
  
  const shoppingCarts = useService<IPaginationResponse<IShoppingCart>>({
    fetchData: getShoppingCartsPagination,
    params: {
      $skip: 0,
      $limit: 10,
      '$sort[createdAt]': -1,
    }
  });

  const shoppingCartActions = (data: IShoppingCart): ItemType[] => {
    let actions: ItemType[] = [];

    if (data.status === EShoppingCartStatus.Active) {
      actions = [
        {
          key: `select-${data.id}`,
          label: t('g.select'),
          onClick: () => {
            selectedShoppingCart(data.id);
            message.success({
              content: t('g.done'),
              duration: 2,
              key: 'selectedShoppingCart',
            });
          }
        },
        {
          key: `checkout-${data.id}`,
          label: t('g.checkout'),
          onClick: () => { 
            selectedShoppingCart(data.id);
            navigate('/checkout/pos', { replace: true });
          }
        },
        {
          key: `transfer-${data.id}`,
          label: t('g.transfer'),
          onClick: () => {
            setShoppingCartToTransfer(data.id);
            shoppingCartTransferModal.show();
          }
        },
        {
          key: `delete-${data.id}`,
          label: t('g.delete'),
          onClick: () => confirmDeleteShoppingCart(data.id)
        }
      ];
    }

    return actions;
  };

  const onChangeTabFilter = (filter: string) => {
    const { All } = EShoppingCartsFilters;
    shoppingCarts.onChangePage(1);

    if (filter === All) {
      const newFilter = omit(shoppingCarts.currentParams, ['creatorId']);
      shoppingCarts.setCurrentParams({
        ...newFilter,
        "$limit": 10,
        "$skip": 0
      });
      return;
    }

    shoppingCarts.setCurrentParams({
      ...shoppingCarts.currentParams,
      creatorId: customer?.id,
      "$limit": 10,
      "$skip": 0
    });
  };

  const onSearch = (search: string) => {
    shoppingCarts.onChangePage(1);

    if (!search) {
      const newFilter = omit(shoppingCarts.currentParams, ['$or[1][id][$like]', '$or[0][alias][$like]']);
      shoppingCarts.setCurrentParams(newFilter);
      return;
    }

    shoppingCarts.setCurrentParams({
      ...shoppingCarts.currentParams,
      "$or[0][alias][$like]": `%${search}%`,
      "$or[1][id][$like]": `%${search}%`,
    });
  };

  const onCloseShoppingCartModal = () => {
    shoppingCartModal.hide();
    shoppingCartForm.resetFields();
  };

  const onAddShoppingCart = async () => {
    try {
      shoppingCartModal.setLoading(true);
      if (!customer) throw new Error('ERROR_CODE.CUSTOMER_NOT_EXISTS');

      const data = await shoppingCartForm.validateFields();

      const shoppingCart: Partial<IShoppingCart> = {
        ...data,
        creatorId: customer.id,
        status: EShoppingCartStatus.Active,
      }

      await createShoppingCart(shoppingCart);
      shoppingCarts.reload();
      onCloseShoppingCartModal();
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'createShoppingCartKey',
      });
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'createShoppingCartKey',
      });
    } finally {
      shoppingCartModal.setLoading(false);
    }
  };

  const confirmDeleteShoppingCart = async (
    deleteId: IShoppingCart['id'],
  ) => {
    confirm({
      title: t('g.delete_shopping_cart'),
      icon: <ExclamationCircleOutlined />,
      content: `${t('g.do_you_want_to_delete_this_shopping_cart?')}`,
      okText: t('g.delete_shopping_cart'),
      cancelText: t('g.cancel'),
      okButtonProps: { className:"bg-blue-500" },
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            deleteShoppingCart({ id: deleteId })
              .then(() => {
                shoppingCarts.reload();
                message.success({
                  content: t('g.done'),
                  duration: 2,
                  key: 'createShoppingCartKey',
                });
                resolve('Nice');
              })
              .catch((error) => {
                console.error(error);
                message.error({
                  content: t('g.error'),
                  duration: 2,
                  key: 'createShoppingCartKey',
                });
                reject(Error('It broke'));
              });
          });
        } catch {
          return console.log('Oops errors!');
        }
      },
      onCancel() {},
    });
  };

  const onCloseShoppingCartTransferModal = () => {
    shoppingCartTransferModal.hide();
    shoppingCartTransferForm.resetFields();
    setShoppingCartToTransfer(undefined);
  };

  const onTranferShoppingCart = async () => {
    const data = await shoppingCartTransferForm.validateFields();

    try {
      shoppingCartTransferModal.setLoading(true);

      await transferFromOneShoppingCartToAnother(data);

      shoppingCarts.reload();
      onCloseShoppingCartTransferModal();
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'tranferShoppingCartKey',
      });
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'tranferShoppingCartKey',
      });
    } finally {
      shoppingCartTransferModal.setLoading(false);
    }
  };

  return (
    <Card bordered={false}>
      <Modal 
        title={t('g.add_shopping_cart')}
        open={shoppingCartModal.visible}
        onCancel={onCloseShoppingCartModal}
        confirmLoading={shoppingCartModal.isLoading}
        okText={t('g.save')}
        okButtonProps={{ className:"bg-blue-500" }}
        onOk={onAddShoppingCart}
        destroyOnClose
      >
        <ShoppingCartForm form={shoppingCartForm}/>
      </Modal>
      <Modal 
        title={t('g.transfer_shopping_cart')}
        open={shoppingCartTransferModal.visible}
        onCancel={onCloseShoppingCartTransferModal}
        confirmLoading={shoppingCartTransferModal.isLoading}
        okText={t('g.save')}
        okButtonProps={{ className:"bg-blue-500" }}
        onOk={onTranferShoppingCart}
        destroyOnClose
      >
        <ShoppingCartTransferForm 
          form={shoppingCartTransferForm}
          initialShoppingCart={shoppingCartToTransfer ?? '1'}
        />
      </Modal>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <ShoppingCartsHeader 
            onAddShoppingCart={() => shoppingCartModal.show()}
          />
        </Col>
        <Col span={24}>
          <ShoppingCartsFilters 
            onChangeTab={onChangeTabFilter}
            onSearch={onSearch}
          />
        </Col>
        <Col span={24}>
          <ShoppingCartsList 
            loading={shoppingCarts.loading}
            onChangePage={shoppingCarts.onChangePage}
            currentShoppingCartId={shoppingCart?.id}
            pagination={{
              total: shoppingCarts.data?.total,
              current: shoppingCarts.currentPage,
              pageSize: 10,
            }}
            dataSorce={shoppingCarts.data?.data ?? []}
            actions={shoppingCartActions}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ShoppingCartPage;
