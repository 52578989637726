import { Col, Row, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './Title.css';

interface ITitle {
  title?: string;
  subTitle?: ReactNode | string;
}

const Title = ({
  title,
  subTitle,
}: ITitle) => {
  const { t } = useTranslation('common');

  return (
    <Row>
      <Col span={24}>
        <Typography.Title 
          level={4}
        >
          {title ?? t('g.title')}
        </Typography.Title>
        {
          subTitle && (
            <Typography.Text>
              {subTitle}
            </Typography.Text>
          )
        }
      </Col>
    </Row>
  );
};

export default Title;
