import IWarehouse from '@/interfaces/IWarehouse';
import Location from '@/components/Location';
import Schedule from './Schedule';
import { ShopOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/hooks/useBreakpoint';

const { Text } = Typography;

interface IWarehouseLocationProps {
  warehouse?: IWarehouse;
  iconHint?: React.ReactNode;
  showHint?: boolean;
  showIconHint?: boolean;
  showSchedule?: boolean;
  miniSchedule?: boolean;
  extra?: React.ReactNode;
}

const WarehouseLocation = ({
  warehouse,
  iconHint = <ShopOutlined className="fix-icon"/>,
  showHint = false,
  showIconHint = (iconHint) ? true : false,
  showSchedule = false,
  miniSchedule = false,
  extra,
}: IWarehouseLocationProps) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  const customTextColor = process.env.REACT_APP_THEME_LAYOUT_HEADER_COLOR_TEXT;
  
  function getHint(){
    if(!showHint){ return }

    const hintText = (warehouse) 
      ? t('g.your_warehouse') 
      : t('g.choose_your_store');

    return (
      <Text className="text-xs text-white" style={{ color: customTextColor }}>
        {hintText}
      </Text>
    );
  }

  function getContent(){
    let content = t('g.no_warehouse_avaiable');
    
    if(warehouse){
      const { address } = warehouse;
      content = `${warehouse.alias}, ${address.postalCode.code}`;
    }

    return  (
      <Text 
        className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }  
        strong 
        ellipsis
        style={{ color: customTextColor }}
      >
        {content}
      </Text>
    );
  }

  function getExtra(){
    if(warehouse && showSchedule){
      return <Schedule  miniShedule={miniSchedule} schedule={warehouse.schedule} actions={extra}/>
    }

    if(!showSchedule && extra){
      return extra;
    }

    if(!warehouse && extra){
      return extra;
    }
  }

  const hint = getHint();
  const content = getContent();
  const extraContent = getExtra();

  const icon = <ShopOutlined className="icon-large block" style={{ color: customTextColor }}/>;

  return (
    <Location
      icon={icon}
      hint={hint}
      content={content}
      extra={extraContent}
    />
  );
};

export default WarehouseLocation;
