import React, { useState } from 'react';
import { 
  Row, 
  Col, 
  Input, 
  Button, 
  Typography,
  Tag,
  message,
  Form, 
} from 'antd';
import { useTranslation } from 'react-i18next';
import { GiftOutlined } from '@ant-design/icons';
import formatCurrencyMX from '@/utils/formatCurrency';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';
import { validateCoupon } from '@/services/CouponService';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useForm } from 'antd/lib/form/Form';

interface ICheckoutDiscountSection {
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>
}

const CheckoutDiscountSection = ({
  couponCode,
  setCouponCode,
}: ICheckoutDiscountSection) => {
  const { 
    productsPrice,
    shippingPrice,
    totalDiscount,
    setTotalDiscount,
  } = useCheckoutDrawer();

  const [cuoponsForm] = useForm();

  const { isBreakpointEqualsAndBelow } = useBreakpoint();

  const { t } = useTranslation('common');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validateCouponCode = async () => {
    setError(false);
    setLoading(true);

    const values = await cuoponsForm.getFieldsValue();

    if (!values.couponCodeLocal) {
      errorValiteCouponCode('coupon.invalid_coupon');
      setLoading(false);
      return;
    };

    try {
      const isValidCoupon = await validateCoupon({
        couponCode: values.couponCodeLocal,
        payload: {
          amount: productsPrice + shippingPrice,
        },
      });

      if (!isValidCoupon.discount || !isValidCoupon.result) {
        errorValiteCouponCode(isValidCoupon.msg);
        return;
      }

      setTotalDiscount(isValidCoupon.discount);
      setCouponCode(values.couponCodeLocal);
      cuoponsForm.resetFields();
      message.success({
        duration: 2,
        key: 'ValidCouponKey',
        content: t(`coupon.valid_coupon`),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const errorValiteCouponCode = (errorCode: string) => {
    setError(true);
    setTotalDiscount(0);
    setCouponCode('');
    message.error({
      duration: 2,
      key: 'ValidCouponKey',
      content: t(`coupon.${errorCode}`),
    });
  };

  return (
    <Row 
      style={{
        background: '#f0f2f5a8', 
        padding: 20
      }}
      gutter={[0, 15]}
    >
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Typography.Text>
              {t('g.items')}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text 
              style={{ float: 'right' }}
            >
              {formatCurrencyMX(productsPrice)}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text>
              {t('g.shipping')}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text 
              style={{ float: 'right' }}
            >
              {formatCurrencyMX(shippingPrice)}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Typography.Text 
              strong
            >
              {t('g.subtotal')}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text 
              strong 
              style={{ float: 'right' }}
            >
              {formatCurrencyMX(productsPrice + shippingPrice)}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col flex={isBreakpointEqualsAndBelow('md') ? "100%" : 5}>
            <Form form={cuoponsForm}>
              <Form.Item name="couponCodeLocal" style={{ margin: 0 }}>
                <Input 
                  disabled={loading}
                  addonAfter={<GiftOutlined />}
                  placeholder={t('g.promotial_code')}
                  status={error ? 'error' : undefined}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col flex={isBreakpointEqualsAndBelow('md') ? "100%" : 1}>
            <Button 
              type="primary"
              loading={loading}
              style={isBreakpointEqualsAndBelow('md') ? {
                marginTop: '20px',
                float: 'right',
              } : {
                float: 'right',
              }}
              onClick={validateCouponCode}
            >
              {t('g.validate_code')}
            </Button>
          </Col>
        </Row>
      </Col>
      {
        (totalDiscount > 0 
        && couponCode
        && !error) ? (
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col flex="75px">
                    <Typography.Text >
                      {t('g.discount')}
                    </Typography.Text>
                  </Col>
                  <Col flex={1}>
                    <Tag className={isBreakpointEqualsAndBelow('md') ? "" : "ml-2"}>
                      {couponCode}
                    </Tag>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Typography.Text 
                  className="float-right"
                >
                  -{formatCurrencyMX(totalDiscount)}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        ) : null
      }
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Typography.Text 
              strong
            >
              {t('g.total')}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text 
              strong 
              style={{ float: 'right' }}
            >
              {formatCurrencyMX(productsPrice + shippingPrice - totalDiscount)}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CheckoutDiscountSection;
