import getTranlation from '@/helpers/getTranslation';
import IQuantifier from '@/interfaces/IQuantifier';
import { Radio, Space, Typography } from 'antd';

interface IQuantifiersRadioSelectProps {
  quantifiers: IQuantifier[];
  onChange: (quantifierId: IQuantifier['id']) => void;
  selected?: IQuantifier['id'];
}

const QuantifiersRadioSelect = ({
  onChange,
  quantifiers,
  selected,
}: IQuantifiersRadioSelectProps) => {

  return (
    <Radio.Group onChange={(e) => onChange(e.target.value)} value={selected}>
      <Space direction="vertical">
        {quantifiers.map(quantifier => (
          <Radio value={quantifier.id}>
            <Typography.Title level={5}>{getTranlation(quantifier.name)}</Typography.Title>
            <Typography.Paragraph>{getTranlation(quantifier.desc)}</Typography.Paragraph>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default QuantifiersRadioSelect;
