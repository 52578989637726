import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Sider from '@/containers/_layouts/Sider';
import Header from '@/containers/_layouts/Header';
import Content from '@/containers/_layouts/Content';
import Footer from '@/containers/_layouts/Footer';

const LayoutPage = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  
  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <Layout 
        style={{ 
          height: '100%',
          minHeight: '100%',
        }}
      >
        <Sider 
          collapsed={collapsed} 
          updateCollapsed={setCollapsed}
        />
        <Layout style={{
          backgroundColor: '#FFFFFF',
          minHeight: '100%',
        }}>
          <Header 
            collapsed={collapsed}
            updateCollapsed={setCollapsed}
          />
          <Content>
            <Outlet />
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutPage;
