import IMixBaseProduct from "@/interfaces/IMixBaseProduct";
import IMixBaseProductDto from "@/interfaces/IMixBaseProductDto";
import { adapterDtoToProduct } from "@/adapters/ProductAdapter";

export const adapterDtoToMixBaseProduct = (mixBaseProduct: IMixBaseProductDto): IMixBaseProduct => {
  const { 
    qty,
    product,
  } = mixBaseProduct;

  return {
    qty,
    product: adapterDtoToProduct(product),
  };
};
