import EGroupDeliveryCode from '@/enums/EGroupDeliveryCode';
import IGroupDeliveryDeniedResponse from '@/interfaces/IGroupDeliveryDeniedResponse';
import IGroupDeliverySuccessResponse from '@/interfaces/IGroupDeliverySuccessResponse';
import IGroupPickUpSuccessResponse from '@/interfaces/IGroupPickUpSuccessResponse';

const isGroupPickUp = (
  groupDelivery: IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupPickUpSuccessResponse>
): groupDelivery is IGroupDeliverySuccessResponse<IGroupPickUpSuccessResponse> => {
 return (groupDelivery as IGroupDeliverySuccessResponse<IGroupPickUpSuccessResponse>).code === EGroupDeliveryCode.Success;
};

export default isGroupPickUp;
