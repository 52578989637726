import IScheduleDto from '@/interfaces/IScheduleDto';
import ISchedule from '@/interfaces/ISchedule';
import Day from '@/enums/Day';
import RangeScheduleType from '@/types/RangeScheduleType';
import { Moment } from 'moment';

export const adapterDtoToSchedule = (schedule: IScheduleDto): ISchedule => {
  const { 
    id,
    mon24,
    monClosed,
    monRange,
    tue24,
    tueClosed,
    tueRange,
    wed24,
    wedClosed,
    wedRange,
    thu24,
    thuClosed,
    thuRange,
    fri24,
    friClosed,
    friRange,
    sat24,
    satClosed,
    satRange,
    sun24,
    sunClosed,
    sunRange,
  } = schedule;

  return {
    id,
    [Day.Monday]: {
      day: Day.Monday,
      isOpen24hr: mon24,
      isClosed: monClosed,
      rangeSchedule: formatRangeSchedule(monRange),
    },
    [Day.Tuesday]: {
      day: Day.Tuesday,
      isOpen24hr: tue24,
      isClosed: tueClosed,
      rangeSchedule: formatRangeSchedule(tueRange),
    },
    [Day.Wednesday]: {
      day: Day.Wednesday,
      isOpen24hr: wed24,
      isClosed: wedClosed,
      rangeSchedule: formatRangeSchedule(wedRange),
    },
    [Day.Thursday]: {
      day: Day.Thursday,
      isOpen24hr: thu24,
      isClosed: thuClosed,
      rangeSchedule: formatRangeSchedule(thuRange),
    },
    [Day.Friday]: {
      day: Day.Friday,
      isOpen24hr: fri24,
      isClosed: friClosed,
      rangeSchedule: formatRangeSchedule(friRange),
    },
    [Day.Saturday]: {
      day: Day.Saturday,
      isOpen24hr: sat24,
      isClosed: satClosed,
      rangeSchedule: formatRangeSchedule(satRange),
    },
    [Day.Sunday]: {
      day: Day.Sunday,
      isOpen24hr: sun24,
      isClosed: sunClosed,
      rangeSchedule: formatRangeSchedule(sunRange),
    }
  };
};


const formatRangeSchedule = (ranges: [Moment, Moment][]): RangeScheduleType[] => {
  const newRange: RangeScheduleType[] = ranges.map(
    range => ({
      startTime: range[0],
      endTime: range[1],
    }),
  );

  return newRange;
};
