import IProduct from '@/interfaces/IProduct';
import ISelectionMix from '@/interfaces/ISelectionMix';

const isSelectionMix = (
  selectionMix: ISelectionMix | IProduct['id']
): selectionMix is ISelectionMix => {
 return (
  (selectionMix as ISelectionMix).baseProducts !== undefined
  && (selectionMix as ISelectionMix).sections !== undefined
  );
};

export default isSelectionMix;
