import IQuantifier from '@/interfaces/IQuantifier';
import IQuantifierDto from '@/interfaces/IQuantifierDto';
import IQuantifierRequirement from '@/interfaces/IQuantifierRequirement';
import IQuantifierRequirementDto from '@/interfaces/IQuantifierRequirementDto';
import { adapterDtoToProduct } from './ProductAdapter';

export const adapterDtoToQuantifier = (quantifier: IQuantifierDto): IQuantifier => {
  const {
    nameEs,
    nameEn,
    descEn,
    descEs,
    requirements,
  } = quantifier;

  return {
    ...quantifier,
    requirements: requirements.map(requeriment => adapterDtoToQuantifierRequeriment(requeriment)),
    name: {
      en: nameEn,
      es: nameEs,
    },
    desc: {
      en: descEn,
      es: descEs,
    },
  };
};

export const adapterDtoToQuantifierRequeriment = (quantifierRequeriment: IQuantifierRequirementDto): IQuantifierRequirement => {
  return {
    ...quantifierRequeriment,
    product: adapterDtoToProduct(quantifierRequeriment.product),
  };
};
