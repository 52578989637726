import useService from "@/hooks/useService";
import IOrder from "@/interfaces/IOrder";
import { getOrderDetails, IGetOrderDetailsRes } from "@/services/OrdersService";
import Optional from "@/types/Optional";
import useAuth from "@/hooks/useAuth";
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IOrderDetailsRes {
  order: Optional<IOrder>;
  isLoading: boolean;
  showThankYouMessage: boolean,
}

function getOrderAccessKey(orderId: IOrder['id']): string {
  const ordersAccessItem = localStorage.getItem('orders_access');
  const ordersAccessStorage = JSON.parse(ordersAccessItem || '{}');

  return ordersAccessStorage[orderId] || '';
}

const OrderDetails = (orderId: IOrder['id']): IOrderDetailsRes => {
  const [ searchParams ] = useSearchParams();
  const showThankYouMessage = searchParams.get('thankyou') !== null ?? false;

  const auth = useAuth();
  const navigate = useNavigate();
  const orderAccessKey = getOrderAccessKey(orderId);

  const orderState = useService<IGetOrderDetailsRes>({
    fetchData: getOrderDetails,
    params: {
      orderId,
      customerId: auth.customer?.id,
      key: orderAccessKey,
    }
  });
  
  if(orderState.data === false){
    const isFirstTry = !(orderAccessKey.length > 0);

    if(isFirstTry){
      navigate(`/orders/details/${orderId}/access_pin`);
    }else{
      navigate(`/orders/details/${orderId}/access_pin?fail`);
    }

    return {
      order: null,
      isLoading: false,
      showThankYouMessage,
    };
  }

  return {
    order: orderState.data,
    isLoading: orderState.loading,
    showThankYouMessage,
  };
};

export default OrderDetails;
