enum EPaymentStatus {
  Paid = 'pif',
  Retained = 'ret',
  Pending = 'pend',
  Available = 'avbl',
  Refunded = 'rfd',
  Expired = 'exp',
  Canceled = 'cnx',
}
export default EPaymentStatus;
