import React from 'react';
import { Col, FormInstance, Row } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import IAddressFormData from '@/interfaces/IAddressFormData';
import CheckoutAddressPage from '@/containers/views/Checkout/CheckoutAddressPage';
import IUser from '@/interfaces/IUser';
import CheckoutShippingContactPage from '@/containers/views/Checkout/CheckoutShippingContactPage';
import CheckoutPickUp from '@/containers/views/Checkout/CheckoutPickUp';
import EDeliveryType from '@/enums/EDeliveryType';
import IShippingContact from '@/interfaces/IShippingContact';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IWarehouse from '@/interfaces/IWarehouse';

interface ICheckoutDeliveryPage {
  addressForm: FormInstance<IAddressFormData>;
  userId?: IUser['id'];
  deliveryType: SegmentedValue;
  deliveryPointId?: IDeliveryPoint['id'];
  shippingContact?: IShippingContact;
  warehouseId: IWarehouse['id'];
  shoppingCartId: string;
  onChangeShippingContact:(e: IShippingContact) => void;
  onChangeDeliveryPointId: (e: IDeliveryPoint['id']) => void;
}

const CheckoutDeliveryPage = ({
  addressForm,
  userId,
  deliveryType,
  deliveryPointId,
  shippingContact,
  warehouseId,
  shoppingCartId,
  onChangeShippingContact,
  onChangeDeliveryPointId,
}: ICheckoutDeliveryPage) => {
  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={24} className="my-4">
        {
          (deliveryType === EDeliveryType.Shipping && !userId) && (
            <CheckoutAddressPage 
              form={addressForm}
            />
          )
        }
        {
          (deliveryType === EDeliveryType.Shipping && userId) && (
            <CheckoutShippingContactPage 
              userId={userId}
              shippingContact={shippingContact}
              onChangeShippingContact={onChangeShippingContact}
            />
          )
        }
        {
          (deliveryType === EDeliveryType.PickUp) && (
            <CheckoutPickUp 
              shoppingCartId={shoppingCartId}
              deliveryPointId={deliveryPointId}
              warehouseId={warehouseId}
              onChangeDeliveryPointId={onChangeDeliveryPointId}
            />
          )
        }
      </Col>
    </Row>
  );
};

export default CheckoutDeliveryPage;
