import React from 'react';
import EBrands from '@/enums/EBrands';
import { Image } from 'antd';
import { 
  VisaLogo, 
  MasterCardLogo, 
  AmericanExpressLogo, 
} from '@/assets/images';

interface IBrandImage {
  width?: number;
  height?: number;
  brand: EBrands
}

const BrandImage = ({
  width = 70,
  height = 40,
  brand
}: IBrandImage) => {
  const { Visa, Mastercard, AmericanExpress} = EBrands;

  const images: { [key in EBrands]: string } = {
    [Visa]: VisaLogo,
    [Mastercard]: MasterCardLogo,
    [AmericanExpress]: AmericanExpressLogo,
  };

  return (
    <Image 
      preview={false}
      width={width} 
      height={height}
      src={images[brand]}
    />
  );
};

export default BrandImage;
