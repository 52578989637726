import IOrder from '@/interfaces/IOrder';
import {
  Row, 
  Col,
  Card,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import DeliveryListItem from '@containers/views/MyOrders/DeliveryListItem';
import OrderDetailsCard from '@/components/ThankYou/OrderDetailsCard';
import { useNavigate } from 'react-router-dom';

interface OrderDeliveriesProps{
  orders: IOrder[];
}

const OrderDeliveries = ({
  orders,
}: OrderDeliveriesProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate(); 

  const orderBtn = (orderId: IOrder['id']) => {
    return (
      <Button 
        onClick={() => {
          navigate(`/orders/details/${orderId}`)
        }}
      >
        {t('g.show_order')}
      </Button>
    );
  };

  const deliveriesList = (deliveries: IOrder['deliveries']) => {
    return deliveries.map((delivery, key) => (
      <DeliveryListItem 
        key={key}
        delivery={delivery}
      />
    ));
  }

  if(orders.length < 1){
    return (
      <Row>
        <Col span={24}>
          {t('g.no_orders')}
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 24]}>
      {orders.map((order, key) => (
        <Col span={24} key={key}>
          <Card 
            size="small" 
            title={t('g.order_details')} 
            extra={orderBtn(order.id)} 
            bodyStyle={{ padding : '0px' }}
            className="shadow-lg rounded-md"
          >
            <Row>
              <Col span={24} className="px-2 py-4 bg-gray-100 border-t bg-opacity-50">
                <OrderDetailsCard order={order} /> 
              </Col>
              <Col span={24}>
                {deliveriesList(order.deliveries)}
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default OrderDeliveries;
