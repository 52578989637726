import formatNumberQty from '@/helpers/formatters/formatNumberQty';
import getTranlation from '@/helpers/getTranslation';
import IQuantifier from '@/interfaces/IQuantifier';
import IQuantifierRequirement from '@/interfaces/IQuantifierRequirement';
import { FileTextOutlined } from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { Button, Card, Col, Divider, Row, Table, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QtyInput from '../General/QtyInput';
import QuantifierExport from './QuantifierExport';
import IQuantifierMultiplier from '@/interfaces/IQuantifierMultiplier';
import { v4 as uuidv4 } from 'uuid';

interface IQuantifierProps {
  quantifier: IQuantifier;
}

interface IQuantifierMultiplierObj extends IQuantifierMultiplier{
  id: string;
  qty: number;
}

export interface IQuantifierMultipliersObj {
  [key: string]: IQuantifierMultiplierObj
}

const Quantifier = ({ quantifier }: IQuantifierProps) => {
  const { t } = useTranslation('common');
  const [currentStepper, setcurrentStepper] = useState<number>(quantifier.stepper);
  const relation = (currentStepper / quantifier.stepper);

  const [multipliersObj, setMultipliersObj] = useState<IQuantifierMultipliersObj>(() => {
    const objs: {[key: string]: IQuantifierMultiplierObj} = {};

    quantifier.multipliers.forEach((multiplier) => {
      const id = uuidv4();

      objs[id] = {
        ...multiplier,
        id,
        qty: 1,
      };
    });

    return objs;
  });
  
  const columns = [
    {
      title: t('g.sku'),
      render: (quantifierRequeriment: IQuantifierRequirement) => quantifierRequeriment.product.sku,
    },
    {
      title: t('g.article'),
      render: (quantifierRequeriment: IQuantifierRequirement) => getTranlation(quantifierRequeriment.product.name),
    },
    {
      title: t('g.unit'),
      className: 'text-center',
      render: (quantifierRequeriment: IQuantifierRequirement) => quantifierRequeriment.unit,
    },
    {
      title: t('g.spq'),
      className: 'text-center',
      render: (quantifierRequeriment: IQuantifierRequirement) => quantifierRequeriment.spq,
    },
    {
      title: t('g.qty_required'),
      className: 'text-center bg-gray-200',
      render: (quantifierRequeriment: IQuantifierRequirement) => {
        const requiredQty = quantifierRequeriment.qty * relation;
        let addition = 0;

        Object.keys(multipliersObj).forEach(multiplierObjKey => {
          const multiplierObj = multipliersObj[multiplierObjKey];
          const multiplierProducts = multiplierObj.products.map(product => product.productId);

          if(multiplierProducts.includes(quantifierRequeriment.product.id)){
            const newAddition = (requiredQty * multiplierObj.qty) - requiredQty;
            addition = addition + newAddition;
          }
        });

        return (
          <Typography.Title level={5}>
            {formatNumberQty(requiredQty + addition)}
          </Typography.Title>
        );
      }
    },
  ];

  function onIncreaseStepper(){
    const nextStepper = Number(currentStepper) + quantifier.stepper;
    const residue = nextStepper % quantifier.stepper;

    if(residue === 0){
      setcurrentStepper(nextStepper);
      return;
    }

    const stepperRounded = nextStepper - residue;
    setcurrentStepper(stepperRounded);
  }

  function onDecreaseStepper(){
    const prevStepper = Number(currentStepper) - quantifier.stepper;
    const residue = prevStepper % quantifier.stepper;

    if(residue === 0){
      setcurrentStepper(prevStepper);
      return;
    }

    const stepperRounded = (Number(currentStepper)) - residue;
    setcurrentStepper(stepperRounded);
  }

  async function exportQuantifer(){
    if(!quantifier){
      return;
    }

    const pdfDoc = pdf(
      <QuantifierExport 
        quantifier={quantifier}  
        stepper={currentStepper} 
        multipliers={multipliersObj}    
      />
    );
  
    const blob = await pdfDoc.toBlob();
    const fileURL = window.URL.createObjectURL(blob);

    window.open(fileURL, '_blank');
  }

  function onIncreaseMultiplier(multiplierId: string) {
    const newQty = multipliersObj[multiplierId]['qty'] + 1;

    changeMultiplierQty(multiplierId, newQty);
  }

  function onDecreaseMultiplier(multiplierId: string) {
    const newQty = multipliersObj[multiplierId]['qty'] - 1;

    changeMultiplierQty(multiplierId, newQty);
  }

  function changeMultiplierQty(multiplierId: string, newQty: number) {
    setMultipliersObj({
      ...multipliersObj, 
      [multiplierId]: {
        ...multipliersObj[multiplierId], 
        qty: newQty,
      },
    })
  }

  function renderMultiplier(multiplierId: string) {
    return (
      <Col span={8} style={{ display: 'flex' }}>
        <QtyInput
          size="large"
          min={1}
          value={multipliersObj[multiplierId].qty} 
          onIncrease={() => onIncreaseMultiplier(multiplierId)} 
          onDecrease={() => onDecreaseMultiplier(multiplierId)}
          onChange={() => alert('eee')}
        />
        <Typography.Text 
            strong 
            style={{
              textTransform: 'capitalize',
              margin: 10,
            }}
          >
            {multipliersObj[multiplierId].text}
          </Typography.Text>
      </Col>
    );
  }

  return (
    <>
      <Row className="mb-4">
        <Col span={24}>
          <Typography.Title level={5}>{quantifier && getTranlation(quantifier.name)}</Typography.Title>
          <Typography.Paragraph>{quantifier && getTranlation(quantifier.desc)}</Typography.Paragraph>
        </Col>
        <Col span={24} style={{ display: 'flex' }}>
          <QtyInput 
            allowInput
            size="large"
            value={currentStepper} 
            onIncrease={onIncreaseStepper} 
            onDecrease={onDecreaseStepper}
            disableDecrease={currentStepper - quantifier.stepper <= 0}
            onChange={value => setcurrentStepper(value)}
          />
          <Typography.Text 
            strong 
            style={{ 
              alignSelf: 'center', 
              textTransform: 'capitalize',
              margin: 10,
            }}
          >
            {quantifier.unit}
          </Typography.Text>
        </Col>
        <Divider />
        {Object.keys(multipliersObj).length > 0 && (
          <>
            {Object.keys(multipliersObj).map(multiplierId => renderMultiplier(multiplierId))}
            <Divider />
          </>
        )}
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={quantifier.requirements}
            pagination={false}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button 
            type="primary" 
            icon={<FileTextOutlined />}
            onClick={exportQuantifer} 
          >
            {t('g.export')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Quantifier;
