import IVariantDto from '@/interfaces/IVariantDto';
import IVariant from '@/interfaces/IVariant';
import { adapterDtoToProduct } from './ProductAdapter';
import IVariantAttributesDto from '@/interfaces/IVariantAttributesDto';
import IAttribute from '@/interfaces/IAttribute';
import IProductDto from '@/interfaces/IProductDto';
import { adapterDtoToAttributeOption } from '@/adapters/AttributeOptionAdapter';
import IAttributeOptionDto from '@interfaces/IAttributeOptionDto';
import IWarehouse from '@/interfaces/IWarehouse';
import {
  getProductEstimatedPrice,
  getProductInventory,
  getWarehouseProductConfigurationById,
} from '@/services/ProductService';
import { getWarehouseVariants } from '@/services/VariantService';
import { adapterDtoToBadge } from './BadgeAdapter';

export const adapterDtoToVariant = (variant: IVariantDto): IVariant => {
  const {
    id,
    alias,
    slug,
    nameEs,
    nameEn,
    descEn,
    descEs,
    priority,
    isActive,
    groupingCode,
    products,
    variant_attributes,
    variant_imgs,
    badges,
  } = variant;

  return {
    id,
    groupingCode,
    key: 'variant',
    name: {
      en: nameEn,
      es: nameEs,
    },
    desc: {
      en: descEn,
      es: descEs,
    },
    stepper: 1,
    alias,
    slug,
    priority,
    isActive,
    imgs: variant_imgs,
    products: products.map((product) => adapterDtoToProduct(product)),
    attributes: formatVariantAttributes(variant_attributes, products),
    getPrice: async (config) => await getPrice(config?.selectionItem as string, config?.warehouseId),
    getInventory: async (config) => await getInventory(config?.selectionItem as string, config?.warehouseId),
    getProductsConfig: async (config) => getConfig(config?.selectionItem as string, config?.warehouseId),
    isInWarehouse: async (config) => getIsInWarehouse(id, config.warehouseId),
    badges: badges?.length > 0 ? badges.map(badge => adapterDtoToBadge(badge)) : [],
  }
};

const formatVariantAttributes = (
  variantAttributes: IVariantAttributesDto[],
  products: IProductDto[]
): IAttribute[] => {
  const attributeOptions: IAttributeOptionDto[] = [];

  products.forEach((product) => {
    product.attributeOptions.forEach((attrOpt) => {
      const isExist = attributeOptions.find(attributeOption => attributeOption.id === attrOpt.id);
      if (!isExist) {
        attributeOptions.push(attrOpt);
      }
    });
  });

  const validVariantAttributes = variantAttributes.filter(
    (vAttr) => vAttr.isRequired
  );

  const attributes: IAttribute[] = validVariantAttributes.map(
    (variantAttribute) => {
      const relatedAttributeOptions = attributeOptions.filter(
        (attrOpts) => attrOpts.attributeId === variantAttribute.attribute.id
      );

      const formatRelatedAttrOpt = relatedAttributeOptions.map(
        (relatedAttributeOption) =>
          adapterDtoToAttributeOption(relatedAttributeOption)
      );

      return {
        id: variantAttribute.id,
        label: {
          en: variantAttribute.attribute.labelEn,
          es: variantAttribute.attribute.labelEs,
        },
        isVisible: variantAttribute.isRequired,
        attributeOptions: formatRelatedAttrOpt,
      };
    }
  );
  
  return attributes;
};

const getPrice = async (
  selection?: string,
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try{
    if (!selection) return 0;

    const productId = selection;
    
    if (warehouseId) {
      const config = await getWarehouseProductConfigurationById(
        productId,
        warehouseId
      );

      return config.price;
    }

    const estimatedPrices = await getProductEstimatedPrice([productId]);
    return estimatedPrices[0].price;
  } catch (e) {
    return  0;
  }
};

const getInventory = async (
  selection?: string,
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try {
    if (!warehouseId || !selection) return 0;

    const inventory = await getProductInventory(
      [selection as string],
      warehouseId
    );

    return inventory[0].qty;
  } catch (e) {
    return 0;
  }
};

const getConfig = async (
  productId?: string,
  warehouseId?: IWarehouse['id']
) => {
  try{
    if (!warehouseId || !productId) return [];

    const productData = await getWarehouseProductConfigurationById(productId, warehouseId);

    return [productData];}
  catch(e) {
    return [];
  }
};

const getIsInWarehouse = async (
  variantId: IVariant['id'],
  warehouseId?: IWarehouse['id']
) => {
  try{
    if (!warehouseId) return false;

    const data = await getWarehouseVariants({variantId, warehouseId});

    return data.length > 0
  } catch(e){
    return false;
  }
};
