import { Button, Card, Col, Row, Typography } from 'antd';
import Billing from '@/containers/views/Billing/Billing';
import { useTranslation } from 'react-i18next';
import BillingRequestForm from '@/components/Forms/BillingRequestForm';
import { HomeOutlined, MailOutlined } from '@ant-design/icons';

const BillingPage = () => {
  const {
    billingForm,
    billingFormOnSubmit,
    goToHome
  } = Billing();
  
  const { t } = useTranslation('common');

  // TODO translations

  return (
    <Card className="h-full" bordered={false}>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            Solicitar Factura
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Paragraph className='m-0 mt-2'>
            Ingresa la información correspondiente para la emisión de la factura.
          </Typography.Paragraph>
          <Typography.Paragraph className='m-0 mb-2'>
            Recuerda que las facturas se pueden solicitar dentro del mismo mes de compra.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BillingRequestForm 
            form={billingForm} 
            formName={'billingForm'} 
            onSubmit={billingFormOnSubmit}
          />
        </Col>
      </Row>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Button 
            className="w-full bg-blue-500" 
            type="primary"
            onClick={() => billingForm.submit()}
          >
            Solicitar Factura
            <MailOutlined className="fix-icon"/>
          </Button>
        </Col>
        <Col span={24}>
          <Button 
            className="w-full"
            onClick={goToHome}
          >
            <HomeOutlined className="fix-icon"/> 
            Regresar a Home
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default BillingPage;
