import Address, { EAddressLayout } from '@/components/Address';
import TagLabel from '@/components/TagLabel';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import IDelivery from '@/interfaces/IDelivery';
import {
  Row, 
  Col,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import EDeliveryStatus from '@/enums/EDeliveryStatus';
import moment from 'moment';

const { Text, Title } = Typography;

interface DeliveryListItemProps{
  delivery: IDelivery;
  extra?: React.ReactNode;
}

const DeliveryListItem = ({
  delivery,
  extra,
}: DeliveryListItemProps) => {
  const { t } = useTranslation('common');
  const lang = i18next.language;

  const statusTagOptions = [
    {
      value: EDeliveryStatus.Suggested,
      bgColor: "gray",
      label: t('g.received'),
    },
    {
      value: EDeliveryStatus.Confirmed,
      bgColor: "orange",
      label: t('g.confirmed'),
    },
    {
      value: EDeliveryStatus.Packing,
      bgColor: "blue",
      label: t('g.packing'),
    },
    {
      value: EDeliveryStatus.Packed,
      bgColor: "green",
      label: t('g.packed'),
    },
    {
      value: EDeliveryStatus.Canceled,
      bgColor: "red",
      label: t('g.canceled'),
    },
  ];

  function getStatusInfo(status: IDelivery['status']){
    let statusInfo;

    switch (status) {
      case EDeliveryStatus.Suggested:
        statusInfo = t('g.your_order_has_been_received_by_the_warehouse');
      break;
      case EDeliveryStatus.Confirmed:
        statusInfo = t('g.your_order_is_pending_to_be_pack');
      break;
      case EDeliveryStatus.Packing:
        statusInfo = t('g.your_order_is_being_packed');
      break;
      case EDeliveryStatus.Packed:
        statusInfo = t('g.your_order_has_been_pack');
      break;
      case EDeliveryStatus.Canceled:
        statusInfo = t('g.your_order_has_been_canceled');
      break;
    }

    return (
      <Text>{statusInfo}</Text>
    );
  }

  function getDeliveryDateInfo(deliveryDate: IDelivery['deliveryDate']){
    const label = t('g.arrive', {
      date: moment(deliveryDate).format('LLL'),
    });

    return (
      <Title level={5} className="m-0">{label}</Title>
    );
  }

  function getDeliveryStrategyInfo(delivery: IDelivery){
    let strategyLabel: string;
    let strategyContent: React.ReactNode = (
      <div className='text-gray-500 bg-gray-100 p-2'>
        {t('g.without_address')}
      </div>
    );

    switch (delivery.deliveryType) {
      case EDeliveryMethod.Pickup:
        strategyLabel = t('g.pickup_address');

        if(delivery.deliveryPoint && delivery.deliveryPoint.address){
          strategyContent = (
            <Address 
              className='bg-gray-100 text-gray-500 m-0 p-2'
              address={delivery.deliveryPoint.address} 
              layout={EAddressLayout.Multi} 
            />
          );
          break;
        }
      break;

      case EDeliveryMethod.Shipping:
        strategyLabel = t('g.shipping_address');

        if(delivery.address){
          strategyContent = (
            <Address 
              className='bg-gray-100 text-gray-500 m-0 p-2'
              address={delivery.address} 
              layout={EAddressLayout.Multi} 
            />
          );
        }
      break;
    }

    return (
      <Row>
        <Col span={24}>
          <Text className='text-gray-500'>
            {strategyLabel}:
          </Text>
        </Col>
        <Col span={24}>
          {strategyContent}
        </Col>
      </Row>
    );
  }

  return (
    <Row className='p-4 border-t'>
      <Col span={extra ? 16 : 24}>
        <Row className='mb-1'>
          <Col span={24}>
            <TagLabel
              selected={delivery.status}
              options={statusTagOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {getDeliveryDateInfo(delivery.deliveryDate)}
          </Col>
          <Col span={24}>
            {getStatusInfo(delivery.status)}
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col span={24}>
            {getDeliveryStrategyInfo(delivery)}
          </Col>
        </Row>
      </Col>
      <Col span={extra ? 8 : 0}>
        {extra}
      </Col>
    </Row>
  );
};

export default DeliveryListItem;
