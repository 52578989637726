import axiosApiConfig from '@/config/axiosConfig';
import IParams from '@/interfaces/IParams';
import IAttribute from '@/interfaces/IAttribute';
import IAttributeDto from '@/interfaces/IAttributeDto';
import { adapterDtoToAttribute } from '@/adapters/AttributeAdapter';

export const getAttributes = async (params?: IParams): Promise<IAttribute[]> => {
  const result = await axiosApiConfig.get<IAttributeDto[]>('/attributes', {
    params: { ...params },
  });

  return result.data.map(adapterDtoToAttribute);
};

export const getAvailablesAttributes = async (params?: IParams): Promise<IAttribute[]> => {
  const result = await axiosApiConfig.get<IAttributeDto[]>('/get_available_attributes', {
    params: { ...params },
  });

  return result.data.map(adapterDtoToAttribute);
};