export const InternalPaymentServiceImage = 'https://demo.api.zellship.com/img/images/internal.png';
export const ManualPaymentServiceImage = 'https://demo.api.zellship.com/img/images/manual.png';
export const OpenpayPaymentServiceImage = 'https://demo.api.zellship.com/img/images/openpay.jpg';
export const LogoImage = 'https://demo.api.zellship.com/img/images/logo.png';
export const LogoMenuImage = 'https://demo.api.zellship.com/img/images/icon.png';
export const BgPatternImage = 'https://demo.api.zellship.com/img/store/pattern.jpeg';
export const NotFoundImage = 'https://demo.api.zellship.com/img/store/image_not_found.png';
export const DefaultFileImage = 'https://demo.api.zellship.com/img/store/default_file.png';
export const AmericanExpressLogo = 'https://demo.api.zellship.com/img/store/american_express.png';
export const BanorteLogo = 'https://demo.api.zellship.com/img/store/banorte.png';
export const BanregioLogo = 'https://demo.api.zellship.com/img/store/banregio.png';
export const BbvaLogo = 'https://demo.api.zellship.com/img/store/bbva.png';
export const CarnetLogo = 'https://demo.api.zellship.com/img/store/carnet.png';
export const CitibanamexLogo = 'https://demo.api.zellship.com/img/store/citibanamex.png';
export const HsbcLogo = 'https://demo.api.zellship.com/img/store/hsbc.png';
export const InbursaLogo = 'https://demo.api.zellship.com/img/store/inbursa.png';
export const OpenpayLogo = 'https://demo.api.zellship.com/img/store/openpay_logo.jpg';
export const MasterCardLogo = 'https://demo.api.zellship.com/img/store/mastercard.png';
export const SantanderLogo = 'https://demo.api.zellship.com/img/store/santander.png';
export const ScotiabankLogo = 'https://demo.api.zellship.com/img/store/scotiabank.png';
export const VisaLogo = 'https://demo.api.zellship.com/img/store/visa.png';
export const DefaultPlaceholderImage = 'https://demo.api.zellship.com/img/store/placeholder.png';
export const ViewMoreImage = 'https://demo.api.zellship.com/img/store/view_more.png';
export const ViewAllImage = 'https://demo.api.zellship.com/img/store/view_all.png';
export const EmptySelectionImage = 'https://demo.api.zellship.com/img/store/empty_selection.png';
export const EmptyResultImage = 'https://demo.api.zellship.com/img/store/empty_result.png';