import React from 'react';
import EInventoryStatus from '@/enums/EInventoryStatus';
import { Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import ESoldOutFormat from '@/enums/ESoldOutFormat';
import { BaseType } from 'antd/lib/typography/Base';

const { Text } = Typography;

export interface IItemAvailability {
  withInventory: EInventoryStatus;
  allowSaleWithoutInventory?: boolean;
  currentQty?: number;
  loading?: boolean;
  format?: ESoldOutFormat; // sold out | not available
}

const ItemAvailability = ({
  withInventory,
  currentQty = 0,
  allowSaleWithoutInventory = false,
  loading = false,
  format = ESoldOutFormat.NotAvailable,
}: IItemAvailability) => {
  const { t } = useTranslation('common');

  const COLORS: {[key: string]: BaseType} = {
    default: 'secondary',
    success: 'success',
    warning: 'warning',
    error: 'danger',
  }

  const LABELS = {
    default: withInventory.toString(),
    avaliable: t('g.available'),
    multi: t('g.multiple_deliveries'),
    partial: t('g.only_qty_availables', { qty: currentQty }),
    notAvailable: format === 'na' ? t('g.not_available') : t('g.sold_out'),
    loading: t('g.loading'),
  }

  let selectedColor = COLORS.default;
  let label = LABELS.default;
  
  switch (withInventory) {
    case EInventoryStatus.WithInventory: 
      label = LABELS.avaliable
      selectedColor = COLORS.success
      break;

    case EInventoryStatus.PartialInventory:
      if (allowSaleWithoutInventory) {
        label = LABELS.multi
        selectedColor = COLORS.success
        break;
      }

      label = LABELS.partial
      selectedColor = COLORS.warning
      break;

    case EInventoryStatus.WithoutInventory:
      if (allowSaleWithoutInventory) {
        label = LABELS.multi
        selectedColor = COLORS.success
        break;
      }
      
      label = LABELS.notAvailable
      selectedColor = COLORS.error
      break;
  
    default:
      break;
  }

  if (loading) {
    label = LABELS.loading;
    selectedColor = COLORS.default;
  }

  return (
    <Space align="center" direction="horizontal">
      {loading && <LoadingOutlined />}
      <Text type={selectedColor}>{label}</Text>
    </Space>
  );
};

export default ItemAvailability;
