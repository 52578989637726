import axiosApiConfig from '@/config/axiosConfig';
import IParams from '@/interfaces/IParams';
import ICategory from '@/interfaces/ICategory';
import IPaginatedResult from '@/interfaces/IPaginatedResult';
import { adapterDtoToCategory } from '@/adapters/CategoryAdapter';
import ICategoryDto from '@/interfaces/ICategoryDto';

export const getCategories = async (params?:IParams): Promise<ICategory[]> => {
  const result = await axiosApiConfig.get<ICategoryDto[]>('/custom_categories', {
    params: { ...params },
  });

  return result.data.map(adapterDtoToCategory);
};

export const getCategoriesPaginated = async (params?:IParams): Promise<IPaginatedResult<ICategory>> => {
    const result = await axiosApiConfig.get<IPaginatedResult<ICategoryDto>>('/custom_categories', {
      params: { ...params },
    });
  
    const resultObject: IPaginatedResult<ICategory> = {
        data: result.data.data.map(adapterDtoToCategory),
        limit: result.data.limit,
        skip: result.data.skip,
        total: result.data.total,
    }
    return resultObject;
  };
