import common_es from '@utils/translations/es.json';
import common_en from '@utils/translations/en.json';

const resources = {
  es: {
    common: common_es,
  },
  en: {
    common: common_en,
  },
};

export const config = {
  interpolation: { escapeValue: false },
  lng: process.env.REACT_APP_LANGUAGE || 'en',
  resources,
};

export { resources };
