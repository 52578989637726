import IWarehouse from "@/interfaces/IWarehouse";
import axiosApiConfig from '@/config/axiosConfig';
import IProduct from "@/interfaces/IProduct";

// export interface ShippingTypeInWarehouseData {
//         shippingTypeId: string;
// }

export const isProductHaveShippingTypeInWarehouse = async (data:{ productIds: IProduct['id'][], warehouseId?: IWarehouse['id']}):Promise<boolean> => {
  try{
  const result = await axiosApiConfig.post<boolean>('/shipping_type_in_warehouse', { 
      productIds: data.productIds,
      warehouseId: data.warehouseId
  });

  return result.data;
} catch (e) {
  return false
}
};
