import axiosApiConfig from '@/config/axiosConfig';
import IMasterFranchiseConfiguration from '@/interfaces/IMasterFranchiseConfiguration';

export const getMasterFranchiseConfigurations = async (): Promise<IMasterFranchiseConfiguration[]> => {
  const res = await axiosApiConfig.get('/master_franchise_configurations', { params: { 
    masterFranchiseId: 1,
   } });

  return res.data;
};
