import ELanguages from '@/enums/ELanguages';
import MultiLanguageType from '@/types/MultiLanguageType';
import i18next from 'i18next';

const getTranlation = <T>(object: MultiLanguageType<T>) => {
  const currentLanguaje: string = i18next.language;

  if (currentLanguaje === ELanguages.Spanish) return object['es'];
  if (currentLanguaje === ELanguages.English) return object['en'];

  return object['en'];
};

export default getTranlation;
