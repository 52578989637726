import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';

interface DebounceSearchProps {
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  allowClear?: boolean;
  onSearch?: (value: string) => void;
  onChange?: (newValue: string) => void;
  timeout?: number;
  className?: string,
}

const DebounceSearch = ({
  searchValue,
  setSearchValue,
  placeholder,
  allowClear = false,
  onSearch = () => {},
  onChange = () => {},
  timeout = 400,
  className,
}: DebounceSearchProps) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  const setValue = (setSearchValue) 
    ? (newValue: string) => { setSearchValue(newValue); } 
    : (newValue: string) => { setCurrentValue(newValue); };

  function onChangeHandler(input: any): void {
    const newValue: string = input.target.value;

    setValue(newValue);
    onChange(newValue);
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      onSearch(currentValue);
    }, timeout);

    return () => clearTimeout(timeOut);
  }, [searchValue || currentValue]);

  return (
    <Search
      allowClear={allowClear}
      value={searchValue || currentValue}
      className={className}
      placeholder={placeholder}
      onChange={onChangeHandler}
      onSearch={onSearch}
    />
  );
};

export default DebounceSearch;
