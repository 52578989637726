interface IId {
  id: string;
}

const getCurrentData = <T>(
  data: (T & IId)[],
  selectedId?: string,
): T | undefined => {
  if (!data || !selectedId) return undefined;

  return data.find(d => d.id === selectedId);
}

export default getCurrentData;
