import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Spin, Card, Button, Divider } from "antd";
import PlaceholderImage from "@/components/General/PlaceholderImage";
import SideScrollList from "@/components/General/SideScrollList";
import getTranslation from "@/helpers/getTranslation";
import goToItemDetails from "@/helpers/goToItemDetails";
import IMix from "@/interfaces/IMix";
import IProduct from "@/interfaces/IProduct";
import IVariant from "@/interfaces/IVariant";
import Optional from "@/types/Optional";
import { NotFoundImage } from "@/assets/images";

interface HomeFeaturedProductsListProps {
    featuredItems:  Optional<(IProduct | IVariant | IMix)[]>,
    isLoadingFeaturedItems: boolean;
}
 
const HomeFeaturedProductsList = ({
    featuredItems,
    isLoadingFeaturedItems,
}:HomeFeaturedProductsListProps) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    
    return ( 
        <Row>
          <Col span={24}>
            <Divider orientation="left" className="m-0">
              <Typography.Title level={5} className="text-blue-500">
                {t('g.featured_items')}
              </Typography.Title>
            </Divider>
            <Row justify='center' className="py-4">
              <Col>
                {(!featuredItems || isLoadingFeaturedItems) ? (
                    <Row justify='center'>
                      <Col>
                        <Spin size='large' className='m-4' />
                      </Col>
                    </Row>
                ) : (
                  <SideScrollList
                    items={featuredItems}
                    renderItem={(item) => {
                      return (
                        <Card style={{ width: '200px' }} size='small'>
                          <Row align='stretch' justify='center'>
                            <Col>
                              <Row justify='center'>
                                {item.imgs.length > 0 ? (
                                  <LazyLoadImage
                                    alt={item.name.es}
                                    placeholderSrc={NotFoundImage}
                                    src={item.imgs[0].url}
                                    className="h-24"
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'contain',
                                      aspectRatio: 1,
                                      marginBottom: '8px',
                                      backgroundColor: '#f3f3f3',
                                    }}
                                  />
                                ) : (
                                  <PlaceholderImage className='h-28' />
                                )}
                              </Row>
                              <Row justify='center' className='h-12'>
                                <Typography.Paragraph ellipsis={{rows:2}}>
                                  {item.name ? getTranslation(item.name) : ''}
                                </Typography.Paragraph>
                              </Row>
                              <Row justify='center'>
                                <Button block onClick={() => goToItemDetails(item, navigate)} type="ghost">{t('g.view_details')}</Button>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      );
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
     );
}
 
export default HomeFeaturedProductsList;