import IWarehouse from "@/interfaces/IWarehouse";
import axiosApiConfig from '@/config/axiosConfig';
import IPostalCode from '@/interfaces/IPostalCode';
import IWarehouseDto from "@/interfaces/IWarehouseDto";
import { adapterDtoToWarehouse } from "@/adapters/WarehouseAdapter";

export const getWarehousesByPostalCode = async (postalCode: IPostalCode['code']):Promise<IWarehouse[]> => {
  const result = await axiosApiConfig.get<IWarehouseDto[]>('/warehouses_postal_code', { 
    params: { 
      postal_code: postalCode,
    } 
  });

  return result.data.map(d => adapterDtoToWarehouse(d));
};

export const getSingleWarehouse = async (data:{id: IPostalCode['id']}):Promise<IWarehouse> => {
  const result = await axiosApiConfig.get<IWarehouseDto>('/warehouses_postal_code', { 
  });
  const resultToReturn = adapterDtoToWarehouse(result.data)
  return resultToReturn;
}

export const getWarehouse = async (warehouseId: IWarehouse['id']): Promise<IWarehouse> => {
  const result = await axiosApiConfig.get<IWarehouseDto>(`/warehouses/${warehouseId}`);

  return adapterDtoToWarehouse(result.data);
}

export const getWarehouseNameById = async (warehouseId: IWarehouse['id']): Promise<IWarehouse['alias']> => {
  const result = await axiosApiConfig.get<Pick<IWarehouseDto, 'alias'>>(`/warehouses/${warehouseId}?$select[]=alias`);

  return result.data.alias;
}
