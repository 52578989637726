import React, { memo } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import IAttachment from '@/interfaces/IAttachment';
import { DownOutlined, FileOutlined } from '@ant-design/icons';

interface IFileDropdown {
  files: IAttachment[];
  placeholder: string;
}

const FileDropdown = memo(({
  files,
  placeholder
}: IFileDropdown) => {
  const openToPage = (url: string) => window.open(url);

  const menuProps = (
    <Menu>
      {
        files.sort((a, b) => a.order - b.order).map(({ id, filename, url }) => (
          <Menu.Item 
            key={id} 
            icon={<FileOutlined />}
            onClick={() => openToPage(url)}
          >
            {filename}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <Dropdown 
      overlay={menuProps} 
      className="rounded-md"
    >
      <Button>
        {placeholder} <DownOutlined className="fix-icon"/>
      </Button>
    </Dropdown>
  );
});

export default FileDropdown;
