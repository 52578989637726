import React from 'react';
import { Col, FormInstance, Row } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import IAddressFormData from '@/interfaces/IAddressFormData';
import CheckoutAddressPage from '@/containers/views/Checkout/CheckoutAddressPage';
import CheckoutPickUp from '@/containers/views/Checkout/CheckoutPickUp';
import EDeliveryType from '@/enums/EDeliveryType';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IWarehouse from '@/interfaces/IWarehouse';

interface ICheckoutDeliveryPage {
  addressForm: FormInstance<IAddressFormData>;
  deliveryType: SegmentedValue;
  deliveryPointId?: IDeliveryPoint['id'];
  warehouseId: IWarehouse['id'];
  shoppingCartId: string;
  onChangeDeliveryPointId: (e: IDeliveryPoint['id']) => void;
}

const CheckoutDeliveryPagePOS = ({
  addressForm,
  deliveryType,
  deliveryPointId,
  warehouseId,
  shoppingCartId,
  onChangeDeliveryPointId,
}: ICheckoutDeliveryPage) => {

  return (
    <Row>
      <Col span={24} className="my-4">
        {
          (deliveryType === EDeliveryType.Shipping) && (
            <CheckoutAddressPage 
              form={addressForm}
            />
          )
        } 
        {
          (deliveryType === EDeliveryType.PickUp) && (
            <CheckoutPickUp 
              shoppingCartId={shoppingCartId}
              deliveryPointId={deliveryPointId}
              warehouseId={warehouseId}
              onChangeDeliveryPointId={onChangeDeliveryPointId}
            />
          )
        }
      </Col>
    </Row>
  );
};

export default CheckoutDeliveryPagePOS;
