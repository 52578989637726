import IAddress from '@/interfaces/IAddress';
import IPostalCode from '@/interfaces/IPostalCode';
import Location from '@/components/Location';
import { EnvironmentFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/hooks/useBreakpoint';

const { Text } = Typography;

interface ICustomerLocationProps {
  address?: IAddress;
  postalCode?: IPostalCode;
  extra?: React.ReactNode;
}

const CustomerLocation = ({
  address,
  postalCode,
  extra,
}: ICustomerLocationProps) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  
  const customTextColor = process.env.REACT_APP_THEME_LAYOUT_HEADER_COLOR_TEXT;

  function getHint(){
    const hintText = (address || postalCode) ? t('g.your_location') : t('g.choose_your');

    return (
      <Text className="text-xs text-white" color='error' style={{ color: customTextColor }}>
        {hintText}
      </Text>
    );
  }

  function getContent(){
    let contentText = t('g.location');

    if(postalCode){
      contentText = `${postalCode.city.name} ${postalCode.code}`;
    }

    if(address){
      contentText = `${address.street} ${address.extNum}, ${address.suburb.name} - ${address.postalCode.city.name}`; 
    }

    return (
      <Text 
        className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white ant-text-color-inverse" } 
        color="success"
        strong 
        ellipsis
        style={{ color: customTextColor }}
      >
        {contentText}
      </Text>
    );
  }

  const icon = <EnvironmentFilled className="icon-large block" style={{ color: customTextColor }} />;
  const hint = getHint();
  const content = getContent();

  return (
    <Location
      icon={icon}
      hint={hint}
      content={content}
      extra={extra}
    />
  );
};

export default CustomerLocation;
