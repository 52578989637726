import { Col, Empty, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface ISideScrollListProps<T> {
  items: T[];
  title?: string;
  renderItem: (data: T) => ReactNode;
}

const SideScrollList = <T,>({
  items,
  renderItem,
  title,
}: ISideScrollListProps<T>) => {
  return (
    <Row>
      <Col span={24}>
        {title && (
          <Row>
            <Col>
              <Typography.Title level={3}>{title}</Typography.Title>
            </Col>
          </Row>
        )}
        <div
          className='hide_scrollbar'
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
            scrollbarWidth: 'none',
          }}
        >
          {items.length > 0 ? (
            items.map((i) => {
              return (
                <Col
                  key={uuidv4()}
                  style={{
                    margin: '8px',
                  }}
                >
                  {renderItem(i)}
                </Col>
              );
            })
          ) : (
            <Col span={24}>
              <Row justify='center'>
                <Empty />
              </Row>
            </Col>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SideScrollList;
