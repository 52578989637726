import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Footer from '@/containers/_layouts/Footer';
import CheckoutTopbar from '@/components/TopBar/CheckoutTopbar';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';

const CheckoutLayout = () => {
  const { Header, Content } = Layout;
  const { 
    active,
    productsPrice,
    shippingPrice,
    totalDiscount,
    setActive, 
  } = useCheckoutDrawer();
  
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <Layout style={{ 
        height: "100%",
        minHeight: "100%",
      }}>
        <Layout style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100%",
        }}>
          <Header 
            className="ant-layout-header" 
            style={{ 
              lineHeight: "2",
              padding: "0",
              height: "initial",
            }} 
          >
            <CheckoutTopbar 
              active={active}
              onClick={() => setActive(prev => !prev)}
              price={productsPrice + shippingPrice - totalDiscount}
            />
          </Header>
          <Content style={{ overflowY: 'auto', position: 'relative' }}>
            <Outlet />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </div>
  );
};

export default CheckoutLayout;
