import IShippingContact from '@/interfaces/IShippingContact';
import { Col, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import ShippingContact from '@/components/MyAddresses/ShippingContact';
import { useTranslation } from 'react-i18next';

interface IShippingContactCardProps {
  shippingContact: IShippingContact;
  isDefault: boolean;
  actions?: ReactNode;
  onClick?: () => void;
}

const ShippingContactCard = ({
  shippingContact,
  isDefault,
  actions,
  onClick,
}: IShippingContactCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Row onClick={onClick}>
      <Col
        span={24}
        className={`p-0 flex flex-col justify-between h-60 border-4 rounded-lg border-solid ${
          isDefault ? 'border-primary' : 'border-black'
        }`}
      >
        <Row className='p-2'>
          <Col>
            <ShippingContact shippingContact={shippingContact} />
          </Col>
        </Row>
        <Row>
          <Col>{actions}</Col>
        </Row>
        <Row className={`mb-0 bg-primary ${!isDefault && 'hidden'}`}>
          <Col>
            <Typography.Text className='text-white p-1'>
              {t('g.default_address')}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShippingContactCard;
