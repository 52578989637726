import IBadgeDto from '../interfaces/IBadgeDto';
import IBadge from '@/interfaces/IBadge';

export const adapterDtoToBadge = (badge: IBadgeDto): IBadge => {
  const { 
    id,
    labelEn,
    labelEs,
    alias,
    url,
  } = badge;
  
  return {
    id,
    label: {
      en: labelEn,
      es: labelEs,
    },
    alias,
    url,
  };
};
