import IProductDto, { IProductRestrictionsDto } from '@/interfaces/IProductDto';
import IProduct from '@/interfaces/IProduct';
import IWarehouse from '@/interfaces/IWarehouse';
import {
  getProductEstimatedPrice,
  getProductInventory,
  getWarehouseProductConfigurationById,
  getWarehouseProducts,
} from '@/services/ProductService';
import { adapterDtoToBadge } from '@/adapters/BadgeAdapter';
import { adapterDtoToProductAttributeOption } from '@/adapters/ProductAttributeOptionAdapter';
import IProductConfiguration from '@/interfaces/IProductConfiguration';
import EVisibility from '@/enums/EVisibility';
import ECanAddToCart from '@/enums/ECanAddToCart';
import ESoldOutFormat from '@/enums/ESoldOutFormat';
import EPriceFormat from '@/enums/EPriceFormat';

export const adapterDtoToProduct = (product: IProductDto): IProduct => {
  const {
    id,
    sku,
    alias,
    slug,
    nameEs,
    nameEn,
    descEn,
    descEs,
    isActive,
    stepper,
    priority,
    barCode,
    useFolio,
    publicationDate,
    publicationDeadlineDate,
    saleFormat,
    unit,
    attributeOptions,
    productImgs,
    tags,
    internalTags,
    productAttachments,
    badges,
  } = product;

  const PRODUCT_RESTRICTIONS: IProductRestrictionsDto = {
    isManagedSale: product.restrictions.isManagedSale,
    visibility: product.restrictions.visibility,
    canAddToCart: product.restrictions.canAddToCart,
    inventory: {
      allowSaleWithoutInventory: product.restrictions.inventory.allowSaleWithoutInventory,
    },
    formats: {
      soldOut: product.restrictions.formats.soldOut,
      price: product.restrictions.formats.price,
      comparativeValue: product.restrictions.formats.comparativeValue,
    },
  };

  return {
    id,
    key: 'product',
    sku,
    stepper,
    name: {
      en: nameEn,
      es: nameEs,
    },
    desc: {
      en: descEn,
      es: descEs,
    },
    barCode,
    useFolio,
    publicationDate,
    publicationDeadlineDate,
    saleFormat,
    alias,
    slug,
    priority,
    isActive,
    unit: {
      id: unit.id,
      code: unit.code,
      label: {
        en: unit.labelEn,
        es: unit.labelEs,
      },
    },
    attributeOptions: attributeOptions.length > 0 ? attributeOptions.map((attrOpt) =>
      adapterDtoToProductAttributeOption(attrOpt)
    ): [],
    tags,
    internalTags,
    productAttachments,
    imgs: productImgs,
    getPrice: async (config) => await getPrice(id, config?.warehouseId),
    getInventory: async (config) => await getInventory(id, config?.warehouseId),
    getProductsConfig: async (config) => getConfig(id, PRODUCT_RESTRICTIONS, config?.warehouseId),
    isInWarehouse: async (config) => getIsInWarehouse(id, config?.warehouseId),
    badges: badges?.length > 0 ? badges.map(badge => adapterDtoToBadge(badge)) : [],
  };
};

const getPrice = async (
  productId: IProduct['id'],
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try{
    if (warehouseId) {
      const config = await getWarehouseProductConfigurationById(
        productId,
        warehouseId
      );
      return config.price;
    }

    const estimatedPrices = await getProductEstimatedPrice([productId]);
    return estimatedPrices[0].price;
  } catch(e) {
    return 0;
  }
};

const getInventory = async (
  productId: IProduct['id'],
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try{
    if (!warehouseId) return 0;
    const inventory = await getProductInventory([productId], warehouseId);

    return inventory[0].qty;
  } catch (e) {
    return 0;
  }
};

const getConfig = async (
  productId: IProduct['id'],
  restrictions: IProductRestrictionsDto,
  warehouseId?: IWarehouse['id'],
): Promise<IProductConfiguration[]> => {
  const DEFAULT_CONFIG = {
    id: productId,
    visibility: EVisibility.Always,
    canAddToCart: ECanAddToCart.Never,

    formats: {
      soldOut: ESoldOutFormat.NotAvailable,
      price: EPriceFormat.FromBase,
      comparativeValue: 1_000_000_000,
    },

    inventory: {
      allowSaleWithoutInventory: false,
      min: null ?? 0,
      max: null ?? 0,
      alertValue: null ?? 0,
    },

    discountPrice: null,
    discountExpDate: null,
    cost: 1_000_000_000,
    price: 1_000_000_000,
    refill: {
      isAuto: false,
      delayTime: null,
      formMinAmount: null,
    },
  };

  const GLOBAL_CONFIG = {
    id: productId,
    visibility: restrictions.visibility,
    canAddToCart: restrictions.canAddToCart,
    isManagedSale: restrictions.isManagedSale,

    formats: {
      soldOut: restrictions.formats.soldOut,
      price: restrictions.formats.price,
      comparativeValue: restrictions.formats.comparativeValue,
    },

    inventory: {
      allowSaleWithoutInventory: restrictions.inventory.allowSaleWithoutInventory,
      min: null,
      max: null,
      alertValue: null,
    },

    discountPrice: null,
    discountExpDate: null,
    cost: 1_000_000_000,
    price: 1_000_000_000,
    refill: {
      isAuto: null,
      delayTime: null,
      formMinAmount: null,
    },
  };

  const CONFIG = {
    ...DEFAULT_CONFIG,
    isManagedSale: GLOBAL_CONFIG.isManagedSale,
    visibility:
      GLOBAL_CONFIG.visibility ?? 
      DEFAULT_CONFIG.visibility,
    canAddToCart: 
      GLOBAL_CONFIG.canAddToCart ?? 
      DEFAULT_CONFIG.canAddToCart,

    formats: {
      price: 
        GLOBAL_CONFIG.formats.price ?? 
        DEFAULT_CONFIG.formats.price,
      soldOut: 
        GLOBAL_CONFIG.formats.soldOut ?? 
        DEFAULT_CONFIG.formats.soldOut,
      comparativeValue: 
        GLOBAL_CONFIG.formats.comparativeValue ?? 
        DEFAULT_CONFIG.formats.comparativeValue,
    },

    inventory: {
      allowSaleWithoutInventory: 
        GLOBAL_CONFIG.inventory.allowSaleWithoutInventory ??
        DEFAULT_CONFIG.inventory.allowSaleWithoutInventory,
      min: 
        GLOBAL_CONFIG.inventory.min ?? 
        DEFAULT_CONFIG.inventory.min,
      max: 
        GLOBAL_CONFIG.inventory.min ?? 
        DEFAULT_CONFIG.inventory.max,
      alertValue: 
        GLOBAL_CONFIG.inventory.min ?? 
        DEFAULT_CONFIG.inventory.alertValue,
    },
  };

  try {
    if (!warehouseId) return [CONFIG];

    const WH_PROD_CONFIG = await getWarehouseProductConfigurationById(productId, warehouseId);

    return [{
      ...CONFIG,
      id: WH_PROD_CONFIG.id,

      visibility: 
        GLOBAL_CONFIG.visibility ?? 
        WH_PROD_CONFIG.visibility ?? 
        DEFAULT_CONFIG.visibility,
      canAddToCart: 
        GLOBAL_CONFIG.canAddToCart ?? 
        WH_PROD_CONFIG.canAddToCart ?? 
        DEFAULT_CONFIG.canAddToCart,

      formats: {
        price: 
          GLOBAL_CONFIG.formats.price ?? 
          WH_PROD_CONFIG.formats.price ?? 
          DEFAULT_CONFIG.formats.price,
        soldOut: 
          GLOBAL_CONFIG.formats.soldOut ??
          WH_PROD_CONFIG.formats.soldOut ??
          DEFAULT_CONFIG.formats.soldOut,
        comparativeValue: 
          GLOBAL_CONFIG.formats.comparativeValue ??
          WH_PROD_CONFIG.formats.comparativeValue ?? 
          DEFAULT_CONFIG.formats.comparativeValue,
      },

      inventory: {
        allowSaleWithoutInventory: 
          GLOBAL_CONFIG.inventory.allowSaleWithoutInventory ?? 
          WH_PROD_CONFIG.inventory.allowSaleWithoutInventory ?? 
          DEFAULT_CONFIG.inventory.allowSaleWithoutInventory,
        min: 
          GLOBAL_CONFIG.inventory.min ?? 
          WH_PROD_CONFIG.inventory.min ?? 
          DEFAULT_CONFIG.inventory.min,
        max: 
          GLOBAL_CONFIG.inventory.min ?? 
          WH_PROD_CONFIG.inventory.min ?? 
          DEFAULT_CONFIG.inventory.max,
        alertValue: 
          GLOBAL_CONFIG.inventory.min ?? 
          WH_PROD_CONFIG.inventory.min ?? 
          DEFAULT_CONFIG.inventory.alertValue,
      },
    }];
  }
  catch (e) {
    return [CONFIG];
  }
};

const getIsInWarehouse = async (
  productId: IProduct['id'],
  warehouseId?: IWarehouse['id']
) => {
  try{
    if (!warehouseId) return false;

    const data = await getWarehouseProducts({ productId, warehouseId });

    return data.length > 0
  } catch(e){
    return false;
  }
};
