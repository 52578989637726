import IMixTopping from '@/interfaces/IMixTopping';
import IMixToppingDto from '@/interfaces/IMixToppingDto';
import { adapterDtoToProduct } from './ProductAdapter';

export const adapterDtoToMixTopping = (mixTopping: IMixToppingDto): IMixTopping => {
  const { 
    id,
    customNameEs,
    customNameEn,
    useCustomEn,
    useCustomEs,
    order,
    maxLimit,
    restrictedPrice,
    price,
    multiplier,
    discountType,
    discount,
    product,
    baseAmount,
  } = mixTopping;
  
  return {
    id,
    isAvailable: true,
    name: {
      en: useCustomEn ? customNameEn : product.nameEn,
      es: useCustomEs ? customNameEs : product.nameEs,
    },
    order,
    restrictions: {
      min: baseAmount,
      max: maxLimit,
      multiplier,
    },
    priceRestrictions: {
      discountType,
      discount,
      type: restrictedPrice,
      price,
    },
    product: adapterDtoToProduct(product),
  };
};
