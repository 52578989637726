import React, { ReactNode, useEffect, useState } from 'react';
import EShoppingCartStatus from '@/enums/EShoppingCartStatus';
import IShoppingCart from '@/interfaces/IShoppingCart';
import getShoppingCarts, { createShoppingCart, getSingleShoppingCart, transferFromOneShoppingCartToAnother, updateShoppingCart } from '@/services/ShoppingCart';
import moment from 'moment';
import useAuth from '@/hooks/useAuth';
import ICustomer from '@/interfaces/ICustomer';

interface IShoppingCartContext {
  shoppingCart: IShoppingCart | undefined;
  loading: boolean;
  getActiveShoppingCart: () => Promise<IShoppingCart>;
  selectedShoppingCart: (shoppingCartId: IShoppingCart['id']) => void;
}

interface IChildren {
  children : ReactNode;
}

const shoppingCartContext = React.createContext<IShoppingCartContext>({} as IShoppingCartContext);

function useShoppingCart(): IShoppingCartContext {
  const { authenticated, customer } = useAuth();
  const [shoppingCart, setShoppingCart] = useState<IShoppingCart>();
  const [loading, setLoading] = useState<boolean>(false);

  const selectedShoppingCart = async (shoppingCartId: IShoppingCart['id']) => {
    localStorage.setItem('selectedShoppingCart', JSON.stringify(shoppingCartId));
    await getActiveShoppingCart();
  };

  const saveShoppingCart = (data: IShoppingCart) => {
    localStorage.setItem('shoppingCart', JSON.stringify(data));
    setShoppingCart(data);
  };

  const getLocalShoppingCart = (): IShoppingCart | null => {
    const item = localStorage.getItem('shoppingCart');
    if (item) {
      return JSON.parse(item);
    }

    return null;
  };

  const onCreateShoppingCart = async () => {
    let shoppingCart: Partial<IShoppingCart> = {
      status: EShoppingCartStatus.Active,
      expDate: moment().add('hours', 72),
    };

    if (authenticated && customer) {
      shoppingCart = {
        ...shoppingCart,
        userId: customer.id
      }
    }

    const newCart = await createShoppingCart(shoppingCart);

    return newCart;
  };

  const validateShoppingCart = async (shoppingCartId: IShoppingCart['id']) => {
    try {
      const shoppingCart = await getSingleShoppingCart({ id: shoppingCartId });
      return shoppingCart;
    } catch (error) {
      return null;
    }
  };

  const getShoppingCartWithoutAuth = async () => {
    let shoppingCart = getLocalShoppingCart();

    if (!shoppingCart || shoppingCart === null) {
      shoppingCart = await onCreateShoppingCart();
      return shoppingCart;
    }
    
    shoppingCart = await validateShoppingCart(shoppingCart.id);

    if (shoppingCart?.status !== EShoppingCartStatus.Active) {
      shoppingCart = await onCreateShoppingCart();
      return shoppingCart;
    }

    return shoppingCart as IShoppingCart;
  };

  const getShoppingCartWithAuth = async (custormerId: ICustomer['id']): Promise<IShoppingCart> => {
    const shoppingCarts = await getShoppingCarts({
      userId: custormerId,
      status: EShoppingCartStatus.Active,
    });
    
    if (!shoppingCarts || shoppingCarts.length === 0) {
      const shoppingCart = await onCreateShoppingCart();
      return shoppingCart;
    }
    
    if (shoppingCarts.length > 1) {
      await transferFromOneShoppingCartToAnother({
        fromId: shoppingCarts[0].id,
        toId: shoppingCarts[1].id,
      });
      
      const shoppingCart = await getShoppingCartWithAuth(custormerId);
      return shoppingCart;
    }
    
    if (shoppingCarts[0].status !== EShoppingCartStatus.Active) {
      const shoppingCart = await onCreateShoppingCart();
      return shoppingCart;
    }
    
    return shoppingCarts[0];
  };

  const getSelectedShoppingCart = async (): Promise<IShoppingCart> => {
    const item = localStorage.getItem('selectedShoppingCart');

    if (item) {
      const itemParse = JSON.parse(item);
      let shoppingCart = await getSingleShoppingCart({
        id: itemParse
      });

      if (shoppingCart.status !== EShoppingCartStatus.Active && authenticated && customer) {
        shoppingCart = await getShoppingCartWithAuth(customer?.id);
      }

      return shoppingCart;
    } 
    
    if (authenticated && customer) {
      const shoppingCart = await getShoppingCartWithAuth(customer?.id);
      return shoppingCart;
    }

    return getShoppingCartWithoutAuth();
  };

  const getActiveShoppingCart = async (): Promise<IShoppingCart> => {
    setLoading(true);
    if (authenticated && customer && customer.isStaff) {
      const shoppingCart = await getSelectedShoppingCart();
      saveShoppingCart(shoppingCart);
      setLoading(false);
      return shoppingCart;
    }

    if (authenticated && customer && !customer.isStaff) {
      const shoppingCart = await getShoppingCartWithAuth(customer?.id);
      saveShoppingCart(shoppingCart);
      setLoading(false);
      return shoppingCart;
    }

    const shoppingCart = await getShoppingCartWithoutAuth();
    saveShoppingCart(shoppingCart);
    setLoading(false);
    return shoppingCart;
  };

  return {
    shoppingCart,
    loading,
    getActiveShoppingCart,
    selectedShoppingCart,
  };
};

export const ShoppingCartProvider = ({ children }: IChildren) => {
  const checkoutDrawer = useShoppingCart();

  return <shoppingCartContext.Provider value={checkoutDrawer}>{children}</shoppingCartContext.Provider>;
};

export default function ShoppingCartConsumer() {
  return React.useContext(shoppingCartContext);
}
