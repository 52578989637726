import { createRef, useEffect, useState } from "react";
import { Carousel, Col, Image, Row } from "antd";
import styled from "styled-components";
import { CarouselRef } from 'antd/lib/carousel';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import IImage from "@/interfaces/IImage";
import { NotFoundImage } from "@/assets/images";

interface ImagesPreviewProps{
  images: IImage[],
}

const ImagePreviewContainer = (styled.div`
  {
    width: 50px;
    height: 50px;
    border: 2px solid #dedede;
    margin: auto;
    background: #f3f4f6;
  }
  &:hover {
    cursor: pointer;
  }
  &.selected {
    border-color: #00abff;
  }
  .ant-image {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-image-img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
  }
`);

const ColNoScrollbar = (styled(Col)`
  ::-webkit-scrollbar {
    display: none;
  }
`);

const ImagesPreview = ({
  images,
}: ImagesPreviewProps) => {
  const carouselRef = createRef<CarouselRef>();
  const screens = useBreakpoint();

  const [columnImagesPreviewHeight, setColumnImagesPreviewHeight] = useState<number>();
  const [imageSelected, setImageSelected] = useState<number>(0);

  const MainImageContainer = (styled.div`
    .ant-image {
      height: 100%;
      width: 100%;
    }
  `);
  
  const MainImageWrapper = (styled(Image)`
    object-fit: contain;
    max-width: 100%;
    max-height: 500px;
  `);
  const CarouselStyled = (styled(Carousel)`
    .slick-track {
      display: flex;
      align-items: center;
    }
  `);

  const sortImages = images.length > 0 ? images.sort((a, b) => a.order - b.order) : [{
    url: NotFoundImage,
  }];

  function resizeColumnImagesPreview(){
    const columnMainImage = window.document.getElementById('columnMainImage');
    if(columnMainImage === null) return;
    setColumnImagesPreviewHeight(columnMainImage.clientHeight);
  }

  useEffect(() => {
    carouselRef.current?.goTo(imageSelected);
  }, [imageSelected]);

  useEffect(() => {
    window.onresize = resizeColumnImagesPreview;
  }, []);

  return (
    <Row gutter={[6, 6]}>
      <ColNoScrollbar 
        className="h-full overflow-auto"
        md={{ span: 2, order: 1 }} 
        xs={{ span: 24, order: 2 }} 
        style={{ maxHeight: screens.md ? columnImagesPreviewHeight : undefined }}
      >
        <Row justify="center" gutter={[6, 6]} style={screens.md ? {} : { flexFlow: 'row', overflow: 'auto' }}>
          {sortImages.map((image, index) => (
            <Col
              key={`noscrollimage-${image.url}`}
              md={{ span: 24 }} 
              xs={{ flex: 'none' }}
            >
              <ImagePreviewContainer className={index === imageSelected ? 'selected' : ''}>
                <Image
                  src={image.url} 
                  preview={false} 
                  onClick={() => setImageSelected(index)} 
                />
              </ImagePreviewContainer>
            </Col>
          ))}
        </Row>
      </ColNoScrollbar>
      <Col 
        id="columnMainImage"
        className="h-full text-center bg-gray-100"
        md={{ span: 22, order: 2 }} 
        xs={{ span: 24, order: 1 }} 
      >
        <CarouselStyled 
          className='main-img-carousel' 
          style={{ 
            padding: '8px',
          }} 
          effect="fade" 
          dots={false} 
          ref={carouselRef} 
          onReInit={resizeColumnImagesPreview}
        >
          {sortImages.map(image => (
            <MainImageContainer key={`scrollimage-${image.url}`} className='main-img-container'>
              <MainImageWrapper
                className='main-img'
                src={image.url}
                preview={false} 
              />
            </MainImageContainer>
          ))}
        </CarouselStyled>
      </Col>
    </Row>
  );
};

export default ImagesPreview;
