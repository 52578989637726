import IAddressFormData from '@/interfaces/IAddressFormData';
import ICountry from '@/interfaces/ICountry';
import IPostalCode from '@/interfaces/IPostalCode';
import ISuburb from '@/interfaces/ISuburb';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface IAddressFormProps {
  form: FormInstance<IAddressFormData>;
  countries: ICountry[];
  getPostalCodes: (filter: { code: string }) => Promise<IPostalCode[]>;
  initialSuburbs: Partial<ISuburb>[];
  name: string;
}

interface ICustomError {
  type: '' | 'success' | 'warning' | 'error' | 'validating' | undefined;
  msg: string;
}

const AddressForm = ({
  form,
  countries,
  getPostalCodes,
  initialSuburbs,
  name,
}: IAddressFormProps) => {
  const { t } = useTranslation('common');
  const { Option } = Select;

  // const counryState = useCountry();
  const [loading, setLoading] = useState<boolean>(false);
  const [suburbs, setSuburbs] = useState<[] | Partial<ISuburb>[]>([]);
  const [error, setError] = useState<ICustomError>({ type: '', msg: '' });

  const requiredRule = [
    {
      required: true,
      message: t('validation.required'),
    },
  ];

  const getPartialAddress = async (initialSuburb?: ISuburb['id']) => {
    const FormValues = form.getFieldsValue(true);
    setError({ type: '', msg: '' });

    if (!FormValues.postalCode) return;

    if (!FormValues.countryId) {
      setError({ type: 'error', msg: t('g.select_country') });
      return;
    }

    setLoading(true);

    const postalCodes = await getPostalCodes({ code: FormValues.postalCode });

    const postalCode = postalCodes.find(
      (postalCodeItem: IPostalCode) =>
        postalCodeItem.city?.state?.country?.id.toString() ===
        FormValues.countryId
    );

    if (!postalCode) {
      setError({ type: 'error', msg: t('g.invalid_postal_code') });
    }

    form.setFieldsValue({
      ...FormValues,
      state: postalCode?.city?.state?.name,
      city: postalCode?.city?.name,
      postalCodeId: postalCode?.id,
      suburbId: initialSuburb ? parseInt(initialSuburb, 10) : null,
    });
    setSuburbs(postalCode?.suburbs ?? []);

    setLoading(false);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;
    if (!value) return;
    if (value.length >= 5) {
      getPartialAddress();
    }
  };

  useEffect(() => {
    if (initialSuburbs.length > 0) {
      getPartialAddress(initialSuburbs[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSuburbs(initialSuburbs ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSuburbs]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Form form={form} name={name} layout='vertical' {...layout}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='postalCodeId' hidden>
            <Input />
          </Form.Item>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='countryId'
                label={t('g.country')}
                rules={requiredRule}
              >
                <Select allowClear>
                  {countries.map((country) => (
                    <Option
                      key={`cty_${country.id}`}
                      value={country.id}
                    >{`${country.name} (${country.code})`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='postalCode'
                label={t('g.postal_code')}
                rules={requiredRule}
                validateStatus={error.type}
                help={error.msg}
              >
                <Search
                  loading={loading}
                  placeholder='00000'
                  onChange={onChange}
                  onSearch={() => getPartialAddress()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name='street'
                label={t('g.street')}
                rules={requiredRule}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name='suburbId'
                label={t('g.suburb')}
                rules={requiredRule}
              >
                <Select allowClear>
                  {suburbs.map((suburb) => (
                    <Option key={`sub_${suburb.id}`} value={suburb.id}>
                      {suburb.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='extNum'
                label={t('g.ext_num')}
                rules={requiredRule}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item name='intNum' label={t('g.int_num')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item name='city' label={t('g.city')} rules={requiredRule}>
                <Input
                  readOnly
                  placeholder={t('g.select_a_country_and_postal_code')}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item name='state' label={t('g.state')} rules={requiredRule}>
                <Input
                  placeholder={t('g.select_a_country_and_postal_code')}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default AddressForm;
