import { adapterDtoToMix } from '@/adapters/MixAdapter';
import { adapterDtoToProduct } from '@/adapters/ProductAdapter';
import { adapterDtoToVariant } from '@/adapters/VariantAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import ERelatedProductTable from '@/enums/ERelatedProductTable';
import formatRelatedProducts from '@/helpers/formatters/formatRelatedProducts';
import IMixDto from '@/interfaces/IMixDto';
import IParams from '@/interfaces/IParams';
import IProductDto from '@/interfaces/IProductDto';
import IProductRelatedToList from '@/interfaces/IProductRelatedToList';
import IProductRelatedToListDto from '@/interfaces/IProductRelatedToListDto';
import IRelatedProduct from '@/interfaces/IRelatedProduct';
import IRelatedProductDto from '@/interfaces/IRelatedProductDto';
import IVariantDto from '@/interfaces/IVariantDto';
import IWarehouse from '@/interfaces/IWarehouse';
import ItemType from '@/types/ItemType';
import Optional from '@/types/Optional';

const getRelatedProducts = async (
  params?: IParams
): Promise<IRelatedProduct[]> => {
  const result = await axiosApiConfig.get<{ data: IRelatedProductDto[] }>(
    '/related_products',
    { params: { ...params } }
  );
  const finalData: IRelatedProduct[] = result.data.data.map(
    formatRelatedProducts
  );

  return finalData;
};

export const createRelatedProduct = async (
  data: Partial<IRelatedProduct>
): Promise<IRelatedProduct> => {
  const result = await axiosApiConfig.post<IRelatedProduct>(
    '/related_products/',
    data
  );
  return result.data;
};

export const editRelatedProduct = async (
  data: Partial<IRelatedProduct>
): Promise<IRelatedProduct> => {
  const result = await axiosApiConfig.patch<IRelatedProduct>(
    `/related_products/${data.id}`,
    data
  );
  return result.data;
};

export const deleteShoppingCartItem = async (data: {
  id?: IRelatedProduct['id'];
}): Promise<IRelatedProduct> => {
  const result = await axiosApiConfig.delete<IRelatedProduct>(
    `/related_products/${data.id}`
  );
  return result.data;
};

interface ProductsRelatedToItemData {
  productsData: {id:string, entityType: ItemType}[];
  warehouseId: Optional<IWarehouse['id']>,
  skip?: number;
  limit?: number;
}

export interface ProductsRelatedToItemResultDto {
  data: IProductRelatedToListDto[],
  total: number
}

export interface ProductsRelatedToItemResult {
  data: IProductRelatedToList[],
  total: number
}
export const getProductsRelatedToItem  = async (data: ProductsRelatedToItemData): Promise<ProductsRelatedToItemResult> => {
  const result = await axiosApiConfig.post<ProductsRelatedToItemResultDto>(
    '/get_products_related_to_list',
    data
  );
  const returnResult: ProductsRelatedToItemResult = {
    data: result.data.data.map(d=>{
      if(d.toTable===ERelatedProductTable.Product){
        return {
          fromId: d.fromId,
          fromTable: d.fromTable,
          id: d.id,
          toId:d.toId,
          toTable: d.toTable,
          type: d.type,
          item: {
            ...adapterDtoToProduct(d.item as IProductDto),
            calculatedCost: d.price,
          },
        }
      }
      else if(d.toTable===ERelatedProductTable.Variant){
        return {
          fromId: d.fromId,
          fromTable: d.fromTable,
          id: d.id,
          toId:d.toId,
          toTable: d.toTable,
          type: d.type,
          item: {
            ...adapterDtoToVariant(d.item as IVariantDto),
            calculatedCost: d.price,
          },
        }
      }
      else{
        return {
          fromId: d.fromId,
          fromTable: d.fromTable,
          id: d.id,
          toId:d.toId,
          toTable: d.toTable,
          type: d.type,
          item: {
            ...adapterDtoToMix(d.item as IMixDto),
            calculatedCost: d.price,
          }
        }
      }
    }),
    total: result.data.total
  }
  return returnResult;
};

export default getRelatedProducts;
