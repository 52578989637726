import React from 'react';
import { Col, Empty, List, Radio, Row, Spin, Image, Typography, Space } from 'antd';
import useService from '@/hooks/useService';
import { getPaymentServiceByWarehouse } from '@/services/PaymentServiceService';
import IWarehouse from '@/interfaces/IWarehouse';
import { useTranslation } from 'react-i18next';
import IPaymentService from '@/interfaces/IPaymentService';
import { useLocation } from 'react-router-dom';
import formatCurrencyMX from '@/utils/formatCurrency';
import Optional from '@/types/Optional';

interface ICheckoutPaymentServicePage {
  warehouseId: IWarehouse['id']
  paymentServiceId?: IPaymentService['code'];
  onChangePaymentServiceId: (e: IPaymentService['code']) => void;
}

const CheckoutPaymentServicePage = ({
  warehouseId,
  paymentServiceId,
  onChangePaymentServiceId
}: ICheckoutPaymentServicePage) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  
  const operativePaymentServiceState = useService<IPaymentService[]>({
    fetchData: getPaymentServiceByWarehouse,
    params: { 
      warehouseId,
      isPrivate: location.pathname === '/checkout/pos'
    },
  });

  const emptyList = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t('g.there_are_no_contacts')}
    />
  );

  const getPaymentServiceMinStrategy = (strategies: IPaymentService['strategies']): Optional<number> => {
    const validStrategies: number[] = [];
    for (let i = 0; i < strategies.length; i++) {
      const { orderMinimum } = strategies[i];
      if (typeof orderMinimum === 'number') {
        validStrategies.push(orderMinimum);
      }
    }
    if (validStrategies.length === 0) {
      return null;
    }
    return Math.min(...validStrategies);
  };
  const getPaymentServiceMaxStrategy = (strategies: IPaymentService['strategies']): Optional<number> => {
    const validStrategies: number[] = [];
    for (let i = 0; i < strategies.length; i++) {
      const { orderLimit } = strategies[i];
      if (typeof orderLimit === 'number') {
        validStrategies.push(orderLimit);
      }
    }
    if (validStrategies.length === 0) {
      return null;
    }
    return Math.max(...validStrategies);
  };

  const itemList = (
    item: IPaymentService
  ) => (
    <List.Item
      onClick={() => onChangePaymentServiceId(item.code)}
      className="cursor-pointer"
    >
      <Row align="middle" className="w-full" style={{ cursor: 'pointer' }}>
        <Col span={4} >
          <Row justify="center">
            <Col>
              <Radio value={item.code} />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Space direction="vertical">
            <Image 
              preview={false}
              src={item.imageUrl} 
              alt={`${item.name} - ${item.code}`}
              style={{ height: '50px', width: 'auto' }}
            />
            {item.strategies && getPaymentServiceMinStrategy(item.strategies) != null && (
              <Typography.Paragraph style={{ margin: 0 }}>
                <Typography.Text>{`${t('g.purchase_minimum')}: `}</Typography.Text>
                <Typography.Text strong>{formatCurrencyMX(getPaymentServiceMinStrategy(item.strategies) ?? 0)}</Typography.Text>
              </Typography.Paragraph>
            )}
            {item.strategies && (
              <Typography.Paragraph style={{ margin: 0 }}>
                <Typography.Text>{`${t('g.purchase_limit')}: `}</Typography.Text>
                <Typography.Text strong>{formatCurrencyMX(getPaymentServiceMaxStrategy(item.strategies) ?? 0)}</Typography.Text>
              </Typography.Paragraph>
            )}
          </Space>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <Col>
              <Typography.Text>
                {item.name}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </List.Item>
  );

  if (!operativePaymentServiceState.data) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24} className="text-center">
        <Typography.Title level={3} className="underline">
          {t('g.payment_options')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('hints.select_a_payment_service')}
        </Typography.Paragraph>
      </Col>
      <Col span={24} style={{ margin: '20% 0px' }}>
        <Radio.Group 
          onChange={(e) => onChangePaymentServiceId(e.target.value)} 
          className="w-full" 
          value={paymentServiceId}
        >
          <List
            bordered
            className="w-full"
            dataSource={operativePaymentServiceState.data}
            renderItem={itemList}
            locale={{
              emptyText: emptyList,
            }}
          />
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default CheckoutPaymentServicePage;
