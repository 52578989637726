import { useNavigate } from 'react-router-dom';

function useGoToBackPage() {
  const navigate = useNavigate();

  const redirectToBackPage = (route: string) => {
    if (navigate.length > 1) {
      navigate(-1);
    } else {
      navigate(`${route}`);
    }
  };

  return {
    redirectToBackPage,
  };
}

export default useGoToBackPage;
