import { Badge } from 'antd';
import React from 'react';

interface IBadgeCounterProps {
  element: React.ReactNode;
  count?: number;
  countLimit?: number;
  showZero?: boolean;
  onClick?: () => void;
};

const BadgeCounter = ({
  element, 
  count = 0, 
  countLimit = 99, 
  showZero = false, 
  onClick,
}: IBadgeCounterProps) => {
  const containerStyle: React.CSSProperties = {
    cursor: (onClick) ? "pointer" : '',
  };

  return (
    <div onClick={onClick} style={containerStyle}>
      <Badge count={count} overflowCount={countLimit} showZero={showZero} size="small">
        {element}
      </Badge>
    </div>
  );
};

export default BadgeCounter;
