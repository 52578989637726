import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useMemo } from 'react';

const useBreakpoints = () => {
  const breakpoints = useBreakpoint();
  const { xxl, xl, lg, md, sm, xs } = breakpoints;
  const levelsMap = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    xxl: 5,
  };

  const currentBreakpoint = useMemo(() => {
    if (xxl) {
      return 'xxl';
    }
    if (xl) {
      return 'xl';
    }
    if (lg) {
      return 'lg';
    }
    if (md) {
      return 'md';
    }
    if (sm) {
      return 'sm';
    }
    return 'xs';
  }, [xxl, xl, lg, md, sm]);

  const exclude = (...bps: Breakpoint[]) => {
    return bps.every(bp => !breakpoints[bp]);
  };

  const isBreakpointExact = (bp: Breakpoint) => {
    if (bp === 'xxl' && xxl) {
      return true;
    }
    if (bp === 'xl' && xl && exclude('xxl')) {
      return true;
    }
    if (bp === 'lg' && lg && exclude('xxl', 'xl')) {
      return true;
    }
    if (bp === 'md' && md && exclude('xxl', 'xl', 'lg')) {
      return true;
    }
    if (bp === 'sm' && sm && exclude('xxl', 'xl', 'lg', 'md')) {
      return true;
    }
    if (bp === 'xs' && xs && exclude('xxl', 'xl', 'lg', 'md', 'sm')) {
      return true;
    }
    return false;
  };

  const isBreakpointEqualsAndBelow = (bp: Breakpoint) => {
    const mappedLevel = levelsMap[bp];
    const currentLevel = levelsMap[currentBreakpoint];
    return currentLevel <= mappedLevel;
  };

  const isBreakpointEqualsAndAbove = (bp: Breakpoint) => {
    const mappedLevel = levelsMap[bp];
    const currentLevel = levelsMap[currentBreakpoint];
    return currentLevel >= mappedLevel;
  };

  return {
    breakpoints,
    currentBreakpoint,
    isBreakpointEqualsAndBelow,
    isBreakpointEqualsAndAbove,
    isBreakpointExact,
  };
};

export default useBreakpoints;
