import { adapterDtoToMix } from '@/adapters/MixAdapter';
import { adapterDtoToMixBaseProduct } from '@/adapters/MixBaseProduct';
import axiosApiConfig from '@/config/axiosConfig';
import IMix from '@/interfaces/IMix';
import IMixBaseProduct from '@/interfaces/IMixBaseProduct';
import IMixBaseProductDto from '@/interfaces/IMixBaseProductDto';
import IMixDto from '@/interfaces/IMixDto';
import IParams from '@/interfaces/IParams';
import IWarehouseMixDto from '@/interfaces/IWarehouseMix';

export const getMixById = async (mixId: IMix['id']):Promise<IMix> => {
  const result = await axiosApiConfig.get<IMixDto>(`/mixes/${mixId}`);

  return adapterDtoToMix(result.data);
};

export const getMixBaseProducts = async (params?: IParams): Promise<IMixBaseProduct[]> => {
  try {
    const result = await axiosApiConfig.get<IMixBaseProductDto[]>('/mix_base_products', { params: { ...params } });
    return result.data.map(mixBaseProduct => adapterDtoToMixBaseProduct(mixBaseProduct));
  } catch(e) {
    return []
  }
};

export const getWarehouseMixes = async (params?: IParams): Promise<IWarehouseMixDto[]> => {
  const result = await axiosApiConfig.get<IWarehouseMixDto[]>('/warehouse_mixes', {
    params: { ...params },
  });

  const mixes: IWarehouseMixDto[] = result.data;

  return mixes;
};
