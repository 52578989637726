import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Button, Card, Col, Image, Row, Typography, message, 
} from 'antd';
import useBreakpoints from '@/hooks/useBreakpoint';
import PasswordRecoveryForm, { IPasswordRecoveryValues } from '@/components/Forms/PasswordRecoveryForm';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { passwordRecovery, validatePasswordRecoveryToken } from '@/services/PasswordRecoveryService';
import { BgPatternImage, LogoImage } from '@/assets/images';
import isUrl from '@/helpers/isUrl';

const { Title, Paragraph } = Typography;

const PasswordRecovery = () => {
  const customImage = process.env.REACT_APP_BG_LOGIN_IMG;

  const backgroundImage = customImage 
    ? isUrl(customImage) ?  `URL(${customImage})` : customImage
    : `URL(${BgPatternImage})`;

  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const token = searchParams.get('token');
  
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const [passwordRecoveryForm] = useForm<IPasswordRecoveryValues>();

  const [email, setEmail] = useState<string | null>('');
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onGetToken = async (tokenToValidate: string) => {
    try {
      const validateToken = await validatePasswordRecoveryToken(tokenToValidate);

      setEmail(validateToken.email);
    } catch (error) {
      console.error(error);
      setEmail(null);
    }
  };

  const onChangePassword = async () => {
    try {
      setLoading(true);
      const { password } = await passwordRecoveryForm.validateFields();
      if (!token) return;
  
      await passwordRecovery(
        token,
        password
      );

      setPasswordChanged(true);
      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'password-recovery',
      });
    } catch (error) {
      console.error(error);
      message.success({
        content: t('g.error'),
        duration: 2,
        key: 'password-recovery',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;

    onGetToken(token);
  }, [token]);
  
  return (
    <Row
      className="min-h-screen"
      style={{ backgroundImage }}
      justify="center"
      align="middle"
    >
      <Col 
        flex={isBreakpointEqualsAndBelow('md') ? "70%" : "35%"}
      >
        <Card className="px-6 min-h-full">
          <Row justify="center">
            <Col>
              <Image 
                preview={false}  
                width="100%"
                src={process.env.REACT_APP_LOGO_URL || LogoImage}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={5} className="text-center mt-2">
                {t('g.password_recovery')}
              </Title>
            </Col>
            {
              (email && !passwordChanged) ? (
                <>
                  <Col span={24}>
                    <Paragraph className="text-center">
                      {t('g.hello_name_please_enter_your_new_password', { name: email })}
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <PasswordRecoveryForm form={passwordRecoveryForm} />
                  </Col>
                  <Col span={24}>
                    <Button 
                      type="primary"
                      onClick={onChangePassword}
                      loading={loading}
                      style={{ float: 'right' }}
                    >
                      {t('g.change_password')}
                    </Button>
                  </Col>
                </>
              ) : null
            }
            {
              (!email && !passwordChanged) ? (
                <Col span={24}>
                  <Paragraph className="text-center">
                    {t('g.sorry_the_token_does_not_exist_or_has_expired')}
                  </Paragraph>
                </Col>
              ) : null
            }
            {
              passwordChanged ? (
                <>
                  <Col span={24}>
                    <Paragraph className="text-center">
                      {t('g.password_was_changed_successfully')}
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <Row justify="center">
                      <Col>
                        <Button 
                          type="link"
                          onClick={() => navigate('/login')}
                        >
                          {t('g.log_in')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : null
            }
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordRecovery;
