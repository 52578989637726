import React from 'react';
import { Row, Col, Spin, FormInstance } from 'antd';
import useService from '@/hooks/useService';
import ICountry from '@/interfaces/ICountry';
import getCountries from '@/services/Country';
import AddressForm from '@/components/MyAddresses/AddressForm';
import getPostalCodes from '@/services/PostalCode';
import IAddressFormData from '@/interfaces/IAddressFormData';

interface ICheckoutAddressPage {
  form: FormInstance<IAddressFormData>;
}

const CheckoutAddressPage = ({
  form,
}: ICheckoutAddressPage) => {
  const countriesState = useService<ICountry[]>({
    fetchData: getCountries,
    params: {},
  });

  if (!countriesState.data) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }
  
  return (
    <Row>
      <Col span={24}>
        <AddressForm
          countries={countriesState.data}
          getPostalCodes={getPostalCodes}
          initialSuburbs={[]}
          name="AddressName"
          form={form}
        />
      </Col>
    </Row>
  );
};

export default CheckoutAddressPage;
