import IShippingContact from '@/interfaces/IShippingContact';
import { Col, Row, Typography } from 'antd';
import Address, { EAddressLayout } from '@/components/Address';

interface IShippingContactProps {
  shippingContact: IShippingContact;
}

const ShippingContact = ({ shippingContact }: IShippingContactProps) => {
  return (
    <Row>
      <Col>
      <Row>
      <Typography.Title ellipsis={true} level={3} className='mb-0'>
          {shippingContact.alias}
        </Typography.Title>
        </Row>
      <Row>
        <Typography.Title ellipsis={true} level={4} className='mb-1'>
          {shippingContact.name}
        </Typography.Title>
        </Row>
        {shippingContact?.address && (
          <Address
            address={shippingContact?.address}
            layout={EAddressLayout.Multi}
            ellipsis
          />
        )}
      </Col>
    </Row>
  );
};

export default ShippingContact;
