import IAttributeOption from "@/interfaces/IAttributeOption";
import ICategory from "@/interfaces/ICategory";
import Optional from "@/types/Optional";
import React, { ReactNode, useEffect } from "react";
import { useState } from "react";

interface IFilterContext {
    search: string;
    categoryId: Optional<ICategory['id']>;
    sort: ISortValue;
    attributeOptions: IAttributeOption['id'][];
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    setCategoryId: React.Dispatch<React.SetStateAction<Optional<ICategory['id']>>>;
    setSort: React.Dispatch<React.SetStateAction<ISortValue>>;
    setAttributeOptions: React.Dispatch<React.SetStateAction<IAttributeOption['id'][]>>;
}

interface IChildren {
    children: ReactNode;
}

type ISortValue = { 'sortByPriority' : 'ASC' } 
| { 'sortByName' : 'ASC' } 
| { 'sortByName' : 'DESC' }
| null;

const FilterContext = React.createContext<IFilterContext>(
    {} as IFilterContext
);

function useFilter() {
    const [search, setSearch] = useState<string>('');
    const [categoryId, setCategoryId] = useState<Optional<ICategory['id']>>(null);
    const [sort, setSort] = useState<ISortValue>({ sortByPriority: "ASC" });
    const [attributeOptions, setAttributeOptions] = useState<IAttributeOption['id'][]>([]);
    
    useEffect(() => {
        if(!search || search === '') return;

        setCategoryId(null);
    }, [search]);

    useEffect(() => {
        if(!categoryId) return;
        
        setSearch('');
    }, [categoryId]);
    
    return {
        sort,
        search,
        categoryId,
        attributeOptions,
        setSort,
        setSearch,
        setCategoryId,
        setAttributeOptions,
    }
}

export const FilterProvider = ({ children }: IChildren) => {
    const Filter = useFilter();

    return (
        <FilterContext.Provider value={Filter}>
            {children}
        </FilterContext.Provider>
    );
};

export default function FilterConsumer() {
    return React.useContext(FilterContext);
}