import formatCurrency from '@/utils/formatCurrency';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface IPurchaseSummaryProps {
  shipping?: number;
  discount?: number;
  iva?: number;
  subtotal: number;
  total: number;
}

const PurchaseSummary = ({
  subtotal,
  total,
  iva,
  discount,
  shipping = 0,
}: IPurchaseSummaryProps) => {
  const { t } = useTranslation('common');

  return (
    <Row>
      <Col span={24}>
        <Row justify='space-between' align='middle'>
          <Typography.Text type='secondary' className='mb-0'>
            {t('g.subtotal')}:
          </Typography.Text>
          <Typography.Paragraph className='mb-0'>
            {formatCurrency(subtotal)}
          </Typography.Paragraph>
        </Row>

        {(shipping > 0) && (
          <Row justify='space-between' align='middle'>
            <Typography.Text type='secondary' className='mb-0'>
              {t('g.shipping')}:
            </Typography.Text>
            <Typography.Paragraph className='mb-0'>
              {formatCurrency(shipping)}
            </Typography.Paragraph>
          </Row>
        )}

        {discount && (
          <Row justify='space-between' align='middle'>
            <Typography.Text type='secondary' className='mb-0'>
              {t('g.discount')}:
            </Typography.Text>
            <Typography.Paragraph className='mb-0'>
              {formatCurrency(discount)}
            </Typography.Paragraph>
          </Row>
        )}

        {iva && (
          <Row justify='space-between' align='middle'>
            <Typography.Text type='secondary' className='mb-0'>
              {t('g.iva_16_percent')}:
            </Typography.Text>
            <Typography.Paragraph className='mb-0'>
              {formatCurrency(iva)}
            </Typography.Paragraph>
          </Row>
        )}

        <Row justify='space-between' align='middle'>
          <Col>
            <Typography.Title className='mb-0' level={4}>
              {t('g.total')}:
            </Typography.Title>
            <Typography.Text type='secondary'>
              {t('g.price_with_iva')}:
            </Typography.Text>
          </Col>
          <Typography.Title level={3} className='mb-0 text-center'>
            {formatCurrency(total)}
          </Typography.Title>
        </Row>
      </Col>
    </Row>
  );
};

export default PurchaseSummary;
