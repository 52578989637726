import React from 'react';
import { Col, Form, Radio, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import IAttribute from '@/interfaces/IAttribute';
import IAttributeOption from '@/interfaces/IAttributeOption';
import IRadioOption from '@/interfaces/IRadioOption';
import getTranlation from '@/helpers/getTranslation';

interface IFormValues {
  atrributeOptions: IAttributeOption['id'][];
}

interface IVariantAttributes {
  options: IAttribute[];
  form: FormInstance<IFormValues>;
  onChangeAttributes: () => void;
}

const VariantAttributes = ({
  options,
  form,
  onChangeAttributes,
}: IVariantAttributes) => {
  const validOptions: IAttribute[] = options.filter(option => option.isVisible);

  const formatAttributeOptions = (
    attributeOptions: IAttributeOption[],
  ): IRadioOption[] => {
    const radioOptions = attributeOptions.map(attributeOption => {
      const { id, label, disabled } = attributeOption;

      return {
        value: id,
        label: getTranlation(label),
        disabled,
      };
    });

    return radioOptions;
  };

  return (
    <Form
      form={form}
      name="atributes"
      layout="vertical"
    >
      <Row>
        {
          validOptions.map((option, index) => (
            <Col span={24} key={`attribute-${option.id}`}>
              <Form.Item
                label={`${getTranlation(option.label)}:`}
                name={['attributeOptions', index]}
                key={`attribute-${option.id}`}
              >
                <Radio.Group
                  options={formatAttributeOptions(option.attributeOptions)}
                  onChange={onChangeAttributes}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
          ))
        }
      </Row>
    </Form>
  );
};

export default VariantAttributes;
