import { IBillingRequestFormData } from "@/components/Forms/BillingRequestForm";
import { orderBillingRequest } from "@/services/OrdersService";
import { Form, FormInstance, message } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface BillingRes {
  billingForm: FormInstance<IBillingRequestFormData>;
  billingFormOnSubmit: (formValues: IBillingRequestFormData) => void;
  goToHome: () => void;
}

const Billing = (): BillingRes => {
  const [billingForm] = Form.useForm<IBillingRequestFormData>();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  function goToHome(){
    navigate('/');
  }

  async function billingFormOnSubmit(formValues: IBillingRequestFormData){
    try {
      await orderBillingRequest(formValues);

      message.success({content: t('g.done'), duration: 2, onClose: goToHome});
    } catch (error) {
      message.error({content: t('g.error'), duration: 2});
    }
  }
  
  return {
    billingForm,
    billingFormOnSubmit,
    goToHome,
  };
};

export default Billing;
