import ISchedule from '@/interfaces/ISchedule';
import moment from 'moment';
import { Moment } from 'moment';
import equalizeDate from '@/helpers/equalizeDate';
import getScheduleDayByDate from '@/helpers/getScheduleDayByDate';
import RangeScheduleType from '@/types/RangeScheduleType';

function nextCloseSchedule(schedule: ISchedule, referenceDate: Moment = moment()): string{
  const DATE_FORMAT = "hh:mm a";
  const scheduleDay = getScheduleDayByDate(schedule, referenceDate);

  if (scheduleDay.isOpen24hr) return moment().endOf('day').format(DATE_FORMAT);

  if (
    scheduleDay?.rangeSchedule 
    && scheduleDay.rangeSchedule.length > 0
  ) {
    const openRange: RangeScheduleType | undefined = scheduleDay.rangeSchedule.find(range => {
      const startDateTime: Moment = equalizeDate(range.startTime, referenceDate);
      const endDateTime: Moment = equalizeDate(range.endTime, referenceDate);

      if (!referenceDate.isBetween(startDateTime, endDateTime)) return false;

      return true;
    });

    return moment(openRange?.endTime).format(DATE_FORMAT);
  }

  return  moment().endOf('day').format(DATE_FORMAT);
}

export default nextCloseSchedule;
