import { adapterDtoToProduct } from '@/adapters/ProductAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import IEstimatedPrice from '@/interfaces/IEstimatedPrice';
import IParams from '@/interfaces/IParams';
import IProduct from '@/interfaces/IProduct';
import IProductConfiguration from '@/interfaces/IProductConfiguration';
import IProductDto from '@/interfaces/IProductDto';
import IWarehouse from '@/interfaces/IWarehouse';
import IWarehouseProductConfigurationDto from '@/interfaces/IWarehouseProductConfigurationDto';
import IWarehouseProductDto from '@/interfaces/IWarehouseProductDto';
import IProductInventory from '../interfaces/IProductInventory';

export const getProductById = async (
  productId: IProduct['id']
): Promise<IProduct> => {
  const result = await axiosApiConfig.get<IProductDto>(
    `/products/${productId}`
  );

  return adapterDtoToProduct(result.data);
};

export const getWarehouseProductConfigurationById = async (
  productId: IProduct['id'],
  warehouseId: IWarehouse['id']
): Promise<IProductConfiguration> => {
  const result = await axiosApiConfig.get<IWarehouseProductConfigurationDto>(
    `/products/${productId}`,
    {
      params: {
        scope: 'warehouses',
        id: warehouseId,
      },
    }
  );

  return result.data.config;
};

export const getProductEstimatedPrice = async (
  productIds: IProduct['id'][]
): Promise<IEstimatedPrice[]> => {
  const result = await axiosApiConfig.post<IEstimatedPrice[]>(
    '/estimated_products_price',
    {
      productIds,
    }
  );

  return result.data;
};

export const getProductInventory = async (
  productIds: IProduct['id'][],
  warehouseId: IWarehouse['id']
): Promise<IProductInventory[]> => {
  const result = await axiosApiConfig.post<IProductInventory[]>(
    '/product_inventory_quantity',
    {
      productIds,
      warehouseId,
    }
  );

  return result.data;
};

export const getWarehouseProducts = async (params?: IParams): Promise<IWarehouseProductDto[]> => {
  const result = await axiosApiConfig.get<IWarehouseProductDto[]>('/warehouse_products', {
    params: { ...params },
  });
  const finalData: IWarehouseProductDto[] = result.data;

  return finalData;
};