import Optional from '@/types/Optional';
import { Row, Typography, Col } from 'antd';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;

interface IBannerProps {
  text: string;
  fontColor?: string;
  bgColor?: string;
  url?: Optional<string>;
  urlLabel?: Optional<string>;
}

const Banner = ({
  text,
  url,
  urlLabel,
  fontColor = '#FFFFFF',
  bgColor = '#292929',
}: IBannerProps) => {
  const { t } = useTranslation('common');
  const [ellipsis, setEllipsis] = useState<boolean>(true);

  const rowWrapperStyle: CSSProperties = {
    lineHeight: 'normal',
    backgroundColor: bgColor,
  };  

  const containerStyle: CSSProperties = {
    padding: '8px 16px',
    lineHeight: 'normal',
    cursor: 'pointer',
  };
  const containerRowStyle: CSSProperties = {
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const mainTextStyle: CSSProperties = {
    color: fontColor,
    fontSize: 'small',
    margin: '0px',
  };

  const helpTextStyle: CSSProperties = {
    color: fontColor,
    opacity: '0.8',
    textDecoration: 'underline',
    cursor: 'pointer',
  };

  return (
    <Row justify='center' style={rowWrapperStyle}>
      <Col 
        span={24}
        style={containerStyle}
        onClick={
          url 
          ? () => window.open(url, '_blank') 
          : () => setEllipsis(prev => !prev)
        }
      >
        <Row justify="start" gutter={[16, 8]} style={containerRowStyle}>
          <Col span="auto">
            <Paragraph
              className={ellipsis ? "cursor-pointer" : "cursor-default"}
              style={mainTextStyle} 
              ellipsis={ellipsis}
            >
              {text}  
            </Paragraph>
          </Col>
            {ellipsis ? (
              <Col
                flex="none"
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setEllipsis(false)
                }} 
              >
                <Text style={helpTextStyle}>{t('g.show_more')}</Text>
              </Col>
            )
            : (
              <Col
                flex="none"
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setEllipsis(true)
                }} 
              >
                <Text style={helpTextStyle}>{t('g.show_less')}</Text>
              </Col>
              )
            }
        </Row>
      </Col>
    </Row>
  );
};

export default Banner;
