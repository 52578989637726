import React from 'react';
import { 
  Col, Row, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@/containers/_layouts/MenuItems';

interface IMenu {
  onCloseDrawer: () => void
}

const Menu = ({
  onCloseDrawer
}: IMenu) => {
  const navigate = useNavigate();
  const items = MenuItem();

  return (
    <Row gutter={[{ xs: 24, sm: 12, md: 8 }, 20]} wrap justify="space-evenly" >
      {
        items.map(item => (
          <Col 
            key={item.key} 
            onClick={() => {
              navigate(item.key);
              onCloseDrawer();
            }}
            className="cursor-pointer"
            hidden={(item?.isHidden && item.isHidden())}
          >
            <div className="center_children">
              {item.icon}
            </div>
            <Typography.Paragraph
              className="center_children mb-0 mt-1 text-xs text-center"
              ellipsis={{
                rows: 2,
                expandable: false,
              }}
            >
              {item.label}
            </Typography.Paragraph>
          </Col>
        ))
      }
    </Row>
  );
};

export default Menu;
