import React from 'react';
import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/hooks/useBreakpoint';

export interface IPasswordRecoveryValues {
  password: string;
  confirmPassword: string;
}

interface IPasswordRecoveryForm {
  form: FormInstance<IPasswordRecoveryValues>;
}

const PasswordRecoveryForm = ({
  form,
}: IPasswordRecoveryForm) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();

  return (
    <Form 
      form={form} 
      layout="vertical"
      name="passwordRecoveryForm"
    >
      <Form.Item 
        hasFeedback
        name="password" 
        label={t('g.password')} 
        rules={[
          {
            min: 7,
            required: true,
          }
        ]}
      >
        <Input.Password 
          minLength={7}
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.password')} 
        />
      </Form.Item>
      <Form.Item 
        hasFeedback
        name="confirmPassword" 
        label={t('g.confirm_password')} 
        rules={[
          {
            min: 7,
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Las contraseñas no son iguales'));
            },
          }),
        ]}
      >
        <Input.Password 
          minLength={7}
          className="rounded-3xl"
          size={isBreakpointEqualsAndBelow('xl') ? 'middle' : 'large'}
          placeholder={t('g.confirm_password')} 
        />
      </Form.Item>
    </Form>
  );
};

export default PasswordRecoveryForm;
