import {
  Tag,
} from 'antd';

interface optionProp<T>{
  value: T,
  label: string,
  bgColor: string,
  icon?: React.ReactNode,
}

interface TagLabelProps<T>{
  selected: T;
  options: optionProp<T>[];
  showIcon?: boolean;
}

const TagLabel = <T,>({
  selected,
  options,
  showIcon = false,
}: TagLabelProps<T>) => {
  function getSelectedOption(): optionProp<T>{
    const selectedOption = options.filter(option => option.value === selected)[0];
    return selectedOption;
  }

  const selectedOption = getSelectedOption();

  return (
    <Tag 
      icon={showIcon && selectedOption?.icon}
      color={selectedOption?.bgColor}
    >
      {selectedOption?.label}
    </Tag>
  );
};

export default TagLabel;
