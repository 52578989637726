import React from 'react';
import { Col, Row, Input, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EShoppingCartsFilters from '@/enums/EShoppingCartsFilters';

interface IShoppingCartsFilters {
  onChangeTab: (activeKey: string) => void;
  onSearch: (search: string) => void;
}

const ShoppingCartsFilters = ({
  onChangeTab,
  onSearch,
}: IShoppingCartsFilters) => {
  const { t } = useTranslation('common');

  const { All, My } = EShoppingCartsFilters;

  return (
    <Row>
      <Col span={24}>
        <Input 
          name="search" 
          placeholder={t('g.search')} 
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
        <Tabs onChange={onChangeTab}>
          <Tabs.TabPane tab={t('g.all')} key={All} />
          <Tabs.TabPane tab={t('g.my')} key={My} />
        </Tabs>
      </Col>
    </Row>
  );
};

export default ShoppingCartsFilters;
