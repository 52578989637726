import { ReactNode } from 'react';
import { Card, Col, Row } from 'antd';
import useBreakpoints from '@/hooks/useBreakpoint';

interface IItemCardProps {
  thumb?: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  thumRatio?: number;
  cols? : number;
  layout?: 'horizontal' | 'vertical';
  onClick?: () => void;
}

const ItemCard = ({
  title,
  actions,
  content,
  thumb,
  onClick,
}: IItemCardProps) => {
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const imgSize = isBreakpointEqualsAndBelow('sm') ? "100px" : "200px";
  return (
    <Card size='small'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row
            onClick={onClick} 
            gutter={[16, 16]}
           >
            <Col flex={imgSize}>
              {thumb}
            </Col>
            <Col flex="1">
              <Row>
                <Col span={24}>
                  {title}
                </Col>
                {content ? ( 
                  <Col span={24}> 
                    {content}
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {actions ? (
            <Row>
              <Col>
                {actions}
              </Col>
            </Row>
          ): null}
        </Col>
      </Row>
    </Card>
  );
};

export default ItemCard;
