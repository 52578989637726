import { EBillingPayment } from '@/enums/EBillingPayment';
import { ECfdi } from '@/enums/ECfdi';
import { ETaxRegime } from '@/enums/ETaxRegime';
import ICity from '@/interfaces/ICity';
import IOrder from '@/interfaces/IOrder';
import IPostalCode from '@/interfaces/IPostalCode';
import IState from '@/interfaces/IState';
import getPostalCodes from '@/services/PostalCode';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import Search from 'antd/lib/input/Search';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface ICustomError {
  type: ValidateStatus;
  msg: string;
}

export interface IBillingRequestFormData {
  orderId: IOrder['id'];
  businessName: string;
  rfc: string;
  address: string;
  postalCode: IPostalCode['code'];
  state: IState['name'];
  city: ICity['name'];
  email: string;
  billingPaymentType: EBillingPayment;
  cfdi: ECfdi;
  taxRegime: ETaxRegime;
}

interface IBillingRequestFormProps {
  form: FormInstance<IBillingRequestFormData>;
  formName: string;
  onSubmit: (values: IBillingRequestFormData) => void;
}

const BillingRequestForm = ({
  form,
  formName,
  onSubmit,
}: IBillingRequestFormProps) => {
  const { t } = useTranslation('common');
  const { Option } = Select;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ICustomError>({ type: '', msg: '' });

  const billingPaymentOptions = Object.values(EBillingPayment);
  const cfdiOptions = Object.values(ECfdi);
  const taxRegimeOptions = Object.values(ETaxRegime);

  const requiredRule = [
    {
      required: true,
      message: t('validation.required'),
    },
  ];

  const getPartialAddress = async () => {
    const FormValues = form.getFieldsValue();
    setError({ type: '', msg: '' });

    if (!FormValues.postalCode) return;

    setLoading(true);

    const postalCodes = await getPostalCodes({ code: FormValues.postalCode });

    const postalCode = postalCodes.find(
      (postalCodeItem: IPostalCode) =>
        // TODO: Country MX hardcode remove
        postalCodeItem.city?.state?.country?.id.toString() === '1'
    );

    if (!postalCode) {
      setError({ type: 'error', msg: t('g.invalid_postal_code') });
    }

    form.setFieldsValue({
      ...FormValues,
      state: postalCode?.city?.state.name,
      city: postalCode?.city?.name,
      postalCode: postalCode?.code,
    });

    setLoading(false);
  };

  const onChangePostalCode = async (e: ChangeEvent<HTMLInputElement>) => {
    const postalCode = e.target.value;

    if (postalCode?.length < 5) return;
    
    getPartialAddress();
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    // TODO validation form
    <Form form={form} name={formName} layout='vertical' {...layout} onFinish={onSubmit}>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='orderId'
                // TODO translate
                label={'Número de Orden'}
                rules={requiredRule}
              >
                <Input placeholder={'Ingrese número de orden'} />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='email'
                // TODO translate
                label={'Correo electrónico'}
                rules={requiredRule}
              >
                <Input placeholder={'Ingrese el correo al cual le enviaremos la factura'} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='businessName'
                // TODO translate
                label={'Nombre o Razón Social'}
                rules={requiredRule}
              >
                <Input placeholder={'Ingrese Nombre o Razón Social'} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='rfc'
                // TODO translate
                label={'RFC'}
                rules={requiredRule}
              >
                <Input placeholder={'Ingrese RFC'} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='address'
                // TODO translate
                label={'Direccion'}
                rules={requiredRule}
              >
                <Input placeholder={'Ingrese calle, número, colonia, número exterior (si aplica)'} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='postalCode'
                label={t('g.postal_code')}
                rules={requiredRule}
                validateStatus={error.type}
                help={error.msg}
              >
                <Search
                  loading={loading}
                  // TODO translate
                  placeholder={'Ingrese código postal'}
                  onChange={onChangePostalCode}
                  onSearch={() => getPartialAddress()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item name='city' label={t('g.city')} rules={requiredRule}>
                <Input
                  readOnly
                  placeholder={t('g.select_a_postal_code')}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item name='state' label={t('g.state')} rules={requiredRule}>
                <Input
                  placeholder={t('g.select_a_postal_code')}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='billingPaymentType'
                // TODO translate
                label={'Tipo de pago'}
                rules={requiredRule}
              >
                <Select
                  placeholder={'Selecciona un tipo de pago'}
                  options={billingPaymentOptions.map(billingPaymentOption => ({
                    value: billingPaymentOption,
                    label: billingPaymentOption
                  }))}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='cfdi'
                // TODO translate
                label={'Uso de CFDI'}
                rules={requiredRule}
              >
                <Select
                  placeholder={'Selecciona un uso de CDFI'}
                  options={cfdiOptions.map(cfdiOption => ({
                    value: cfdiOption,
                    label: cfdiOption
                  }))}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='taxRegime'
                // TODO translate
                label={'Régimen Fiscal'}
                rules={requiredRule}
              >
                <Select
                  placeholder={'Selecciona un régimen fiscal'}
                  options={taxRegimeOptions.map(taxRegimeOption => ({
                    value: taxRegimeOption,
                    label: taxRegimeOption
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default BillingRequestForm;
