import EInventoryStatus from '@/enums/EInventoryStatus';
import Optional from '@/types/Optional';

const getEInventoryStatus = (
  qtyInventory: number, 
  minInventory: Optional<number>,
): EInventoryStatus => {
  if (qtyInventory <= 0) return EInventoryStatus.WithoutInventory;
  if (minInventory && qtyInventory <= minInventory) return EInventoryStatus.PartialInventory;

  return EInventoryStatus.WithInventory;
};

export default getEInventoryStatus;
