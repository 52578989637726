import { DefaultPlaceholderImage } from "@/assets/images";

interface IPlaceholderImageProps {
  className?: string;
  alt?: string;
}

const PlaceholderImage = ({
  className,
  alt = 'placeholder',
}: IPlaceholderImageProps) => {
  return <img className={className} alt={alt} src={DefaultPlaceholderImage} />;
};

export default PlaceholderImage;
