import axiosApiConfig from '@/config/axiosConfig';
import IAddress from '@/interfaces/IAddress';
import IParams from '@/interfaces/IParams';
import IShippingContact from '@/interfaces/IShippingContact';
import IShippingContactDto from '@/interfaces/IShippingContactDto';
import IUser from '@/interfaces/IUser';

const formatShippingContact = (sc: IShippingContactDto): IShippingContact => {
  return {
    address: {
      ...sc.address,
      id: sc.address.id.toString(),
      postalCode: sc.address.postal_code,
    },
    email: sc.email,
    id: sc.id.toString(),
    isDefault: sc.isDefault,
    name: sc.name,
    alias: sc.alias,
    phone: sc.phone,
    user: sc.user,
  };
};

const getShippingContacts = async (
  params?: IParams
): Promise<IShippingContact[]> => {
  const result = await axiosApiConfig.get<IShippingContactDto[]>(
    '/user_shipping_contacts',
    { params: { ...params } }
  );
  const finalData: IShippingContact[] = result.data.map(formatShippingContact);

  return finalData;
};

export const getSingleShippingContact = async (data: {
  id?: IShippingContact['id'];
}): Promise<IShippingContact> => {
  const result = await axiosApiConfig.get<IShippingContactDto>(
    `/user_shipping_contacts/${data.id}`
  );
  const finalData = formatShippingContact(result.data);
  return finalData;
};

export const createShippingContact = async (
  data: Partial<
    IShippingContact & { userId: IUser['id']; addressId: IAddress['id'] }
  >
): Promise<IShippingContact> => {
  const result = await axiosApiConfig.post<IShippingContact>(
    '/user_shipping_contacts/',
    data
  );
  return result.data;
};

export const editShippingContact = async (
  data: Partial<
    IShippingContact & { userId: IUser['id']; addressId: IAddress['id'] }
  >
): Promise<IShippingContact> => {
  const result = await axiosApiConfig.patch<IShippingContact>(
    `/user_shipping_contacts/${data.id}`,
    data
  );
  return result.data;
};

export const deleteShippingContact = async (data: {
  id?: IShippingContact['id'];
}): Promise<IShippingContact> => {
  const result = await axiosApiConfig.delete<IShippingContact>(
    `/user_shipping_contacts/${data.id}`
  );
  return result.data;
};

interface AddressData {
  extNum: string;
  intNum: string;
  street: string;
  suburbId: string;
  postalCodeId: string;
}

interface ContactData {
  alias: string;
  name: string;
  email: string;
  phone: string;
  isDefault: boolean;
  userId: string;
}

interface InputValueForCompleteShippingContact {
  addressData: AddressData;
  contactData: ContactData;
}

interface EditAddressData {
  id: string;
  extNum: string;
  intNum: string;
  street: string;
  suburbId: string;
  postalCodeId: string;
}

interface EditContactData {
  id: string;
  alias: string;
  name: string;
  email: string;
  phone: string;
  isDefault: boolean;
  userId: string;
  addressId: string;
}

interface InputValueForEditCompleteShippingContact {
  addressData: EditAddressData;
  contactData: EditContactData;
}

export const createCompleteShippingContact = async (
  data: InputValueForCompleteShippingContact
): Promise<IShippingContact> => {
  const result = await axiosApiConfig.post<IShippingContact>(
    '/create_complete_user_shipping_contact/',
    data
  );
  return result.data;
};

export const editCompleteShippingContact = async (
  data: InputValueForEditCompleteShippingContact
): Promise<IShippingContact> => {
  const result = await axiosApiConfig.post<IShippingContact>(
    '/edit_complete_user_shipping_contact/',
    data
  );
  return result.data;
};

export default getShippingContacts;
