import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import Topbar from '@/components/TopBar/TopBar';
import Banner from '@/components/Banner';
import useFilter from '@/hooks/useFilter';
import { BannerLocation, IBanner, getBanner } from '@/services/BannersService';

interface IHeader {
  collapsed: boolean;
  updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  hideSearch?: boolean;
}

const Header = ({
  collapsed,
  updateCollapsed,
  hideSearch = false,
}: IHeader) => {
  const { Header } = Layout;
  const [banner, setBanner] = useState<IBanner>();
  const filter = useFilter();
  
  useEffect(() => {
    getBanner(BannerLocation.Main).then(banner => {
      // console.log({ banner });
      if(!banner) {
        setBanner(undefined);
        return;
      }

      setBanner(banner);
    })
  }, []);

  return (
    <>
      <Header 
        id="zel-header"
        className="ant-layout-header" 
        style={{ 
          color: 'white',
          padding: '0',
          height: 'initial',
        }} 
      >
        <Topbar 
          collapsed={collapsed}
          updateCollapsed={updateCollapsed}
          hideSearch={hideSearch}
        />
        {
          banner ? (
            <Banner 
              text={banner.value}
              bgColor={banner.bgColor}
              fontColor={banner.fontColor}
              url={banner.url}
              urlLabel={banner.urlLabel}
            />
          ) : null
        }
      </Header>
    </>
  );
};

export default Header;
