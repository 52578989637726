import IWarehouse from '@/interfaces/IWarehouse';
import IWarehouseDto from '@/interfaces/IWarehouseDto';
import { adapterDtoToAddress } from '@/adapters/AddressAdapter';
import { adapterDtoToSchedule } from '@/adapters/ScheduleAdapter';

export const adapterDtoToWarehouse = (warehouse: IWarehouseDto): IWarehouse => {
  const { 
    id,
    isActive,
    type,
    alias,
    orderLimit,
    address,
    schedule,
  } = warehouse;

  return {
    id,
    isActive,
    type,
    alias,
    orderLimit,
    address: adapterDtoToAddress(address),
    schedule: adapterDtoToSchedule(schedule),
  };
};
