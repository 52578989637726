import { AuthenticationRequest } from '@feathersjs/authentication';
import { Params } from '@feathersjs/feathers';
import client, { authenticationConfig } from '@/config/client';
import Service from './Service';

export default class AuthenticationService implements Service {
  private static instance = client;

  public static async authenticate(
    request: AuthenticationRequest,
    params?: Params,
  ) {
    try {
      const result = await this.instance.authenticate(request, params);
      
      if (!request.remember) {
        localStorage.removeItem(authenticationConfig.storageKey!);
      }

      return result;
    } catch (error) {
      console.error('authentication error', error);
      throw error;
    }
  }

  public static get isAuthenticated() {
    return this.instance.authentication.authenticated;
  }

  public static async logout() {
    return await this.instance.logout();
  }

  public static async register(request: any) {
    return this.instance.service('users').create(request);
  }

  public static async reAuthenticate() {
    return await this.instance.reAuthenticate();
  }
}
