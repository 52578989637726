import React, { useEffect, forwardRef, useImperativeHandle, Ref } from 'react';
import { 
  Row, Col, Form, 
  Input, FormInstance, 
} from 'antd';
import { useTranslation } from 'react-i18next';
import IOpenpayResponse from '@/interfaces/IOpenpayResponse';
import IOpenpayToken from '@/interfaces/IOpenpayToken';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';

export interface IPaymentSourceFormRef {
  onSubmit: (
    onSuccess: (res: IOpenpayResponse<IOpenpayToken>) => void, 
    onError: (res: IOpenpayTokenError) => void
  ) => Promise<void>,
}

interface IPaymentSourceForm {
  merchantId: string;
  publicKey: string;
  form: FormInstance<any>;
  setDeviceSessionId: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentSourceForm = forwardRef(({
  form,
  publicKey,
  merchantId,
  setDeviceSessionId,
}: IPaymentSourceForm, ref: Ref<IPaymentSourceFormRef>) => {
  const { t } = useTranslation('common');
  const PAYMENT_LIVEMODE = process.env.REACT_APP_PAYMENT_LIVEMODE === 'true';
  const IS_SANDBOX = !PAYMENT_LIVEMODE;

  let OpenPay = window?.OpenPay;

  const handleChange = (e: any) => {
    const { value, maxLength } = e.target;

    let newValue = value.replace(/[^\d]/g, '');
    
    form.setFieldsValue({
      [e.target.name]: newValue.substr(0, maxLength)
    });
  };

  const onSubmit = async (
    onSuccess: (res: IOpenpayResponse<IOpenpayToken>) => void, 
    onError: (res: IOpenpayTokenError) => void
  ) => {
    try {
      const validValues = await form.validateFields()
      console.log('valid values', { validValues });
      validValues.card_number = validValues.card_number.toString().replace(/[\s]/g, '');
      OpenPay.token.create(validValues, onSuccess, onError);
    } catch (error) {
      console.error(error);
      onError({
        category: 'local',
        description: 'local',
        error_code: 1,
        http_code: 400,
        request_id: 'string',
      });
    }
  };

  useImperativeHandle(ref, () => ({ onSubmit }));

  useEffect(() => {
    OpenPay = window?.OpenPay;

    if (!OpenPay) return;
    
    OpenPay.setId(merchantId);
    OpenPay.setApiKey(publicKey);
    OpenPay.setSandboxMode(IS_SANDBOX);

    setDeviceSessionId(OpenPay.deviceData.setup('payment-form', 'deviceSessionId'));
  }, [window?.OpenPay]);

  return (
    <Form form={form} action="#" id="payment-form">
      <Col span={24}>
        <Form.Item name="deviceSessionId" id="deviceSessionId" hidden>
          <Input name="deviceSessionId" />
        </Form.Item>
        <Form.Item name="token_id" id="token_id" hidden>
          <Input name="token_id" />
        </Form.Item>
        <Form.Item name="use_card_points" id="use_card_points" initialValue={false} hidden>
          <Input name="use_card_points" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="holder_name" 
          id="holder_name"
          rules={[{ required: true }]}
        >
          <Input
            size="large"
            name="holder_name"
            className="w-full"
            placeholder={t('g.card_name')}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="card_number" 
          id="card_number"
          rules={[{ required: true },]}
        >
          <Input
            size="large"
            name="card_number"
            className="w-full"
            inputMode="numeric"
            placeholder="XXXX XXXX XXXX XXXX"
            // type="number"
            maxLength={16}
            onChange={handleChange}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item 
              name="expiration_month" 
              id="expiration_month"
              rules={[{ required: true }, { len: 2 }]}
            >
              <Input
                size="large"
                name="expiration_month"
                className="w-full"
                placeholder="MM"
                inputMode="numeric"
                max={12}
                type="number"
                onChange={handleChange}
                maxLength={2}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name="expiration_year" 
              id="expiration_year"
              rules={[{ required: true }, { len: 2 }]}
            >
              <Input
                size="large"
                name="expiration_year"
                className="w-full"
                placeholder="YY"
                inputMode="numeric"
                onChange={handleChange}
                type="number"
                maxLength={2}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="cvv2" 
              id="cvv2"
              rules={[{ required: true }, { max: 4 }]}
            >
              <Input
                size="large"
                name="cvv2"
                className="w-full"
                placeholder="CVC"
                inputMode="numeric"
                maxLength={4}
                type="number"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Form>
  );
});

export default PaymentSourceForm;
