import { useEffect, useRef, useState } from 'react';

interface IDimensions {
  width: number;
  height: number;
}

interface IUseDimensions {
  heightLimit: number;
}

const useDimensions = ({
  heightLimit,
}: IUseDimensions) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [height, setHeight] = useState<string>('100%');
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 0,
    height: 0,
  });

  const onShowMore = () => {
    setShowMore(!showMore);
  };

  const getDiminension = () => {
    if (!targetRef.current) return;

    setDimensions({
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
    });
  };

  const isPossibleShowMore = dimensions.height > heightLimit;
  const maskClassName = (isPossibleShowMore && !showMore) ? "mask-image-text" : "";

  useEffect(() => {
    if (!isPossibleShowMore) {
      setHeight('100%');
      return;
    };

    setHeight(showMore ? '100%' : `${heightLimit}px`);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore, dimensions]);

  return {
    showMore,
    height,
    targetRef,
    maskClassName,
    isPossibleShowMore,
    onShowMore,
    getDiminension,
  };
};

export default useDimensions;
