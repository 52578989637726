import IAddress from '@/interfaces/IAddress';
import IContactInfo from '@/interfaces/IContactInfo';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Address, { EAddressLayout } from '@/components/Address';
import Contact from './Contact';

interface IAddressDetailsCardProps {
  address: IAddress;
  contact: IContactInfo;
}

const AddressDetailsCard = ({ address, contact }: IAddressDetailsCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Row gutter={12}>
      <Col span={12}>
        <Typography.Title level={5}>
          {t('g.shipping_address')}:
        </Typography.Title>
        <Address address={address} layout={EAddressLayout.Multi}/>
      </Col>
      {contact && (
        <Col span={12}>
          <Typography.Title level={5}>
            {t('g.shipping_contact')}:
          </Typography.Title>
          <Contact contact={contact} />
        </Col>
      )}
    </Row>
  );
};

export default AddressDetailsCard;
