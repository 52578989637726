import React, { ReactElement } from 'react';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import { Card, Col, Popover, Row, Space, Spin, Typography } from 'antd';
import ShoppingCartItemsList from '@/components/ShoppingCart/ShoppingCartItemsList';
import formatCurrency from '@/utils/formatCurrency';
import useShoppingCartItemsList from '@/hooks/useShoppingCartItemsList';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IGroupShoppingCartItemsCard {
  date: string;
  price?: number;
  title: string | ReactElement;
  shoppingcartItemsParams: IShoppingCartItem[];
  disclaimer?: string;
}

const GroupShoppingCartItemsCard = ({
  title,
  price,
  date,
  shoppingcartItemsParams,
  disclaimer = ""
}: IGroupShoppingCartItemsCard) => {
  const {
    shoppingCartItems,
  } = useShoppingCartItemsList(shoppingcartItemsParams);

  return (
    <Card>
      <Row gutter={[0, 20]}>
        <Col span={8}>
          {
            typeof title === 'string' ? (
              <Typography.Text>
                {title}
              </Typography.Text>
            ) : title
          }
        </Col>
        <Col span={16}>
          <Row justify="end">
            <Col>
              <Typography.Text style={{ cursor: 'pointer'}} >
                <Popover
                  content={disclaimer}
                >
                  <Space>
                    {date} 
                    <InfoCircleOutlined style={{ paddingTop: '3px' }}/>
                  </Space>
                </Popover>
              </Typography.Text>
              {
                (price && price > 0) ? (
                  <Typography.Text>
                    {formatCurrency(price)}
                  </Typography.Text>
                ) : undefined
              }
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {!shoppingCartItems ? (
            <Row justify='center'>
              <Col>
                <Spin size='large' className='m-4' />
              </Col>
            </Row>
          ) : (
            <ShoppingCartItemsList
              shoppingCartItems={shoppingCartItems}
              hideBadge={false}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default GroupShoppingCartItemsCard;
