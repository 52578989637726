import { Badge, Card, Col, Row, BadgeProps } from 'antd';
import React, { ReactNode } from 'react';

interface IItemCardProps {
  thumb?: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  thumRounded?: boolean;
  layout?: 'horizontal' | 'vertical';
  badge?: BadgeProps;
  hideBorder?: boolean;
}

const ItemCard = ({
  title,
  actions,
  badge,
  content,
  layout = 'vertical',
  hideBorder = false,
  thumRounded,
  thumb,
}: IItemCardProps) => {
  return (
    <Card size='small' bordered={!hideBorder} hoverable>
      <Row gutter={ layout === 'vertical' ? [16, 0] : [0, 0] }>
        <Col 
          span={layout === 'vertical' ? (thumb ? 24 : 0) : undefined} 
          className={'text-center'}
        >
          {badge ? <Badge {...badge} children={thumb} /> : thumb}
        </Col>
        <Col span={ layout === 'vertical' ? 24 : 16 }>
          <Row>
            <Col span={ 24 }>
              {title}
            </Col>
            <Col span={ content ? 24 : 0 }>
              {content}
            </Col>
          </Row>
        </Col>
        <Col span={ actions ? 24 : 0 }>
          { actions }
        </Col>
      </Row>
    </Card>
  );
};

export default ItemCard;
