import React from 'react';
import { Col, Drawer, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ShoppingCartItemsList from '@/components/ShoppingCart/ShoppingCartItemsList';
import PurchaseSummary from '@/components/ShoppingCart/PurchaseSummary';
import IShoppingCartItemWithConfig from '@/interfaces/IShoppingCartItemWithConfig';
interface IShoppingCartDrawer {
  active: boolean;
  onClose: () => void;
  shoppingCartItems: IShoppingCartItemWithConfig[] | undefined;
  subtotal: number;
  shippingTotal: number;
  discount: number;
}

const ShoppingCartDrawer = ({
  active,
  onClose,
  shoppingCartItems,
  subtotal,
  shippingTotal,
  discount,
}: IShoppingCartDrawer) => {
  const { t } = useTranslation('common');
  
  return (
    <Drawer
      title={t('g.shopping_cart')}
      width="100%"
      height="100%"
      placement="top"
      closable={false}
      onClose={onClose}
      open={active}
      getContainer={false}
      className="absolute"
    >
      <Row>
        <Col span={24}>
          {!shoppingCartItems ? (
            <Row justify='center'>
              <Col>
                <Spin size='large' className='m-4' />
              </Col>
            </Row>
          ) : (
            <ShoppingCartItemsList
              shoppingCartItems={shoppingCartItems}
            />
          )}
        </Col>
        <Col span={24}>
          <PurchaseSummary
            subtotal={subtotal}
            shipping={shippingTotal}
            total={subtotal + shippingTotal - discount}
            discount={discount}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default ShoppingCartDrawer;
