import PurchaseSummary from '@/components/ShoppingCart/PurchaseSummary';
import useLocation from '@/hooks/useLocation';
import { getProductsRelatedToItem } from '@/services/RelatedProduct';
import {
  Card,
  Row,
  Col,
  PageHeader,
  Button,
  Spin,
  message,
  Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCartItemsList from '@/components/ShoppingCart/ShoppingCartItemsList';
import useShoppingCartItemsList from '@/hooks/useShoppingCartItemsList';
import IProductRelatedToList from '@/interfaces/IProductRelatedToList';
import { useNavigate } from 'react-router-dom';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import { cloneDeep } from 'lodash';
import validateItemsForInventoryAndWarehouse from '@/helpers/validateItemsForInventoryAndWarehouse';
import useAuth from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';
import EPage from '@/enums/EPages';
import usePermissions from '@/hooks/usePermissions';
import RelatedProducts from '@/components/RelatedProducts';

const ShoppingCart = () => {
  // HOOKS
  const { t } = useTranslation('common');
  const { authenticated, customer } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { isValidAction } = usePermissions();

  const {
    shoppingCartItems,
    loadingCart,
    totalPrice,
    onDecrease,
    onIncrease,
    onDelete,
  } = useShoppingCartItemsList();

  const [relatedItems, setRelatedItems] = useState<IProductRelatedToList[]>([]);

  const getRelatedProducts = async (items: IShoppingCartItem[]) => {
    try {
      const itemsCopy = cloneDeep(items);

      const productData = itemsCopy.map(item => {
        return {
          id: item.entityId,
          entityType: item.entityType
        }
      });

      const relatedProducts = await getProductsRelatedToItem({
        warehouseId: location.selectedWarehouse ?? null,
        skip: 0,
        limit: 10,
        productsData: productData,
      });

      setRelatedItems(relatedProducts.data);
    } catch (error) {
      setRelatedItems([]);
    }
  }

  //EFEECTS
  useEffect(() => {
    if (shoppingCartItems && shoppingCartItems.length > 0) {
      getRelatedProducts(shoppingCartItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCartItems?.length, location.selectedWarehouse]);

  const onPay = async () => {
    if (!location.selectedWarehouse) {
      message.warning({
        content: t('g.no_warehouse_selected'),
        key: 'No warehouse',
      });
      return;
    }

    const proceed = await validateItemsForInventoryAndWarehouse(
      shoppingCartItems,
      location.selectedWarehouse
    );
    
    if (!proceed.passed) {
      message.warning({
        content: t('g.some_products_not_in_warehouse_or_stock', {index: proceed.indexOfError+1}),
        key: 'No warehouse',
      });
      return;
    }

    if (
      authenticated 
      && customer 
      && customer.isStaff
      && isValidAction(EBasicActions.Create, EPage.ExtendedCheckout)
    ) {
      navigate('/checkout/pos', { replace: true });
    } else {
      navigate('/checkout', { replace: true });
    }
  };

  return (
    <Card size='small'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <PageHeader
            title={t('g.shopping_cart')}
            // onBack={() => redirectToBackPage('/')}
          />
        </Col>
        <Col span={24}>
          <Card size="small">
            <PurchaseSummary
              subtotal={totalPrice}
              total={totalPrice}
            />
            <Row justify='center'>
              <Col span={24}>
                <Button 
                  type="primary"
                  onClick={onPay}
                  block
                >
                  {t('g.pay')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          {loadingCart ? (
            <Row justify='center'>
              <Col>
                <Space>
                  <Spin size='large' className='m-4' />
                </Space>
              </Col>
            </Row>
          ) : (
            <ShoppingCartItemsList
              onDecrease={onDecrease}
              onIncrease={onIncrease}
              onDelete={onDelete}
              shoppingCartItems={shoppingCartItems}
              hideBadge
            />
          )}
        </Col>

        {relatedItems.length < 0 ? (
          <Col span={24}>
            <Row justify='center'>
              <Col>
                <Spin size='large' className='m-4' />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col span={24}>
            <RelatedProducts 
              relatedProducts={relatedItems} 
              gridRules={{ gutter: 12, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
            />
          </Col>
        )}

      </Row>
    </Card>
  );
};

export default ShoppingCart;
