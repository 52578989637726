import axiosApiConfig from '@/config/axiosConfig';
import ICustomer from '@/interfaces/ICustomer';
import IParams from '@/interfaces/IParams';

  export const getSingleuser = async (params: {
    id?: ICustomer['id'];
  }): Promise<ICustomer> => {
    const result = await axiosApiConfig.get<ICustomer>(`/users/${params.id}`);
    const finalData = result.data;
    return finalData;
  };

  export const getSingleuserByEmail = async (params: {
    email?: ICustomer['id'];
  }): Promise<ICustomer> => {
    const result = await axiosApiConfig.get<ICustomer[]>(`/users/`,{
        params: {email: params.email}
    });
    const finalData = result.data[0];
    return finalData;
  };

  export const getUsers = async (params: IParams): Promise<ICustomer[]> => {
    const result = await axiosApiConfig.get<ICustomer[]>('/users', { params });

    return result.data;
  };
