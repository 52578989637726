import axiosApiConfig from '@/config/axiosConfig';
import formatShoppingCartItem from '@/helpers/formatters/formatShoppingCartItems';
import IAddress from '@/interfaces/IAddress';
import IParams from '@/interfaces/IParams';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import IShoppingCartItemDto from '@/interfaces/IShoppingCartItemDto';
import IUser from '@/interfaces/IUser';

const getShoppingCartItems = async (
  params?: IParams
): Promise<IShoppingCartItem[]> => {
  const result = await axiosApiConfig.get<{ data: IShoppingCartItemDto[] }>(
    '/shopping_cart_items',
    { params: { ...params } }
  );

  const finalData: IShoppingCartItem[] = result.data.data.map(
    formatShoppingCartItem
  );

  return finalData;
};

export const getSingleShoppingCartItem = async (data: {
  id?: IShoppingCartItem['id'];
}): Promise<IShoppingCartItem> => {
  const result = await axiosApiConfig.get<IShoppingCartItemDto>(
    `/shopping_cart_items/${data.id}`
  );
  const finalData = formatShoppingCartItem(result.data);
  return finalData;
};

export const createShoppingCartItem = async (
  data: Partial<
    IShoppingCartItem & { userId: IUser['id']; addressId: IAddress['id'] }
  >
): Promise<IShoppingCartItem> => {
  const result = await axiosApiConfig.post<IShoppingCartItem>(
    '/shopping_cart_items/',
    data
  );
  return result.data;
};

export const editShoppingCartItem = async (
  data: Partial<
    IShoppingCartItem & { userId: IUser['id']; addressId: IAddress['id'] }
  >
): Promise<IShoppingCartItem> => {
  const result = await axiosApiConfig.patch<IShoppingCartItem>(
    `/shopping_cart_items/${data.id}`,
    data
  );
  return result.data;
};

export const deleteShoppingCartItem = async (data: {
  id?: IShoppingCartItem['id'];
}): Promise<IShoppingCartItem> => {
  const result = await axiosApiConfig.delete<IShoppingCartItem>(
    `/shopping_cart_items/${data.id}`
  );
  return result.data;
};

export const deleteShoppingCartItemWithPost = async (
  data: Partial<{ shoppingCartItemId: IShoppingCartItem['id'] }>
): Promise<number> => {
  const result = await axiosApiConfig.post<number>(
    '/shopping_cart_items/delete',
    data
  );
  return result.data;
};

export default getShoppingCartItems;
