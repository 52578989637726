import axiosApiConfig from '@/config/axiosConfig';
import IFranchise from '@/interfaces/IFranchise';
import IWarehouse from '@/interfaces/IWarehouse';
import IWarehouseDto from '@/interfaces/IWarehouseDto';

export const getFranchiseNameById = async (franchiseId: IFranchise['id']): Promise<IFranchise['alias']> => {
  const result = await axiosApiConfig.get<Pick<IFranchise, 'alias'>>(`/franchises/${franchiseId}/?$select[]=alias`);

  return result.data.alias;
};

export const getFranchiseNameByWarehouseId = async (warehouseId: IWarehouse['id']): Promise<IFranchise['alias']> => {
  const result = await axiosApiConfig.get<Pick<IWarehouseDto, 'operative'>>(`/warehouses/${warehouseId}?$select[]=operative`);

  return result.data.operative.franchise?.alias ?? '';
};

export default getFranchiseNameById;
