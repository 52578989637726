import IProductAttributeOption from "@/interfaces/IProductAttributeOption";
import IProductAttributeOptionDto from "@/interfaces/IProductAttributeOptionDto";

export const adapterDtoToProductAttributeOption = (
  productAttributeOption: IProductAttributeOptionDto
): IProductAttributeOption => {
  const { 
    id,
    labelEn,
    labelEs,
    order,
    attribute
  } = productAttributeOption;

  return {
    id,
    label: {
      en: labelEn,
      es: labelEs,
    },
    sort: order,
    attribute: {
      id: attribute?.id,
      label: {
        en: attribute?.labelEn,
        es: attribute?.labelEs,
      },
      isVisible: true
    }
  };
};
