import React, { useEffect, useState } from 'react';
import { 
  Row, Col, List, Spin, Empty, 
  Typography, Radio, Button, Modal, Form, message 
} from 'antd';
import IUser from '@/interfaces/IUser';
import useService from '@/hooks/useService';
import IShippingContact from '@/interfaces/IShippingContact';
import getShippingContacts, { createCompleteShippingContact } from '@/services/ShippingContact';
import { useTranslation } from 'react-i18next';
import Address, { EAddressLayout } from '@/components/Address';
import useModal from '@/hooks/useModal';
import AddressForm from '@/components/MyAddresses/AddressForm';
import ICountry from '@/interfaces/ICountry';
import getCountries from '@/services/Country';
import getPostalCodes from '@/services/PostalCode';
import IAddressFormData from '@/interfaces/IAddressFormData';
import ContactForm from '@/components/MyAddresses/ContactForm';
import IContactFormData from '@/interfaces/IContactFormData';

interface ICheckoutShippingContactPage{
  userId: IUser['id'];
  shippingContact?: IShippingContact;
  onChangeShippingContact: (e: IShippingContact) => void;
}

const CheckoutShippingContactPage = ({
  userId,
  shippingContact,
  onChangeShippingContact
}: ICheckoutShippingContactPage) => {
  const { t } = useTranslation('common');
  const addressModal = useModal();
  const [loading, setLoading] = useState<boolean>();

  const [addressForm] = Form.useForm<IAddressFormData>();
  const [contactForm] = Form.useForm<IContactFormData>();
  

  const userShippingContactsState = useService<IShippingContact[]>({
    fetchData: getShippingContacts,
    params: { userId },
  });

  const countriesState = useService<ICountry[]>({
    fetchData: getCountries,
    params: {},
  });

  const onSaveAddress = async () => {
    try {
      setLoading(true);
      const addressData = await addressForm.validateFields();
      const contactData = await contactForm.validateFields();

      await createCompleteShippingContact({
        addressData: {
          extNum: addressData.extNum,
          intNum: addressData.intNum,
          postalCodeId: addressData.postalCodeId,
          street: addressData.street ,
          suburbId: addressData.suburbId,
        },
        contactData: {
          alias: contactData.alias,
          email: contactData.email,
          name: contactData.name,
          phone: contactData.phone,
          isDefault: false,
          userId: userId,
        }
      });

      message.success({
        content: t('g.done'),
        key: 'shippingContactKey',
      });

      addressForm.resetFields();
      contactForm.resetFields();
      addressModal.hide();

      userShippingContactsState.reload();

      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error'),
        key: 'shippingContactKey',
      });
      
      setLoading(false);
    }
  };
  
  const emptyList = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t('g.there_are_no_contacts')}
    />
  );

  const itemList = (item: IShippingContact) => (
    <List.Item 
      onClick={() => onChangeShippingContact(item)}
      className="cursor-pointer"
    >
      <Row align="middle" className="w-full">
        <Col span={4} >
          <Row justify="center">
            <Col>
              <Radio value={item} />
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Typography.Title ellipsis={true} level={5}>
            {item.name}
          </Typography.Title>
          <Address
            address={item.address}
            layout={EAddressLayout.Oneline}
            ellipsis={{
              expandable: false,
              rows: 2,
            }}
          />
        </Col>
      </Row>
    </List.Item>
  );

  useEffect(() => {
    userShippingContactsState.data?.forEach(shippingContact => {
      (shippingContact.isDefault) && onChangeShippingContact(shippingContact);
    });
  }, [userShippingContactsState.data]);

  if (!userShippingContactsState.data || !countriesState.data) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }  

  return (
    <Row>
      <Modal
        title={t('g.new_contact')}
        open={addressModal.visible}
        onCancel={() => addressModal.hide()}
        confirmLoading={loading}
        okText={t('g.save')}
        okButtonProps={{ className:"bg-blue-500" }}
        onOk={onSaveAddress}
      > 
        <>
          <ContactForm form={contactForm} />
          <AddressForm
            countries={countriesState.data}
            getPostalCodes={getPostalCodes}
            initialSuburbs={[]}
            name="AddressName"
            form={addressForm} />
        </>
      </Modal>
      <Col span={24}>
        <Radio.Group 
          onChange={(e) => onChangeShippingContact(e.target.value)} 
          className="w-full" 
          value={shippingContact}
        >
          <List
            bordered
            className="w-full"
            dataSource={userShippingContactsState.data}
            renderItem={itemList}
            locale={{
              emptyText: emptyList,
            }}
          />
        </Radio.Group>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Button type="link" onClick={() => addressModal.show()}>
              {t('g.add_address')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CheckoutShippingContactPage;
