import IContactInfo from '@/interfaces/IContactInfo';
import { Col, Row, Typography } from 'antd';

interface IContactProps {
  contact: IContactInfo;
}

const Contact = ({ contact }: IContactProps) => {
  return (
    <Row>
      <Col>
        <Row>
          <Typography.Text>{contact.name}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text type='secondary'>{contact.email}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text type='secondary'>{contact.phone}</Typography.Text>
        </Row>
      </Col>
    </Row>
  );
};

export default Contact;
