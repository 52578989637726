import React from 'react';
import { 
  Col, 
  Radio, 
  Result, 
  Row, 
  Space, 
  Spin, 
  Tag, 
  Typography, 
} from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import DeliveryTypeOption from '@/components/Delivery/DeliveryTypeOption';
import OrderType from '@/types/OrderType';
import IGroupDeliveryDeniedResponse from '@/interfaces/IGroupDeliveryDeniedResponse';
import IGroupDeliverySuccessResponse from '@/interfaces/IGroupDeliverySuccessResponse';
import isGroupDeliverySuccessResponse from '@/helpers/isGroupDeliverySuccessResponse';
import 'moment/locale/es';
import IGroupDeliveryShippingConfiguration from '@/interfaces/IGroupDeliveryShippingConfiguration';
import GroupShoppingCartItemsCard from '@/components/Delivery/GroupShoppingCartItemsCard';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';

type GroupDeliveryOptions = Record<
  OrderType, 
  IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>
>;

interface ICheckoutGroupDeliveriesPage {
  shippingTypeOrder: OrderType;
  onChageShippingTypeOrder :React.Dispatch<React.SetStateAction<OrderType>>
  loading: boolean;
  isSameGroupDelivery: boolean;
  hasEmptyDeliveryData: boolean;
  groupDelivery?: GroupDeliveryOptions,
  getGroupDeliveryDate: (configuration: IGroupDeliveryShippingConfiguration) => string;
  sortGroupDeliveries: (configurations: IGroupDeliveryShippingConfiguration[]) => IGroupDeliveryShippingConfiguration[];
  getShoppingCartItemsByGroup: (configuration: IGroupDeliveryShippingConfiguration) => IShoppingCartItem[];
  getLastGroupDeliveryDate: (groupDelivery: IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>) => string;
  getGroupDeliveryPrice: (groupDelivery: IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>) => number;
}


const CheckoutGroupDeliveriesPage = ({
  shippingTypeOrder,
  onChageShippingTypeOrder,
  hasEmptyDeliveryData,
  loading,
  groupDelivery,
  isSameGroupDelivery,
  getGroupDeliveryDate,
  sortGroupDeliveries,
  getShoppingCartItemsByGroup,
  getLastGroupDeliveryDate,
  getGroupDeliveryPrice,
}: ICheckoutGroupDeliveriesPage) => {
  const { t } = useTranslation('common');
  
  if (loading) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }

  return (
    <Row >
      <Col span={24}>
        <Typography.Title level={3}>
          {t('g.deliveries')}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Paragraph>
        {t('hints.select_a_delivery_option')}
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        {
          hasEmptyDeliveryData && !loading
          && (
            <Result
              status="warning"
              title={t('g.sorry')}
              subTitle={t('g.group_delivery_error')}
            />
          )
        }
        <Radio.Group 
          onChange={(e) => onChageShippingTypeOrder(e.target.value)} 
          className="w-full" 
          value={shippingTypeOrder}
        >
          <Space 
            direction="vertical" 
            size="middle"
            className="flex w-full"
          >
            {
              groupDelivery?.ASC 
              && isGroupDeliverySuccessResponse(groupDelivery?.ASC)
              && groupDelivery?.DESC 
              && isGroupDeliverySuccessResponse(groupDelivery?.DESC)
              && groupDelivery.DESC.data.length > 0
              && groupDelivery.ASC.data.length > 0
              && (
                <>
                  <DeliveryTypeOption 
                    title={`${t('g.option')} 1`}
                    qty={groupDelivery.ASC.data.length}
                    shippings={groupDelivery.ASC.data}
                    price={getGroupDeliveryPrice(groupDelivery.ASC)}
                    date={getLastGroupDeliveryDate(groupDelivery.ASC)}
                    value="ASC"
                  />
                  {
                    (!isSameGroupDelivery) && (
                      <DeliveryTypeOption 
                        title={`${t('g.option')} 2`}
                        qty={groupDelivery.DESC.data.length}
                        shippings={groupDelivery.DESC.data}
                        price={getGroupDeliveryPrice(groupDelivery.DESC)}
                        date={getLastGroupDeliveryDate(groupDelivery.DESC)}
                        value="DESC"
                      />
                    )
                  }
                  {
                    shippingTypeOrder === 'ASC' && (
                      <>
                        {
                          sortGroupDeliveries(groupDelivery.ASC.data).map((d, index) => (
                            <GroupShoppingCartItemsCard 
                              key={`group-delivery-${index}`}
                              title={
                                <Row gutter={20}>
                                  <Col>
                                    <Typography.Text>
                                      {`${t('g.delivery')} ${index + 1}`}
                                    </Typography.Text>
                                  </Col>
                                  <Col>
                                    <Tag>
                                      {i18next.language === 'en' ? d.shippingType.labelEn : d.shippingType.labelEs}
                                    </Tag>
                                  </Col>
                                </Row>
                              }
                              price={d.shippingCost}
                              date={getGroupDeliveryDate(d)}
                              shoppingcartItemsParams={getShoppingCartItemsByGroup(d)}
                              disclaimer={t('hints.recieve_it_from_date_once_your_order_is_confirmed', { date: getGroupDeliveryDate(d) })}
                            />
                          ))
                        }
                      </>
                    )
                  }
                  {
                    shippingTypeOrder === 'DESC' && (
                      <>
                        {
                          sortGroupDeliveries(groupDelivery.DESC.data).map((d, index) => (
                            <GroupShoppingCartItemsCard 
                              key={`group-delivery-${index}`}
                              title={
                                <Row gutter={20}>
                                  <Col>
                                    <Typography.Text>
                                      {`${t('g.delivery')} ${index + 1}`}
                                    </Typography.Text>
                                  </Col>
                                  <Col>
                                    <Tag>
                                      {i18next.language === 'en' ? d.shippingType.labelEn : d.shippingType.labelEs}
                                    </Tag>
                                  </Col>
                                </Row>
                              }
                              price={d.shippingCost}
                              date={getGroupDeliveryDate(d)}
                              shoppingcartItemsParams={getShoppingCartItemsByGroup(d)}
                              disclaimer={t('hints.recieve_it_from_date_once_your_order_is_confirmed', { date: getGroupDeliveryDate(d) })}
                            />
                          ))
                        }
                      </>
                    )
                  }
                </>
              )
            }
          </Space>
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default CheckoutGroupDeliveriesPage;
