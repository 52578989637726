import React from 'react';
import { useTranslation } from 'react-i18next';
import IWarehouseDeliveryPoint from '@/interfaces/IWarehouseDeliveryPoint';
import useService from '@/hooks/useService';
import { getShoppingCartDevliveryPoints } from '@/services/DeliveryPointService';
import { Col, Empty, List, Radio, Row, Spin, Typography } from 'antd';
import Address, { EAddressLayout } from '@/components/Address';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IWarehouse from '@/interfaces/IWarehouse';

interface ICheckoutPickUp {
  deliveryPointId?: IDeliveryPoint['id'];
  warehouseId: IWarehouse['id'];
  shoppingCartId: string;
  onChangeDeliveryPointId: (e: IDeliveryPoint['id']) => void;
}

const CheckoutPickUp = ({
  deliveryPointId,
  warehouseId,
  shoppingCartId,
  onChangeDeliveryPointId,
}: ICheckoutPickUp) => {
  const { t } = useTranslation('common');

  const warehouseDeliveryPointsState = useService<IWarehouseDeliveryPoint[]>({
    fetchData: getShoppingCartDevliveryPoints,
    params: { 
      warehouseId,
      shoppingCartId,
    },
  });

  const emptyList = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t('g.there_are_no_contacts')}
    />
  );

  const itemList = (item: IWarehouseDeliveryPoint) => (
    <List.Item 
      onClick={() => onChangeDeliveryPointId(item.deliveryPointId)}
      className="cursor-pointer"
    >
      <Row align="middle" className="w-full">
        <Col span={4} >
          <Row justify="center">
            <Col>
              <Radio value={item.deliveryPointId} />
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Typography.Title ellipsis={true} level={5}>
            {item.deliveryPoint.alias}
          </Typography.Title>
          <Address
            address={item.deliveryPoint.address}
            layout={EAddressLayout.Oneline}
            ellipsis
          />
        </Col>
      </Row>
    </List.Item>
  );

  if (!warehouseDeliveryPointsState.data) {
    return (
      <Row justify="center">
        <Col>
          <Spin size="large" className="m-4" />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Radio.Group 
          onChange={(e) => onChangeDeliveryPointId(e.target.value)} 
          className="w-full" 
          value={deliveryPointId}
        >
          <List
            bordered
            className="w-full"
            dataSource={warehouseDeliveryPointsState.data}
            renderItem={itemList}
            locale={{
              emptyText: emptyList,
            }}
          />
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default CheckoutPickUp;
