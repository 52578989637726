import React from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoMenuImage } from '@/assets/images';

const ContactAssociateThankYou = () => {
  const logoSource = process.env.REACT_APP_LOGO_MENU || LogoMenuImage;
  const { t } = useTranslation('common');
  
  return (
    <Card 
      className="h-full" 
      bordered={false}
      style={{
        minHeight: '80vh',
      }}
      bodyStyle={{
        height: '80vh'
      }}
    >
      <Row 
        gutter={[0, 10]} 
        justify="center" 
        align="middle" 
        style={{ 
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Col>
          <Image 
            alt='thank you image' 
            src={logoSource}
            preview={false}
            style={{
              borderRadius: '50%',
              width: 'auto', 
              maxHeight: 150
            }}
          />
        </Col>
        <Col>
          <Typography.Title className='text-primary'>
            {`¡${t('g.thank_you_so_much')}!`}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Text>
            {t('g.an_agent_will_contact_you_shortly_to_continue_with_your_process')}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default ContactAssociateThankYou;
