import React, { useEffect, useState } from 'react';
import { 
  CarOutlined,
  ShopOutlined, 
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { 
  Button, Card, Col, Form, 
  message, Radio, Row, Spin, 
  Typography, 
} from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import CheckoutContactPage from '@/containers/views/Checkout/CheckoutContactPage';
import CheckoutDeliveryPage from '@/containers/views/Checkout/CheckoutDeliveryPage';
import CheckoutGroupPickUpPage from '@/containers/views/Checkout/CheckoutGroupPickUpPage';
import CheckoutGroupDeliveriesPage from '@/containers/views/Checkout/CheckoutGroupDeliveriesPage';
import CheckoutPaymentServicePage from '@/containers/views/Checkout/CheckoutPaymentServicePage';
import EDeliveryType from '@/enums/EDeliveryType';
import ECheckoutStep from '@/enums/ECheckoutStep';
import IContactFormData from '@/interfaces/IContactFormData';
import IAddressFormData from '@/interfaces/IAddressFormData';
import IShippingContact from '@/interfaces/IShippingContact';
import IDeliveryPoint from '@/interfaces/IDeliveryPoint';
import IPaymentService from '@/interfaces/IPaymentService';
import IPreCheckout from '@/interfaces/IPreCheckout';
import useAuth from '@/hooks/useAuth';
import useLocation from '@/hooks/useLocation';
import CheckoutPayPage from './CheckoutPayPage';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import useShoppingCartItemsList from '@/hooks/useShoppingCartItemsList';
import ShoppingCartDrawer from '@/components/ShoppingCart/ShoppingCartDrawer';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';
import { checkout } from '@/services/CheckoutService';
import validatePreCheckoutData from '@/helpers/validatePreCheckoutData';
import { useNavigate } from 'react-router-dom';
import useShoppingCart from '@/hooks/useShoppingCart';
import { IOnPay } from './CheckoutPOS';
import useGroupDeliveries from '@/hooks/useGroupDeliveries';
import { BreakpointMap } from 'antd/lib/_util/responsiveObserve';
import styled from 'styled-components';
import ICheckout from '@/interfaces/ICheckout';

const RadioGroupPrimary = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    height: auto;
    width: 275px;
    padding: 3px;
    border-radius: 3px;

    .radio-group-label {
      color: inherit;
      display: flex;
      align-items: center;

      .anticon {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    &:hover .radio-group-label {
      text-decoration: underline;
    }
  }
`;

const CheckoutPage = () => {
  const { t } = useTranslation('common');
  const { authenticated, customer } = useAuth();
  const { selectedWarehouse } = useLocation();
  const navigate = useNavigate();

  const { shoppingCart } = useShoppingCart();

  const { 
    active,
    productsPrice,
    shippingPrice,
    totalDiscount,
    setActive, 
    setTotalDiscount,
    setProductsPrice,
    setShippingPrice
  } = useCheckoutDrawer();

  const {
    loadingCart,
    shoppingCartItems,
    totalPrice,
  } = useShoppingCartItemsList();

  const { 
    Contact, 
    Delivery, 
    Loading, 
    GroupDeliveries,
    GroupPickUps,
    PaymentService,
    CheckoutPay
  } = ECheckoutStep;

  const [step, setStep] = useState<ECheckoutStep>(Loading);
  const [couponCode, setCouponCode] = useState<string>('');
  const [checkoutData, setCheckoutData] = useState<Partial<IPreCheckout>>();
  const [deliveryType, setDeliveryType] = useState<EDeliveryType>(EDeliveryType.Shipping);
  const [shippingContact, setShippingContact] = useState<IShippingContact>();
  const [deliveryPointId, setDeliveryPointId] = useState<IDeliveryPoint['id']>();
  const [paymentServiceCode, setPaymentServiceCode] = useState<IPaymentService['code']>();
  const [paymentStrategy, setPaymentStrategy] = useState<SegmentedValue>(EPaymentStrategy.Card);
  const [checkoutBreakpoints, setCheckoutBreakpoints] = useState<BreakpointMap>({
    xs: 'auto',
    sm: 'auto',
    md: 'auto',
    lg: 'auto',
    xl: 'auto',
    xxl: 'auto',
  });

  const deliveryGroupsHook = useGroupDeliveries({
    shoppingCartItems: shoppingCartItems ?? undefined,
    destAddress: checkoutData?.destAddress ?? undefined,
    postalCode: checkoutData?.destAddress?.postalCode ?? undefined,
    warehouseId: selectedWarehouse ?? undefined,
  });

  const [addressForm] = Form.useForm<IAddressFormData>();
  const [contactForm] = Form.useForm<IContactFormData>();

  const onSavePartialCheckoutData = (data: Partial<IPreCheckout>) => {
    setCheckoutData(prev => ({ ...prev, ...data }));
  };

  const goToDeliveries = async () => {
    const values = await contactForm.validateFields();
    const { email, name, phone } = values;

    onSavePartialCheckoutData({ customer: { email, name, phone } });
    setStep(Delivery);
  };

  const goToGroupDeliveriesNotAuth = async () => {
    const values = await addressForm.validateFields();
    const { street, extNum, intNum, postalCode, suburbId } = values;

    onSavePartialCheckoutData({ 
      destAddress: { 
        phone: checkoutData?.customer?.phone ?? '',
        street, 
        extNum, 
        intNum, 
        postalCode, 
        suburbId, 
      },
      deliveryType: EDeliveryType.Shipping, 
    });
    setStep(GroupDeliveries);
  };

  const goToGroupDeliveriesAuth = async () => {
    if (!shippingContact) {
      message.error({
        content: t('g.select_shipping_contact'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    }

    if (!customer) {
      message.error({
        content: t('g.not_auth'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    };

    const { address, phone } = shippingContact;

    onSavePartialCheckoutData({ 
      customer: customer,
      destAddress: {
        phone,
        street: address.street,
        extNum: address.extNum,
        intNum: address.intNum,
        postalCode: address.postalCode.code,
        suburbId: address.suburb.id,
      },
      deliveryType: EDeliveryType.Shipping,
    });
    setStep(GroupDeliveries);
  };

  const goToGroupPickUpNotAuth = async () => {
    if (!deliveryPointId) {
      message.error({
        content: t('g.select_delivery_point'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    };

    onSavePartialCheckoutData({ 
      deliveryPointId,
      deliveryType: EDeliveryType.PickUp,
    });
    setStep(GroupPickUps);
  };

  const goToGroupPickUpAuth = async () => {
    if (!customer) {
      message.error({
        content: t('g.not_auth'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    };

    if (!deliveryPointId) {
      message.error({
        content: t('g.select_delivery_point'),
        duration: 2,
        key: 'CheckoutKey',
      });

      return;
    };

    onSavePartialCheckoutData({ 
      customer: customer,
      deliveryPointId,
      deliveryType: EDeliveryType.PickUp,
    });
    setStep(GroupPickUps);
  };

  const onPay = async (props: IOnPay) => {
    if (!checkoutData) return;

    const vaidatePreCheckoutData = validatePreCheckoutData(checkoutData);

    const totalAmount = parseFloat((productsPrice + shippingPrice - totalDiscount).toFixed(2));

    const { tokeId = '', deviceSessionId = '' } = props;
    const checkoutReq: ICheckout = {
      amount: totalAmount,
      couponCode: totalDiscount > 0 ? couponCode : undefined,
      token: tokeId,
      deviceSessionId,
      paymentStrategy,
      ...vaidatePreCheckoutData,
    };
    const { success, orderId, url } = await checkout(checkoutReq);

    if (!success || !orderId) {
      message.error({
        content: 'La tarjeta fue rechazada, por favor inténtelo de nuevo más tarde.',
        duration: 6,
        key: 'CheckoutKey',
      });
      return;
    }

    if (url) {
      window.location.href = url;
      return;
    }

    navigate(`/orders/details/${orderId}`);
  };

  const onPayError = async (error: IOpenpayTokenError) => {
    console.error(error);

    message.error({
      content: t('g.error'),
      duration: 2,
      key: 'CheckoutKey',
    });

  };

  const continueStep = async () => {
    // continue with deliveries
    if (step === Contact && !authenticated) {
      await goToDeliveries();
    }

    // continue with group deliveries not auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.Shipping
      && !authenticated
    ) {
      await goToGroupDeliveriesNotAuth();
    }

    // continue with group deliveries auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.Shipping
      && authenticated
    ) {
      await goToGroupDeliveriesAuth();
    }

    // continue with group pick up not auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.PickUp
      && !authenticated
    ) {
      goToGroupPickUpNotAuth();
    }

    // continue with group pick up auth
    if (
      step === Delivery 
      && deliveryType === EDeliveryType.PickUp
      && authenticated
    ) {
      goToGroupPickUpAuth();
    }

    // continue with payment service
    if (step === GroupPickUps) {
      setStep(PaymentService);
    }

    // continue with payment service
    if (step === GroupDeliveries) {
      if (deliveryGroupsHook.hasEmptyDeliveryData) {
        return;
      }
      onSavePartialCheckoutData({ shippingTypeOrder: deliveryGroupsHook.shippingTypeOrder });
      setStep(PaymentService);
    }

    if (step === PaymentService) {
      if (!paymentServiceCode) {
        message.error({
          content: t('g.select_payment_service'),
          duration: 2,
          key: 'CheckoutKey',
        });

        return;
      }

      onSavePartialCheckoutData({ paymentServiceCode });
      setStep(CheckoutPay);
    }
  };

  const previousStep = () => {
    if (step === Contact && !authenticated) return navigate('/shopping_cart');
    if (step === Delivery && authenticated) return navigate('/shopping_cart');
    if (step === Delivery && !authenticated) setStep(Contact);
    if (step === GroupDeliveries || step === GroupPickUps) {
      setStep(Delivery);
      setShippingPrice(0);
    };
    if (step === PaymentService && deliveryType === EDeliveryType.Shipping) setStep(GroupDeliveries);
    if (step === PaymentService && deliveryType === EDeliveryType.PickUp) setStep(GroupPickUps);
  };

  const continueText = () => {
    if (
      step === Contact 
      || step === Delivery
      || step === GroupDeliveries
      || step === GroupPickUps
    ) return t('g.continue');
    
    if (step === PaymentService) return t('g.go_to_pay')
  };

  const previousText = () => {
    if (step === Contact && !authenticated) return t('g.back_to_cart');
    if (step === Delivery && authenticated) return t('g.back_to_cart');
    if (step === Delivery && !authenticated) return t('g.back_to_contact');
    if (step === GroupDeliveries) return t('g.back_to_delivery_types');
    if (step === GroupPickUps) return t('g.back_to_delivery_points');
    if (
      step === PaymentService
      && deliveryType === EDeliveryType.Shipping
    ) return t('g.back');
    if (
      step === PaymentService
      && deliveryType === EDeliveryType.PickUp
    ) return t('g.back');
  };

  const getFirstPage = () => {
    if (authenticated) {
      // getUserContact in useAuth
      setStep(Delivery);
    }
    if (!authenticated) {
      setStep(Contact);
    }
  };

  useEffect(() => {
    getFirstPage();
    // eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    if (loadingCart) {
      setStep(Loading);
      return;
    }

    getFirstPage();
    // eslint-disable-next-line
  }, [loadingCart]);

  useEffect(() => {
    setProductsPrice(totalPrice);

    // eslint-disable-next-line
  }, [totalPrice]);


  useEffect(() => {
    if (!selectedWarehouse) {
      onSavePartialCheckoutData({ 
        ...checkoutData,
        warehouseId: undefined,
      });

      return;
    }

    onSavePartialCheckoutData({ 
      ...checkoutData,
      warehouseId: selectedWarehouse,
    });
    // eslint-disable-next-line
  }, [selectedWarehouse]);

  useEffect(() => {
    setShippingPrice(0);
    setTotalDiscount(0);
    setCouponCode('');
  }, []);

  useEffect(() => {
    if (!shoppingCart) {
      onSavePartialCheckoutData({
        ...checkoutData,
        shoppingCartId: undefined
      });

      return;
    }

    onSavePartialCheckoutData({
      ...checkoutData,
      shoppingCartId: shoppingCart.id
    });
  }, [shoppingCart]);

  useEffect(() => {
    switch (step) {
      case Contact:  
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
      case Delivery:  
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'});
      break;
      case Loading:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
      case GroupDeliveries:
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'});
      break;
      case GroupPickUps:
        setCheckoutBreakpoints({ xs: '24', sm: '24', md: '22', lg: '20', xl: '20', xxl: '20'}); 
      break;
      case PaymentService:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});  
      break;
      case CheckoutPay:
        setCheckoutBreakpoints({ xs: '24', sm: '20', md: '16', lg: '14', xl: '12', xxl: '12'});
      break;
    }
  }, [step]);

  useEffect(() => {
    if(step !== ECheckoutStep.GroupDeliveries && step !== ECheckoutStep.GroupPickUps) return;

    setStep(ECheckoutStep.Delivery);
  }, [deliveryType]);

  const deliveryMethodOptions = [
    {
      label: (
        <Typography.Text className="radio-group-label">
          <CarOutlined /> {t('g.home_delivery')}
        </Typography.Text>
      ),
      value: EDeliveryType.Shipping,
    },
    {
      label: (
        <Typography.Text className="radio-group-label">
          <ShopOutlined /> {t('g.pick_up')}
        </Typography.Text>
      ),
      value: EDeliveryType.PickUp,
    }
  ];

  return (
    <Card className="h-full" bordered={false}>
      <ShoppingCartDrawer 
        active={active}
        onClose={() => setActive(false)}
        shoppingCartItems={shoppingCartItems}
        subtotal={productsPrice}
        shippingTotal={shippingPrice}
        discount={totalDiscount}
      />
      <Row gutter={[0, 20]} justify="center">
        <Col 
          xs={{ span: checkoutBreakpoints.xs }}
          sm={{ span: checkoutBreakpoints.sm }}
          md={{ span: checkoutBreakpoints.md }}
          lg={{ span: checkoutBreakpoints.lg }}
          xl={{ span: checkoutBreakpoints.xl }}
          xxl={{ span: checkoutBreakpoints.xxl }}
        >
          {
            step === Loading && (
              <Row justify="center">
                <Col>
                  <Spin size="large" className="m-4" />
                </Col>
              </Row>
            )
          }
          {
            step === Contact && (
              
              <CheckoutContactPage 
                contactForm={contactForm}
              />
            )
          }
          {
            (
              (
                step === Delivery
                && selectedWarehouse
              )
              || (
                step === GroupDeliveries
                && shoppingCartItems
                && checkoutData?.destAddress
                && selectedWarehouse
              )
              || (
                step === GroupPickUps
                && shoppingCartItems
                && selectedWarehouse
                && deliveryPointId
              )
            ) && (
              <Row>
                <Col span={24} className="text-center">
                  <Typography.Title level={3} className="underline">
                    {t('g.merchandise_delivery')}
                  </Typography.Title>
                  <Typography.Paragraph>
                    {t('hints.select_a_delivery_method')}
                  </Typography.Paragraph>
                </Col>
                <Col span={24} className="text-center">
                  <RadioGroupPrimary
                    value={deliveryType} 
                    onChange={(e: any) => setDeliveryType(e.target.value)} 
                    optionType="button"
                    options={deliveryMethodOptions} 
                    size="large"
                    buttonStyle="solid"
                    style={{ padding: 10 }}
                  />
                </Col>
              </Row>
            )
          }
          {
            (
              step === Delivery
              && selectedWarehouse
              && shoppingCart
            ) && (
              <CheckoutDeliveryPage
                shoppingCartId={shoppingCart.id}
                userId={customer?.id} 
                addressForm={addressForm}
                deliveryType={deliveryType}
                shippingContact={shippingContact}
                deliveryPointId={deliveryPointId}
                onChangeShippingContact={setShippingContact}
                onChangeDeliveryPointId={setDeliveryPointId}
                warehouseId={selectedWarehouse}
              />
            )
          }
          {
            (
              step === GroupDeliveries
              && shoppingCartItems
              && checkoutData?.destAddress
              && selectedWarehouse
            ) && (
              <CheckoutGroupDeliveriesPage 
                groupDelivery={deliveryGroupsHook.groupDelivery}
                hasEmptyDeliveryData={deliveryGroupsHook.hasEmptyDeliveryData}
                isSameGroupDelivery={deliveryGroupsHook.isSameGroupDelivery}
                getGroupDeliveryDate={deliveryGroupsHook.getGroupDeliveryDate}
                sortGroupDeliveries={deliveryGroupsHook.sortGroupDeliveries}
                getShoppingCartItemsByGroup={deliveryGroupsHook.getShoppingCartItemsByGroup}
                getLastGroupDeliveryDate={deliveryGroupsHook.getLastGroupDeliveryDate}
                getGroupDeliveryPrice={deliveryGroupsHook.getGroupDeliveryPrice}
                loading={deliveryGroupsHook.loading}
                shippingTypeOrder={deliveryGroupsHook.shippingTypeOrder}
                onChageShippingTypeOrder={deliveryGroupsHook.setShippingTypeOrder}
              />
            )
          }
          {
            (
              step === GroupPickUps
              && shoppingCartItems
              && selectedWarehouse
              && deliveryPointId
            ) && (
              <CheckoutGroupPickUpPage 
                groupPickUpRequest={{
                  deliveryPointId,
                  shoppingCartItems: shoppingCartItems,
                  warehouseId: selectedWarehouse,
                }}
              />
            )
          }
          {
            (
              step === PaymentService
              && selectedWarehouse
            ) && (
              <CheckoutPaymentServicePage 
                warehouseId={selectedWarehouse}
                paymentServiceId={paymentServiceCode}
                onChangePaymentServiceId={setPaymentServiceCode}
              />
            )
          }
          {
            (
              step === CheckoutPay
              && selectedWarehouse
              && paymentServiceCode
            ) && (
              <CheckoutPayPage 
                warehouseId={selectedWarehouse}
                paymentServiceCode={paymentServiceCode}
                paymentStrategy={paymentStrategy}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                setPaymentStrategy={setPaymentStrategy}
                onPay={onPay}
                onPayError={onPayError}
                onClickAlert={() => setStep(ECheckoutStep.PaymentService)}
              />
            )
          }
          {
            step !== CheckoutPay && (
              <Row style={{ marginTop: '20px' }} gutter={[0, 24]}>
                <Col sm={{ span: 12, order: 1}} xs={{ span: 24, order: 2}}>
                  <Button type="text" className="text-button" onClick={previousStep}>
                    {previousText()}
                  </Button>
                </Col>
                <Col sm={{ span: 12, order: 2}} xs={{ span: 24, order: 1}}>
                  <Button 
                    onClick={continueStep}
                    disabled={(step === GroupDeliveries && (deliveryGroupsHook.hasEmptyDeliveryData || deliveryGroupsHook.loading))}
                    type="primary" 
                    className="primary-button"
                  >
                    {continueText()} 
                  </Button>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </Card>
  );
};

export default CheckoutPage;
