import React from 'react';
import { Col, Row, Typography, Image } from 'antd';
import { Link } from 'react-router-dom';
import CollapseRow from '@/components/General/CollapseRow';
import { useTranslation } from 'react-i18next';
import formatCurrencyMX from '@/utils/formatCurrency';
import { LogoMenuImage } from '@/assets/images';

interface ICheckoutTopbar {
  active: boolean,
  onClick: () => void,
  price?: number;
  loading?: boolean;
}

const CheckoutTopbar = ({
  active,
  onClick,
  price,
  loading,
}: ICheckoutTopbar) => {
  const {t} = useTranslation('common');

  const logoSource = process.env.REACT_APP_LOGO_MENU || LogoMenuImage;

  return (
    <Row gutter={[0, 15]} className="my-4">
      <Col sm={8}>
        <Link to="/" className="center_children">
          <Image style={{ width: 'auto', maxHeight: 40 }} preview={false} src={logoSource} />
        </Link>
      </Col>
      <Col sm={16} className="text-white">
        <CollapseRow 
          active={active}
          onClick={onClick}
          primaryText={t('g.show_details')}
          secundaryText={t('g.hidden_details')}
          extra={
            <>
              <Typography.Text style={{ color: '#fff', marginRight: 5 }}>
                {t('checkout.amount_total')}:
              </Typography.Text>
              <Typography.Text style={{ color: '#fff', fontSize: '1.5rem' }}>
                {price ? formatCurrencyMX(price) : t('g.pending_price')}
              </Typography.Text>
            </>
          }
        />
      </Col>
    </Row>
  );
};

export default CheckoutTopbar;
