import { adapterDtoToMix } from '@/adapters/MixAdapter';
import { adapterDtoToProduct } from '@/adapters/ProductAdapter';
import { adapterDtoToVariant } from '@/adapters/VariantAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import IMix from '@/interfaces/IMix';
import IMixDto from '@/interfaces/IMixDto';
import IPaginatedResult from '@/interfaces/IPaginatedResult';
import IProduct from '@/interfaces/IProduct';
import IProductDto from '@/interfaces/IProductDto';
import IVariant from '@/interfaces/IVariant';
import IVariantDto from '@/interfaces/IVariantDto';

export interface FilteredItemsParams {
  "search"?: string,
  "attrs"?: string[],
  "sortByPriority"?:  "DESC" | "ASC",
  "sortByName"?:  "DESC" | "ASC",
  "categoryIds"?: string[],
  "warehouseId"?: string,
  "isOutstanding"?: boolean,
  "skip": number,
  "limit": number
}

interface FilteredItemsResponse {
  type: 'product' | 'variant' | 'mix';
  calculatedCost?: number;
  isProductWithShippingType?: boolean;
  isProductWithDeliveryPoint?: boolean;
  item: IProductDto | IVariantDto | IMixDto;
}

export interface ProductExtra extends IProduct {
  calculatedCost?: number;
  isProductWithShippingType?: boolean;
  isProductWithDeliveryPoint?: boolean;
}

export interface VariantExtra extends IVariant {
  calculatedCost?: number;
  isProductWithShippingType?: boolean;
  isProductWithDeliveryPoint?: boolean;
}

export interface MixExtra extends IMix {
  calculatedCost?: number;
  isProductWithShippingType?: boolean;
  isProductWithDeliveryPoint?: boolean;
}

export type ItemExtra = (
  ProductExtra | VariantExtra | MixExtra
);

export const getFilteredItems = async (filters: FilteredItemsParams): Promise<IPaginatedResult<(IProduct | IVariant | IMix)>> => {
  const result = await axiosApiConfig.post<IPaginatedResult<FilteredItemsResponse>>(`/get_filtered_items`,filters);

  const resultData: (IProduct | IVariant | IMix)[]= result.data.data.map(i=> {
    if(i.type ==='product'){
      const adaptedItem = adapterDtoToProduct(i.item as IProductDto);
      return {
        calculatedCost: i.calculatedCost,
        isProductWithShippingType: i.isProductWithShippingType,
        isProductWithDeliveryPoint: i.isProductWithDeliveryPoint,
        ...adaptedItem}
    }
    if(i.type ==='variant'){
      const adaptedItem = adapterDtoToVariant(i.item as IVariantDto);
      return {
        calculatedCost: i.calculatedCost,
        isProductWithShippingType: i.isProductWithShippingType,
        isProductWithDeliveryPoint: i.isProductWithDeliveryPoint,
        ...adaptedItem}
    }
    const adaptedItem = adapterDtoToMix(i.item as IMixDto);
    return {
      calculatedCost: i.calculatedCost,
      isProductWithShippingType: i.isProductWithShippingType,
      isProductWithDeliveryPoint: i.isProductWithDeliveryPoint,
      ...adaptedItem}
  })
  
  const resultPaginated: IPaginatedResult<(IProduct | IVariant | IMix)> = {
    data: resultData,
    limit: result.data.limit,
    skip: result.data.skip,
    total: result.data.total,
  }

  return resultPaginated;
};