import {
  Row, 
  Col,
  PageHeader,
  Skeleton,
  Typography,
  Segmented,
} from 'antd';
import { useTranslation } from 'react-i18next';
import MyOrders from '@containers/views/MyOrders/MyOrders';
import MyOrdersFilters from '@containers/views/MyOrders/MyOrdersFilters';
import OrderDeliveries from './OrderDeliveries';
import { LeftOutlined, MenuOutlined, PicCenterOutlined } from '@ant-design/icons';
import NotAuthenticatedSign from '@/components/General/NotAuthenticatedSign';
import useAuth from '@/hooks/useAuth';
import { useState } from 'react';
import OrdersList from './OrdersList';

const { Text } = Typography;

enum EOrdersLayouts{
  Simple = 'simple',
  Detailed = 'detailed',
};

const MyOrdersPage = () => {
  const auth = useAuth();
  const { t } = useTranslation('common');

  const [ordersLayout, setOrdersLayout] = useState<EOrdersLayouts>(EOrdersLayouts.Simple);

  const customerId = auth.customer?.id;

  if(!customerId){
    return (
      <Row className="p-4">
        <Col span={24}>
          <NotAuthenticatedSign />
        </Col>
      </Row>
    );
  }
  
  const {
    isLoading,
    myOrders,
    listParams,
    setListParams,
    goBack,
  } = MyOrders(customerId);

  const MyOrdersTitle = () => (
    <Text className="text-primary">
      {t('g.my_orders')}
    </Text>
  );

  const GoBackIcon = () => (
    <LeftOutlined className="text-primary" />
  );

  return (
    <Row 
      className="p-4"
      gutter={[0, 16]}
    >
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <PageHeader 
              className="p-0"
              title={<MyOrdersTitle />} 
              backIcon={<GoBackIcon />}
              onBack={goBack}
            />
          </Col>
          <Col>
            <Segmented 
              size="large"
              value={ordersLayout}
              onChange={option => setOrdersLayout(option as EOrdersLayouts)}
              options={[
                {value: EOrdersLayouts.Simple, icon: <MenuOutlined />},
                {value: EOrdersLayouts.Detailed, icon: <PicCenterOutlined /> },
              ]}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <MyOrdersFilters 
          listParams={listParams} 
          setListParams={setListParams} 
        />
      </Col>
      <Col span={24}>
        {isLoading ? (
          <div>
            <Skeleton active className='my-2'/>
            <Skeleton active className='my-2'/>
            <Skeleton active className='my-2'/>
          </div>
        ): (
          ordersLayout === EOrdersLayouts.Simple ? (
            <OrdersList orders={myOrders} />
          ) : (
            <OrderDeliveries orders={myOrders} />
          )
        )}
      </Col>
    </Row>
  );
};

export default MyOrdersPage;
