import IWarehouse from '@/interfaces/IWarehouse';
import { Button, Col, Input, Row, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WarehouseList from './WarehouseList';

interface ISelectWarehouseProps {
  initialValue?: IWarehouse['id'];
  options: IWarehouse[];
  loading: boolean;
  onChangePostalCode: (postalCode: string) => void;
  onChangeWarehouse: (warehouseId: IWarehouse['id']) => void;
}

const SelectWarehouse = ({
  initialValue,
  options,
  loading,
  onChangePostalCode,
  onChangeWarehouse,
}: ISelectWarehouseProps) => {
  const { t } = useTranslation('common');

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');

  function onClickWarehouse(warehouseId: IWarehouse['id']){
    onChangeWarehouse(warehouseId);
    setCurrentSearchTerm('');
  }

  return (
    <Row justify='center'>
      <Col span={24}>
        <Row justify='center' gutter={[8, 16]}>
          <Col>
            <Input
              size="large"
              value={currentSearchTerm}
              onChange={(e) => setCurrentSearchTerm(e.target.value)}
              onPressEnter={() => onChangePostalCode(currentSearchTerm)}
            />
          </Col>
          <Col>
            <Button
              size="large"
              loading={loading}
              onClick={() => onChangePostalCode(currentSearchTerm)}
              className="bg-blue-500"
              type='primary'
            >
              {t('g.confirm')}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify='center' className='mt-2 mb-2'>
          <Col span={24}>
            <WarehouseList
              initialValue={initialValue}
              onClick={onClickWarehouse}
              warehouses={options}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Text type="secondary">
          *{t('g.the_prices_availability_and_delivery_speed_may_vary_according_to_the_store')}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default SelectWarehouse;
