import axiosApiConfig from '@/config/axiosConfig';
import ICountry from '@/interfaces/ICountry';
import IParams from '@/interfaces/IParams';

const formatCountry = (country: ICountry): ICountry => {
  return {
    code: country.code,
    id: country.id.toString(),
    name: country.name,
  };
};

const getCountries = async (params?: IParams): Promise<ICountry[]> => {
  const result = await axiosApiConfig.get<ICountry[]>('/countries', {
    params: { ...params },
  });
  const finalData: ICountry[] = result.data.map(formatCountry);

  return finalData;
};

export const createCountry = async (
  data: Omit<ICountry, 'id'>
): Promise<ICountry> => {
  const result = await axiosApiConfig.post<ICountry>('/countries/', data);
  return result.data;
};

export const editCountry = async (
  data: Partial<ICountry>
): Promise<ICountry> => {
  const result = await axiosApiConfig.patch<ICountry>(
    `/countries/${data.id}`,
    data
  );
  return result.data;
};

export default getCountries;
