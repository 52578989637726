import EOrderStatus from '@/enums/EOrderStatus';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

interface IOrderDetailsCardProps {
  status: EOrderStatus;
}

const OrderStatusTag = ({ status }: IOrderDetailsCardProps) => {
  const { t } = useTranslation('common');
  let color = 'default'

  if (status === EOrderStatus.Placed) {
    color = 'processing'
  }
  else if (status === EOrderStatus.Confirmed) {
    color = 'success'
  }
  else if (status === EOrderStatus.Done) {
    color = 'default'
  }
  else if (status === EOrderStatus.Canceled) {
    color = 'error'
  }

  return (
    <Tag color={color}>{t(`order.status.${status}`)}</Tag>
  );
};

export default OrderStatusTag;
