import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { ConfigProvider } from 'antd';
import es from 'antd/es/locale/es_ES';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'antd/dist/antd.min.css';
import { ScrollProvider } from '@/hooks/useScroll';
import { AuthProvider } from '@/hooks/useAuth';
import { config as i18nextConfig  } from '@config/translationsConfig';
import { LocationProvider } from '@/hooks/useLocation';
import { CheckoutDrawerProvider } from '@/hooks/useCheckoutDrawer';
import { ShoppingCartProvider } from '@/hooks/useShoppingCart';
import { FilterProvider } from '@/hooks/useFilter';

i18n.init(i18nextConfig);

function App() {
  return (
    <ConfigProvider locale={es}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <LocationProvider>
            <ShoppingCartProvider>
              <ScrollProvider>
                <BrowserRouter>
                  <CheckoutDrawerProvider>
                    <FilterProvider>
                      <Router />
                    </FilterProvider>
                  </CheckoutDrawerProvider>
                </BrowserRouter>
              </ScrollProvider>
            </ShoppingCartProvider>
          </LocationProvider>
        </AuthProvider>
      </I18nextProvider>
    </ConfigProvider>
  );
}

export default App;
