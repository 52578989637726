import IAddress from '@/interfaces/IAddress';
import { Typography } from 'antd';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;

export enum EAddressLayout {
  Oneline = 'oneline',
  Multi = 'multiline',
}

interface IAddressProps {
  address?: IAddress;
  layout?: EAddressLayout;
  fallback?: React.ReactNode;
  ellipsis?: boolean | EllipsisConfig;
  className?: string;
  style?: CSSProperties;
}

const Address = ({
  address,
  layout = EAddressLayout.Oneline,
  fallback,
  ellipsis = false,
  style = {},
  className,
}: IAddressProps) => {
  const { t } = useTranslation('common');

  function getContent() {
    if (!address && fallback) return fallback;

    if (!address) return <Text>{t('g.address_not_available')}</Text>;

    switch (layout) {
      case EAddressLayout.Oneline:
        return (
          <Text>
            {`${address.suburb.name}, ${address.street} ${address.extNum}, ${address.postalCode.city.state.name}, ${address.postalCode.city.name}`}
          </Text>
        );
      case EAddressLayout.Multi:
        const inheritProps = { color: 'inherit' };

        return (
          <>
            <Text style={inheritProps}>
              {`${address.street} ${address.extNum}, ${address.suburb.name}`}
            </Text>
            <Text style={inheritProps}>
              {`${address.postalCode.city.state.name}, ${address.postalCode.city.name}`}
            </Text>
            <Text style={inheritProps}>
              {`${t('g.postal_code')}: ${address.postalCode.code}`}
            </Text>
          </>
        );
    }
  }

  const content = getContent();
  const display = layout === EAddressLayout.Multi ? 'grid' : '';

  return (
    <Paragraph
      ellipsis={ellipsis}
      style={{ display, ...style }}
      className={className}
    >
      {content}
    </Paragraph>
  );
};

export default Address;
