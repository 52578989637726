import IContactInfo from '@/interfaces/IContactInfo';
import IDelivery from '@/interfaces/IDelivery';
import { Col, List, Row, Typography } from 'antd';
import DeliveryCard from './DeliveryCard';

interface IDeliveriesListProps {
  title?: string;
  deliveries: IDelivery[];
  contactInfo: IContactInfo;
}

const DeliveriesList = ({
  contactInfo,
  deliveries,
  title,
}: IDeliveriesListProps) => {
  return (
    <Row>
      <Col span={24}>
        {title && <Typography.Title>{title}</Typography.Title>}
        <List
          bordered={false}
          dataSource={deliveries}
          renderItem={(d) => (
            <Row className="mb-8">
              <Col span={24}>
                <DeliveryCard contactInfo={contactInfo} delivery={d} />
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default DeliveriesList;
