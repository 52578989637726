import React from 'react';
import { Tag, message } from 'antd';
import EShoppingCartStatus from '../../enums/EShoppingCartStatus';
import { useTranslation } from 'react-i18next';

interface IShoppingCartStatusTag {
  status: EShoppingCartStatus
}

const ShoppingCartStatusTag = ({ status }: IShoppingCartStatusTag) => {
  const { t } = useTranslation('common');
  const { Active, Bought, Expired, Moved } = EShoppingCartStatus;

  let color = '';
  let message = '';

  if (status === Active) {
    color = 'green';
    message = t('g.active');
  }

  if (status === Bought) {
    color = 'blue';
    message = t('g.bought');
  }

  if (status === Expired) {
    color = 'volcano';
    message = t('g.expired');
  }

  if (status === Moved) {
    color = 'cyan';
    message = t('g.moved');
  }

  return (
    <Tag color={color}>{message}</Tag>
  );
};

export default ShoppingCartStatusTag;
