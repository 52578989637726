import moment from 'moment';
import { Moment } from 'moment';

function equalizeDate(originalDate: Moment, referenceDate: Moment): Moment {
  const dateEqualized: Moment = moment(referenceDate);
  const originalDateMoment: Moment = moment(originalDate);

  dateEqualized.hour(originalDateMoment.hour());
  dateEqualized.minute(originalDateMoment.minute());
  dateEqualized.second(originalDateMoment.second());
  dateEqualized.millisecond(originalDateMoment.millisecond());

  return dateEqualized;
}

export default equalizeDate;
