import IDeliveryProduct from '@/interfaces/IDeliveryProduct';
import IDeliveryProductDto from '@/interfaces/IDeliveryProductDto';
import { adapterDtoToProduct } from './ProductAdapter';

export const adapterDtoToDeliveryProduct = (deliveryProduct: IDeliveryProductDto): IDeliveryProduct => {
  const { 
    qty,
    price,
    shoppingCartItemId,
  } = deliveryProduct;
  
  const productDto = deliveryProduct.product

  const product = productDto ? adapterDtoToProduct(productDto) : null;

  return {
    qty,
    product,
    price,
    shoppingCartItemId,
  };
};
