import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import IMix from '@/interfaces/IMix';

const isMix = (
  item: IProduct | IVariant | IMix
): item is IMix => {
 return (item as IMix).key === 'mix';
};

export default isMix;
