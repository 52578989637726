import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { 
  Form, Row, Col, Input, 
  FormInstance, DatePicker, Checkbox, 
} from 'antd';
import ICustomer from '@/interfaces/ICustomer';
import Optional from '@/types/Optional';
import { useTranslation } from 'react-i18next';
import DebounceSelect from '@/components/General/DebounceSelect';
import { getUsers } from '../../services/User';
import moment from 'moment';

export interface IShoppingCartData {
  alias: Optional<string>;
  userId: ICustomer['id'];
  expDate: Moment;
}

interface IShoppingCartForm {
  form: FormInstance<IShoppingCartData>
}

const ShoppingCartForm = ({
  form,
}: IShoppingCartForm) => {
  const { t } = useTranslation('common');
  const [isGuest, setIsGuest] = useState<boolean>(true);
  const tomorrow = moment().startOf('day').add(1, 'day');
  const nextMonth = moment().startOf('day').add(1, 'month');

  form.setFieldsValue({
    expDate: nextMonth
  });

  useEffect(() => {
    if (isGuest) {
      form.setFieldsValue({
        userId: undefined
      });
    }
  }, [isGuest]);

  return (
    <Form 
      form={form}
      layout="vertical"
      initialValues={{
        isGuest: true
      }}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="alias" label={t('g.alias')}>
            <Input 
              name="alias"
              placeholder={t('g.alias')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name="expDate" 
            label={t('g.expiration_date')} 
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <DatePicker 
              className="w-full" 
              disabledDate={d => !d || d.isBefore(tomorrow)}
              format="DD/MM/YYYY"
              defaultValue={nextMonth}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            name="userId" 
            label={t('g.owner')}
            rules={!isGuest ? [{ required: true, message: t('validation.required') }] : undefined}
          >
            <DebounceSelect 
              style={{ width: '100%' }}
              fetchOptions={(value: string) => getUsers({
                '$or[0][name][$like]': `%${value}%`, 
                '$or[1][email][$like]': `%${value}%`, 
              })}
              disabled={isGuest}
              refactFunc={(users: ICustomer[]) => (
                users.map(user => ({
                  value: user.id,
                  label: user.name,
                  tag: user.email
                }))
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="isGuest"
          >
            <Checkbox 
              onChange={() => setIsGuest(!isGuest)}
              checked={isGuest}
            > 
              Crear para invitado
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShoppingCartForm;
