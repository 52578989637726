import EDeliveryType from '@/enums/EDeliveryType';
import IPreCheckout from '@/interfaces/IPreCheckout';

const validatePreCheckoutData = (
  data: Partial<IPreCheckout>,
): IPreCheckout => {
  if (!data.customer) throw new Error('Customer is empty');
  if (!data.warehouseId) throw new Error('WarehouseId is empty');
  if (!data.shoppingCartId) throw new Error('ShoppingCartId is empty');
  if (!data.paymentServiceCode) throw new Error('PaymentServiceCode is empty');
  if (!data.deliveryType) throw new Error('DeliveryType is empty');

  if (data.deliveryType === EDeliveryType.Shipping) {
    if (!data.destAddress) throw new Error('DestAddress is empty');
    if (!data.shippingTypeOrder) throw new Error('ShippingTypeOrder is empty');
  }

  if (data.deliveryType === EDeliveryType.PickUp) {
    if (!data.deliveryPointId) throw new Error('DeliveryPointId is empty');
  }

  return data as IPreCheckout;
};

export default validatePreCheckoutData;
