import React, { useEffect, useState } from 'react';
import { 
  Col, PageHeader, Row, 
  Typography, List, Button, 
  Modal, Form, message 
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled, LeftOutlined } from '@ant-design/icons';
import useService from '@/hooks/useService';
import {
  IGetPaymentSource, 
  createPaymentSource, 
  getPaymentSourcesByUser,
  deletePaymentSource,
  updatePaymentSource, 
  isDefaultPaymentService
} from '@/services/PaymentSourceService';
import useLocation from '@/hooks/useLocation';
import PaymentSourceCard from '@/components/PaymentSource/PaymentSourceCard';
import IPaymentSource from '@/interfaces/IPaymentSource';
import useModal from '@/hooks/useModal';
import PaymentSourceForm, { IPaymentSourceFormRef } from '@/components/Forms/PaymentSourceForm';
import IOperativePaymentService from '@/interfaces/IOperativePaymentService';
import { getOperativePaymentServiceByWarehouse } from '@/services/PaymentServiceService';
import EPaymentService from '@/enums/EPaymentService';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import { useRef } from 'react';
import IOpenpayResponse from '@/interfaces/IOpenpayResponse';
import IOpenpayToken from '@/interfaces/IOpenpayToken';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import useAuth from '@/hooks/useAuth';
import SelectWarehouse from '@/components/SelectWarehouseModal/SelectWarehouse';
import IWarehouse from '@/interfaces/IWarehouse';

const PaymentSourcesPage = () => {
  const { confirm } = Modal;
  const { t } = useTranslation('common');

  const { customer } = useAuth();
  const newPaymentSourceFormRef = useRef<IPaymentSourceFormRef>(null);
  const editPaymentSourceFormRef = useRef<IPaymentSourceFormRef>(null);
  const [newCardForm] = Form.useForm();
  const [editCardForm] = Form.useForm();
  const { 
    warehouses, 
    selectedWarehouse,
    onChangePostalCode,
    onSelectWarehouse,
    loading 
  } = useLocation();
  const newPaymentSourceModal = useModal();
  const editPaymentSourceModal = useModal();
  const warehouseModal = useModal();
  const [config, setConfig] = useState<any>();
  const [newDeviceSessionId, setNewDeviceSessionId] = useState<string>('');
  const [editDeviceSessionId, setEditDeviceSessionId] = useState<string>('');
  const [paymentSourceIdToUpdate, setPaymentSourceIdToUpdate] = useState<number | null>(null);

  const paymentSourcesService = useService<IGetPaymentSource>({
    fetchData: getPaymentSourcesByUser,
    params: { warehouseId: selectedWarehouse }
  });

  const paymentSources = paymentSourcesService.data?.paymentSources ?? [];
  const validPaymentSources = paymentSourcesService.data?.validPaymentSources ?? [];

  const operativePaymentServiceState = useService<IOperativePaymentService[]>({
    fetchData: getOperativePaymentServiceByWarehouse,
    params: { 
      warehouseId: selectedWarehouse,
      paymentServiceCode: EPaymentService.Openpay
    },
  });

  const onChangeWarehouse = (warehouseId: IWarehouse['id']) => {
    onSelectWarehouse(warehouseId);
    warehouseModal.hide();
  }

  const PaymentSourceTitle = () => (
    <Typography.Text className="text-primary">
      {t('g.payment_sources')}
    </Typography.Text>
  );

  const getLast4 = (paymentSourceId: IPaymentSource['id']) => {
    return paymentSources.find(
      paymentSource => paymentSource.id === paymentSourceId
    )?.last4 ?? 'XXXX'
  };

  const onDeletePaymentSource = (paymentSourceId: IPaymentSource['id']) => {
    confirm({
      title: t('g.do_you_want_to_delete_this_payment_source?'),
      icon: <ExclamationCircleFilled />,
      okText: t('g.confirm'),
      content: t('g.termination_last4', {
        last4: getLast4(paymentSourceId)
      }),
      async onOk() {
        try {
          if (!selectedWarehouse) return;
          await deletePaymentSource({
            warehouseId: selectedWarehouse,
            paymentServiceCode: EPaymentService.Openpay,
            paymentSourceId
          });

          message.success({
            content: t('g.done'),
            duration: 2,
            key: 'Key-paymentSource',
          });

          await paymentSourcesService.reload();
          return true;
        } catch {
          message.error({
            content: t('g.error'),
            duration: 2,
            key: 'Key-paymentSource',
          });
        }
      },
      onCancel() {},
    });
  };

  const onIsDefaultPaymentSource = (paymentSourceId: IPaymentSource['id']) => {
    confirm({
      title: t('g.do_you_want_to_make_this_your_default_payment_method?'),
      icon: <ExclamationCircleFilled />,
      okText: t('g.confirm'),
      content: t('g.termination_last4', {
        last4: getLast4(paymentSourceId)
      }),
      async onOk() {
        try {
          if (!customer?.id) return;

          await isDefaultPaymentService({
            paymentSourceId,
            userId: customer.id
          });

          message.success({
            content: t('g.done'),
            duration: 2,
            key: 'Key-paymentSource',
          });

          await paymentSourcesService.reload();
          return true;
        } catch {
          message.error({
            content: t('g.error'),
            duration: 2,
            key: 'Key-paymentSource',
          });
        }
      },
      onCancel() {},
    });
  };

  const onUpdatePaymentSourceModal = (paymentSourceId: IPaymentSource['id']) => {
    setPaymentSourceIdToUpdate(paymentSourceId);
    const selectedPaymentSource = paymentSources.find(
      paymentSource => paymentSource.id === paymentSourceId
    );

    if (!selectedPaymentSource) return;

    editCardForm.setFieldsValue({
      holder_name: selectedPaymentSource.cardHolder,
      expiration_month: selectedPaymentSource.expirationMonth,
      expiration_year: selectedPaymentSource.expirationYear
    });

    editPaymentSourceModal.show();
  };

  const onCreatePaymentSource = async () => {
    try {
      if (!newPaymentSourceFormRef.current || !selectedWarehouse) throw new Error('warehouse not found');
      newPaymentSourceModal.setLoading(true);
      
      const token: IOpenpayResponse<IOpenpayToken> | null = await new Promise((resolve, reject) => {
        if (!newPaymentSourceFormRef.current) {
          reject(null);
          return;
        };
        newPaymentSourceFormRef.current.onSubmit(
          (res: IOpenpayResponse<IOpenpayToken>) => resolve(res),
          (res: IOpenpayTokenError) => reject(null))
      });
  
      if (!token) throw new Error('error in token'); 
      
      await createPaymentSource({
        warehouseId: selectedWarehouse,
        paymentServiceCode: EPaymentService.Openpay,
        payload: {
          token: token.data.id,
          deviceSessionId: newDeviceSessionId
        }
      });

      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'Key-paymentSource',
      });
  
      await paymentSourcesService.reload();
      newCardForm.resetFields();
      newPaymentSourceModal.hide();
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'Key-paymentSource',
      });
    } finally {
      newPaymentSourceModal.setLoading(false);
    }
  };

  const onUpdatePaymentSource = async () => {
    try {
      if (!editPaymentSourceFormRef.current || !selectedWarehouse || !paymentSourceIdToUpdate) throw new Error('warehouse not found');
      editPaymentSourceModal.setLoading(true);
      
      const token: IOpenpayResponse<IOpenpayToken> | null = await new Promise((resolve, reject) => {
        if (!editPaymentSourceFormRef.current) {
          reject(null);
          return;
        };
        editPaymentSourceFormRef.current.onSubmit(
          (res: IOpenpayResponse<IOpenpayToken>) => resolve(res),
          (res: IOpenpayTokenError) => reject(null))
      });
  
      if (!token) throw new Error('error in token'); 
      
      await updatePaymentSource({
        warehouseId: selectedWarehouse,
        paymentSourceId: paymentSourceIdToUpdate,
        paymentServiceCode: EPaymentService.Openpay,
        payload: {
          token: token.data.id,
          deviceSessionId: editDeviceSessionId
        }
      });

      message.success({
        content: t('g.done'),
        duration: 2,
        key: 'Key-paymentSource',
      });

      setPaymentSourceIdToUpdate(null);
      await paymentSourcesService.reload();
      editCardForm.resetFields();
      editPaymentSourceModal.hide();
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error'),
        duration: 2,
        key: 'Key-paymentSource',
      });
    } finally {
      editPaymentSourceModal.setLoading(false);
    }
  };

  const isUpdate = (
    paymentSourceId: IPaymentSource['id']
  ) => {
    if (!selectedWarehouse) return true;
    if (!validPaymentSources || validPaymentSources.length === 0) return false;
    return validPaymentSources.some((validPaymentSource) => validPaymentSource.paymentSourceId === paymentSourceId)
  };

  useEffect(() => {
    if (!operativePaymentServiceState.data) return;

    const selectOperativePaymentService = operativePaymentServiceState.data.find(operativePaymentService => {
      return operativePaymentService.paymentStrategy.code === EPaymentStrategy.Card;
    });

    if (!selectOperativePaymentService) return;

    setConfig(selectOperativePaymentService.paymentServiceConfig);
  }, [operativePaymentServiceState.data]);

  useEffect(() => {
    operativePaymentServiceState.setCurrentParams({
      warehouseId: selectedWarehouse,
      paymentServiceCode: EPaymentService.Openpay
    });

    paymentSourcesService.setCurrentParams({
      warehouseId: selectedWarehouse
    });
  }, [selectedWarehouse])

  return (
    <Row 
      className="p-4"
      gutter={[0, 16]}
    > 
      <Modal
        title={t('g.select_the_store_of_your_preference')}
        open={warehouseModal.visible}
        onCancel={() => warehouseModal.hide()}
        footer={null}
      >
        <SelectWarehouse 
          loading={loading}
          initialValue={selectedWarehouse}
          onChangePostalCode={onChangePostalCode}
          onChangeWarehouse={onChangeWarehouse}
          options={warehouses}
        />
      </Modal>
      {
        config ? (
          <Modal 
            title={t('g.create_payment_source')} 
            open={newPaymentSourceModal.visible} 
            confirmLoading={newPaymentSourceModal.isLoading}
            onOk={() => onCreatePaymentSource()} 
            onCancel={() => {
              newCardForm.resetFields();
              newPaymentSourceModal.hide()
            }}
            okButtonProps={{ className:"bg-blue-500" }}
            okText={t('g.save')}
          >
            <PaymentSourceForm
              ref={newPaymentSourceFormRef}
              merchantId={config.merchantId}
              publicKey={config.publicKey}
              form={newCardForm}
              setDeviceSessionId={setNewDeviceSessionId}
            />
          </Modal>
        ) : null
      }
      {
        config ? (
          <Modal 
            title={t('g.update_payment_source_with_termination', {
              last4: paymentSourceIdToUpdate ? getLast4(paymentSourceIdToUpdate) : 'xxxx'
            })} 
            open={editPaymentSourceModal.visible} 
            confirmLoading={editPaymentSourceModal.isLoading}
            onOk={() => onUpdatePaymentSource()} 
            onCancel={() => {
              editCardForm.resetFields();
              editPaymentSourceModal.hide()
            }}
            okButtonProps={{ className:"bg-blue-500" }}
            okText={t('g.save')}
          >
            <PaymentSourceForm
              ref={editPaymentSourceFormRef}
              merchantId={config.merchantId}
              publicKey={config.publicKey}
              form={editCardForm}
              setDeviceSessionId={setEditDeviceSessionId}
            />
          </Modal>
        ) : null
      }
      <Col span={24}>
        <PageHeader 
          className="p-0"
          title={<PaymentSourceTitle />} 
          backIcon={<LeftOutlined className="text-primary" />}
          onBack={() => {}}
          extra={(
            <Button 
              type="primary" 
              loading={operativePaymentServiceState.loading}
              className="bg-blue-500"
              onClick={() => {
                if (!selectedWarehouse) {
                  warehouseModal.show();
                  return;
                }
                newPaymentSourceModal.show();
              }}
            >
              {t('g.add')}
            </Button>
          )}
        />
      </Col>
      <Col span={24}>
        <List
          grid={{ gutter: 20, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }}
          dataSource={paymentSourcesService.data?.paymentSources ?? []}
          renderItem={(item) => (
            <List.Item>
              <PaymentSourceCard 
                paymentSource={item} 
                isUpdate={isUpdate(item.id)}
                onDelete={onDeletePaymentSource}
                onUpdate={onUpdatePaymentSourceModal}
                onDefault={onIsDefaultPaymentSource}
              />
            </List.Item>
          )}
          loading={paymentSourcesService.loading}
        />
      </Col>
    </Row>
  );
};

export default PaymentSourcesPage;
