import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import {
  addItemToShoppingCart,
  getSingleShoppingCart,
  removeItemFromShoppingCart,
} from '@/services/ShoppingCart';
import useShoppingCart from '@/hooks/useShoppingCart';
import useLocation from '@/hooks/useLocation';
import { deleteShoppingCartItemWithPost } from '@/services/ShoppingCartItem';
import { isProductHaveShippingTypeInWarehouse } from '@/services/ShippingTypeInWarehouse';
import getMixProductsIds from '@/helpers/getMixProductsIds';
import isVariant from '@/helpers/isVariant';
import isProduct from '@/helpers/isProduct';
import { getMixBaseProducts } from '@/services/MixService';
import allowsSaleWithoutInventory from '@/helpers/allowsSaleWithoutInventory';
import IWarehouse from '../interfaces/IWarehouse';
import SelectionMix from '@/interfaces/ISelectionMix';
import IShoppingCartItemWithConfig from '@/interfaces/IShoppingCartItemWithConfig';
import IShoppingCart from '@/interfaces/IShoppingCart';
import getShoppingCartItemStepper from '@/helpers/getShoppingCartItemStepper';
import { isMixHaveDeliveryTypeInWarehouse, isProductHaveDeliveryTypeInWarehouse } from '@/services/DeliveryPointService';

const useShoppingCartItemsList = (
  shoppingCartItemsParams?: IShoppingCartItem[] | string
) => {
  const { t } = useTranslation('common');
  const { selectedWarehouse } = useLocation();
  const { shoppingCart, loading } = useShoppingCart();

  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [shoppingCartItems, setShoppingCartItems] = useState<IShoppingCartItemWithConfig[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const getItemsDetails = async (shoppingCartId: IShoppingCart['id']) => {
    setLoadingItems(true);

    if (!shoppingCartItemsParams) {
      const shoppingCartDetailed = await getSingleShoppingCart({ id: shoppingCartId });

      if (!shoppingCartDetailed) {
        setLoadingItems(false);
        return;
      } 

      await initializeItems(shoppingCartDetailed.items, selectedWarehouse);
      setLoadingItems(false);
      return;
    }

    if (typeof shoppingCartItemsParams === 'string' ) {
      const shoppingCartDetailed = await getSingleShoppingCart({ id: shoppingCartItemsParams.toString() });

      if (!shoppingCartDetailed) {
        setLoadingItems(false);
        return;
      } 

      await initializeItems(shoppingCartDetailed.items, selectedWarehouse);
      setLoadingItems(false);
      return;
    }

    if (!shoppingCartItemsParams) {
      setLoadingItems(false);
      return;
    }

    await initializeItems(shoppingCartItemsParams, selectedWarehouse);
    setLoadingItems(false);
  }

  const getShoppingCartItemConfig = async (
    shoppingCartItem: IShoppingCartItem,
    warehouseId?: IWarehouse['id']
  ): Promise<IShoppingCartItemWithConfig> => {
    const { 
      item, 
      selection 
    } = shoppingCartItem;
  
    if (!item) return {
      ...shoppingCartItem,
      config: [],
      price: 0,
      isInWarehouse: false,
      inventory: 0,
      shippingType: false,
      deliveryPoint: false,
    }
  
    if (isProduct(item)) {
      const promises = await Promise.all([
        item.getProductsConfig({ warehouseId }),
        item.getPrice({ warehouseId }),
        item.isInWarehouse({ warehouseId }),
        item.getInventory({ warehouseId }),
        isProductHaveShippingTypeInWarehouse({
          productIds: [item.id],
          warehouseId,
        }),
        warehouseId ?
          isProductHaveDeliveryTypeInWarehouse({
            productId: item.id,
            warehouseId,
          })
        : false
      ]);
  
      return {
        ...shoppingCartItem,
        config: promises[0],
        price: promises[1],
        isInWarehouse: promises[2],
        inventory: promises[3],
        shippingType: promises[4],
        deliveryPoint: promises[5],
      };
    }
  
    if (isVariant(item)) {
      const promises = await Promise.all([
        item.getProductsConfig({ 
          selectionItem: selection,
          warehouseId, 
        }),
        item.getPrice({ 
          selectionItem: selection,
          warehouseId 
        }),
        item.isInWarehouse({ warehouseId }),
        item.getInventory({ 
          selectionItem: selection,
          warehouseId 
        }),
        isProductHaveShippingTypeInWarehouse({
          productIds: [selection] as string[],
          warehouseId,
        }),
        true,
      ]);
  
      return {
        ...shoppingCartItem,
        config: promises[0],
        price: promises[1],
        isInWarehouse: promises[2],
        inventory: promises[3],
        shippingType: promises[4],
        deliveryPoint: promises[5],
      };
    }
  
    const promises = await Promise.all([
      item.getProductsConfig({ 
        selectionItem: selection,
        warehouseId, 
      }),
      item.getPrice({ 
        selectionItem: selection,
        warehouseId 
      }),
      item.isInWarehouse({ warehouseId }),
      item.getInventory({ 
        selectionItem: selection,
        warehouseId 
      }),
      isProductHaveShippingTypeInWarehouse({
        productIds: getMixProductsIds(selection as SelectionMix),
        warehouseId: selectedWarehouse,
      }),
      getMixBaseProducts({ mixId: shoppingCartItem.entityId }),
      warehouseId ?
          isMixHaveDeliveryTypeInWarehouse({
            mixId: item.id,
            warehouseId,
          })
        : false
    ]);
  
    return {
      ...shoppingCartItem,
      config: promises[0],
      price: promises[1],
      isInWarehouse: promises[2],
      inventory: promises[3],
      shippingType: promises[4],
      baseProducts: promises[5],
      deliveryPoint: promises[6],
    };
  };

  const initializeItems = useCallback(async (
    items: IShoppingCartItem[],
    warehouseId?: IWarehouse['id']
  ) => {
    const itemsWithConfig = await Promise.all(
      items.map((item) => getShoppingCartItemConfig(item, warehouseId))
    );

    setShoppingCartItems(itemsWithConfig);
  }, [
    shoppingCartItemsParams, 
    shoppingCart?.items, 
    selectedWarehouse
  ]);

  // EVENTS
  const onIncrease = useCallback(async (
    shoppingCartItem: IShoppingCartItemWithConfig,
  ) => {
    if (!shoppingCartItem) return;
    
    const stepper = getShoppingCartItemStepper(shoppingCartItem);
    const increasedQty = stepper;

    if (
      !allowsSaleWithoutInventory(shoppingCartItem.config) &&
      shoppingCartItem.inventory < increasedQty
    ) { 
      message.warning({
        content: t('inventory_status.partial_inventory'),
        duration: 2,
        key: 'shopping-cart-key',
      });

      return;
    }

    await addItemToShoppingCart({
      entityId: shoppingCartItem.entityId,
      entityType: shoppingCartItem.entityType,
      qty: stepper,
      selection: shoppingCartItem.selection,
      shoppingCartId: shoppingCart?.id,
    });

    setShoppingCartItems((prevShoppingCartItems) => {
      return prevShoppingCartItems.map((prevShoppingCartItem) => {
        if (prevShoppingCartItem.id !== shoppingCartItem.id) return prevShoppingCartItem;
        
        return {
          ...prevShoppingCartItem,
          qty: shoppingCartItem.qty + increasedQty
        };
      });
    });
  },[shoppingCartItems]);

  const onDecrease = useCallback(async (
    shoppingCartItem: IShoppingCartItemWithConfig
  ) => {
    if (!shoppingCartItems) return;

    let stepper = getShoppingCartItemStepper(shoppingCartItem);

    const decreasedQty = stepper;

    if (shoppingCartItem.qty - decreasedQty <= 0) {
      await onDelete(shoppingCartItem);
      return;
    }
     
    await removeItemFromShoppingCart({
      entityId: shoppingCartItem.entityId,
      entityType: shoppingCartItem.entityType,
      qty: decreasedQty,
      selection: shoppingCartItem.selection,
      shoppingCartId: shoppingCart?.id,
    });

    setShoppingCartItems((prevShoppingCartItems) => {
      return prevShoppingCartItems.map((prevShoppingCartItem) => {
        if (prevShoppingCartItem.id !== shoppingCartItem.id) return prevShoppingCartItem;
        
        return {
          ...prevShoppingCartItem,
          qty: shoppingCartItem.qty - decreasedQty
        };
      });
    });
  },[shoppingCartItems]);

  const onDelete = async (shoppingCartItem: IShoppingCartItemWithConfig) => {
    const dataCopy = cloneDeep(shoppingCartItems);

    setShoppingCartItems(dataCopy);

    await deleteShoppingCartItemWithPost({
      shoppingCartItemId: shoppingCartItem.id,
    });

    const newItems = dataCopy.filter(data => data.id !== shoppingCartItem.id);
    setShoppingCartItems(newItems)

    message.success({
      content: t('g.deleted'),
      key: 'shopping-cart-key',
    });
  };

  const getTotalCost = useCallback((items: IShoppingCartItemWithConfig[]) => {
    const price = items.reduce((acc, curr) => acc += curr.price * curr.qty, 0);
    return price;
  }, [shoppingCartItems]);

  useEffect(() => {
    if (!shoppingCartItems || (shoppingCartItems && shoppingCartItems.length <= 0)) return;
    const price = getTotalCost(shoppingCartItems);
    setTotalPrice(price);
  }, [shoppingCartItems]);

  useEffect(() => {
    if(!shoppingCart) return;

    getItemsDetails(shoppingCart.id);
    // eslint-disable-next-line
  }, [
    shoppingCartItemsParams, 
    shoppingCart?.items, 
    selectedWarehouse
  ]);

  return {
    shoppingCartItems,
    loadingCart: loading || loadingItems,
    totalPrice,
    setShoppingCartItems,
    onIncrease,
    onDecrease,
    onDelete,
  };
};

export default useShoppingCartItemsList;
