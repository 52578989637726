import React from 'react';
import { Dropdown, DropdownProps, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { MoreOutlined } from '@ant-design/icons';

interface IDotsDropdown extends Omit<DropdownProps, 'overlay'> {
  options: ItemType[];
}

const DotsDropdown = (props: IDotsDropdown) => {

  const menu = (
    <Menu items={props.options}/>
  );

  return (
    <Dropdown 
      {...props}
      overlay={menu} 
    >
      <MoreOutlined />
    </Dropdown>
  );
};

export default DotsDropdown;
