import React, { ReactNode } from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import { 
  ShoppingCartOutlined, 
  CaretDownOutlined, 
  CaretUpOutlined, 
} from '@ant-design/icons';

const ColWrapper = styled(Col)`
  display: flex;
  
  .ant-typography {
    display: inherit;
    align-self: center;
  }
  
  .anticon {
    align-self: center;
  }
`;

interface ICollapseRow {
  icon?: ReactNode;
  primaryText?: string;
  secundaryText?: string;
  extra?: ReactNode;
  active: boolean;
  onClick: () => void;
}

const CollapseRow = ({
  icon = (<ShoppingCartOutlined className="fix-icon" />),
  extra,
  primaryText = '',
  secundaryText = '',
  active = false,
  onClick = () => {}
}: ICollapseRow) => {

  return (
    <Row 
      className="cursor-pointer text-current"
      onClick={onClick}
    > 
      <Col span={extra ? 12 : 0} className="no-seleccionable">
        {extra}
      </Col>
      <ColWrapper span={extra ? 12 : 24}>
        {
          !active && (
            <Typography.Text className="text-white text-current no-seleccionable">
              {primaryText}
              <CaretDownOutlined className="ml-2 fix-icon"/>
            </Typography.Text>
          )
        }
        {
          active && (
            <Typography.Text className="text-white text-current no-seleccionable">
              {secundaryText}
              <CaretUpOutlined className="ml-2 fix-icon"/>
            </Typography.Text>
          )
        }
      </ColWrapper>
    </Row>
  );
};

export default CollapseRow;
