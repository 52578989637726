import IAddress from '@/interfaces/IAddress';
import IAddressDto from '@/interfaces/IAddressDto';

export const adapterDtoToAddress = (address: IAddressDto): IAddress => {
  const { 
    id,
    street,
    extNum,
    intNum,
    additionalComments,
    suburb,
    postal_code,
  } = address;
  
  return {
    id,
    street,
    extNum,
    intNum,
    additionalComments,
    suburb,
    postalCode: postal_code,
  };
};

export const adapterDtoDeliveryAddressToAddress = (address: any): IAddress => {
  const { 
    id,
    street,
    extNum,
    intNum,
    additionalComments,
    suburb,
    postalCode,
    postal_code,
    city,
    state,
    country,
  } = address;
  
  return {
    id,
    street,
    extNum,
    intNum,
    additionalComments,
    suburb,
    postalCode: {
      id: postalCode?.id ?? postal_code.id,
      code: postalCode.code,
      city: {
        id: city.id,
        name: city.name,
        code: city.code,
        state: {
          id: state.id,
          name: state.name,
          code: state.code,
          country: {
            id: country.id,
            name: country.name,
            code: country.code,
          },
        },
      },
    },
  };
};
