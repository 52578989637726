import IWarehouseDeliveryPoint from '@/interfaces/IWarehouseDeliveryPoint';
import IWarehouseDeliveryPointDto from '@/interfaces/IWarehouseDeliveryPointDto';
import { adapterDtoToDeliveryPoint } from '@/adapters/DeliveryPointAdapter';

export const adapterDtoToWarehouseDeliveryPoint = (
  WDeliveryPoint: IWarehouseDeliveryPointDto,
): IWarehouseDeliveryPoint => {
  const { 
    id,
    warehouseId,
    deliveryPointId,
    delivery_point
  } = WDeliveryPoint;

  return {
    id,
    warehouseId,
    deliveryPointId,
    deliveryPoint: adapterDtoToDeliveryPoint(delivery_point)
  };
};
