import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// interface NotAuthenticatedSignProps {}

const NotAuthenticatedSign = () => {
  const { t } = useTranslation('common');

  return (
    <Row justify='center'>
      <Col span={24}>
        <Typography.Text>
          {t('g.this_page_is_not_available_because_you_are_not_authenticated')}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default NotAuthenticatedSign;
