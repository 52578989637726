import React, { ReactNode, useState } from "react";

interface ICheckoutDrawer {
  active: boolean;
  shippingPrice: number;
  productsPrice: number;
  totalDiscount: number;
  setTotalDiscount: React.Dispatch<React.SetStateAction<number>>;
  setProductsPrice: React.Dispatch<React.SetStateAction<number>>;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setShippingPrice: React.Dispatch<React.SetStateAction<number>>;
}

interface IChildren {
  children : ReactNode;
}

const checkoutDrawerContext = React.createContext<ICheckoutDrawer>({} as ICheckoutDrawer);

function useCheckoutDrawer(): ICheckoutDrawer {
  const [active, setActive] = useState<boolean>(false);
  const [shippingPrice, setShippingPrice] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [productsPrice, setProductsPrice] = useState<number>(0);

  return {
    active,
    productsPrice,
    shippingPrice,
    totalDiscount,
    setTotalDiscount,
    setProductsPrice,
    setActive,
    setShippingPrice,
  };
};

export const CheckoutDrawerProvider = ({ children }: IChildren) => {
  const checkoutDrawer = useCheckoutDrawer();

  return <checkoutDrawerContext.Provider value={checkoutDrawer}>{children}</checkoutDrawerContext.Provider>;
};

export default function CheckoutDrawerConsumer() {
  return React.useContext(checkoutDrawerContext);
}
