import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isEqual, maxBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import IGroupDeliveryRequest from '@/interfaces/IGroupDeliveryRequest';
import OrderType from '@/types/OrderType';
import { getGroupDelivery } from '@/services/CheckoutService';
import IGroupDeliveryDeniedResponse from '@/interfaces/IGroupDeliveryDeniedResponse';
import IGroupDeliverySuccessResponse from '@/interfaces/IGroupDeliverySuccessResponse';
import isGroupDeliverySuccessResponse from '@/helpers/isGroupDeliverySuccessResponse';
import moment from 'moment';
import 'moment/locale/es';
import i18next from 'i18next';
import IGroupDeliveryShippingConfiguration from '@/interfaces/IGroupDeliveryShippingConfiguration';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';
import MakePartial from '@/types/MakePartial';
import IGroupDeliveryItem from '@/interfaces/IGroupDeliveryItem';
import IShoppingCartItem, { IShoppingCartItemDTO } from '@/interfaces/IShoppingCartItem';


type IUseGroupDeliveriesProps = Omit<MakePartial<IGroupDeliveryRequest>, 'shippingTypeOrder'>;
type GroupDeliveryOptions = Record<
  OrderType, 
  IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>
>;

const useGroupDeliveries = ({
  destAddress,
  postalCode,
  shoppingCartItems,
  warehouseId,
}: IUseGroupDeliveriesProps) => {
  const { t } = useTranslation('common');
  const history = useNavigate();
  const [groupDelivery, setGroupDelivery] = useState<GroupDeliveryOptions>();
  const [isSameGroupDelivery, setIsSameGroupDelivery] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shippingTypeOrder, setShippingTypeOrder] = useState<OrderType>("ASC");

  const { setShippingPrice } = useCheckoutDrawer();

  const getGroupDeliveries = async () => {
    try {
      setLoading(true);
      if (!destAddress || !postalCode || !shoppingCartItems || !warehouseId) {
        throw new Error('missing props');
      }

      const groupDeliveryRequest: Omit<IGroupDeliveryRequest, 'shippingTypeOrder'> = {
        destAddress,
        postalCode,
        shoppingCartItems,
        warehouseId,
      }
      const formatShippingCartItemsDTO = (shoppingCatItems: IShoppingCartItem[]): IShoppingCartItemDTO[] => {
        return shoppingCatItems.map(item => ({
          id: item.id,
          entityId: item.entityId,
          entityType: item.entityType,
          qty: item.qty,
          selection: item.selection,
        }));
      };
      const optionASC = await getGroupDelivery({
        ...groupDeliveryRequest,
        shoppingCartItems: formatShippingCartItemsDTO(groupDeliveryRequest.shoppingCartItems),
        shippingTypeOrder: 'ASC'
      });
  
      const optionDESC = await getGroupDelivery({
        ...groupDeliveryRequest,
        shoppingCartItems: formatShippingCartItemsDTO(groupDeliveryRequest.shoppingCartItems),
        shippingTypeOrder: 'DESC'
      });

      if (!isGroupDeliverySuccessResponse(optionASC) || !isGroupDeliverySuccessResponse(optionDESC)) {
        if (optionASC.msg === 'without_stock') {
          message.error({
            content: t('g.products_without_inventory'),
            duration: 5,
            key: 'CheckoutKey',
          });
    
          history('/shopping_cart');
        }
      }

      const isSame = isSameGroupDeliveryResponses(
        optionASC.data as IGroupDeliveryShippingConfiguration[], 
        optionDESC.data as IGroupDeliveryShippingConfiguration[]
      );

      setIsSameGroupDelivery(isSame);

      setGroupDelivery({
        ASC: optionASC,
        DESC: optionDESC
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isSameGroupDeliveryResponses = (
    optionsA: IGroupDeliveryShippingConfiguration[], 
    optionsB: IGroupDeliveryShippingConfiguration[],
  ): boolean => {
    if (optionsA.length !== optionsB.length) return false;
    
    const isSame = optionsA.every((optionA, index) => {
      const optionB = optionsB[index];
      return isSameGroupDeliveryResponse(optionA, optionB);
    });
    
    return isSame;
  };

  const isSameGroupDeliveryResponse = (
    optionA: IGroupDeliveryShippingConfiguration, 
    optionB: IGroupDeliveryShippingConfiguration,
  ): boolean => {
    const deliveryDateA = getGroupDeliveryDate(optionA); 
    const deliveryDateB = getGroupDeliveryDate(optionB); 

    if (optionA.carrier.code !== optionB.carrier.code) return false;
    if (optionA.shippingType.id !== optionB.shippingType.id) return false;
    if (optionA.shippingCost !== optionB.shippingCost) return false;
    if (deliveryDateA !== deliveryDateB) return false;

    let orderDeliveryItemsA = optionA.items.sort((a, b) => +a.entityId - +b.entityId);
    let orderDeliveryItemsB = optionB.items.sort((a, b) => +a.entityId - +b.entityId);

    orderDeliveryItemsA = orderDeliveryItemsA.map(orderDeliveryItemA => {
      return {
        ...orderDeliveryItemA,
        products: orderDeliveryItemA.products.sort((a, b) => +a.id - +b.id)
      }
    });

    orderDeliveryItemsB = orderDeliveryItemsB.map(orderDeliveryItemB => {
      return {
        ...orderDeliveryItemB,
        products: orderDeliveryItemB.products.sort((a, b) => +a.id - +b.id)
      }
    });

    if (!isEqual(orderDeliveryItemsA, orderDeliveryItemsB)) return false;

    return true;
  };

  const getGroupDeliveryPrice = (groupDelivery: IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>): number => {
    const price = groupDelivery.data.reduce((acc, curr) => {
      return acc += curr.shippingCost;
    }, 0);

    return price;
  };

  const getLastGroupDeliveryDate = (groupDelivery: IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>) => {
    const maxDelivery = maxBy(groupDelivery.data, (quote) => quote.delayTime ?? 0);
    moment.locale(i18next.language === 'en' ? 'en' : 'es');

    if (!maxDelivery) return moment().add(0).format('LL');

    return moment().add(maxDelivery?.delayTime ?? 0, 'hours').format('LL');
  };

  const getGroupDeliveryDate = (configuration: IGroupDeliveryShippingConfiguration) => {
    moment.locale(i18next.language === 'en' ? 'en' : 'es');
    return moment().add(configuration?.delayTime ?? 0, 'hours').format('LL');
  };

  const getShoppingCartItemsByGroup = (configuration: IGroupDeliveryShippingConfiguration) => {
    const shoppingCartItemIds: string[] = [];

    configuration.items.forEach(
      item => shoppingCartItemIds.push(item.shoppingCartItemId),
    );

    const shoppingCartItemsArr = shoppingCartItems || [];
    const filteredShoppingcartItems = shoppingCartItemsArr.filter(
      item => shoppingCartItemIds.includes(item.id)
    );

    return filteredShoppingcartItems;
  };

  const sortGroupDeliveries = (configurations: IGroupDeliveryShippingConfiguration[]) => {  
    return configurations.sort(
      (a, b) => (a.delayTime ?? 0) - (b.delayTime ?? 0)
    )
  };

  const hasEmptyDeliveryData = () => {
    return (groupDelivery?.ASC 
      && groupDelivery?.DESC 
      && !isGroupDeliverySuccessResponse(groupDelivery?.ASC)
      && !isGroupDeliverySuccessResponse(groupDelivery?.DESC)
    ) ? true : false;
  }

  useEffect(() => {
    if (!groupDelivery) return;

    if (
      isGroupDeliverySuccessResponse(groupDelivery.ASC) 
      && shippingTypeOrder === 'ASC'
    ) {
      setShippingPrice(getGroupDeliveryPrice(groupDelivery.ASC))
    }

    if (
      isGroupDeliverySuccessResponse(groupDelivery.DESC) 
      && shippingTypeOrder === 'DESC'
    ) {
      setShippingPrice(getGroupDeliveryPrice(groupDelivery.DESC))
    }

    // eslint-disable-next-line
  }, [shippingTypeOrder, groupDelivery]);
  
  useEffect(() => {
    getGroupDeliveries();
    // eslint-disable-next-line
  }, [destAddress, postalCode, shoppingCartItems, warehouseId]);
  
  return {
    loading: loading,
    validDeliveryGroupOptions: true,
    isSameGroupDelivery,
    deliveryGroupOptions: groupDelivery,
    shippingTypeOrder: shippingTypeOrder,
    groupDelivery: groupDelivery,


    // helpers
    getGroupDeliveryDate,
    sortGroupDeliveries,
    getShoppingCartItemsByGroup,
    getLastGroupDeliveryDate,
    getGroupDeliveryPrice,
    

    // to refactor
    hasEmptyDeliveryData: hasEmptyDeliveryData(),
    setShippingTypeOrder,
  }
};

export default useGroupDeliveries;
