import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  Button, Card, Col, Form, Row, Tag, Typography,
  message, 
} from 'antd';

import IProduct from '@/interfaces/IProduct';
import { getProductById } from '@/services/ProductService';
import ContactForm from '@/components/MyAddresses/ContactForm';
import IContactFormData from '@/interfaces/IContactFormData';
import useAuth from '@/hooks/useAuth';
import { managedSaleRequest } from '@/services/ManagedSaleService';
import useLocation from '@/hooks/useLocation';

const ContactAssociatePage = () => {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { authenticated, customer } = useAuth();
  const location = useLocation();

  const { t } = useTranslation('common');
  
  const productId = searchParams.get('id');

  const [product, setProduct] = useState<IProduct | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [contactForm] = Form.useForm<IContactFormData>();

  const MSG_KEY = 'managedSaleRequest';
  const onManagedSaleRequest = async () => {
    try {
      setLoading(true);

      message.loading({
        content: t('g.loading'),
        key: MSG_KEY,
      });

      if (!productId) {
        throw new Error(t('g.product_not_found'));
      }
      if (!location.selectedWarehouse) {
        throw new Error(t('g.no_warehouse_selected'));
      }

      const { email, name, phone } = await contactForm.validateFields();

      await managedSaleRequest({
        email, 
        name, 
        phone,
        productId,
        whareouseId: location.selectedWarehouse,
      });
      
      message.success({
        content: t('g.done'),
        duration: 2,
        key: MSG_KEY,
      });
      
      navigate('/contact_associate_thank_you');
    } catch (error: any) {
      message.error({
        content: error.message || t('g.error'),
        duration: 2,
        key: MSG_KEY,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    if (!productId) return;

    getProductById(productId).then((product)=> setProduct(product));
  }, [productId]);

  useEffect(() => {
    if (!customer) return;

    contactForm.setFieldsValue({
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
    });

  }, [customer]);

  return (
    <Card 
      className="h-full" 
      bordered={false}
      style={{
        minHeight: '80vh',
      }}
    >
      <Row gutter={[0, 20]} justify="center">
        <Col 
          xs={{ span: 24 }}
          sm={{ span: 20 }}
          md={{ span: 16 }}
          lg={{ span: 14 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <Row align="middle">
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  <Typography.Title level={3}>
                    {t('g.enter_your_contact_information')}
                  </Typography.Title>
                </Col>
                <Col>
                  {
                    !authenticated ? (
                      <Button 
                        onClick={() => navigate('/login')}
                        type="primary" 
                      >
                        {t('g.login')}
                      </Button>
                    ) : null
                  }
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Paragraph>
                {t('g.an_agent_will_contact_you_shortly_about_your_interest_in_purchasing')}:
              </Typography.Paragraph>
            </Col>
            <Col span={24}>
              <Typography.Paragraph className='text-primary'>
                {
                  product ? (
                    <>
                      <Tag>{product?.sku}</Tag> {product?.name['es']}
                    </>
                  ): <Tag>{t('g.product_not_found')}</Tag>
                }
              </Typography.Paragraph>
            </Col>
            <Col span={24}>
              <ContactForm 
                form={contactForm}
                fields={{
                  alias: {
                    required: false,
                    visible: false,
                  },
                  name: {
                    required: true,
                    visible: true,
                  },
                  email: {
                    required: false,
                    visible: true,
                  },
                  phone: {
                    required: true,
                    visible: true,
                  }
                }}
              />
            </Col>
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  <Button 
                    onClick={() => {}}
                    type="text" 
                  >
                    {t('g.back')}
                  </Button>
                </Col>
                <Col>
                  <Button 
                    onClick={onManagedSaleRequest}
                    loading={loading}
                    type="primary" 
                  >
                    {t('g.contact_associate')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ContactAssociatePage;
