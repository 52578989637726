import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import IMix from '@/interfaces/IMix';

const isProduct = (
  item: IProduct | IVariant | IMix
): item is IProduct => {
 return (item as IProduct).key === 'product';
};

export default isProduct;
