import SelectionMix from "@/interfaces/ISelectionMix";
import removeRepetedNumbersFromArray from "@/helpers/removeRepetedNumbersFromArray";

const getMixProductsIds = (
  selection: SelectionMix,
) => {
  const mixIds: string[] = [];

  if (selection?.baseProducts) {
    selection.baseProducts.forEach(baseProduct => {
      mixIds.push(
        baseProduct.productId
      );
    });
  }

  if (selection?.sections) {
    selection.sections.forEach(section => {
      section.products.forEach(prod => {
        mixIds.push(
          prod.productId,
        );
      });
    });
  }

  return removeRepetedNumbersFromArray(mixIds);
};

export default getMixProductsIds