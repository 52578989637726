import axiosApiConfig from '@/config/axiosConfig';
import IOrder from "@/interfaces/IOrder";
import ICustomer from '@/interfaces/ICustomer';
import IOrderDto from '@/interfaces/IOrderDto';
import { adapterDtoToOrder } from '@/adapters/OrderAdapter';
import { IBillingRequestFormData } from '@/components/Forms/BillingRequestForm';

export type IGetOrderDetailsRes = IOrder | null | false;
type IGetOrderDetailsServiceRes = IOrderDto | null | false;

interface IGetOrderDetailsProps{
  orderId: IOrder['id'],
  customerId: ICustomer['id'],
  key: string,
}

export const getOrderDetails = async ({
  orderId,
  customerId,
  key,
}: IGetOrderDetailsProps): Promise<IGetOrderDetailsRes> => {
  const result = await axiosApiConfig.get<IGetOrderDetailsServiceRes>('/order/details', { 
    params: {
      orderId,
      customerId,
      key,
    }
  });

  const order = result.data;

  if(order === null || order === false){
    return order;
  }

  return adapterDtoToOrder(order);
};

interface IGetOrdersByCustomerIdProps{
  customerId: ICustomer['id'],
  params?: any,
}

export const getOrders = async ({
  customerId,
  params,
}: IGetOrdersByCustomerIdProps): Promise<IOrder[]> => {
  const result = await axiosApiConfig.get<{data: IOrderDto[]}>('/orders', { 
    params: { 
      customerId,
      salesOriginCode: 'platform',
      ...params,
    } 
  });

  return Promise.all(result.data.data.map(
    async (order: IOrderDto) => await adapterDtoToOrder(order)
  ));
};

export const orderBillingRequest = async (billingData: IBillingRequestFormData): Promise<any> => {
  try {
    const result = await axiosApiConfig.post<any>('/billing_request', { billingData });
    return result;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
