import React, { useState } from 'react';
import { Button, Col, Form, Row, Typography, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { IOnPay } from '@/containers/views/Checkout/CheckoutPOS';

interface IManualPaymentForm {
  paymentStrategy: string | number; // TODO: replace wt EPaymentStrategy
  isPaymentBlock: boolean,
  decription?: string,
  onSuccess: (props: IOnPay) => Promise<void>;
}

const ManualPaymentForm = ({
  onSuccess,
  isPaymentBlock = false,
  decription,
  paymentStrategy,
}: IManualPaymentForm) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);
  const [ManualPaymentForm] = Form.useForm();

  const handlePayment = async () => {
    setLoading(true);
    const values = await ManualPaymentForm.validateFields();
    if (!values.paymentCheckbox) {
      setLoading(false);
      return;
    }

    onSuccess({
      paymentStrategy,
    })
  };


  return (
    <Row>
      { decription ? (
        <Col span={24}>
          <Typography.Text>
            {htmlParser(decription)}
          </Typography.Text>
        </Col>
      ) : null }
      <Col span={24}>
        <Form 
          form={ManualPaymentForm} 
          action="#" 
          id="payment-form" 
          layout="vertical" 
          initialValues={{
            paymentCheckbox: false,
          }}
        >
          <Row>
            <Form.Item
              id="paymentCheckbox" 
              name="paymentCheckbox"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Checkbox>{t('g.manual_payment_service_other_messsage')}</Checkbox>
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        <Button 
          type="primary"
          block
          className="bg-blue-500"
          onClick={handlePayment}
          loading={loading}
          disabled={isPaymentBlock}
        >
          {t('g.request_order')}
        </Button>
      </Col>
    </Row>
  );
};

export default ManualPaymentForm;