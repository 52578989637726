import React, { useEffect, useState } from 'react';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/lib/segmented';
import IWarehouse from '@/interfaces/IWarehouse';
import IPaymentService from '@/interfaces/IPaymentService';
import { Alert, Button, Col, Row, Segmented, Space, Typography,  } from 'antd';
import { useTranslation } from 'react-i18next';
import useService from '@/hooks/useService';
import { getOperativePaymentServiceByWarehouse } from '@/services/PaymentServiceService';
import IOperativePaymentService from '@/interfaces/IOperativePaymentService';
import getTranlation from '@/helpers/getTranslation';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import OpenpayCardForm from '@/components/Forms/OpenpayCardForm';
import IOpenpayTokenError from '@/interfaces/IOpenpayTokenError';
import InternalPaymentForm from '@/components/Forms/InternalPaymentForm';
import EPaymentService from '@/enums/EPaymentService';
import { IOnPay } from '@/containers/views/Checkout/CheckoutPOS';
import CheckoutDiscountSection from './CheckoutDiscountSection';
import formatCurrencyMX from '@/utils/formatCurrency';
import useCheckoutDrawer from '@/hooks/useCheckoutDrawer';
import ManualPaymentForm from '@/components/Forms/ManualPaymentForm';
import Optional from '@/types/Optional';

interface ICheckoutPay {
  warehouseId: IWarehouse['id'];
  paymentServiceCode: IPaymentService['code'];
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>
  paymentStrategy: SegmentedValue; // TODO: change to EPaymentStrategy
  setPaymentStrategy: (e: SegmentedValue) => void;
  onPay: (props: IOnPay) => Promise<void>;
  onPayError: (response: IOpenpayTokenError) => void;
  onClickAlert: () => void;
}

const CheckoutPayPage = ({
  warehouseId,
  paymentServiceCode,
  paymentStrategy,
  couponCode,
  setCouponCode,
  setPaymentStrategy,
  onPay,
  onPayError,
  onClickAlert,
}: ICheckoutPay) => {
  const { t } = useTranslation('common');
  const [options, setOptions] = useState<(SegmentedValue | SegmentedLabeledOption)[]>([]);
  const [config, setConfig] = useState<any>();

  const { 
    productsPrice,
    shippingPrice,
    totalDiscount,
  } = useCheckoutDrawer();

  const totalAmount = (productsPrice + shippingPrice) - totalDiscount;

  const [orderLimit, setOrderLimit] = useState<null | number>(null);
  const [orderMinimum, setOrderMinimum] = useState<null | number>(null);

  const isOrderOverLimit = (orderLimit && totalAmount > orderLimit) as boolean;
  const isOrderUnderMinimum = (orderMinimum && totalAmount < orderMinimum) as boolean;

  const operativePaymentServiceState = useService<IOperativePaymentService[]>({
    fetchData: getOperativePaymentServiceByWarehouse,
    params: { 
      warehouseId,
      paymentServiceCode: paymentServiceCode
    },
  });

  const generatePaymentStrategiesOptions = (
    operativePaymentServices: IOperativePaymentService[]
  ) => {
    const newOptions = operativePaymentServices.map(operativePaymentService => {
      return {
        label: (
          <Row>
            <Col span={24}>
              {getTranlation(operativePaymentService.paymentStrategy.label)}
            </Col>
            {operativePaymentService.orderMinimum && (
              <Col span={24}>
                <Typography.Paragraph style={{ margin: 0 }}>
                  <Typography.Text>{`${t('g.purchase_minimum')}: `}</Typography.Text>
                  <Typography.Text strong>{formatCurrencyMX(operativePaymentService.orderMinimum)}</Typography.Text>
                </Typography.Paragraph>
              </Col>
            )}
            {operativePaymentService.orderLimit && (
              <Col span={24}>
                <Typography.Paragraph style={{ margin: 0 }}>
                  <Typography.Text>{`${t('g.purchase_limit')}: `}</Typography.Text>
                  <Typography.Text strong>{formatCurrencyMX(operativePaymentService.orderLimit)}</Typography.Text>
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
        ),
        value: operativePaymentService.paymentStrategy.code,
      }
    });

    setOptions(newOptions);
  };

  const AlertUnderOrderMinimum = () => (
    <>
      <Alert
        showIcon
        message={
          <Space>
            <Typography.Text>{`${t('g.purchase_minimum')}: `}</Typography.Text>
            <Typography.Text strong>{orderMinimum && formatCurrencyMX(orderMinimum)}</Typography.Text>
          </Space>
        }
        description={t('hints.purchase_minimum')}
        type="error"
        style={{ borderBottom: 0 }}
      />
      <Alert
        type="error"
        style={{ borderTop: 0 }}
        action={
          <Button danger onClick={onClickAlert}>
            Consultar otros métodos de pago
          </Button>
        }
      />
    </>
  );

  const AlertOverOrderLimit = () => (
    <>
      <Alert
        showIcon
        message={
          <Space>
            <Typography.Text>{`${t('g.purchase_limit')}: `}</Typography.Text>
            <Typography.Text strong>{orderLimit && formatCurrencyMX(orderLimit)}</Typography.Text>
          </Space>
        }
        description={t('hints.purchase_limit')}
        type="error"
        style={{ borderBottom: 0 }}
      />
      <Alert
        type="error"
        style={{ borderTop: 0 }}
        action={
          <Button danger onClick={onClickAlert}>
            {t('hints.more_payments_methods')}
          </Button>
        }
      />
    </>
  );
  
  useEffect(() => {
    if (!operativePaymentServiceState.data) return;
    generatePaymentStrategiesOptions(operativePaymentServiceState.data);
    const initStrategy: Optional<EPaymentStrategy> = operativePaymentServiceState.data[0].paymentStrategy.code as EPaymentStrategy;
    if (initStrategy) {
      setPaymentStrategy(initStrategy);
    }
  }, [operativePaymentServiceState.data]);

  useEffect(() => {
    setOrderLimit(null);
    setOrderMinimum(null);

    if (!operativePaymentServiceState.data || !paymentStrategy) return;

    const selectOperativePaymentService = operativePaymentServiceState.data.find(operativePaymentService => {
      return operativePaymentService.paymentStrategy.code === paymentStrategy;
    });

    if (!selectOperativePaymentService) return;

    if(selectOperativePaymentService.orderLimit){
      setOrderLimit(selectOperativePaymentService.orderLimit);
    }

    if(selectOperativePaymentService.orderMinimum){
      setOrderMinimum(selectOperativePaymentService.orderMinimum);
    }

    setConfig(selectOperativePaymentService.paymentServiceConfig);
  }, [operativePaymentServiceState.data, paymentStrategy]);

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={3}>
          {t('g.payment_methods')}
        </Typography.Title>
      </Col>
      {isOrderOverLimit && (
        <Col span={24} className="mb-2">
          <AlertOverOrderLimit />
        </Col>
      )}
      {isOrderUnderMinimum && (
        <Col span={24} className="mb-2">
          <AlertUnderOrderMinimum />
        </Col>
      )}
      <Col span={24} className="mb-4">
        <Segmented 
          block 
          options={options}
          value={paymentStrategy}
          onChange={setPaymentStrategy}
        />
      </Col>
      <Col span={24} className="mb-4">
        <CheckoutDiscountSection 
          couponCode={couponCode}
          setCouponCode={setCouponCode}
        />
      </Col>
      <Col span={24}>
        {
          (
            paymentServiceCode === EPaymentService.Openpay
            && paymentStrategy === EPaymentStrategy.Card
            && config
          ) && (
            <OpenpayCardForm 
              merchantId={config.merchantId}
              publicKey={config.publicKey}
              fiscalAddress={config.fiscalAddress}
              phone={config.phone}
              mailSupport={config.mailSupport}
              onSuccess={onPay}
              onError={onPayError}
              isPaymentBlock={isOrderOverLimit || isOrderUnderMinimum}
            />
          )
        }
        {
          (
            paymentServiceCode === EPaymentService.Internal
            &&  (
              EPaymentStrategy.Card === paymentStrategy
              || EPaymentStrategy.Transfer === paymentStrategy
              || EPaymentStrategy.Cash === paymentStrategy
              || EPaymentStrategy.Other === paymentStrategy 
            )
            && config
          ) && (
            <InternalPaymentForm 
              paymentStrategy={paymentStrategy}
              onSuccess={onPay}
              isPaymentBlock={isOrderOverLimit || isOrderUnderMinimum}
            />
          )
        }
        {
          (
            paymentServiceCode === EPaymentService.Manual
            && (
              EPaymentStrategy.Other === paymentStrategy
              || EPaymentStrategy.Transfer === paymentStrategy
              || EPaymentStrategy.Cash === paymentStrategy
            )
            && config
          ) && (
            <ManualPaymentForm 
              paymentStrategy={paymentStrategy}
              decription={config?.desc}
              onSuccess={onPay}
              isPaymentBlock={isOrderOverLimit || isOrderUnderMinimum}
            />
          )
        }
      </Col>
    </Row>
  );
};

export default CheckoutPayPage;
