import IOperativePaymentService from '@/interfaces/IOperativePaymentService';
import IOperativePaymentServiceDto from '@/interfaces/IOperativePaymentServiceDto';
import { adapterDtoToPaymentStrategy } from '@/adapters/PaymentStrategyAdapter';
import { operativePaymentServiceAdapter } from '@/adapters/PaymentServiceAdapter';


export const adapterDtoToOperativePaymentService = (
  paymentService: IOperativePaymentServiceDto
): IOperativePaymentService => {
  const { 
    id,
    paymentServiceConfig,
    isDefault,
    orderMinimum,
    orderLimit,
    currency,
    payment_strategy,
    payments_service,
  } = paymentService;

  return {
    id,
    paymentServiceConfig,
    isDefault,
    orderMinimum,
    orderLimit,
    currency, 
    paymentStrategy: adapterDtoToPaymentStrategy(payment_strategy),
    paymentService: operativePaymentServiceAdapter(payments_service),
  };
};
