import React, { 
  Dispatch, 
  SetStateAction,
  useState,
} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, DatePicker } from 'antd';
import SearchInput from '@/components/General/SearchInput';
import { CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import DebounceSearch from '@/components/General/DebounceSearch';

interface MyOrdersFiltersProps{
  listParams: { [k: string]: any };
  setListParams: Dispatch<SetStateAction<{ [k: string]: any }>>;
}

const MyOrdersFilters = ({
  listParams,
  setListParams,
}: MyOrdersFiltersProps) => {
  const { t } = useTranslation('common');
  const { RangePicker } = DatePicker;

  const [searchValue, setSearchValue] = useState<string>('');

  const onchangeRnge = (dates: any, dateStrings: string[]): void => {
    if (dateStrings[0] !== '') {
      setListParams({
        ...listParams,
        startOfDateRange: moment(dateStrings[0]).startOf('day').toString(),
        endOfDateRange: moment(dateStrings[1]).endOf('day').toString(),
      });
    } else {
      const newListParams = _.omit(listParams, ['startOfDateRange', 'endOfDateRange']);
      setListParams(newListParams);
    }
  };

  function applyFilters(){
    const newListParams = _.omit(listParams, ['search']);
    newListParams.search = searchValue;
  
    setListParams(newListParams);
  }
  
  return (
    <Row gutter={[8, 16]}>
      <Col xs={{span: 24}} sm={{span: 8}}>
        <DebounceSearch 
          allowClear
          placeholder={t('g.search')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearch={applyFilters}
        />
      </Col>
      <Col xs={{span: 24}} sm={{span: '16'}} lg={{span: '12'}}>
        <RangePicker 
          style={{ width: '100%' }} 
          suffixIcon={<CaretDownOutlined />}
          onChange={onchangeRnge} 
        />
      </Col>
    </Row>
  );
};

export default MyOrdersFilters;
