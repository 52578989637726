import { adapterDtoToVariant } from '@/adapters/VariantAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import IParams from '@/interfaces/IParams';
import IVariant from '@/interfaces/IVariant';
import IVariantDto from '@/interfaces/IVariantDto';
import IWarehouseVariantDto from '@/interfaces/IWarehouseVariant';

export const getVariantById = async (variantId: IVariant['id']):Promise<IVariant> => {
  const result = await axiosApiConfig.get<IVariantDto>(`/variants/${variantId}`);

  return adapterDtoToVariant(result.data);
};

export const getWarehouseVariants = async (params?: IParams): Promise<IWarehouseVariantDto[]> => {
  const result = await axiosApiConfig.get<{data: IWarehouseVariantDto[]}>('/warehouse_variants', {
    params: { ...params },
  });
  const finalData: IWarehouseVariantDto[] = result.data.data;

  return finalData;
};
