import React, { CSSProperties } from 'react';
import { 
  MenuOutlined, 
  MenuFoldOutlined,
} from '@ant-design/icons';

interface ISiderButton {
  collapsed: boolean;
  updateCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  style?: CSSProperties;
}

const SiderButton = ({
  collapsed,
  updateCollapsed,
  style,
}: ISiderButton) => {
  const siderButtonColor = process.env.REACT_APP_THEME_LAYOUT_HEADER_COLOR_TEXT;
  const customeStyle = {...style, color: siderButtonColor };

  return (
    React.createElement(collapsed ? MenuOutlined : MenuFoldOutlined, {
      className: "icon-large text-white trigger",  
      style: customeStyle, 
      onClick: () => updateCollapsed(!collapsed),
    })
  );
};

export default SiderButton;
