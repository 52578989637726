import React, { ReactNode } from 'react';
import { Col, Row, Space, Tag, Typography } from 'antd';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import DeliveryMethod from '@/components/DeliveryMethod';
import EInventoryStatus from '@/enums/EInventoryStatus';
import { useTranslation } from 'react-i18next';
import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import IMix from '@/interfaces/IMix';
import getTranslation from '@/helpers/getTranslation';

interface IItemDetailsProps {
  item: IProduct | IVariant | IMix;
  deliveryMethods: EDeliveryMethod[];
  priceSection?: ReactNode;
  content?: ReactNode;
  inventoryStatus?: EInventoryStatus;
}

const ItemDetails = ({
  deliveryMethods,
  item,
  content,
  inventoryStatus,
  priceSection,
}: IItemDetailsProps) => {
  const { t } = useTranslation('common');

  const inventoryStatusStrings = {
    [EInventoryStatus.WithInventory]: t('inventory_status.with_inventory'),
    [EInventoryStatus.WithoutInventory]: t('inventory_status.without_inventory'),
    [EInventoryStatus.PartialInventory]: t('inventory_status.partial_inventory'),
  };

  return (
    <Row gutter={8}>
      <Col span={24}>
        <Typography.Title level={4}>
          {getTranslation(item.name)}
        </Typography.Title>
      </Col>

      {priceSection ? (
        <Col span={24}>{priceSection}</Col>
      ) : null}

      {inventoryStatus ? (
        <Col span={24}>
          <Tag>{inventoryStatusStrings[inventoryStatus]}</Tag>
        </Col>
      ) : null}

      {deliveryMethods.map((d, index) => (
        <Col key={`DeliveryMethod-${index}`} > 
          <DeliveryMethod deliveryMethod={d} />
        </Col>
      ))}

      {content ? (
        <Col span={24}>{content}</Col>
      ) : null}
    </Row>
  );
};

export default ItemDetails;
