import React, { useEffect, useState } from 'react';
import { Card, Col, message, Modal, Row, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IItem from '@/interfaces/IItem';
import ItemType from '@/types/ItemType';
import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import IMix from '@/interfaces/IMix';
import { getProductById } from '@/services/ProductService';
import { getVariantById } from '@/services/VariantService';
import { getMixById } from '@/services/MixService';
import isProduct from '@/helpers/isProduct';
import isVariant from '@/helpers/isVariant';
import useLocation from '@/hooks/useLocation';
import useModal from '@/hooks/useModal';
import SelectWarehouse from '@/components/SelectWarehouseModal/SelectWarehouse';
import ProductDetailsView from '@views/ItemDetails/ProductDetailsView';
import VariantDetailsView from '@views/ItemDetails/VariantDetailsView';
import MixDetailsView from '@views/ItemDetails/MixDetailsView';
import isMix from '@/helpers/isMix';
import { addItemToShoppingCart } from '@/services/ShoppingCart';
import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import isSelectionMix from '@/helpers/isSelectionMix';
import useShoppingCart from '@/hooks/useShoppingCart';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import useAuth from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';
import EPage from '@/enums/EPages';
import usePermissions from '@/hooks/usePermissions';


const ItemDetailsPage = () => {
  const { t } = useTranslation('common');
  const { shoppingCart, getActiveShoppingCart } = useShoppingCart();
  const screens = useBreakpoint();
  const { authenticated, customer } = useAuth();
  const { isValidAction } = usePermissions();

  const { itemId, itemType } =
    useParams<{
      itemId: IItem['id'];
      itemType: ItemType;
    }>();

  const {
    loading: loadingModal,
    warehouses,
    selectedWarehouse,
    onChangePostalCode,
    onSelectWarehouse,
  } = useLocation();
  const navigate = useNavigate();

  const warehouseModal = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<IProduct | IVariant | IMix>();
  const [qty, setQty] = useState<number>(1);
  const [selection, setSelection] = useState<IShoppingCartItem['selection']>();

  const onAddItemToShoppingCart = async (qtyToAdd = 1) => {
    if (!selectedWarehouse) {
      warehouseModal.show();
      return;
    }
    // TODO: validate selection on mix
    if (!item || !selection) return;
    if (!shoppingCart) {
      message.success({
        content: t('g.shopping_cart_not_found'),
        key: 'shoppingCartKey',
      });
      return;
    }
    
    // TODO: Validate invenory
    try {
      await addItemToShoppingCart({
        entityId: isSelectionMix(selection) ? itemId : selection,
        entityType: itemType === 'variant' ? 'product' : itemType,
        qty: qtyToAdd,
        selection: selection,
        shoppingCartId: shoppingCart.id
      });

      message.success({
        content: t('g.product_added_success'),
        key: 'shoppingCartKey',
      });
      await getActiveShoppingCart();
    } catch (error) {
      console.error(error);
      message.error({
        content: t('g.error_adding_product'),
        key: 'No warehouse',
      });
    }
  };

  const onBuyNow = async (qtyToAdd = 1) => {
    if (!selectedWarehouse) {
      warehouseModal.show();
      return;
    }

    console.log('on buy now ...');
    await onAddItemToShoppingCart(qtyToAdd).then(() => {
      console.log('add item to shopping cart ...', qtyToAdd);
      
      if (
        authenticated 
        && customer 
        && customer.isStaff
        && isValidAction(EBasicActions.Create, EPage.ExtendedCheckout)
      ) {
        navigate('/checkout/pos', { replace: true });
      } else {
        navigate('/checkout', { replace: true });
      }
    });
  }

  const getItem = async (itemType: ItemType, itemId: IItem['id']) => {
    setLoading(true);

    if (itemType === 'product') {
      const product = await getProductById(itemId);
      setItem(product);
    }

    if (itemType === 'variant') {
      const variant = await getVariantById(itemId);
      setItem(variant);
    }

    if (itemType === 'mix') {
      const mix = await getMixById(itemId);
      setItem(mix);
    }

    setLoading(false);
  };

  const isHasMoreOptions = () => warehouses.length > 1;

  const onIncreaseItemToAdd = () => {
    if (!selectedWarehouse) {
      warehouseModal.show();
      return;
    }
    setQty(prev => prev + 1);
  };

  const onDecreaseItemToAdd = () => {
    if (!selectedWarehouse) {
      warehouseModal.show();
      return;
    }
    setQty(prev => prev - 1);
  };

  useEffect(() => {
    if (!itemType || !itemId) return;

    getItem(itemType, itemId);
    setQty(1);
  }, [itemType, itemId]);

  return (
    <Card bordered={false} style={{ padding: screens.md ? '0 5%' : '0' }}>
      <Modal
        title={t('g.enter_your_postal_code')}
        open={warehouseModal.visible}
        onCancel={() => warehouseModal.hide()}
        footer={null}
      >
        <SelectWarehouse
          loading={loadingModal}
          initialValue={selectedWarehouse}
          onChangePostalCode={onChangePostalCode}
          onChangeWarehouse={onSelectWarehouse}
          options={warehouses}
        />
      </Modal>
      {item && isProduct(item) && !loading && (
        <ProductDetailsView
          product={item}
          qty={qty}
          onAddToCart={onAddItemToShoppingCart}
          onBuyNow={onBuyNow}
          onIncreaseItemToAdd={() => onIncreaseItemToAdd()}
          onDecreaseItemToAdd={() => onDecreaseItemToAdd()}
          onClickMoreOptions={() => warehouseModal.show()}
          hasMoreOptions={isHasMoreOptions()}
          onChangeSelection={setSelection}
        />
      )}
      {item && isVariant(item) && !loading && (
        <VariantDetailsView
          variant={item}
          qty={qty}
          onAddToCart={onAddItemToShoppingCart}
          onBuyNow={onBuyNow}
          onIncreaseItemToAdd={() => onIncreaseItemToAdd()}
          onDecreaseItemToAdd={() => onDecreaseItemToAdd()}
          onClickMoreOptions={() => warehouseModal.show()}
          hasMoreOptions={isHasMoreOptions()}
          onChangeSelection={setSelection}
        />
      )}
      {item && isMix(item) && !loading && (
        <MixDetailsView
          mix={item}
          qty={qty}
          onAddToCart={onAddItemToShoppingCart}
          onBuyNow={onBuyNow}
          onIncreaseItemToAdd={() => onIncreaseItemToAdd()}
          onDecreaseItemToAdd={() => onDecreaseItemToAdd()}
          onClickMoreOptions={() => warehouseModal.show()}
          hasMoreOptions={isHasMoreOptions()}
          onChangeSelection={setSelection}
        />
      )}
      {loading && (
          <Row justify='center'>
            <Col>
              <Spin size='large' className='m-4' />
            </Col>
          </Row>
        )
      }
    </Card>
  );
};

export default ItemDetailsPage;
