import { List } from 'antd';
import IWarehouse from '@/interfaces/IWarehouse';
import Address, { EAddressLayout } from '@/components/Address';

interface IWarehouseListProps {
  initialValue?: IWarehouse['id'];
  onClick: (warehouseId: IWarehouse['id']) => void;
  warehouses: IWarehouse[];
}

const WarehouseList = ({
  initialValue,
  onClick,
  warehouses,
}: IWarehouseListProps) => {

  return (
    <List
      dataSource={warehouses}
      grid={{ gutter: 16, column: 1 }}
      renderItem={(warehouse: IWarehouse) => (
        <List.Item className={['warehouse-card', warehouse.id === initialValue ? 'active' : ''].join(' ')} onClick={() => onClick(warehouse.id)}>
          <Address address={warehouse.address} layout={EAddressLayout.Oneline} className='m-0'/>
        </List.Item>
      )}
    />
  );
};

export default WarehouseList;
