import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useShoppingCart from "@/hooks/useShoppingCart";

const ShoppingCartGetter = () => {
  const location = useLocation();
  const { getActiveShoppingCart } = useShoppingCart();

  useEffect(() => {
    if (typeof getActiveShoppingCart === 'function') {
      getActiveShoppingCart();
    }
  }, [location.pathname]);

  return <Outlet />;
};

export default ShoppingCartGetter;
