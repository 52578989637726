import EBasicActions from '@/enums/EBasicActions';
import EPages from '@/enums/EPages';
import useAuth from '@/hooks/useAuth';


function usePermissions() {
  const auth = useAuth();

  const isValidAction = (action: EBasicActions, page: EPages) => {
    if (!auth || !auth?.actions) return false;

    if (!auth.actions[page]) return false;

    const isValid = auth.actions[page].includes(action);

    return isValid;
  };

  return {
    isValidAction,
  };
}

export default usePermissions;
