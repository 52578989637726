import { UseService } from '@/hooks/useService';
import IAddressFormData from '@/interfaces/IAddressFormData';
import IContactFormData from '@/interfaces/IContactFormData';
import ICountry from '@/interfaces/ICountry';
import ISuburb from '@/interfaces/ISuburb';
import IUserShippingContactFormContainerData from '@/interfaces/IUserShippingContactFormContainerData';
import getPostalCodes from '@/services/PostalCode';
import { Row, Col, FormInstance, Form, Checkbox, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import AddressForm from './AddressForm';
import ContactForm from './ContactForm';

interface IUserShippingContactFormContainerProps {
  countriesState: UseService<ICountry[]>;
  addressForm: FormInstance<IAddressFormData>;
  contactForm: FormInstance<IContactFormData>;
  initialSuburbs?: Partial<ISuburb>[];
  form: FormInstance<IUserShippingContactFormContainerData>;
}

const UserShippingContactFormContainer = ({
  addressForm,
  contactForm,
  countriesState,
  initialSuburbs = [],
  form,
}: IUserShippingContactFormContainerProps) => {
  const { t } = useTranslation('common');

  const initialValues = {
    isDefault: false,
  };

  return (
    <Row justify='center'>
      <Form form={form} initialValues={initialValues}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              {!countriesState.data ? (
                <Row justify='center'>
                  <Col>
                    <Spin size='large' className='m-4' />
                  </Col>
                </Row>
              ) : (
                <ContactForm form={contactForm} />
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {!countriesState.data ? (
                <Row justify='center'>
                  <Col>
                    <Spin size='large' className='m-4' />
                  </Col>
                </Row>
              ) : (
                <AddressForm
                  countries={countriesState.data}
                  getPostalCodes={getPostalCodes}
                  initialSuburbs={initialSuburbs}
                  name='AddressName'
                  form={addressForm}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} span={24}>
              <Form.Item
                name='isDefault'
                label={t('g.default')}
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </Row>
  );
};

export default UserShippingContactFormContainer;
