import IShoppingCartItem from '@/interfaces/IShoppingCartItem';
import IWarehouse from '@/interfaces/IWarehouse';
import isProduct from '@/helpers/isProduct';
import isVariant from '@/helpers/isVariant';
import IProductConfiguration from '@/interfaces/IProductConfiguration';
import ItemType from '@/types/ItemType';

interface IGetConfiguration {
  id: string;
  type: ItemType;
  config: {
    config: IProductConfiguration[],
    isInWarehouse: boolean,
    inventory: number
  }
}

const validateItemsForInventoryAndWarehouse = async (
  shoppingCartItems: IShoppingCartItem[],
  warehouseId: IWarehouse['id']
) => {
  try {
    const configurations = await Promise.all(
      shoppingCartItems.map((shoppingCartItem) => getConfigurations(shoppingCartItem, warehouseId))
    );
  
    let statusArray: boolean[] = [];

    shoppingCartItems.forEach((shoppingCartItem) => {
      const configurationFound = configurations.find((configuration) => (
        configuration.id === shoppingCartItem.entityId
        && configuration.type === shoppingCartItem.entityType
      ));

      if (!configurationFound) throw new Error('Item not found');

      const { config } = configurationFound;

      const allowSaleWithoutInventory = config.config.every(
        cfg => cfg.inventory.allowSaleWithoutInventory === true
      );
      
      const isPassed = (allowSaleWithoutInventory && config.isInWarehouse) || (config.inventory > 0 && config.isInWarehouse);

      statusArray.push(isPassed);
    });
    console.log('statusArray', statusArray)
    const allItemsAreAvailable = statusArray.every((status) => status === true);
  
    if (!allItemsAreAvailable) {
      const index = statusArray.findIndex((status) => status === true);

      return {
        passed: false,
        indexOfError: index,
      };
    }
  
    return {
      passed: allItemsAreAvailable,
      indexOfError: null,
    };
  } catch (error) {
    console.log(error);

    return {
      passed: false,
      indexOfError: 0,
    };
  }
};

const getConfigurations = async (
  shoppingCartItem: IShoppingCartItem,
  warehouseId: IWarehouse['id']
): Promise<IGetConfiguration> => {
  const { 
    item, 
    selection 
  } = shoppingCartItem;

  if (!item) return {
    id: '0',
    type: 'product',
    config: {
      config: [],
      isInWarehouse: false,
      inventory: 0
    }
  }

  if (isProduct(item)) {
    const promises = await Promise.all([
      item.getProductsConfig({ warehouseId }),
      item.isInWarehouse({ warehouseId }),
      item.getInventory({ warehouseId }) ?? 0
    ]);

    return {
      id: item.id,
      type: item.key,
      config: {
        config: promises[0],
        isInWarehouse: promises[1],
        inventory: promises[2]
      }
    };
  }

  if (isVariant(item)) {
    const promises = await Promise.all([
      item.getProductsConfig({ 
        selectionItem: selection,
        warehouseId, 
      }),
      item.isInWarehouse({ warehouseId }),
      item.getInventory({ 
        selectionItem: selection,
        warehouseId 
      }) ?? 0
    ]);

    return {
      id: item.id,
      type: item.key,
      config: {
        config: promises[0],
        isInWarehouse: promises[1],
        inventory: promises[2]
      }
    };
  }

  const promises = await Promise.all([
    item.getProductsConfig({ 
      selectionItem: selection,
      warehouseId, 
    }),
    item.isInWarehouse({ warehouseId }),
    item.getInventory({ 
      selectionItem: selection,
      warehouseId 
    }) ?? 0
  ]);

  return {
    id: item.id,
    type: item.key,
    config: {
      config: promises[0],
      isInWarehouse: promises[1],
      inventory: promises[2]
    }
  };
};

export default validateItemsForInventoryAndWarehouse;
