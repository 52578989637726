import EDiscountType from '@/enums/EDiscountType';
import EPriceRestrictionType from '@/enums/EPriceRestrictionType';
import IMixTopping from '@/interfaces/IMixTopping';
import IWarehouse from '@/interfaces/IWarehouse';

const getToppingPrice = async (topping: IMixTopping, warehouseId?: IWarehouse['id']) => {
    const { type, discountType, discount, } = topping.priceRestrictions;

    const {
      Marginal,
      WarehousePrice,
      NotAdditionalCost,
      DiscountedWarehousePrice,
    } = EPriceRestrictionType;

    if (type === NotAdditionalCost) return 0;
    if (type === Marginal) {
      return topping.priceRestrictions.price;
    }

    if (type === WarehousePrice) {
      const price = await topping.product.getPrice({ warehouseId});
      return price;
    }

    if (type === DiscountedWarehousePrice) {
      const price = await topping.product.getPrice({ warehouseId});
      
      if (discountType === EDiscountType.Amount) {
        const newPrice = price - discount;
        return newPrice < 0 ? 0 : newPrice;
      }

      if (discountType === EDiscountType.Percent) {
        const newPrice = (price - ((price / 100) * discount));
        return newPrice < 0 ? 0 : newPrice;
      }
    }

    return topping.priceRestrictions.price;
  };

  export default getToppingPrice;