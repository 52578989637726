import IShoppingCartItem from "@/interfaces/IShoppingCartItem";
import isProduct from "./isProduct";
import isVariant from "./isVariant";

const getShoppingCartItemStepper = (shoppingCartItem: IShoppingCartItem) => {
  if (!shoppingCartItem.item) return 1;

  if (isProduct(shoppingCartItem.item)) return shoppingCartItem?.item?.stepper ?? 1;
  
  if (isVariant(shoppingCartItem.item)) {
    const selectedProduct = shoppingCartItem.item.products.find(
      (product) => product.id === shoppingCartItem.selection
    );
    
    return selectedProduct?.stepper ?? 1;
  } 
    
  return 1;
};

export default getShoppingCartItemStepper;
