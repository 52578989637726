import IShoppingCartItemWithConfig from "@/interfaces/IShoppingCartItemWithConfig";
import isMix from "./isMix";
import SelectionMix from "@/interfaces/ISelectionMix";
import IProduct from '../interfaces/IProduct';
import IMixSection from "@/interfaces/IMixSection";

const getMixToppingSelection = (shoppingCartItem :IShoppingCartItemWithConfig) => {
  if(!shoppingCartItem.item || !isMix(shoppingCartItem.item)) return [];
  
  const selectionMix = shoppingCartItem.selection as SelectionMix;
  const mix = shoppingCartItem.item;
  
  const toppingSelects: {
    product: IProduct,
    sectionId: IMixSection['id'],
    qty: number,
  }[] = [];

  const toppings: {
    product: IProduct,
    sectionId: IMixSection['id']
  }[] = [];

  mix.sections.forEach(section => {
    section.toppings.forEach(topping => {
      toppings.push({
        product: topping.product,
        sectionId: section.id
      });
    });
  });

  selectionMix.sections.forEach(section => {
    section.products.forEach(product => {
      const toppingFound = toppings.find(topping => (
        topping.product.id === product.productId
        && topping.sectionId === section.sectionId
      ));

      if (!toppingFound) return;

      toppingSelects.push({
        product: toppingFound?.product,
        sectionId: toppingFound?.sectionId,
        qty: product.qty,
      });
    });
  });

  return toppingSelects;
};

export default getMixToppingSelection;
