import { LockOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Rule } from "antd/lib/form";
import hashPin from "@/services/AccessPinService";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import _ from "lodash";

const { Text } = Typography;

interface IFormFields{
  pin: string,
}

interface AccessPinProps{
  callback: (key: string) => void,
  pin?: IFormFields['pin'],
  label?: string,
  placeholder?: string,
  submitBtn?: boolean, 
  rules?: Rule[],
  authFail?: boolean,
}

const AccessPin = (props: AccessPinProps) => {
  const { t } = useTranslation('common');
  const {
    pin = '',
    callback,
    submitBtn = false,
    label,
    placeholder = 'PIN: X X X X X X',
    rules = [{ required: true }],
    authFail = false,
  } = props;

  const [accessPinForm] = useForm<IFormFields>();
  accessPinForm.setFieldsValue({ pin: authFail ? '' : pin });

  const onSubmitPin = async() => {
    const pin = accessPinForm.getFieldValue('pin');
    const key = await hashPin(pin);
    callback(key);
  }

  let customRules: Rule[] = rules;

  if(authFail){
    customRules = customRules.map(rule => {
      if(_.has(rule, 'required')){
        return { 
          required: true, 
          message: t('g.incorrect_pin_type_another_one'),
        };
      }

      return rule;
    });
  }
  
  useEffect(() => {
    authFail && accessPinForm.submit();
  }, []);
  
  return (
    <Row gutter={[0, 6]}>
      {label && (
        <Col span={24}>
          <Text>
            {label}
          </Text>
        </Col>
      )}
      <Col span={24}>
        <Form form={accessPinForm} onFinish={onSubmitPin}>
          <Row gutter={[0, 16]} justify="end">
            <Col span={24}>
              <Form.Item name="pin" rules={customRules} className="m-0" validateFirst>
                <Input 
                  autoFocus
                  size="large"
                  prefix={<LockOutlined />} 
                  placeholder={placeholder}
                />
              </Form.Item>
            </Col>
            {submitBtn && (
              <Col>
                <Form.Item>
                  <Button className="bg-primary" type="primary" htmlType="submit">
                    {t('g.submit')}
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Col>
      <Col span={24} className="pt-4">
        <Row justify="center">
          <Text>
            {t('g.enter')} {t('g.or').toLocaleLowerCase()}
            <Button
              style={{ padding: '4px' }}
              type="link"
              onClick={onSubmitPin}
            >
              {t('g.click_here_to_continue').toLocaleLowerCase()}
            </Button>
          </Text>
        </Row>
      </Col>
    </Row>
  );
}

export default AccessPin;
