import React from 'react';
import { Button, Card, Col, Rate, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import BrandImage from './BrandImage';
import IPaymentSource from '@/interfaces/IPaymentSource';

interface IPaymentSourceCard {
  paymentSource: IPaymentSource;
  isUpdate: boolean;
  onDelete: (paymentSourceId: IPaymentSource['id']) => void;
  onUpdate: (paymentSourceId: IPaymentSource['id']) => void;
  onDefault: (paymentSourceId: IPaymentSource['id']) => void;
}

const PaymentSourceCard = ({
  paymentSource,
  isUpdate = true,
  onDelete,
  onUpdate,
  onDefault
  
}: IPaymentSourceCard) => {
  const { t } = useTranslation('common');
  
  const { 
    id, brand, last4, cardHolder, 
    expirationMonth, expirationYear, 
    isDefault
  } = paymentSource;
  
  return (
    <Card style={{ maxWidth: "500px" }} bodyStyle={{ padding: '12px' }}>
      <Row>
        <Col flex={2}>
          <BrandImage brand={brand} />
        </Col>
        <Col flex="60%">
          <Row>
            <Col span={24}>
              <Typography.Text strong ellipsis>
                {cardHolder}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text>
                {`**** **** **** ${last4}`}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col  flex={1}>
          <Row justify="center">
            <Col>
              <Rate count={1} onChange={() => onDefault(id)} value={isDefault ? 1 : 0}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text type="secondary">
            {t('g.expiration_date')}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>
            {`${expirationMonth}/${expirationYear}`}
          </Typography.Text>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col flex="auto">
          <Button 
            shape="circle" 
            icon={<DeleteOutlined />} 
            onClick={() => onDelete(id)}
          />
        </Col>
        {
          !isUpdate ? (
            <Col flex="100px">
              <Button 
                icon={<ReloadOutlined className="fix-icon"/>}
                onClick={() => onUpdate(id)}
              >
                {t('g.update')}
              </Button>
            </Col>
          ) : null
        }
      </Row>
    </Card>
  );
};

export default PaymentSourceCard;
