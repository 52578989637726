import React, { useEffect } from 'react';
import { 
  Typography, 
  Card, 
  Image,
  Col,
  Row, 
} from 'antd';
import LoginForm from '@/components/Forms/LoginForm';
import useBreakpoints from '@/hooks/useBreakpoint';
import useAuth from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import isUrl from '@/helpers/isUrl';
import { BgPatternImage, LogoImage } from '@/assets/images';

const Login = () => {
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  const { authenticated } = useAuth();
  const navigate = useNavigate();

  const { Paragraph } = Typography;

  const customImage = process.env.REACT_APP_BG_LOGIN_IMG;

  const backgroundImage = customImage 
    ? isUrl(customImage) ?  `URL(${customImage})` : customImage
    : `URL(${BgPatternImage})`;
  
  useEffect(() => {
    if (authenticated) navigate('/');
  });

  return (
    <Row
      className="min-h-screen"
      style={{ background: backgroundImage }}
      justify="center"
      align="middle"
    >
      <Col
        flex={isBreakpointEqualsAndBelow('md') ? "70%" : "35%"}
      >
        <Card className="px-6 w-full min-h-full">
          <Row justify="center">
            <Col>
              <Image 
                preview={false}  
                style={{
                  width: '100%',
                  maxHeight: '120px',
                }}
                src={process.env.REACT_APP_LOGO_URL || LogoImage}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Paragraph className="text-center mb-4">
                {process.env.REACT_APP_SLOGAN ?? ''}
              </Paragraph>
            </Col>
            <Col span={24}>
              <LoginForm />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
