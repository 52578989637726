import React from 'react';
import { Table, Tag, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import IShoppingCart from '@/interfaces/IShoppingCart';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ShoppingCartStatusTag from '@/components/ShoppingCart/ShoppingCartStatusTag';
import DotsDropdown from '@/components/General/DotsDropdown';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface IShoppingCartsList {
  dataSorce: IShoppingCart[];
  pagination: TablePaginationConfig;
  loading: boolean;
  currentShoppingCartId?: IShoppingCart['id'];
  onChangePage: (pagination: TablePaginationConfig) => void;
  actions?: (data: IShoppingCart) => ItemType[];
}

const ShoppingCartsList = ({
  dataSorce,
  pagination,
  loading,
  currentShoppingCartId,
  onChangePage,
  actions
}: IShoppingCartsList) => {
  const { t } = useTranslation('common');

  const columns: ColumnsType<IShoppingCart> = [
    {
      title: 'id',
      dataIndex: "id",
      key: 'id',
    },
    {
      title: t('g.alias'),
      dataIndex: 'alias',
      key: 'alias',
      width: '250px',
      render: (value :IShoppingCart['alias']) => (
        <Typography.Text 
          ellipsis={{ tooltip: value ?? '----' }}
          style={{ width: "250px" }}
        >
          {value ?? '----'}
        </Typography.Text>
      ),
    },
    {
      title: t('g.products'),
      dataIndex: 'items',
      key: 'products',
      render: (value :IShoppingCart['items']) => (
        <Typography.Text>
          {value.length ?? 0}
        </Typography.Text>
      ),
    },
    {
      title: t('g.status'),
      dataIndex: 'status',
      key: 'status',
      render: (value :IShoppingCart['status']) => (<ShoppingCartStatusTag status={value}/>),
    },
    {
      title: t('g.owner'),
      dataIndex: 'owner',
      key: 'owner',
      render: (value: IShoppingCart['owner']) => (
        value?.name 
        ? <Typography.Text>
            {value.name}
          </Typography.Text> 
        : <Tag>
            {t('g.guest')}
          </Tag>
      ),
    },
    {
      title: t('g.creator'),
      dataIndex: 'creator',
      key: 'creator',
      render: (value: IShoppingCart['creator']) => (
        value?.name 
        ? <Typography.Text>
            {value.name}
          </Typography.Text> 
        : <Tag>
            {t('g.auto')}
          </Tag>
      ),
    },
    {
      title: t('g.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: IShoppingCart['createdAt']) => (<p>{moment(value).format('DD/MM/YYYY')}</p>)
    },
    {
      title: t('g.expiration_date'),
      dataIndex: 'expDate',
      key: 'expDate',
      render: (value: IShoppingCart['expDate']) => (<p>{moment(value).format('DD/MM/YYYY')}</p>)
    },
    ...actions ? [{
      title: t('g.actions'),
      dataIndex: 'id',
      key: 'id',
      width: 30,
      render: (id: IShoppingCart['id'], data: IShoppingCart) => {
        const dropdownMenu = actions(data);

        return (
          <div>
            <DotsDropdown 
              options={dropdownMenu}
              trigger={['click']}
              className="float-right cursor-pointer"
              placement="bottomRight"
            />
          </div>
        );
      }
    }] : [],
  ];

  return (
    <Table
      loading={loading}
      key="shopping_carts_list"
      pagination={pagination}
      onChange={onChangePage}
      dataSource={dataSorce}
      columns={columns}
      className="overflow-y-auto"
      rowClassName={(record) => {
        if (!currentShoppingCartId) return '';
        if (record.id === currentShoppingCartId) return "bg-sky-100";
        return '';
      }}
    />
  );
};

export default ShoppingCartsList;
