import React from 'react';
import { Row, Col, Typography, Button, FormInstance } from 'antd';
import ContactForm from '@/components/MyAddresses/ContactForm';
import { useTranslation } from 'react-i18next';
import IContactFormData from '@/interfaces/IContactFormData';
import { useNavigate } from 'react-router-dom';

interface ICheckoutContactPage {
  contactForm: FormInstance<IContactFormData>;
}

const CheckoutContactPage = ({
  contactForm,
}: ICheckoutContactPage) => {
  const { t } =useTranslation('common');
  const navigate = useNavigate();

  return (
    <Row align="middle">
      <Col span={24}>
        <Row gutter={[0, 24]}>
          <Col sm={{ span: 16, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Typography.Title level={3} className="underline">
              {t('checkout.contact_information')}
            </Typography.Title>
          </Col>
          <Col sm={{ span: 8, order: 2 }} xs={{ span: 24, order: 1 }}>
            <Row justify="end" align="top">
              <Col>
                <Button 
                  onClick={() => navigate('/login')}
                  type="primary" 
                  className="primary-button"
                >
                  {t('g.login')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Paragraph>
          {t('hints.complete_the_following_form')}
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <ContactForm 
          form={contactForm}
        />
      </Col>
    </Row>
  );
};

export default CheckoutContactPage;
