import Day from '@/enums/Day';
import ISchedule from '@/interfaces/ISchedule';
import IScheduleDay from '@/interfaces/IScheduleDay';
import moment from 'moment';
import { Moment } from 'moment';

function getScheduleDayByDate(schedule: ISchedule, referenceDate: Moment = moment()): IScheduleDay<Day> {
  const dayToGet = referenceDate.day();

  switch (dayToGet) {
    case 1:
      return schedule[Day.Monday];
    case 2:
      return schedule[Day.Tuesday];
    case 3:
      return schedule[Day.Wednesday];
    case 4:
      return schedule[Day.Thursday];
    case 5:
      return schedule[Day.Friday];
    case 6:
      return schedule[Day.Saturday];
    case 0:
      return schedule[Day.Sunday];
  }

  return schedule[Day.Monday];
}

export default getScheduleDayByDate;
