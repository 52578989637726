import IContactInfo from '@/interfaces/IContactInfo';
import ICustomer from '@/interfaces/ICustomer';
import IOrder from '@/interfaces/IOrder';
import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import DeliveriesList from './DeliveriesList';
import OrderDetailsCard from './OrderDetailsCard';
import useOrderTicket from '@/hooks/useOrderTicket';
import useAuth from '@/hooks/useAuth';
import TagPreviewFiles from '../TagPreviewFiles/TagPreviewFiles';

interface IOrderreciptProps {
  order: IOrder;
}

const OrderRecipt = ({ order }: IOrderreciptProps) => {
  const { t } = useTranslation('common');
  const { loading, printAndOpen } = useOrderTicket(order);

  const { authenticated, customer } = useAuth();

  const contactInfo: IContactInfo = {
    email: order.customer.email,
    name: order.customer.name,
    phone: order.customer.phone,
  };

  if(order.customer.hasOwnProperty('id')){
    const customer = order.customer as ICustomer;
    contactInfo.id = customer.id;
  }

  return (
    <Row>
      <Col span={24}>
        <Row className='mb-4'>
          <Col span={24}>
            <Row>
              <Typography.Title level={3}>
                {t('g.order_details')}
              </Typography.Title>
            </Row>
            <Row>
              <OrderDetailsCard order={order} />
            </Row>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col span={24}>
            <Row>
              <Typography.Title level={3}>
                {t('g.delivery_details')}
              </Typography.Title>
            </Row>
            <DeliveriesList
              deliveries={order.deliveries}
              contactInfo={contactInfo}
            />
          </Col>
        </Row>
        <Row justify="end" gutter={[16,8]}>
          {(order.attachments.length > 0) && (
            <Col>
              <TagPreviewFiles 
                prefix={<FileTextOutlined />}
                files={order.attachments} 
                getUrl={attachment => attachment.url} 
                getLabel={attachment => attachment.filename}
              />
            </Col>
          )}
          {(authenticated && customer && customer.isStaff) && (
            <Col>
              <Button loading={loading} onClick={() => printAndOpen()}>
                <PrinterOutlined />
                {t('g.print_ticket')}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default OrderRecipt;
