import { adapterDtoToQuantifier } from '@/adapters/QuantifierAdapter';
import axiosApiConfig from '@/config/axiosConfig';
import IQuantifier from '@/interfaces/IQuantifier';
import IQuantifierDto from '@/interfaces/IQuantifierDto';

const getQuantifiers = async (params?: any): Promise<IQuantifier[]> => {
  const result = await axiosApiConfig.get<IQuantifierDto[]>('/quantifiers', { params: { ...params } });

  const quantifiers = result.data.map(quantifier => adapterDtoToQuantifier(quantifier));
  
  return quantifiers;
};

export default getQuantifiers;
