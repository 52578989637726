import React from 'react';
import { 
  Row, 
  Col,
  Typography,
} from 'antd';
import ISchedule from '@/interfaces/ISchedule';
import isOpen from '@/helpers/IsOpen';
import nextCloseSchedule from '@/helpers/nextCloseSchedule';
import nextOpenSchedule from '@/helpers/nextOpenSchedule';
import isValidScheduleDay from '@/helpers/isValidScheduleDay';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/hooks/useBreakpoint';

const { Text } = Typography;

interface IScheduleProps {
  schedule: ISchedule;
  actions?: React.ReactNode;
  miniShedule?: boolean;
}

const Schedule = ({
  schedule,
  actions,
  miniShedule = false
}: IScheduleProps) => {
  const { t } = useTranslation('common');
  const { isBreakpointEqualsAndBelow } = useBreakpoints();
  
  let scheduleDisplay: React.ReactNode;

  if(!isValidScheduleDay(schedule)){
    scheduleDisplay = (
      <Text 
        className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }  
      >
        { t('g.schedule_no_available') }
      </Text>
    );
  } 
  else if (miniShedule) {
    switch (isOpen(schedule)) {
      case true:
        scheduleDisplay = (
          <Text 
            className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }  
          >
            <Text type="success">{t('g.open')}</Text>
          </Text>
        );
      break;
      case false:
        scheduleDisplay = (
          <Text 
            className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }
          >
            <Text type="danger">{t('g.close')}</Text>
          </Text>
        );
      break;
    }
  } else {
    switch (isOpen(schedule)) {
      case true:
        scheduleDisplay = (
          <Text 
            className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }  
          >
            <Text type="success">{t('g.open')}</Text> - {t('g.close_at')}: {nextCloseSchedule(schedule)}
          </Text>
        );
      break;
      case false:
        scheduleDisplay = (
          <Text 
            className={ isBreakpointEqualsAndBelow('sm') ? "text-xs text-white" : "text-sm text-white" }
          >
            <Text type="danger">{t('g.close')}</Text> - {t('g.open_at')}: {nextOpenSchedule(schedule)}
          </Text>
        );
      break;
    }
  }

  return (
    <Row>
      {
        !isBreakpointEqualsAndBelow('xs') && (
          <Col span={24}>
            {scheduleDisplay}
          </Col>
        )
      }
      <Col span={24}>
        {actions}
      </Col>
    </Row>
  );
};

export default Schedule;
