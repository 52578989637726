import React from 'react';
import i18next from 'i18next';
import OrderType from '@/types/OrderType';
import { useTranslation } from 'react-i18next';
import formatCurrency from '@/utils/formatCurrency';
import { Card, Col, Radio, Row, Tag, Typography } from 'antd';
import IGroupDeliveryShippingConfiguration from '@/interfaces/IGroupDeliveryShippingConfiguration';

interface IDeliveryTypeOptions {
  title: string;
  price: number;
  date: string;
  qty: number;
  value: OrderType;
  shippings: IGroupDeliveryShippingConfiguration[];
}

interface QtyShipping {
  qty: number;
  label: string;
  id: string;
}

const DeliveryTypeOption = ({
  title,
  price,
  date,
  qty,
  shippings,
  value,
}: IDeliveryTypeOptions) => {
  const { t } = useTranslation('common');

  const formatShippings = (): QtyShipping[] => {
    const qtyShippings: QtyShipping[] = [];

    shippings.forEach(shipping => {
      const isExistIndex = qtyShippings.findIndex(qtyShipping => qtyShipping.id === shipping.shippingType.id);

      if (isExistIndex === -1) {
        qtyShippings.push({
          id: shipping.shippingType.id,
          label: i18next.language === 'en' ? shipping.shippingType.labelEn : shipping.shippingType.labelEs,
          qty: 1
        });

        return;
      }

      qtyShippings[isExistIndex].qty += 1;
    });

    return qtyShippings;
  };

  const newFormatShippings = formatShippings();
  
  return (
    <Card>
      <Row align="middle">
        <Col span={4}>
          <Row justify="center">
            <Col>
              <Radio value={value}/>
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Row gutter={[0, 10]}>
            <Col span={17}>
              <Typography.Text>
                {title}
              </Typography.Text>
            </Col>
            <Col span={7}>
              <Row justify="end">
                <Col>
                  <Typography.Text>
                    {formatCurrency(price)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Text>
                {`${qty} ${qty <= 1 ? t('g.shipping') : t('g.shippings')}`}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Row>
                {newFormatShippings.map(shipping => (
                  <Tag key={shipping.id}>{`${shipping.qty} ${shipping.label}`}</Tag>
                ))}
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Text>
                {`${t('g.last_shipping')} ${date}`}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DeliveryTypeOption;
