import axiosApiConfig from '@/config/axiosConfig';
import IParams from '@/interfaces/IParams';
import ISaleOrigin from '@/interfaces/ISaleOrigin';

interface ISaleOriginDTO {
    code: string;
    aliasEs: string;
    aliasEn: string;
}

const formatSaleOriginDTO = (saleOriginRegister: ISaleOriginDTO): ISaleOrigin => {
  const { code, aliasEn, aliasEs } = saleOriginRegister;
  return {
    code,
    alias: {
      es: aliasEs,
      en: aliasEn,
    }
  };
};

export const getSaleOrigin = async (saleOriginCode: ISaleOrigin['code']): Promise<ISaleOrigin> => {
  const result = await axiosApiConfig.get<ISaleOriginDTO>(`/sales_origins/${saleOriginCode}`);
  const saleOrigin: ISaleOrigin = formatSaleOriginDTO(result.data);

  return saleOrigin;
};

export const getSalesOrigins = async (params?: IParams): Promise<ISaleOrigin[]> => {
  const result = await axiosApiConfig.get<{ data: ISaleOriginDTO[] }>('/sales_origins', { params });
  const data: ISaleOrigin[] = result.data.data.map(formatSaleOriginDTO);

  return data;
};
