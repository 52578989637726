import IMix from '@/interfaces/IMix';
import IProduct from '@/interfaces/IProduct';
import IVariant from '@/interfaces/IVariant';
import { NavigateFunction } from 'react-router-dom';
import isProduct from '@/helpers/isProduct';
import isVariant from '@/helpers/isVariant';
import isMix from '@/helpers/isMix';

const goToItemDetails = (
  item: IProduct | IVariant | IMix,
  navigate: NavigateFunction
) => {
  const itemId = item.id;
    
  if(isProduct(item)){
    navigate(`/item/product/${itemId}`);
    return;
  }

  if(isVariant(item)){
    navigate(`/item/variant/${itemId}`);
    return;
  }

  if(isMix(item)){
    navigate(`/item/mix/${itemId}`);
    return;
  }
};

export default goToItemDetails;
