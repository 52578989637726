import axiosApiConfig from '@/config/axiosConfig';
import ValidateCouponResults from "@/enums/EValidCouponResults";

interface IValidateCouponResult {
  discount?: number;
  result: boolean;
  msg: ValidateCouponResults;
}

interface IValidateCouponPayload {
  amount: number;
}

interface IValidateCouponParams {
  couponCode: string;
  payload: IValidateCouponPayload;
}

export const validateCoupon = async (params: IValidateCouponParams): Promise<IValidateCouponResult> => {
  const result = await axiosApiConfig.post<IValidateCouponResult>('/coupons/validate', params);

  return result.data;
};
