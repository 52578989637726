import { IGroupDeliveryRequestDTO } from '@/interfaces/IGroupDeliveryRequest';
import IGroupDeliveryDeniedResponse from '@/interfaces/IGroupDeliveryDeniedResponse';
import IGroupDeliverySuccessResponse from '@/interfaces/IGroupDeliverySuccessResponse';
import axiosApiConfig from '@/config/axiosConfig';
import IGroupPickUpRequest from '@/interfaces/IGroupPickUpRequest';
import IGroupDeliveryShippingConfiguration from '@/interfaces/IGroupDeliveryShippingConfiguration';
import IGroupPickUpSuccessResponse from '@/interfaces/IGroupPickUpSuccessResponse';
import ICheckout from '@/interfaces/ICheckout';
import ICheckoutResponse from '@/interfaces/ICheckoutResponse';

export const getGroupDelivery = async (
  request: IGroupDeliveryRequestDTO
): Promise<IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>> => {
  const result = await axiosApiConfig.post<
    IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupDeliveryShippingConfiguration>
  >('/group_deliveries', request);

  return result.data;
};

export const getGroupPickUp = async (
  request: IGroupPickUpRequest
): Promise<IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupPickUpSuccessResponse>> => {
  const result = await axiosApiConfig.post<
    IGroupDeliveryDeniedResponse | IGroupDeliverySuccessResponse<IGroupPickUpSuccessResponse>
  >('/group_pick_ups', request);

  return result.data;
};


export const checkout = async (data: ICheckout): Promise<ICheckoutResponse> => {
  const result = await axiosApiConfig.post<ICheckoutResponse>('/express_checkout', {
    ...data,
    host: 'store_host',
  });

  return result.data;
};