import axiosApiConfig from '@/config/axiosConfig';
import IPaymentSource from "@/interfaces/IPaymentSource";
import IConfigPaymentSource from '../interfaces/IConfigPaymentSource';
import IWarehouse from '@/interfaces/IWarehouse';
import EPaymentService from '@/enums/EPaymentService';
import IMetaProp from '../interfaces/IMetaProp';

export interface IGetPaymentSource {
  paymentSources: IPaymentSource[];
  validPaymentSources: IConfigPaymentSource[];
}

interface IGetPaymentSourceProps {
  warehouseId?: IWarehouse['id'];
  paymentServiceCode?: EPaymentService;
}

interface IDeletePaymentSourceProps {
  paymentSourceId: IPaymentSource['id'];
  warehouseId: IWarehouse['id'];
  paymentServiceCode: EPaymentService;
}

interface IUpdatePaymentSourceProps {
  paymentSourceId: IPaymentSource['id'];
  warehouseId: IWarehouse['id'];
  paymentServiceCode: EPaymentService;
  payload: IMetaProp;
}

interface IUpdatePaymentSourceProps {
  paymentSourceId: IPaymentSource['id'];
  warehouseId: IWarehouse['id'];
  paymentServiceCode: EPaymentService;
  payload: IMetaProp;
}

interface ICreatePaymentSourceProps {
  warehouseId: IWarehouse['id'];
  paymentServiceCode: EPaymentService;
  payload: IMetaProp;
}

interface IIsDefaultPaymentSource {
  userId: string;
  paymentSourceId: IPaymentSource['id'];
}

export const getPaymentSourcesByUser = async ({ 
  warehouseId, 
  paymentServiceCode = EPaymentService.Openpay 
}: IGetPaymentSourceProps): Promise<IGetPaymentSource> => {
  const result = await axiosApiConfig.get<IGetPaymentSource>('/payment_sources', { 
    params: { 
      warehouseId, 
      paymentServiceCode 
    } 
  });

  return result.data;
};

export const deletePaymentSource = async ({
  warehouseId,
  paymentSourceId,
  paymentServiceCode = EPaymentService.Openpay,
}: IDeletePaymentSourceProps): Promise<void> => {
  await axiosApiConfig.delete<string>(`/payment_sources/${paymentSourceId}`, {
    params: { 
      warehouseId,
      paymentServiceCode 
    }
  });
};

export const updatePaymentSource = async ({
  warehouseId,
  paymentSourceId,
  paymentServiceCode = EPaymentService.Openpay,
  payload
}: IUpdatePaymentSourceProps): Promise<IPaymentSource> => {
  const paymentSource = await axiosApiConfig.put<IPaymentSource>(`/payment_sources/${paymentSourceId}`, { 
    warehouseId,
    paymentServiceCode,
    payload 
  });

  return paymentSource.data;
};

export const createPaymentSource = async ({
  warehouseId,
  paymentServiceCode = EPaymentService.Openpay,
  payload
}: ICreatePaymentSourceProps) => {
  const paymentSource = await axiosApiConfig.post<IPaymentSource>('/payment_sources', { 
    warehouseId,
    paymentServiceCode,
    payload 
  });

  return paymentSource.data;
};

export const isDefaultPaymentService = async ({ 
  userId, paymentSourceId }: IIsDefaultPaymentSource
): Promise<void> => {
  await axiosApiConfig.patch<IPaymentSource>(`/payment_sources/${paymentSourceId}`, { 
    isDefault: true,
    userId,
  });
};
