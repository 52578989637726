import IOrder from '@/interfaces/IOrder';
import formatCurrencyMX from '@/utils/formatCurrency';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import OrderStatusTag from './OrderStatusTag';

interface IOrderDetailsCardProps {
  order: IOrder;
}

const OrderDetailsCard = ({ order }: IOrderDetailsCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row>
              <Typography.Text>
                {t('g.order_date')}:
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {t('g.order_time')}:
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {t('g.order_number')}:
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {t('g.status')}:
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {t('g.subtotal')}:
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {t('g.shipping')}:
              </Typography.Text>
            </Row>
            {
              order.amounts.discounts ? (
                <Row>
                  <Typography.Text>
                    {t('g.discount')}:
                  </Typography.Text>
                </Row>
              ) : null
            }
            <Row>
              <Typography.Text>
                {t('g.order_total')}:
              </Typography.Text>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Typography.Text>
                {order?.createdAt ? moment(order.createdAt).format('L') : '--'}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {order?.createdAt
                  ? moment(order.createdAt).format('LT a')
                  : '--'}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>{order?.id ?? '--'}</Typography.Text>
            </Row>
            <Row>
              <OrderStatusTag status={order.status} />
            </Row>
            <Row>
              <Typography.Text>
                {order?.amounts?.subtotal
                  ? formatCurrencyMX(order.amounts.subtotal)
                  : '--'}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {order?.amounts?.shipping
                  ? formatCurrencyMX(order.amounts.shipping)
                  : '--'}
              </Typography.Text>
            </Row>
            {
              order?.amounts?.discounts ? (
                <Row>
                  <Typography.Text>
                    {order?.amounts?.discounts
                      ? formatCurrencyMX(order.amounts.discounts)
                      : '--'}
                  </Typography.Text>
                </Row>
              ) : null
            }
            <Row>
              <Typography.Text>
                {order?.amounts?.total
                  ? formatCurrencyMX(order.amounts.total)
                  : '--'}
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderDetailsCard;
