import React from 'react';
import {
  Document, Page, Text, StyleSheet, View
} from '@react-pdf/renderer';
import ICustomer from '@/interfaces/ICustomer';
import INotAuthCustomer from '@/interfaces/INotAuthCustomer';
import EPaymentStrategy from '@/enums/EPaymentStrategy';
import EDeliveryMethod from '@/enums/EDeliveryMethod';
import formatCurrencyMX from '@/utils/formatCurrency';
import Optional from '@/types/Optional';
import IAddress from '@/interfaces/IAddress';

export interface ITicketSubProduct extends Omit<ITicketProduct, 'subProducts'> {
  isExtra: boolean;
}

export interface ITicketProduct {
  id: string;
  name: string;
  price: number | string;
  qty: number;
  subProducts: ITicketSubProduct[];
}

interface ITicketAmount {
  paid: Optional<number>;
  total: number;
  change: Optional<number>;
  subtotal: number;
  shipping: number;
}

interface ITicket {
  address: Optional<IAddress>;
  ticketId: string;
  customer: ICustomer | INotAuthCustomer;
  products: ITicketProduct[];
  createdAt: string;
  salesOrigin: string;
  amounts: ITicketAmount;
  operativeName: string;
  warehouseName: string;
  deliveryMethod: EDeliveryMethod;
  paymentStrategy: EPaymentStrategy;
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  footer: {
    backgroundColor: '#000',
    textAlign: 'center',
    color: '#fff',
    paddingVertical: '10px',
  },
  section: {
    padding: '10px 0px',
    borderBottom: '1px solid #a0a0a0'
  },
  row:{
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 19,
    fontWeight: 'bold',
    padding: '3px 0px',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'semibold',
    padding: '3px 0px',
  },
  text: {
    fontSize: 12,
    fontWeight: 'normal',
    padding: '3px 0px',
  },  
});

const Ticket = ({
  address,
  ticketId,
  customer,
  products,
  createdAt,
  salesOrigin,
  amounts,
  operativeName,
  warehouseName,
  deliveryMethod,
  paymentStrategy,
}: ITicket) => {
  const { Card, Cash } = EPaymentStrategy;

  const addressString = 'Domicilio: '.concat('', address ? (
    `${address.suburb.name}, ${address.street} ${address.extNum}`
  ) : 'N/A'); 

  let deliveryMethodString = 'Envío a domicilio';

  if (deliveryMethod === EDeliveryMethod.Pickup) {
    deliveryMethodString = 'Recoger en tienda';
  }

  let paymentStrategyString: string = 'Pagado con tranferencia';

  if (paymentStrategy === Card) {
    paymentStrategyString = 'Pagado con TDC/TDB';
  }

  if (paymentStrategy === Cash) {
    paymentStrategyString = 'Pagado en efectivo';
  }

  const formatPrice = (price: number | string, qty: number = 1) => {
    if (typeof price === 'string') return price;

    return formatCurrencyMX(price * qty);
  };

  return (
    <Document>
      <Page style={styles.body} size={[400]}>
        <View style={styles.section}>
          <Text style={styles.title}>{customer.name}</Text>
          <Text style={styles.title}>{salesOrigin}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>{customer.phone}</Text>
          <Text style={styles.text}>{addressString}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>{warehouseName}</Text>
          <View style={styles.row}>
            <Text style={{...styles.text, width: '50%'}}>{paymentStrategyString}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{...styles.text, width: '50%'}}>{`${products.length} item(s)`}</Text>
            <Text style={{...styles.text, width: '50%',  textAlign: 'right'}}>{deliveryMethodString}</Text>
          </View>
        </View>

        <View style={styles.section}>
          {products.map(prodcut => (
            <View key={prodcut.id} style={{ marginBottom: '15px' }}>
              <View style={{ ...styles.row }}>
                <Text style={{...styles.text, width: '10%'}}>{`${prodcut.qty}x`}</Text>
                <Text style={{...styles.text, width: '70%', textAlign: 'justify'}}>{prodcut.name}</Text>
                <Text style={{...styles.text, width: '30%', textAlign: 'right'}}>{formatPrice(prodcut.price, prodcut.qty)}</Text>
              </View>
                {prodcut.subProducts.map(subProduct => subProduct.isExtra ? (
                  <View style={styles.row}>
                    <Text style={{...styles.text, width: '10%', textAlign: 'center'}}>+</Text>
                    <Text style={{...styles.text, width: '10%'}}>{`${subProduct.qty}x`}</Text>
                    <Text style={{...styles.text, width: '60%', textAlign: 'justify'}}>{subProduct.name}</Text>
                    <Text style={{...styles.text, width: '30%', textAlign: 'right'}}>{formatPrice(subProduct.price, subProduct.qty)}</Text>
                  </View>
                ) : (
                  <View style={{ ...styles.row, paddingLeft: '10%' }}>
                    <Text style={{...styles.text, width: '10%'}}>{`${subProduct.qty}x`}</Text>
                    <Text style={{...styles.text, width: '70%', textAlign: 'justify'}}>{subProduct.name}</Text>
                    <Text style={{...styles.text, width: '30%', textAlign: 'right'}}>{formatPrice(subProduct.price, subProduct.qty)}</Text>
                  </View>
                ))}
            </View>
          ))}
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={{...styles.subTitle, width: '75%'}}>Subtotal:</Text>
            <Text style={{...styles.subTitle, width: '25%', textAlign: 'right'}}>{formatPrice(amounts.subtotal)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{...styles.subTitle, width: '75%'}}>Flete:</Text>
            <Text style={{...styles.subTitle, width: '25%', textAlign: 'right'}}>{formatPrice(amounts.shipping)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{...styles.subTitle, width: '75%'}}>Total:</Text>
            <Text style={{...styles.subTitle, width: '25%', textAlign: 'right'}}>{formatPrice(amounts.total)}</Text>
          </View>
        </View>

        {
          (amounts.paid && amounts.change) ? (
            <View style={styles.section}>
                <View style={styles.row}>
                  <Text style={{...styles.subTitle, width: '75%'}}>Monto pagado:</Text>
                  <Text style={{...styles.subTitle, width: '25%', textAlign: 'right'}}>{formatPrice(amounts.paid)}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={{...styles.subTitle, width: '75%'}}>Cambio:</Text>
                  <Text style={{...styles.subTitle, width: '25%', textAlign: 'right'}}>{formatPrice(amounts.change)}</Text>
                </View>
            </View>
          ): null
        }
        
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={{...styles.text, width: '50%'}}>ID Interno:</Text>
            <Text style={{...styles.title, width: '50%', textAlign: 'right'}}>{ticketId}</Text>
          </View>
          <Text style={styles.subTitle}>{operativeName}</Text>
        </View>

        <View style={styles.footer}>
          <Text style={{...styles.subTitle}}>{createdAt}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default Ticket;
