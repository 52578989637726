import React, { 
  ReactNode, 
  useLayoutEffect, 
  useState, 
  useEffect,
} from 'react';
import { 
  Button, 
  Col, 
  Row,
  Typography, 
} from 'antd';
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import useDimensions from '@/hooks/useDimensions';

interface IDescription {
  title?: string;
  maskImage?: boolean;
  decription: string;
  beforeExtra?: ReactNode;
  afterExtra?: ReactNode;
  heightLimit?: number;
}

const Description = ({
  title,
  decription,
  beforeExtra,
  afterExtra,
  heightLimit = 100,
  maskImage = false,
}: IDescription) => {
  const { t } = useTranslation('common');
  const { 
    height,
    showMore,
    targetRef, 
    maskClassName,
    isPossibleShowMore,
    onShowMore, 
    getDiminension 
  } = useDimensions({ heightLimit });

  useLayoutEffect(() => {
    getDiminension();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decription]);
  
  return (
    <Row>
      {
        beforeExtra && (
          <Col 
            span={24} 
            style={{
              marginBottom: '15px',
            }}
          >
            {beforeExtra}
          </Col>
        )
      }
      {
        title && (
          <Col span={24}>
            <Typography.Title level={5}>
              {title}:
            </Typography.Title>
          </Col>
        )
      }
      <Col 
        span={24} 
        ref={targetRef} 
        className={maskImage ? `overflow-hidden ${maskClassName}` : "overflow-hidden"}
        style={{ height }}
      >
        <Typography.Text>
          {htmlParser(decription)}
        </Typography.Text>
      </Col>
      {
        isPossibleShowMore && (
          <Col span={24}>
            <div style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}>
              <Button 
                type="link" 
                onClick={() => onShowMore()} 
                style={{ padding: '0px'}}
              >
                {showMore ? t('g.show_less') : t('g.show_more') }
              </Button>
            </div>
          </Col>
        )
      }
      {
        afterExtra && (
          <Col 
            span={24} 
            style={{
              marginTop: '15px',
            }}
          >
            {afterExtra}
          </Col>
        )
      }
    </Row>
  );
};

export default Description;
