import React from 'react';
import { List } from 'antd';
import IMixSection from '@/interfaces/IMixSection';
import MixSection from '@/components/Mix/MixSection';
import { SelectedToppingsType } from '@/containers/views/ItemDetails/MixDetailsView';
import IMixTopping from '@/interfaces/IMixTopping';
import ToppingType from '@/types/ToppingType';
import { SelectedValueType } from '@/containers/views/ItemDetails/useMixConfiguration';

interface IMixSections {
  sections: IMixSection[];
  loading?: boolean;
  selectedOptions: SelectedToppingsType[];
  onChange: (
    topping: IMixTopping, 
    sectionId: IMixSection['id'], 
    type: ToppingType, 
    value: SelectedValueType
  ) => void
}

const MixSections = ({
  sections,
  loading = false,
  selectedOptions,
  onChange,
}: IMixSections) => {
  if (sections.length === 0) return null;

  const sectionsToShow: IMixSection[] = sections.filter(section => section.toppings.length > 0);

  const filterOptionsBySectionId = (sectionId: IMixSection['id']) => {
    return selectedOptions.filter(option => option.sectionId === sectionId);
  };

  return (
    <List 
      dataSource={sectionsToShow}
      renderItem={section => (
        <MixSection 
          loading={loading}
          section={section}
          selectedOptions={filterOptionsBySectionId(section.id)}
          onChange={onChange}
        />
      )}
    />
  );
};

export default MixSections;
