import { Col, List, Radio, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';

interface ICustomRadioSelectProps<T> {
  options: T[];
  optionSelected: T | undefined;
  bordered?: boolean;
  rowSelectable?: boolean;
  renderOption: (option: T) => React.ReactNode;
  renderRadio?: (option: T) => React.ReactNode | void;
  onChange?: (option: T, checked: boolean) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const CustomRadioSelect = <T,>({
  options,
  optionSelected,
  bordered = true,
  rowSelectable = false,
  renderOption,
  renderRadio = () => {},
  onChange = () => {},
  header,
  footer,
}: ICustomRadioSelectProps<T>) => {
  function onClickHandler(event: any){
    const optionString: string = event.target.value;
    const optionParse: T = JSON.parse(optionString);

    const checked = !(optionString === JSON.stringify(optionSelected));

    onChange(optionParse, checked);
  }

  function renderRadioOption(option: T){
    const rowPointer = rowSelectable ? 'cursor-pointer' : '';

    const uuid = uuidv4();

    const onClickRow = rowSelectable ? (event: any) => {
      if (event.target.id === uuid) { return };

      const radioInput = document.getElementById(uuid);
      radioInput?.click();
    } : () => {};

    return (
      <List.Item onClick={onClickRow} className={rowPointer}>
        <Row 
          gutter={[0, 8]}
          align='top' 
          justify="space-between" 
          className="w-full"
        >
          <Col>
            {renderOption(option)}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {renderRadio(option) || <Radio id={uuid} value={JSON.stringify(option)} onClick={onClickHandler} />}
          </Col>
        </Row>
      </List.Item>
    );
  }

  return (
    <Radio.Group 
      className="w-full"
      value={JSON.stringify(optionSelected)}
    >
      <List
        bordered={bordered}
        dataSource={options}
        header={header}
        footer={footer}
        renderItem={option => renderRadioOption(option)}
      />
    </Radio.Group>
  );
};

export default CustomRadioSelect;
