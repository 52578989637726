import IMix from '@/interfaces/IMix';
import IMixDto from '@/interfaces/IMixDto';
import { adapterDtoToMixSection } from '@/adapters/MixSectionAdapter';
import ISelectionMix from '@/interfaces/ISelectionMix';
import IWarehouse from '@/interfaces/IWarehouse';
import {
  getProductInventory,
  getWarehouseProductConfigurationById,
} from '@/services/ProductService';
import IProductInventory from '@/interfaces/IProductInventory';
import IMixTopping from '@/interfaces/IMixTopping';
import getToppingPrice from '@/helpers/getToppingPrice';
import { min, cloneDeep } from 'lodash';
import IProductConfiguration from '@/interfaces/IProductConfiguration';
import { getWarehouseMixes } from '@/services/MixService';
import getMixProductsIds from '@/helpers/getMixProductsIds';
import { adapterDtoToBadge } from './BadgeAdapter';
import IMixRestrictions from '@/interfaces/IMixRestrictions';
import IWarehouseMixDto from '@/interfaces/IWarehouseMix';
import EVisibility from '@/enums/EVisibility';

export const adapterDtoToMix = (mix: IMixDto): IMix => {
  const {
    id,
    groupingCode,
    isActive,
    alias,
    nameEs,
    nameEn,
    descEs,
    descEn,
    slug,
    priority,
    price,
    mix_sections,
    mix_imgs,
    restrictions,
    badges,
  } = mix;

  return {
    id,
    key: 'mix',
    groupingCode,
    price,
    stepper: 1,
    name: {
      en: nameEn,
      es: nameEs,
    },
    desc: {
      en: descEn,
      es: descEs,
    },
    alias,
    slug,
    priority,
    isActive,
    imgs: mix_imgs,
    sections: mix_sections
      ? mix_sections.map((mixSection) => adapterDtoToMixSection(mixSection))
      : [],
    restrictions,
    getPrice: async (config) => await getPrice(mix, config?.selectionItem  as ISelectionMix, config?.warehouseId),
    getInventory: async (config) => await getInventory(config?.selectionItem as ISelectionMix, config?.warehouseId),
    getProductsConfig: async (config) => await getProductsConfig(config?.selectionItem as ISelectionMix, config?.warehouseId),
    getRestrictions: async (config) => await getMixRestrictions(config.mix, config?.warehouseId),
    isInWarehouse: async (config)=> getIsInWarehouse(id, config?.warehouseId),
    badges: badges?.length > 0 ? badges.map(badge => adapterDtoToBadge(badge)) : [],
  }
};

const getPrice = async (
  mix: IMixDto,
  selection?: ISelectionMix,
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try{
  let totalPrice: number = 0;
  const mixPrice = mix.price;
  const selectionData = selection;
  let toppingsConfig: (IMixTopping & { sectionId: string })[] = [];

  if (!selectionData) return mixPrice;

  const convertedMix = adapterDtoToMix(mix);

  convertedMix.sections.forEach((section) => {
    section.toppings.forEach((topping) => {
      toppingsConfig.push({
        ...topping,
        sectionId: section.id,
      });
    });
  });

  await Promise.all(
    selectionData.sections.map(async (section) => {
      await Promise.all(
        section.products.map(async (product) => {
          const matchingTopping = toppingsConfig.find(
            (topping) => (
              topping.product.id === product.productId
              && topping.sectionId === section.sectionId
            )
          );

          if (!matchingTopping) return 0;

          const toppingPrice = await getToppingPrice(
            matchingTopping,
            warehouseId
          );

          totalPrice += (toppingPrice * product.qty);
        })
      );
    })
  );

  return totalPrice + mixPrice;
} catch (e){
  return 0;
}
};

const getInventory = async (
  selection?: ISelectionMix,
  warehouseId?: IWarehouse['id']
): Promise<number> => {
  try {

    if (!selection || !warehouseId) return 0;

    const selectionData = selection;
    
    // get base products Ids
    const baseProductsIds = selectionData.baseProducts.map((baseProduct) => ({
      productId: baseProduct.productId,
      qty: baseProduct.qty,
    }));
    
    // get topping Ids
    let toppingsIds: IProductInventory[] = [];
    selectionData.sections.forEach((section) =>
      section.products.forEach((product) => {
        toppingsIds.push({ productId: product.productId, qty: product.qty });
      })
    );

    // unified array of products
    const totalProducts = cloneDeep(baseProductsIds);

    toppingsIds.forEach((topping) => {
      const existToppingInTotalProducts = totalProducts.some(
        (totalProduct) => totalProduct.productId === topping.productId
      );

      if (!existToppingInTotalProducts) {
        totalProducts.push(topping);
        return;
      } 

      const repeatedIndex = totalProducts.findIndex(
        (totalProduct) => totalProduct.productId === topping.productId
      );

      toppingsIds[repeatedIndex].qty += topping.qty;
    });

    // get inventory
    let productInventories: IProductInventory[] = await getProductInventory(
      totalProducts.map((product) => product.productId), 
      warehouseId
    );

    const possibleMixesInInventoryForEachProduct = totalProducts.map((totalProduct) => {
      const itemInv = productInventories.find((productInventory) => productInventory.productId === totalProduct.productId);

      const availablePacks = Math.trunc((itemInv?.qty ?? 0) / totalProduct.qty);
      return availablePacks;
    });

    const possibleMixesInInventory = min(possibleMixesInInventoryForEachProduct) ?? 0;

    return possibleMixesInInventory; 
  } catch (e) {
    return 0;
  }
};

const getProductsConfig = async (
  selection?: ISelectionMix,
  warehouseId?: IWarehouse['id']
): Promise<IProductConfiguration[]> => {
  try {
    if (!selection || !warehouseId) return [];

    const totalProducts = getMixProductsIds(selection);

    let configList: IProductConfiguration[] = [];

    await Promise.all(
      totalProducts.map(async (totalProduct) => {
        const config = await getWarehouseProductConfigurationById(totalProduct, warehouseId);
        configList.push(config);
      })
    );

    return configList;
} catch (e) {
  return [];
}
};

const getMixRestrictions = async (
  mix: IMix,
  warehouseId?: IWarehouse['id'],
): Promise<IMixRestrictions> => {
  if (warehouseId) {
    const warehouseMix: IWarehouseMixDto[] = await getWarehouseMixes({
      mixId: mix.id,
      warehouseId,
    });
  
    return {
      visibility: warehouseMix[0]?.restrictions.visibility || EVisibility.Hidden
    };
  }
  
  return { visibility: mix.restrictions.visibility || EVisibility.Always };
};

const getIsInWarehouse = async (
  mixId: IMix['id'],
  warehouseId?: IWarehouse['id']
) => {
  try{
    if (!warehouseId) return false;

    const data = await getWarehouseMixes({ mixId, warehouseId });

    return data.length > 0
  } catch(e){
    return false;
  }
};
